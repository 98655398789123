<template>
  <div class="wrapper_file_upload">
    <label
      class="file_upload"
      :class="{'dragging': dragging}"
      :style="`border-color: ${fileUploaderBorderColor}`"
      @mouseover="uploadSectionHover = true"
      @mouseleave="uploadSectionHover = false"
      @drop.prevent="drop"
      @dragover.prevent="dragover"
      @dragleave.prevent="dragleave"
      :disabled="disabled"
    >
      <div class="file_upload_background" :style="`background-color: ${fileUploaderBackgroundColor}`"></div>
      <div class="file_upload_inner">
        <input
          @click="onClick"
          @change="onFilePicked($event)"
          accept="image/*, application/pdf"
          class="inputfile"
          type="file"
          name="file"
          ref="file"
          tabindex="-1"
        />
        <div class="drag-drop position-relative w-100 mb-2">
          <p :style="descriptionStyle">{{description}}</p>
          <button
            class="drag-drop_btn"
            @click="onClick"
          >
            {{btnText}}
          </button>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "file-uploader",
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      dragging: false
    }
  },
  props: {
    fileUploaderBorderColor: {
      required: false,
      type: String,
      default: "#969696"
    },
    fileUploaderBackgroundColor: {
      required: false,
      type: String,
      default: "transparent"
    },
    description: {
      type: String,
      default: ''
    },
    descriptionStyle: {
      type: Object,
      default: function () {
        return {}
      }
    },
    btnText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions(["uploadFile"]),
    onFilePicked (event) {
      console.log('>>>>> on file picked', event);
      const files = event.target.files || event.dataTransfer.files;
      const maxfilesize = 1024 * 1024 * 16; // 1 Mb
      if (files && files[0]) {
        let filename = files[0].name;
        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }
        if (files[0].size > maxfilesize) {
          this.showModal(this.texts.fileSizeLimit);
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg", "tiff", "pdf"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          console.log('found length >>>>>', this.texts.wrongFormat);
          this.showModal(this.texts.wrongFormat);
          return;
        }
        this.uploadFile(files[0]);
        this.$emit('file-picked', files[0]);
        this.$refs.file.value = "";
      }
    },
    dragover () {
      this.dragging = true;
    },
    dragleave () {
      this.dragging = false;
    },
    drop (e) {
      this.dragging = false;
      this.onFilePicked(e);
    },
    onClick () {
      this.$refs.file.value = "";
      this.$refs.file.click();
    }
  }
}
</script>

<style lang="scss" scoped>
    .drag-drop {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            flex: 1;
            display: flex;
            align-items: center;
            opacity: 1;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 20px;
            line-height: 32px;
            font-weight: 300;
            text-align: center;
        }
        button {
            font-family: 'Montserrat', serif
        }
        &_btn {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: var(--brand-color);
            border: 1px solid var(--brand-color);
            border-radius: 40px;
            outline: none;
            background: #FFFFFF 0% 0% no-repeat padding-box;;
        }
    }
    .wrapper_file_upload {
        display: flex;
        flex-direction: column;
    }
    .file_upload {
        cursor: pointer;
        display: flex;
        flex: 1;
        align-items: stretch;
        justify-content: center;
        border: 3px dashed var(--brand-color) !important;
        border-radius: 20px;
        position: relative;
        margin: 0;
        padding: 4.5rem 0;
        &_inner {
            max-width: calc(100% - 9rem);
            display: flex;
        }
        &.dragging {
            border: 2px dashed var(--brand-color);
        }
        &:hover {
            & .file_upload_background {
                opacity: 0.2;
                border-radius: 20px;
                transition: 0.4s ease-in-out;
            }
        }
        & .file_upload_background {
            content: "";
            border-radius: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            z-index: -1;
            background-color: #009639;
            opacity: 0.06;
            transition: 0.4s;
        }
    }
    .file_upload input[type="file"] {
        display: none;
    }
    .hidden {
        display: none;
    }
    .inputfile {
        display: none;
    }
</style>
