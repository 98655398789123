<template>
  <button
    type="button"
    :class="[`btn ${fullWidth?'w-100':''}`, addClass ? addClass : '']"
    :style="cssString"
    :disabled="disabled || loading || (dependency !== 'full' ? hasSomeOfDependencies : hasUnresolvedDependencies)"
    @click="clicked"
  >
    <div
      class="buttonText"
      :style="textCssString"
      v-if="!loading"
    >
      {{ text }}
    </div>
    <div
      v-else
      class="spinner-border spinner-border-sm"
      :style="textCssString"
      role="status"
    >
      <span class="sr-only"></span>
    </div>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  data () {
    return {
      loading: false,
      cssString: "",
      textCssString: ""
    };
  },
  props: {
    requires: {
      type: Array
    },
    grid: {
      type: Object,
      required: false,
      default: () => {}
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#000"
    },
    borderColor: {
      type: String,
      required: false,
      default: "#000"
    },
    textColor: {
      required: false,
      type: String,
      default: "#fff"
    },
    text: {
      required: false,
      type: String,
      default: "Button"
    },
    borderRadius: {
      required: false,
      type: String,
      default: "0"
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    enabledOn:{
      required: false,
      type:String,
      default:''
    },
    fullWidth: {
      type: Boolean
    },
    action: {
      type: [Object, Array]
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    textStyles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    dependency: {
      type: String,
      default: 'full'
    },
    addClass: {
      type: String
    }
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx] + ";"));
        const txtkeys = Object.keys(this.textStyles);
        const txtvals = Object.values(this.textStyles);
        txtkeys.forEach((key, idx) => (this.textCssString += key + ":" + txtvals[idx] + ";"));
      }
    },
    enabledOn:{
      immediate:true,
      handler (val){
        if(!val || val ==''){
          return
        }
        return this.$store.state[val]
      }
    }
  },
  computed: {
    hasUnresolvedDependencies () {
      return this.$store.getters.hasUnresolvedDependencies(this.requires);
    },
    hasSomeOfDependencies () {
      return this.$store.getters.hasSomeOfDependencies(this.requires);
    }
  },
  methods: {
    async clicked () {
      if (!this.action) return;

      if(this.action.anchor){
        return window.location.href = this.action.link
      }
      if (this.action.length > 0) {
        // console.log("inside actions loop::");
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          await this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      await this[fn](params, this);
      // const params = this.action.params.toString();
      // eval(`this.${this.action.fn}(params)`);
    }
  },
  mounted() {
    const stickyBtn = document.querySelector('.stickOnScroll');

    if(stickyBtn) {
      window.onscroll = function (){
      const vh = window.innerHeight;
      if(document.body.scrollTop >= 127*vh/100 || document.documentElement.scrollTop >= 127*vh/100){
          stickyBtn.classList.add("fixedBtn");
      } 
      else{
          stickyBtn.classList.remove("fixedBtn");
      }
    }
}

  }
};
</script>

<style lang="scss" scoped>
.btn {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none;
  }
}

.btn {
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: black;
  border: 1px solid transparent;
  border-radius: 0;
  transition: all 0.3s;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 21px;
  padding: 9px 20px;

  +.btn {
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  &:hover,
  &:focus {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
  }
  &:active {
    border-color: #DEBA06;
  }
  &-min {
    @media (max-width: 574px) {
      font-size:  17px;
    }
  }
  &_disabled {
    opacity: 0.7;
  }
  &:disabled {
    background-color: #E3E3E3 !important;
    div {
      color: #6F6F6F;
    }
  }
  &-black {
    border-color: #424242;
    background-color: #424242;
    max-width: 350px;
    &:hover,
    &focus {
      background-color: #ffffff;
      color: #424242;
    }
  }
  &-transparent {
    max-width: 350px;
    border-color: #424242;
    color: #424242;
    &:hover,
    &focus {
      background-color: #424242;
      color: #ffffff;
    }
  }
}

.btn-123 {
  background: #5D5FEF !important;
  border-radius: 8px !important;
  .buttonText {
    color: #FFF !important;
  }
  &:focus,
  &:hover {
    background: #555AEA !important;
  }
  &:active {
    background: #5D5FEF !important;
  }
  &:disabled {
    background: #5D5FEF !important;
    opacity: .5;
  }
}


.backBtn {
  border: 1px solid #1C3A68;
  border-radius: 6px;
  opacity: 1;
  background: transparent;

  font: normal normal 500 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #1C3A68;
  opacity: 1;

  height: 50px;
}

.nextBtn {
  background: #1C3A68 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;

  font: normal normal 500 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #FBFBFB;
  opacity: 1;

  height: 50px;
}

.loginBtn {
  background: #3F81C8 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  letter-spacing: 0px;
  color: #FBFBFB;
  opacity: 1;

  height: 50px;
}

.guestBtn {
  background: transparent;
  border: 1px solid #3F81C8;
  opacity: 1;
  letter-spacing: 0px;
  color: #3F81C8;
  opacity: 1;

  border-radius: 6px;
  min-height: 50px;
}

@media (min-width: 990px) {
  .btnDesktopNone { display: none;}
  .btnDesktopOnly { display: block;}
}

@media (max-width: 991px) {
  .btn {font: normal normal 500 14px/18px Montserrat;}
  .nextBtn {margin: 10px 0px !important;}
  .backBtn {margin: 10px 0px !important;}


  .btnDesktopNone { display: block;}
  .btnDesktopOnly { display: none;}
}

.zIndex999 {
  z-index: 999;
}

.fixedBtn {
  position: fixed;
  top: 82%;
  display: block !important;
}
</style>
