<template>
  <carousel
    :navigation-enabled="true"
    :loop="true"
    :per-page="1"
    :per-page-custom="[[1441,4],[1150,3],[850,2],[600,1]]"
  >
    <slide
      class="listItem"
      v-for="(listItem, idx) in list"
      :key="idx"
    >
      <img
      class="productImg"
        :src="listItem.src"
        alt="listItem"
      >
      <h1>{{ listItem.name }}</h1>
      <a
        :href="listItem.inspect"
        target="_blank"
      >Ürünü İncele</a>
      <a
        :href="listItem.buy"
        target="_blank"
      >Hemen Satın Al</a>
    </slide>
  </carousel>
</template>

<script>

export default {
  name: "ProductSlider",
  props: {
    products: Array,
    endpoint: String,
    successAction: Object,
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    maxSelectable: {
      required: false,
      type: [String, Number],
      default: -1
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object
    },
    selected:{
      type:[Array,Object],
      default:()=>[]
    },
    fieldId: {
      type: String,
      required: true
    },
    list: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.listWrapper {
  display: flex;
  width: 110%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  // grid-column-gap: 0px;
  // grid-row-gap: 0px;
  align-items: center;
  justify-items: center;

  @media (max-width: 1325px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    // grid-row-gap: 40px;
    // grid-column-gap: 40px;
  }

  @media (max-width: 670px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    // grid-row-gap: 40px;
    // grid-column-gap: 40px;
  }
}

.productImg {
  height: auto;
  width: 311px;
}

.listItem {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  // box-shadow: 0px 4px 8px rgba(17, 17, 17, 0.25);
  // border-radius: 2px;
  // margin: 0px 5px;
  min-height: 504px;
  width: 25%;

  @media (max-width: 1325px) {
    width: 80%;
  }

  @media (max-width: 992px) {
    width: 110%;
  }

  @media (max-width: 670px) {
    width: 294px;
  }

  h1 {
    font-size: 14px;
    min-height: 45px;
    font-weight: bold;
    margin: 5px 0px;
    line-height: 1.2 !important;
    width: 95%;
    color: black;
    text-align: center;
  }

  a {
    display: block;
    border-radius: 26px;
    width: 260px;
    height: 50px;
    background: #1359B6;
    color: white;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #1359B6;

    &:first-of-type {
      background-color: white;
      color: #1359B6;
    }
  }
}
</style>
