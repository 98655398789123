<template>
  <ValidationObserver ref="form">
    <form v-if="!loading" class="row justify-content-center" :id="fieldId">
      <template v-if="state==='success'">
        <template v-for="field in fields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"

            v-bind:key="field.id"
          />
        </template>
      </template>
      <template v-else-if="state==='notFound'">
        <template v-for="field in notFoundFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"

            v-bind:key="field.id"
          />
        </template>
      </template>
      <template v-else-if="state==='expired'">
        <template v-for="field in expiredFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"

            v-bind:key="field.id"
          />
        </template>
      </template>
      <template v-else>
        <template v-for="field in failFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"

            v-bind:key="field.id"
          />
        </template>
      </template>
      <!-- <button @click="submit">SUBMIT</button> -->
    </form>
  </ValidationObserver>
</template>
<script>
import componentRenderer from "@/components/component-renderer";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "reclaim-bucket",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: Array,
    successAction: {
      type: Object,
      default: () => null
    },
    apiUrl: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    failFields: {
      type: Array,
      required: true
    },
    notFoundFields: {
      type: Array,
      required: true
    },
    expiredFields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object
    }
  },
  data: () => {
    return {
      state: "success",
      loading: true
    };
  },
  computed: {
    ...mapGetters(["campaignId"])
  },
  methods: {
    sendReclaimToken () {
      return new Promise((resolve, reject) => {
        ///send this.token to apiUrl
        const data = {
          campaignId: this.campaignId,
          receiptToken: this.token
        };
        axios.post(this.apiUrl, data).then(r => {
          this.loading = false;
          if (r.data) {
            resolve(r.data);
          }
        });
      });
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.token) {
      this.token = this.$route.query.token;
      this.sendReclaimToken().then(result => {
        if (result.response && !result.errorCode) {
          this.state = "success";
        } else if (!result.response && result.errorCode === 12) {
          this.state = "notFound";
        } else if (!result.response && result.errorCode === 60) {
          this.state = "expired";
        } else {
          this.state = "error";
        }
      });
    }
  }
};
</script>
