export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home Page
        "pageName": "dishekimlerio",
        "isPublic": true,
        "locale": "tr",
        "pageId": 402,
        "sections": [
          {
            "sectionId": "orbg8s",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "position": "relative"
            },
            "fields": [
              { // Refresh Token
                "fieldType": "refresh-token",
                "fieldId": "ij3433"
              },
              { // DataLayer
                fieldId: "dte-003",
                fieldType: "dataLayer-event",
                properties: {
                  eventName: "PageView",
                  fbEvent: "PageView"
                }
              },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "margin": "0 0 50px 0"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "/assets/dishekimlerio/iade-banner.png",
                  "tabletImageUrl": "/assets/dishekimlerio/iade-banner.png",
                  "mobileImageUrl": "/assets/dishekimlerio/iade-banner.png"
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "Kampanya 1 Aralık 2022 - 30 Haziran 2025 tarihleri arasında tüm mağazalardan yapılan Oral-B iO ve Pro3 Serisi ile Vitality Çocuk Şarjlı Fırçaları, Aquacare Ağız Duşu ve Oral-B Şarjlı Fırça Başlığı alışverişlerinde geçerlidir. Detaylı bilgi için <a href='/dishekimlerio/katilim-kosullari' target='_blank'><u>https://firsatyaninda.com/dishekimlerio/katilim-kosullari</u></a> sayfasını ziyaret edebilirsiniz.",
                  "classes": ["subHeading", "text-center"],
                  "styles": {
                    "color": "gray",
                    "textAlign": "left !important"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "40px"
                  }
                }
              },
              {
                fieldType: 'share-contact-info'
              },
              { // Step cards Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "Kampanya Adımları",
                  "classes": ["subHeading", "text-center"],
                  "styles": {
                    "color": "#303030",
                    "fontSize": "35px"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "40px"
                  }
                }
              },
              { // Steps Card
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "marginTop": "40px",
                    "marginBottom": "80px"
                  },
                  "className": "col-md-12 stepsCardMobileWithSubHeader",
                  "fields": [
                    {
                      "fieldType": "step-card",
                      "fieldId": "05m65j",
                      "properties": {
                        "stepNumber": "1",
                        "stepStyles": {
                          "background": "rgb(230, 245, 235)",
                          "color": "#009639",
                          "fontFamily": "Jost"
                        },
                        "imageSource": "/assets/steps/dishekimlerio/1.svg",
                        // "arrowImageSource": "/assets/steps/dishekimlerio/arrow.svg",
                        "showArrow": false,
                        "grid": {
                          "columns": 3
                        },
                        "fields": [
                          {
                            "fieldType": "paragraph",
                            "fieldId": "psv9ff",
                            "properties": {
                              "color": "var(--dishekimlerio-color)",
                              "text": "1.Adım: Diş Fırçanı Seç",
                              "classes": ["stepCard"],
                              "styles": {
                                "marginTop": "15px"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "step-card",
                      "fieldId": "ec7jsf",
                      "properties": {
                        "stepNumber": "2",
                        "stepStyles": {
                          "background": "rgb(230, 245, 235)",
                          "color": "#009639",
                          "fontFamily": "Jost"
                        },
                        "imageSource": "/assets/steps/dishekimlerio/2.svg",
                        // "arrowImageSource": "/assets/steps/dishekimlerio/arrow.svg",
                        "showArrow": false,
                        "grid": {
                          "columns": 3
                        },
                        "fields": [
                          {
                            "fieldType": "paragraph",
                            "fieldId": "6sjsfj",
                            "properties": {
                              "color": "var(--dishekimlerio-color)",
                              "text": "2.Adım: Fişini Yükle",
                              "classes": ["stepCard"],
                              "styles": {
                                "marginTop": "15px"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "step-card",
                      "fieldId": "16pk71",
                      "properties": {
                        "stepNumber": "3",
                        "stepStyles": {
                          "background": "rgb(230, 245, 235)",
                          "color": "#009639",
                          "fontFamily": "Jost"
                        },
                        "imageSource": "/assets/steps/dishekimlerio/3.svg",
                        // "arrowImageSource": "/assets/steps/dishekimlerio/arrow.svg",
                        "showArrow": false,
                        "grid": {
                          "columns": 3
                        },
                        "fields": [
                          {
                            "fieldType": "paragraph",
                            "fieldId": "qo8x4a",
                            "properties": {
                              "color": "var(--dishekimlerio-color)",
                              "text": "3.Adım: Kayıt Ol",
                              "classes": ["stepCard"],
                              "styles": {
                                "marginTop": "15px"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "step-card",
                      "fieldId": "16pk712",
                      "properties": {
                        "stepNumber": "4",
                        "stepStyles": {
                          "background": "rgb(230, 245, 235)",
                          "color": "#009639",
                          "fontFamily": "Jost"
                        },
                        "imageSource": "/assets/steps/dishekimlerio/4.svg",
                        // "arrowImageSource": "/assets/steps/dishekimlerio/arrow.svg",
                        "showArrow": false,
                        "grid": {
                          "columns": 3
                        },
                        "fields": [
                          {
                            "fieldType": "paragraph",
                            "fieldId": "qo8x4a",
                            "properties": {
                              "color": "var(--dishekimlerio-color)",
                              "text": "4.Adım: Geri İadeni İste",
                              "classes": ["stepCard"],
                              "styles": {
                                "marginTop": "15px"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              { // Buttons
                "fieldType": "button-component",
                "fieldId": "x1p9hx",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "40px"
                  },
                  "styles": {
                    "background-color": "#1359B6",
                    "borderRadius": "56px",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "padding": "10px",
                    "fontWeight": "bold",
                    "width": "317px"
                  },
                  "text": "Kampanyaya Katıl",
                  "disabled": false,
                  "addClass": "nextBtn col-11 zIndex999",
                  "action": [
                    {
                      "fn": "goToPage",
                      "params": "dishekimlerio/katilimci-tipi"
                    }
                  ]
                }
              },
              { // Buttons
                "fieldType": "button-component",
                "fieldId": "x1p9hx",
                "properties": {
                  "grid": {
                    "columns": "3",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "40px"
                  },
                  "styles": {
                    "background-color": "#1359B6",
                    "borderRadius": "56px",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "padding": "10px",
                    "fontWeight": "bold",
                    "width": "317px"
                  },
                  "text": "Kampanyaya Katıl",
                  "disabled": false,
                  "addClass": "nextBtn col-11 zIndex999 stickOnScroll d-none",
                  "action": [
                    {
                      "fn": "goToPage",
                      "params": "dishekimlerio/katilimci-tipi"
                    }
                  ]
                }
              },
              { // products slider
                "fieldType": "product-slider",
                "fieldId": "vb309g",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    marginBottom: "80px"
                  },
                  list: [
                    {
                      name: "Oral-B Aquacare Oxyjet Ağız Duşu ve iO Serisi Diş Fırçası",
                      src: "/assets/dishekimlerio/products/product-1.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urun-grubu/oral-b-aquacare-agiz-dusu",
                      buy: "https://www.cimri.com/arama?q=oral-b aquacare oxyjet ağız duşu"
                    },
                    {
                      name: "Oral-B iO Serisi Şarjlı Fırça ve Yedek Başlık",
                      src: "/assets/dishekimlerio/products/product-12.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/sarjli-dis-fircalari/oral-b-io-9-serisi#color=siyah",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20iO%209%20%C5%9Earjl%C4%B1%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20-%20Siyah"
                    },
                    {
                      name: "Oral-B iO 10 Şarjlı Diş Fırçası – Siyah",
                      src: "/assets/dishekimlerio/products/product-7.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/sarjli-dis-fircalari/oral-b-io-10-serisi#color=cosmic%20black",
                      buy: "https://www.cimri.com/arama?q=oral-b%20io10"
                    },
                    {
                      name: "Oral-B iO 9 Şarjlı Diş Fırçası – Siyah",
                      src: "/assets/dishekimlerio/products/product-2.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/sarjli-dis-fircalari/oral-b-io-9-serisi#color=siyah",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20iO%209%20%C5%9Earjl%C4%B1%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20-%20Siyah"
                    },
                    {
                      name: "Oral-B iO 8 Şarjlı Diş Fırçası - Beyaz",
                      src: "/assets/dishekimlerio/products/product-3.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/sarjli-dis-fircalari/oral-b-io-8-serisi#color=siyah",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20iO%208%20%C5%9Earjl%C4%B1%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20-%20Beyaz"
                    },
                    {
                      name: "Oral-B iO 7 Şarjlı Diş Fırçası – Siyah",
                      src: "/assets/dishekimlerio/products/product-8.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/sarjli-dis-fircalari/oral-b-io-7-serisi#color=beyaz",
                      buy: "https://www.cimri.com/arama?q=oral-b%20io7"
                    },
                    {
                      name: "Oral-B iO 6 Şarjlı Diş Fırçası – Beyaz",
                      src: "/assets/dishekimlerio/products/product-9.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/sarjli-dis-fircalari/oral-b-io-6-serisi",
                      buy: "https://www.cimri.com/arama?q=oral-b%20io6"
                    },
                    {
                      name: "Oral-B iO 5 Şarjlı Diş Fırçası – Siyah",
                      src: "/assets/dishekimlerio/products/product-10.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/sarjli-dis-fircalari/oral-b-io-5-serisi#color=matte-black",
                      buy: "https://www.cimri.com/arama?q=oral-b%20io5"
                    },
                    {
                      name: "Oral-B iO 4 Şarjlı Diş Fırçası - Eflatun",
                      src: "/assets/dishekimlerio/products/product-4.png",
                      inspect: "https://www.braunshop.com.tr/oral-b-io-4-sarjli-dis-fircasi-eflatun",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20iO%204%20%C5%9Earjl%C4%B1%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20-%20Eflatun"
                    },
                    {
                      name: "Oral-B iO 3 Şarjlı Diş Fırçası - Siyah",
                      src: "/assets/dishekimlerio/products/product-5.png",
                      inspect: "https://www.braunshop.com.tr/oral-b-io-3-sarjli-dis-fircasi-siyah",
                      buy: "https://www.cimri.com/arama?q=oral-b%20io3"
                    },
                    {
                      name: "Oral-B PRO3 Şarjlı Diş Fırçası - Siyah",
                      src: "/assets/dishekimlerio/products/product-6.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urun-grubu/sarjli-dis-fircalari//pro",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20%20PRO3%20%C5%9Earjl%C4%B1%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20-%20Siyah"
                    },
                    {
                      name: "Oral-B D100 Vitality Çocuklar İçin Şarj Edilebilir Diş Fırçası",
                      src: "/assets/dishekimlerio/products/product-11.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/sarjli-dis-fircalari/oral-b-kids-arabalar-cocuk-sarjli-dis-fircasi",
                      buy: "https://www.cimri.com/arama?q=oral-b%20d100%20vitality%20%C3%A7ocuk"
                    }
                  ]
                }
              },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "margin": "0 0 50px 0"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "/assets/dishekimlerio/karsilastirma-banner.jpg",
                  "tabletImageUrl": "/assets/dishekimlerio/karsilastirma-banner.jpg",
                  "mobileImageUrl": "/assets/dishekimlerio/karsilastirma-banner.jpg"
                }
              },
              { // choose Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "Size En Uygun Fırça Başlığını Seçin",
                  "classes": ["subHeading", "text-center"],
                  "styles": {
                    "color": "#303030",
                    "fontSize": "25px",
                    "fontWeight": "bold"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "40px"
                  }
                }
              },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "margin": "0 0 50px 0"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "/assets/dishekimlerio/firca-banner.jpg",
                  "tabletImageUrl": "/assets/dishekimlerio/firca-banner.jpg",
                  "mobileImageUrl": "/assets/dishekimlerio/firca-banner.jpg"
                }
              },
              { // products
                "fieldType": "product-slider",
                "fieldId": "vb309g",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    marginBottom: "100px"
                  },
                  list: [
                    {
                      name: "Oral-B iO Radiant White Beyaz Diş Fırçası Yedek Başlığı 4 Adet",
                      src: "/assets/dishekimlerio/showcase/showcase-1.png",
                      inspect: "https://www.braunshop.com.tr/oral-b-io-radiant-white-beyaz-dis-fircasi-yedek-basligi-4-adet",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20iO%20Radiant%20White%20Beyaz%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20Yedek%20Ba%C5%9Fl%C4%B1%C4%9F%C4%B1%204%20Adet"
                    },
                    {
                      name: "Oral-B iO Ultimate Clean Siyah Diş Fırçası Yedek Başlığı 4 Adet",
                      src: "/assets/dishekimlerio/showcase/showcase-2.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/yedek-firca-basliklari/io-ultimate-clean#color=siyah",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20iO%20Ultimate%20Clean%20%20Siyah%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20Yedek%20Ba%C5%9Fl%C4%B1%C4%9F%C4%B1%204%20Adet"
                    },
                    {
                      name: "Oral-B iO Gentle Care Beyaz Diş Fırçası Yedek Başlığı 2 Adet",
                      src: "/assets/dishekimlerio/showcase/showcase-3.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/yedek-firca-basliklari/io-gentle-care#color=beyaz",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20iO%20Gentle%20Care%20Beyaz%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20Yedek%20Ba%C5%9Fl%C4%B1%C4%9F%C4%B1%202%20Adet"
                    },
                    {
                      name: "Oral-B iO Specialised Clean Beyaz Diş Fırçası Yedek Başlığı 2 Adet",
                      src: "/assets/dishekimlerio/showcase/showcase-4.png",
                      inspect: "https://www.braunshop.com.tr/oral-b-io-radiant-white-beyaz-dis-fircasi-yedek-basligi-4-adet",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20iO%20Specialised%20Clean%20Beyaz%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20Yedek%20Ba%C5%9Fl%C4%B1%C4%9F%C4%B1%202%20Adet"
                    },
                    {
                      name: "Oral-B Vitality Kids Çocuk Fırça Başlıkları – 2 Adet",
                      src: "/assets/dishekimlerio/showcase/showcase-5.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/yedek-firca-basliklari/stages-power-kids-disney",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20Spiderman%20%C3%87ocuklar%20%C4%B0%C3%A7in%202%27li%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20Yedek%20Ba%C5%9Fl%C4%B1%C4%9F%C4%B1"
                    },
                    {
                      name: "Oral-B 3D White Beyaz Diş Fırçası Yedek Başlığı – 4 Adet",
                      src: "/assets/dishekimlerio/showcase/showcase-6.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/yedek-firca-basliklari/yedek-firca-basliklari-3d-white",
                      buy: "https://www.cimri.com/arama?q=Oral-B%203D%20White%204%27l%C3%BC%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20Yedek%20Ba%C5%9Fl%C4%B1%C4%9F%C4%B1"
                    },
                    {
                      name: "Oral-B Cross Action Clean Maximizer Siyah  Diş Fırçası Yedek Başlığı – 4 Adet",
                      src: "/assets/dishekimlerio/showcase/showcase-7.png",
                      inspect: "https://www.oralb.com.tr/tr-tr/urunler/yedek-firca-basliklari/crossaction",
                      buy: "https://www.cimri.com/arama?q=Oral-B%20CrossAction%20Elektrikli%20Di%C5%9F%20F%C4%B1r%C3%A7as%C4%B1%20%C4%B0%C3%A7in%20Yedek%20Ba%C5%9Fl%C4%B1klar"
                    }
                  ]
                }
              },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "margin": "50px 0 50px 0"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "/assets/dishekimlerio/baslik-karsilastirma.jpg",
                  "tabletImageUrl": "/assets/dishekimlerio/baslik-karsilastirma.jpg",
                  "mobileImageUrl": "/assets/dishekimlerio/baslik-karsilastirma.jpg"
                }
              },
              { // 
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "Komple Bir Temizlik için Oral-B Diş Macunlarını Sepetinize Eklemeyi Unutmayın",
                  "classes": ["subHeading", "text-center"],
                  "styles": {
                    "color": "#303030",
                    "fontSize": "25px",
                    "fontWeight": "bold"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "40px"
                  }
                }
              },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "margin": "0 0 50px 0"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "/assets/dishekimlerio/kampanya-detay-banner.png",
                  "tabletImageUrl": "/assets/dishekimlerio/kampanya-detay-banner.png",
                  "mobileImageUrl": "/assets/dishekimlerio/kampanya-detay-banner.png"
                }
              },
              { // kampanya detayları
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "Kampanya Detayları",
                  "classes": ["subHeading", "text-center"],
                  "styles": {
                    "color": "#303030",
                    "fontSize": "25px",
                    "fontWeight": "bold"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "40px"
                  }
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": `<ol>
                  <li>Bu kampanya diş hekiminden geçerli bir kod alan herkes için, Türkiye’de bulunan online ve fiziksel bütün satış noktalarından alınan geçerli ürünler için aktiftir.</li>
                  <br>
                  <li>Tek bir kod ile kampanyaya katılım 10 adet geçerli ürün ile sınırlandırılmıştır.</li>
                  <br>
                  <li>Tek bir kod ile para iadesinden yararlanabilmek için para iadesi alınacak tüm ürünler aynı fişte olmalıdır.</li>
                  <br>
                  <li>Diş hekiminden alınan farklı ve geçerli kodlarla olmak üzere bir kişi aynı TC no ve IBAN ile kampanyaya sınırsız sayıda katılım yapabilir.</li>
                  <br>
                  <li>Şu ürünler için fiş tutarı üzerinden %15 Para İadesi Uygulanır; Oral-B Aquacare Oxyjet Ağız Duşu, Oral-B D100 Vitality Şarjlı Çocuk Fırçaları, Oral-B PRO3 ve iO 4-5-6-7 Serisi Şarjlı Diş Fırçaları. Bu ürünlerin 2’li paketleri için de para iadesi toplam fiş tutarı üzerinden %15 olacak şekilde yapılacaktır.</li>
                  <br>
                  <li>Şu ürünler için %15 Para İadesi Uygulanır; Oral-B iO serisi şarjlı diş fırçaları.</li>
                  <br>
                  <li>Şu ürünlerin birlikte satın alındığı durumda %25 para iadesi uygulanır; Aquacare Oxyjet Ağız Duşu ve Oral-B iO Serisi Şarjlı Diş Fırçası.</li>
                  <br>
                  <li>Şu ürünlerin birlikte satın alındığı durumda %20 para iadesi uygulanır; Oral-B iO serisi şarjlı fırça ve şarjlı fırça başlığı.</li>
                  <br>
                  <li>Şu ürünler için fiş tutarı üzerinden %20 Para İadesi Uygulanır; Bütün Oral-B Şarjlı Diş Fırçası Başlıkları.</li>
                  <br>
                  <li>Daha detaylı bilgi için Katılım Koşulları sayfasını ziyaret ediniz.</li>
                  <br>
                  <li>Kampanyayla ilgili her türlü soru için bu adrese e-mail atabilirsiniz: <a style="color: #1359B6;" href="mailto: destek@dishekimlerio.firsatyaninda.com">destek@dishekimlerio.firsatyaninda.com</a></li>
                  </ol>`,
                  "classes": ["subHeading", "text-left"],
                  "styles": {
                    "color": "gray",
                    "fontSize": "14px"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "60px"
                  }
                }
              },
            ]
          }
        ]
      },
      { // Participant Type page
        pageName: 'dishekimlerio/katilimci-tipi',
        locale: 'tr',
        pageId: 123456,
        requires: [1],
        sections: [
          { // Form
            sectionId: 'mfh0h1',
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              { // Steps
                fieldType: 'step-counter',
                fieldId: 'tj4j49',
                properties: {
                  activeIndex: 1,
                  colorSchema: 'var(--orkid-color)',
                  campaignName: 'altin',
                  steps: [
                    {
                      title: 'Seçili P&G ürününü <b>SATIN AL</b>',
                      page: 'ürün-ekle'
                    },
                    {
                      title: 'Fişini/faturanı <b>YÜKLE</b>',
                      page: 'fis-yukleme'
                    },
                    {
                      title: 'Para iadesi <b>KAZAN</b>',
                      page: 'banka-bilgileri'
                    }
                  ]
                }
              },
              {
                fieldType: 'empty-bucket',
                fieldId: 'fw90342ol',
                properties: {
                  grid: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    columns: 12,
                    marginBottom: '50px'
                  },
                  fields: [
                    {
                      fieldType: 'participant-type2',
                      fieldId: 'participantType',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '50px'
                        },
                        backUrl: 'dishekimlerio',
                        endpoint: '/userType',
                        participants: [
                          {
                            type: 'dentist',
                            title: `Diş Hekimiyim`,
                            campaignIdToSet: 'oralb-dishekimleri-dt',
                            backgroundImage: '/assets/dishekimlerio/dentist.svg',
                            action: {
                              fn: 'goToPage',
                              params: 'dishekimlerio/kod'
                            }
                          },
                          {
                            type: 'individual',
                            title: `Tüketiciyim`,
                            campaignIdToSet: 'oralb-dishekimleri-io',
                            backgroundImage: '/assets/dishekimlerio/consumer.svg',
                            action: {
                              fn: 'goToPage',
                              params: 'dishekimlerio/kod'
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Unique Code page
        "pageName": "dishekimlerio/kod",
        "locale": "tr",
        "pageId": 4071,
        "requires": [
          1
        ],
        "sections": [
          { // Form
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "tj4j49",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px"
                  },
                  "activeIndex": 1,
                  "colorSchema": "var(--dishekimlerio-color)",
                  "campaignName": "dishekimlerio",
                  "steps": [
                    {
                      "title": "Geçerli Oral-B ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Diş Hekiminden aldığın kod ile <b>GİRİŞ YAP</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%25’e kadar para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "fw90342ol",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": 12,
                    "marginBottom": "50px"
                  },
                  "fields": [
                    {
                      "fieldType": "code-component",
                      "fieldId": "kod",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "successAction": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/fis-yukleme"
                        },
                        "endpoint": "/checkCode",
                        barcodeImg: '/assets/dishekimlerio/barcode.jpg'
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Uploader
        "pageName": "dishekimlerio/fis-yukleme",
        "locale": "tr",
        "pageId": 403,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "tj4j49",
                "properties": {
                  "activeIndex": 2,
                  "colorSchema": "var(--dishekimlerio-color)",
                  "campaignName": "dishekimlerio",
                  "steps": [
                    {
                      "title": "Geçerli Oral-B ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Diş Hekiminden aldığın kod ile <b>GİRİŞ YAP</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%25’e kadar para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // Header
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "Lütfen fişinizi/faturanızı yükleyiniz",
                  "classes": ["heading", "text-center"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "Bu adımda, Oral-B alışverişinizi içeren fiş ya da faturanızı yükleyiniz.",
                  "classes": ["subHeading", "text-center"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              }
            ]
          },
          { // Receipt Info and Receipt Upload
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": 12
                  },
                  "styles": {
                    "justifyContent": "center !important"
                  },
                  "fields": [
                    { // Receipt Info Desktop
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fa",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-9 pb-4 receiptInfo desktopOnly",
                        "fields": [
                          {
                            "fieldType": "receipt-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                              "steps": [
                                {
                                  "text": "Satış noktası",
                                  "label": "A"
                                },
                                {
                                  "text": "Satın alma tarihi ve saati",
                                  "label": "B"
                                },
                                {
                                  "text": "Ürünler ve satın alma fiyatları",
                                  "label": "C"
                                },
                                {
                                  "text": "Toplam fiş/fatura ve KDV tutarı",
                                  "label": "D"
                                },
                                {
                                  "text": "Fiş/Fatura Numarası",
                                  "label": "E"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Receipt Processor
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fb",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-9 pb-4 receiptUpload",
                        "fields": [
                          {
                            "fieldType": "receipt-processor",
                            "fieldId": "klvakj",
                            "properties": {
                              texts: {
                                choose: 'Bir Dosya Seçin',
                                receiptUpload1: 'Fişinizi/Faturanızı yüklemek için sürükleyip bırakınız.',
                                receiptUpload2: 'Yalnızca JPEG, PNG ve PDF desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                                receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                                wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, .png, .pdf ve .tiff dosyaları kabul edilir.',
                                fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                              },
                              "subCampaignId": "oralb-dishekimleri-io",
                              "colorSchema": "var(--dishekimlerio-color)",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "addClass": "p-0",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "dishekimlerio/banka-bilgileri"
                              },
                              "phrases": [
                                "Ihr Beleg wird jetzt hochgeladen und bearbeitet.",
                                "Der Beleg wird automatisch bearbeitet. Dies kann eine Minute dauern. Bitte haben Sie Geduld.",
                                "Sollten Sie eine Fehlermeldung erhalten, nehmen Sie bitte eine bessere Version des Belegs auf und laden Sie ihn erneut hoch."
                              ],
                              "cornerDetectionPhrases": [
                                "Lütfen gri dikdörtgeni fiş ile eşleşecek şekilde ayarlayın."
                              ],
                              "eraseColor": "#ffffff",
                              "eraseSize": 12,
                              "cropColor": "#3A96F3",
                              "cropBackground": "rgba(0,0,0,0.6)",
                              "cropTitle": "Kes",
                              "eraseTitle": "Düzenle",
                              "dragTitle": "Hareket ettir",
                              "zoomInTitle": "Yakınlaştır",
                              "zoomOutTitle": "Uzaklaştır",
                              "selectImageTitle": "Select an Image",
                              "selectAnotherTitle": "Select Another",
                              "controlsBackground": "#4193d3",
                              "backgroundColorBtn": "var(--dishekimlerio-color)"
                            }
                          }
                        ]
                      }
                    },
                    { // Receipt Info Desktop
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fc",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-9 pb-4 receiptInfo desktopNone",
                        "fields": [
                          {
                            "fieldType": "receipt-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                              "steps": [
                                {
                                  "text": "Satış noktası",
                                  "label": "A"
                                },
                                {
                                  "text": "Satın alma tarihi ve saati",
                                  "label": "B"
                                },
                                {
                                  "text": "Ürünler ve satın alma fiyatları",
                                  "label": "C"
                                },
                                {
                                  "text": "Toplam fiş/fatura ve KDV tutarı",
                                  "label": "D"
                                },
                                {
                                  "text": "Fiş/Fatura Numarası",
                                  "label": "E"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "marginBottom": "35px",
              "marginTop": "40px",
              justifyContent: "center"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "vdf932fa",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  className: "btn-bucket",
                  "fields": [
                    { // back Button
                      "fieldType": "button-component",
                      "fieldId": "16tzm2",
                      "properties": {
                        "grid": {
                          "columns": "4"
                        },
                        "text": "Geri",
                        "addClass": "col-sm-4 col-xl-3 backBtn",
                        "action": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/kod"
                        }
                      }
                    },
                    { // Next button
                      "fieldType": "button-component",
                      "fieldId": "cwer23452q",
                      "properties": {
                        "grid": {
                          "columns": "4"
                        },
                        "addClass": "col-sm-8 col-xl-3 nextBtn",
                        "text": "İleri",
                        "styles": {
                          "backgroundColor": "var(--dishekimlerio-color)"
                        },
                        "shouldCheckCrop": true,
                        "requires": ["klvakj"],
                        "action": {
                          "fn": "sendReceipt",
                          "params": ""
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Bank Info
        "pageName": "dishekimlerio/banka-bilgileri",
        "locale": "tr",
        "pageId": 409,
        "requires": [
          1
        ],
        "sections": [
          { // Steps and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "wabb03",
                "properties": {
                  "activeIndex": 3,
                  "colorSchema": "var(--dishekimlerio-color)",
                  "campaignName": "dishekimlerio",
                  "steps": [
                    {
                      "title": "Geçerli Oral-B ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Diş Hekiminden aldığın kod ile <b>GİRİŞ YAP</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%25’e kadar para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Banka hesap bilgilerinizi giriniz",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ["heading", 'text-center']
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "Lütfen geri ödeme tutarının aktarılacağı hesabınızın bilgilerini giriniz.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ["subHeading", 'text-center']
                }
              }
            ]
          },
          { // Form
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "bank1Nf0",
                "properties": {
                  "successAction": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/uye-ol"
                  },
                  "endpoint": "/submitIBAN",
                  "keepInState": ["referenceNumber"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "fields": [
                    { // form
                      "fieldType": "empty-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "paddingTop": "30px",
                          "paddingBottom": "30px"
                        },
                        "className": "col-md-6 col-xl-4 formLayout",
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": "2"
                              },
                              "className": "ibanLabelWrapper col-2",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "tw3qab",
                                  "properties": {
                                    "grid": {
                                      "columns": "12"
                                    },
                                    "text": "TR",
                                    "classes": ["ibanLabel"]
                                  }
                                }
                              ]
                            }
                          },
                          { // IBAN
                            "fieldId": "h9arlr",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "iban",
                              "id": "iban",
                              "validation": "required|iban",
                              "label": "IBAN*",
                              "placeholder": "",
                              "text": "",
                              "className": "col-10",
                              "grid": {
                                "columns": "10",
                                "paddingLeft": "5px !important"
                              }
                            }
                          },
                          { // trNumber
                            "fieldId": "7rNumb2r",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "tckn",
                              "id": "tckn",
                              "label": "T.C. Kimlik Numarası*",
                              "validation": "required|identityCard",
                              "placeholder": "",
                              "text": "",
                              maxlength: 11,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // trMobile
                            "fieldId": "7rMob2le",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "phoneNumber",
                              "id": "phoneNumber",
                              "label": "Telefon Numarası*",
                              "validation": "required|phoneNumber",
                              "placeholder": "",
                              "text": "",
                              maxlength: 10,
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // First Name
                            "fieldId": "tp6zfb",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "firstname",
                              "id": "firstname",
                              "label": "Hesap Sahibi Adı*",
                              "validation": "required|ibanName",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // Last Name
                            "fieldId": "p55fao",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "lastname",
                              "id": "lastname",
                              "validation": "required|ibanName",
                              "label": "Hesap Sahibi Soyadı*",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12,
              "alignItems": "center"
            },
            buttonsWrap: true,
            "fields": [
              { // back Button
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px",
                    "marginBottom": "100px"
                  },
                  "text": "Geri",
                  "addClass": "col-sm-4 col-xl-3 backBtn",
                  "action": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/fis-yukleme"
                  }
                }
              },
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px",
                    "marginBottom": "100px"
                  },
                  "addClass": "col-sm-4 col-xl-3 nextBtn",
                  "text": "İleri",
                  "styles": {
                    "backgroundColor": "var(--dishekimlerio-color)"
                  },
                  "disabled": false,
                  "action": {
                    "fn": "sendForm",
                    "params": "bank1Nf0"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Registration
        "pageName": "dishekimlerio/uye-ol",
        "locale": "tr",
        "pageId": 404,
        "sections": [
          { // Steps, Image and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12,
              "paddingBottom": "20px"
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "6gx3um",
                "properties": {
                  "activeIndex": 3,
                  "colorSchema": "var(--dishekimlerio-color)",
                  "campaignName": "dishekimlerio",
                  "steps": [
                    {
                      "title": "Geçerli Oral-B ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Diş Hekiminden aldığın kod ile <b>GİRİŞ YAP</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%25’e kadar para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // Image
                "fieldId": "vn0934kf",
                "fieldType": "square-image",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "marginTop": "81px",
                    "marginBottom": "20px",
                    "padding": "0",
                    "maxHeight": "50px",
                    "maxWidth": "125px"
                  },
                  "className": "imgMobile",
                  "imageSource": "/assets/logo/reg_logo@1x.png",
                  "imageSource2": "/assets/logo/reg_logo@2x.png"
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "fckzg7",
                "properties": {
                  "text": "Fırsatlardan haberdar olmak için kayıt olunuz",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginTop": "10px"
                  },
                  "classes": ["heading", 'text-center']
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "ch2fio",
                "properties": {
                  "text": `"Kadınlar Bilir", P&G'nin bir pazarlama platformudur. Bu platform içerisindeki diğer kampanyalar hakkında bilgi almak için şimdi kayıt olun ve fırsatları kaçırmayın.`,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ["subHeading", 'text-center']
                }
              }
            ]
          },
          { // Forms
            "sectionId": "ci88zt",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 4
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "inzf4w",
                "properties": {
                  "styles": {
                    "margin": "0",
                    "alignItems": "center",
                    "flexDirection": "column"
                  },
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "requires": [
                    "1"
                  ],
                  "successAction": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/sms-sayfasi"
                  },
                  "endpoint": "/register",
                  "fields": [
                    { // Inputs
                      "fieldType": "empty-bucket",
                      "fieldId": "4h3gweb",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          { // Gender
                            "fieldType": "paragraph",
                            "fieldId": "yh54weg",
                            "properties": {
                              "text": "Cinsiyet *",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              classes: ["formTitle"]
                            }
                          },
                          { // Gender Radio Input
                            "fieldType": "radio-input",
                            "fieldId": "Geschlecht",
                            "name": "Cinsiyet*",
                            "properties": {
                              "text": "",
                              "name": "gender",
                              "colorSchema": "var(--dishekimlerio-color)",
                              "grid": {
                                "columns": 12
                              },
                              "validation": "required",
                              "direction": "row",
                              "options": [
                                {
                                  "value": "female",
                                  "text": "Kadın",
                                  "disabled": false,
                                  "name": "firstRadio"
                                },
                                {
                                  "value": "male",
                                  "text": "Erkek",
                                  "disabled": false,
                                  "name": "secondRadio"
                                }
                              ]
                            }
                          },
                          { // First Name
                            "fieldId": "b4baqs",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "firstName",
                              "id": "firstName",
                              "label": "İsim*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|alpha",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              }
                            }
                          },
                          { // Last Name
                            "fieldId": "gofije",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "lastName",
                              "id": "lastName",
                              "label": "Soyisim*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|alpha",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // Birth day
                            "fieldId": "godate",
                            "fieldType": "date-input",
                            "properties": {
                              "name": "birthdate",
                              "id": "birthdate",
                              "label": "Doğum tarihi",
                              "validation": "birthdate",
                              "grid": {
                                "columns": "12"
                              },
                              "params": {
                                "disabledDates": {
                                  "from": "18years",
                                  "to": "01.01.1800"
                                }
                              }
                            }
                          },
                          { // Email
                            "fieldId": "h7kzf9",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "email",
                              "id": "email",
                              "label": "E-posta adresi*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|email",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // Password
                            "fieldId": "sgol2x",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "password",
                              "id": "password",
                              "label": "Parola*",
                              "inputType": "password",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|password",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Check Boxes
                      "fieldId": "8k2f023f",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          justifyContent: "center"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          // { // Sub Heading
                          //   "fieldType": "paragraph",
                          //   "fieldId": "ch2fio",
                          //   "properties": {
                          //     "text": `<a href="assets/dishekimlerio/files/aydinlatma-metni.docx" download><u><b>İnternet Sitesi / Mobil Uygulama Kullanıcısı Aydınlatma Metni</b></u></a> kapsamında; kişisel verilerinizin Procter & Gamble Tüketim Malları Sanayi Anonim Şirketi (P&G) tarafından aşağıdaki amaçlarla işlenmesini kabul ediyor musunuz?`,
                          //     "grid": {
                          //       "alignItems": "center",
                          //       "justifyContent": "center",
                          //       "columns": "12"
                          //     },
                          //     styles: {
                          //       marginLeft: "50px",
                          //       marginTop: "30px"
                          //     }
                          //   }
                          // },
                          { // Terms Checkbox
                            "fieldType": "checkbox",
                            "fieldId": "wgc3j71234",
                            "properties": {
                              "name": "terms",
                              "id": "terms",
                              "validation": {
                                "required": {
                                  "allowFalse": false
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "label": `<a href="https://termsandconditions.pg.com/tr-tr/" target="_blank"><b><u>Kullanım Koşulları</u></b></a>'nı okudum ve kabul ediyorum.`
                            }
                          },
                          { // Check box Age Consent
                            "fieldType": "paragraph",
                            "fieldId": "wgc3j7",
                            "properties": {
                              "grid": {
                                "columns": "12",
                                "marginTop": "10px"
                              },
                              "styles": {
                                paddingLeft: "63px"
                              },
                              "text": `<a href="/pg-aydinlatma-metni" target="_blank"><b><u>İnternet Sitesi / Mobil Uygulama Kullanıcısı Aydınlatma Metni</u></b></a> kapsamında; kişisel verilerinizin Procter & Gamble Tüketim Malları Sanayi Anonim Şirketi (P&G) tarafından aşağıdaki amaçlarla işlenmesini kabul ediyorum.`
                            }
                          },
                          { // Second Checkbox
                            "fieldType": "checkbox",
                            "fieldId": "psyn1c",
                            "properties": {
                              "name": "receiveEmails",
                              "id": "receiveEmails",
                              "validation": {
                                "required": {
                                  "allowFalse": true
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "label": `Kimlik, iletişim, müşteri işlem ve pazarlama verilerimin ürün ve hizmetlerin pazarlama süreçlerinin planlanması ve icrası, profilleme ve analiz faaliyetlerinin yürütülmesi, sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıklarım ve ihtiyaçlarıma göre özelleştirilerek önerilmesi ve tanıtılması amacıyla işlenmesini kabul ediyorum.`
                            }
                          },
                          { // Third Checkbox
                            "fieldType": "checkbox",
                            "fieldId": "psyn1c2",
                            "properties": {
                              "name": "receiveEmails2",
                              "id": "receiveEmails2",
                              "validation": {
                                "required": {
                                  "allowFalse": true
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "label": `Kimlik, iletişim ve pazarlama verilerimin paylaştığım iletişim bilgilerime reklam, kutlama, promosyon vb. ticari elektronik ileti gönderilmesi amacıyla işlenmesini kabul ediyorum.`
                            }
                          },
                          { // Fourth Checkbox
                            "fieldType": "checkbox",
                            "fieldId": "psyn1c3",
                            "properties": {
                              "name": "receiveEmails3",
                              "id": "receiveEmails3",
                              "validation": {
                                "required": {
                                  "allowFalse": true
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "label": `Kimlik ve görüntü (fotoğraf, ses, video) verilerim ile yorum içeriklerimin, ilgili ürün ve hizmetlerin ve katılmam halinde ilgili yarışma, çekiliş, kampanyanın görselleştirilerek tanıtılması amacıyla kullanılması ve kamuya açık olarak P&G’nin sosyal medya hesaplarında, internet sitelerinde, Şirket içi ve dışı sair mecralarda yayımlanması amacıyla işlenmesini kabul ediyorum.`
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "yh453gg4",
            "grid": {
              "columns": "12",
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "40px",
              "marginTop": "40px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "y4h3gwh",
                "properties": {
                  grid: {
                    columns: "6"
                  },
                  "className": "col-md-6 col-xl-4",
                  fields: [
                    { // Next Button
                      "fieldType": "button-component",
                      "fieldId": "5n4b3vg4",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "text": "Kayıt Ol",
                        "styles": {
                          "backgroundColor": "var(--dishekimlerio-color)"
                        },
                        "addClass": "nextBtn",
                        "disabled": false,
                        "action": {
                          "fn": "sendForm",
                          "params": "inzf4w"
                        }
                      }
                    },
                    { // Login
                      "fieldType": "button-component",
                      "fieldId": "9o8k7ujyth",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "0",
                          "marginTop": "40px"
                        },
                        "text": "Kadınlar Bilir hesabı ile Giriş Yap",
                        "addClass": "loginBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/giris-yap"
                        }
                      }
                    },
                    { // Guest Login
                      "fieldType": "button-component",
                      "fieldId": "23vb4n5",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px"
                        },
                        "text": "Kayıt olmadan misafir kullanıcı olarak devam edin",
                        "addClass": "guestBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/misafir-uye"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Guest Registration
        "pageName": "dishekimlerio/misafir-uye",
        "locale": "tr",
        "pageId": 405,
        "sections": [
          { // Steps and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12,
              "paddingBottom": "20px"
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "6gx3um",
                "properties": {
                  "activeIndex": 3,
                  "colorSchema": "var(--dishekimlerio-color)",
                  "campaignName": "dishekimlerio",
                  "steps": [
                    {
                      "title": "Geçerli Oral-B ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Diş Hekiminden aldığın kod ile <b>GİRİŞ YAP</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%25’e kadar para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "fckzg7",
                "properties": {
                  "text": "Misafir kullanıcı olarak devam ediniz",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginTop": "10px"
                  },
                  "classes": ["heading", 'text-center']
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "ch2fio",
                "properties": {
                  "text": `"Kadınlar Bilir" hesabı oluşturmak istememene çok üzüldük. Eğer fikrinizi değiştirirseniz, her zaman <a href="https://www.kadinlarbilir.com" target="_blank">Kadinlarbilir.com</a>’a girerek kayıt olabilirsiniz.<br/><br/>
                  Katılımınızı tamamlamak için aşağıdaki bilgileri doldurmanızı rica ederiz.
                  `,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ["subHeading", 'text-center']
                }
              }
            ]
          },
          { // Form
            "sectionId": "ci88zt",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12"
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "j765n4b",
                "properties": {
                  "styles": {
                    "margin": "0",
                    "alignItems": "center",
                    "flexDirection": "column"
                  },
                  "grid": {
                    "alignItems": "center",
                    "columns": "12",
                    "justifyContent": "center"
                  },
                  "requires": [
                    "1"
                  ],
                  "successAction": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/sms-sayfasi"
                  },
                  "endpoint": "/registerAsGuest",
                  "fields": [
                    { // Input
                      "fieldType": "empty-bucket",
                      "fieldId": "4h3gweb",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          { // Gender
                            "fieldType": "paragraph",
                            "fieldId": "yh54weg",
                            "properties": {
                              "text": "Cinsiyet *",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              classes: ["formTitle"]
                            }
                          },
                          { // Gender Radio
                            "fieldType": "radio-input",
                            "fieldId": "Geschlecht",
                            "name": "Cinsiyet*",
                            "properties": {
                              "text": "",
                              "name": "gender",
                              "colorSchema": "var(--dishekimlerio-color)",
                              "grid": {
                                "columns": 12
                              },
                              "validation": "required",
                              "direction": "row",
                              "options": [
                                {
                                  "value": "female",
                                  "text": "Kadın",
                                  "disabled": false,
                                  "name": "firstRadio"
                                },
                                {
                                  "value": "male",
                                  "text": "Erkek",
                                  "disabled": false,
                                  "name": "secondRadio"
                                }
                              ]
                            }
                          },
                          { // First Name
                            "fieldId": "b4baqs",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "firstName",
                              "id": "firstName",
                              "label": "İsim*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|alpha",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              }
                            }
                          },
                          { // Last Name
                            "fieldId": "gofije",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "lastName",
                              "id": "lastName",
                              "label": "Soyisim*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|alpha",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // Email
                            "fieldId": "h7kzf9",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "email",
                              "id": "email",
                              "label": "E-posta adresi*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|email",
                              "grid": {
                                "columns": 12
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Check Boxes
                      "fieldId": "8k2f023f",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          { // Sub Heading
                            "fieldType": "paragraph",
                            "fieldId": "ch2fio",
                            "properties": {
                              "text": `<a href="assets/dishekimlerio/files/oral-b-aydinlatma-metni.docx" download><u><b>Oral-B Kampanya Katılımcısı Aydınlatma Metni</b></u></a> kapsamında; kişisel verilerinizin Procter & Gamble Tüketim Malları Sanayi Anonim Şirketi (P&G) tarafından aşağıdaki amaçlarla işlenmesini kabul ediyor musunuz?`,
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              styles: {
                                margin: "30px"
                              }
                            }
                          },
                          { // Check box Age Consent
                            "fieldType": "checkbox",
                            "fieldId": "wgc3j7",
                            "properties": {
                              "name": "terms",
                              "id": "terms",
                              "validation": {
                                "required": {
                                  "allowFalse": false
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "styles": {
                                "margin-top": "30px"
                              },
                              "label": `Kimlik, iletişim, müşteri işlem ve pazarlama verilerimin ürün ve hizmet pazarlama süreçlerinin gerçekleştirilmesi, profilleme ve analiz faaliyetleri dahil olmak üzere sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarıma göre özelleştirilerek önerilmesi ve tanıtılması amacıyla işlenmesini kabul ediyorum. `
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "yh453gg4",
            "grid": {
              "columns": "12",
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "40px",
              "marginTop": "40px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "y4h3gwh",
                "properties": {
                  grid: {
                    columns: "6"
                  },
                  "className": "col-md-6 col-xl-4",
                  fields: [
                    { // Next Button
                      "fieldType": "button-component",
                      "fieldId": "5n4b3vg4",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "text": "KATIL",
                        "styles": {
                          "backgroundColor": "var(--dishekimlerio-color)"
                        },
                        "addClass": "nextBtn",
                        "disabled": false,
                        "action": {
                          "fn": "sendForm",
                          "params": "j765n4b"
                        }
                      }
                    },
                    { // Login
                      "fieldType": "button-component",
                      "fieldId": "9o8k7ujyth",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "0",
                          "marginTop": "40px"
                        },
                        "text": "Kadınlar Bilir hesabı ile Giriş Yap",
                        "addClass": "loginBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/giris-yap"
                        }
                      }
                    },
                    { // Guest Login
                      "fieldType": "button-component",
                      "fieldId": "23vb4n5",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px"
                        },
                        "text": "Kadınlar Bilir’e kayıt olarak giriş yap",
                        "addClass": "guestBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/uye-ol"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Login
        "pageName": "dishekimlerio/giris-yap",
        "locale": "tr",
        "pageId": 406,
        "sections": [
          { // Steps and Headings
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12,
              "paddingBottom": "20px"
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "6gx3um",
                "properties": {
                  "activeIndex": 3,
                  "colorSchema": "var(--dishekimlerio-color)",
                  "campaignName": "dishekimlerio",
                  "steps": [
                    {
                      "title": "Geçerli Oral-B ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Diş Hekiminden aldığın kod ile <b>GİRİŞ YAP</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%25’e kadar para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // Image
                "fieldId": "vn0934kf",
                "fieldType": "square-image",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "marginTop": "81px",
                    "marginBottom": "20px",
                    "padding": "0",
                    "maxHeight": "50px",
                    "maxWidth": "125px"
                  },
                  "className": "imgMobile",
                  "imageSource": "/assets/logo/reg_logo@1x.png",
                  "imageSource2": "/assets/logo/reg_logo@2x.png"
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "fckzg7",
                "properties": {
                  "text": `"Kadınlar Bilir" hesabı ile giriş yap`,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginTop": "10px"
                  },
                  "classes": ["heading", 'text-center']
                }
              }
            ]
          },
          { // Form
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "jc3v455",
                "properties": {
                  "styles": {
                    "margin": "0",
                    "justifyContent": "center"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    columns: 12
                  },
                  "requires": [
                    "1"
                  ],
                  "successAction": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/adres"
                  },
                  "endpoint": "/login",
                  "fields": [
                    { // Inputs
                      "fieldId": "fu2309b",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          {
                            "fieldId": "n6b54v",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "email",
                              "id": "email",
                              "label": "E-posta adresi*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|email",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              }
                            }
                          },
                          {
                            "fieldId": "6h54bdd",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "password",
                              "id": "password",
                              "label": "Parola*",
                              "inputType": "password",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|password",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          {
                            "fieldType": "anchor",
                            "fieldId": "fckzg7",
                            "properties": {
                              "text": "Parolanızı mı unuttunuz?",
                              "action": {
                                "fn": "goToPage",
                                "params": "dishekimlerio/parola"
                              },
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "cssClass": "forgotPass"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Button and text
            "sectionId": "vbniw03",
            "grid": {
              "columns": "12",
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "40px",
              "marginTop": "32px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "vb892nwq",
                "properties": {
                  grid: {
                    columns: "6"
                  },
                  "className": "col-md-6 col-xl-4",
                  "styles": {
                    "justifyContent": "center"
                  },
                  fields: [
                    { // Button
                      "fieldType": "button-component",
                      "fieldId": "5n4b3vg4",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "30px"
                        },
                        "text": "Giriş Yap",
                        "styles": {
                          "backgroundColor": "var(--dishekimlerio-color)"
                        },
                        "addClass": "nextBtn",
                        "disabled": false,
                        "action": {
                          "fn": "sendForm",
                          "params": "jc3v455"
                        }
                      }
                    },
                    { // Text
                      "fieldType": "paragraph",
                      "fieldId": "fckzg7",
                      "properties": {
                        "text": `Henüz "Kadınlar Bilir" hesabınız yok mu?`,
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        "classes": ["loginText"]
                      }
                    },
                    { // Registration
                      "fieldType": "button-component",
                      "fieldId": "9o8k7ujyth",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "0",
                          "marginTop": "40px"
                        },
                        "text": "Kadınlar Bilir’e kayıt olarak giriş yap",
                        "addClass": "loginBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/uye-ol"
                        }
                      }
                    },
                    { // Guest Registration
                      "fieldType": "button-component",
                      "fieldId": "23vb4n5",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px"
                        },
                        "text": "Kayıt olmadan misafir olarak devam edin",
                        "addClass": "guestBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/misafir-uye"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Address
        "pageName": "dishekimlerio/adres",
        "locale": "tr",
        "pageId": 407,
        "requires": [
          1
        ],
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Adres bilgileriniz",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "paddingBottom": "90px"
                  },
                  "classes": ["heading", "text-center"]
                }
              }
            ]
          },
          { // Form
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Form
                "fieldType": "form-component",
                "fieldId": "adres",
                "properties": {
                  "successAction": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/sms-sayfasi"
                  },
                  "endpoint": "/submitAddress",
                  "keepInState": ["referenceNumber"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "fields": [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "fw90342ol",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4 justify-content-center",
                        "fields": [
                          { // City
                            "fieldId": "jdtxik",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "ort",
                              "id": "ort",
                              "label": "Şehir*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|street",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // Street
                            "fieldId": "b5q97u",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "strasse",
                              "id": "strasse",
                              "label": "Sokak*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|street",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // House Number
                            "fieldId": "ql5mu4",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "hausnr",
                              "id": "hausnr",
                              "label": "Numara",
                              "placeholder": "",
                              "text": "",
                              "validation": "street",
                              "className": "col-6",
                              "grid": {
                                "columns": 6,
                                "paddingRight": "2.5px !important"
                              }
                            }
                          },
                          { // Postal code
                            "fieldId": "ql6mu5",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "plz",
                              "id": "plz",
                              "label": "Posta kodu*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|street",
                              "className": "col-6",
                              "grid": {
                                "columns": 6,
                                "paddingLeft": "2.5px !important"
                              }
                            }
                          },
                          { // Land
                            "fieldId": "x3d8oz",
                            "fieldType": "select-input",
                            "properties": {
                              "name": "land",
                              "id": "land",
                              "label": "Land",
                              "placeholder": "Ülke*",
                              "text": "",
                              "validation": "required",
                              "options": [
                                {
                                  "text": "Türkiye",
                                  "value": "Turkey"
                                }
                              ],
                              "selectedValue": "",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },

                          { // Buttons
                            "fieldType": "empty-bucket",
                            "fieldId": "fw90342ol",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "8",
                                "paddingTop": "20px",
                                "paddingBottom": "100px"
                              },
                              "className": "btnMobile",
                              "fields": [
                                { // Next Button
                                  "fieldType": "button-component",
                                  "fieldId": "16tzm2",
                                  "properties": {
                                    "grid": {
                                      "columns": "12",
                                      "alignItems": "center",
                                      "justifyContent": "center"
                                    },
                                    "text": "İleri",
                                    "styles": {
                                      "backgroundColor": "var(--dishekimlerio-color)"
                                    },
                                    "addClass": "nextBtn",
                                    "disabled": false,
                                    "action": {
                                      "fn": "sendForm",
                                      "params": "adres"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Sms Code
        "pageName": "dishekimlerio/sms-sayfasi",
        "locale": "tr",
        "pageId": 4090,
        "sections": [
          { // Steps and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "wabb03",
                "properties": {
                  "activeIndex": 3,
                  "colorSchema": "var(--dishekimlerio-color)",
                  "campaignName": "dishekimlerio",
                  "steps": [
                    {
                      "title": "Geçerli Oral-B ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Diş Hekiminden aldığın kod ile <b>GİRİŞ YAP</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%25’e kadar para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Lütfen telefon numaranızı onaylayınız",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ["heading", 'text-center']
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": `Katılımınızı doğrulamak için cep telefonu numarasının belirtilmesi gereklidir.Vermiş olduğunuz telefon numarası sadece tek seferlik, reklamsız, doğrulama için kullanılacak ve kampanya sonrasında tarafımızca silinecektir.
                  <br>
                  Lütfen cep telefonu numaranızı aşağıdaki alana giriniz. Daha sonra size SMS ile katılımınızı onaylamanız için bir kod göndereceğiz, ardından bir sonraki sayfaya girebilirsiniz.
                  <br>
                  Talebinizin durumuyla ilgili SMS ve Mail yoluyla bilgilendirileceksiniz. SMS bilgilendirmesi Oral-B adına kampanyayı yürüten yetkili ajans JustSnap aracılığı ile yapılacaktır.
                  `,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ["subHeading", 'text-center']
                }
              }
            ]
          },
          { // Form
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // SMS
                "fieldType": "sms-verification",
                "fieldId": "c6yysh",
                "properties": {
                  "endpoint": "/mobileAuth",
                  "inputWidth": "720px",
                  "successAction": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/sms-onay"
                  },
                  options: [
                    {
                      "name": "Türkiye",
                      "code": "TR",
                      "phoneCode": "+90"
                    }
                  ],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": 6,
                    marginBottom: "50px"
                  }
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12,
              "alignItems": "center"
            },
            buttonsWrap: true,
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px",
                    "marginBottom": "100px"
                  },
                  "addClass": "col-sm-4 col-xl-3 nextBtn",
                  "text": "İleri",
                  "styles": {
                    "backgroundColor": "var(--dishekimlerio-color)"
                  },
                  "requires": ["phoneNumber"],
                  "action": {
                    "fn": "sendSms",
                    "params": ""
                  }
                }
              }
            ]
          }
        ]
      },
      { // Sms Receive
        "pageName": "dishekimlerio/sms-onay",
        "locale": "tr",
        "pageId": 4091,
        "sections": [
          { // Steps and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "wabb03",
                "properties": {
                  "activeIndex": 3,
                  "colorSchema": "var(--dishekimlerio-color)",
                  "campaignName": "dishekimlerio",
                  "steps": [
                    {
                      "title": "Geçerli Oral-B ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Diş Hekiminden aldığın kod ile <b>GİRİŞ YAP</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%25’e kadar para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "Lütfen telefon numaranızı onaylayınız",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ["heading", 'text-center']
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": `Lütfen size SMS ile gönderdiğimiz onay kodunu girin.`,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ["subHeading", 'text-center']
                }
              }
            ]
          },
          { // Form
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // SMS
                "fieldType": "sms-receiving",
                "fieldId": "c6yysh",
                "properties": {
                  "endpoint": "/mobileVerification",
                  "inputWidth": "720px",
                  "successAction": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/kayit-tamamlandi"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": 4
                  }
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12,
              "alignItems": "center"
            },
            buttonsWrap: true,
            "fields": [
              { // back Button
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px",
                    "marginBottom": "100px"
                  },
                  "text": "Geri",
                  "addClass": "col-sm-4 col-xl-3 backBtn",
                  "action": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/sms-sayfasi"
                  }
                }
              },
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px",
                    "marginBottom": "100px"
                  },
                  "addClass": "col-sm-4 col-xl-3 nextBtn",
                  "text": "İleri",
                  "styles": {
                    "backgroundColor": "var(--dishekimlerio-color)"
                  },
                  "requires": ["code"],
                  "action": {
                    "fn": "verifySms",
                    "params": ""
                  }
                }
              }
            ]
          }
        ]
      },
      { // Thank you page
        "pageName": "dishekimlerio/kayit-tamamlandi",
        "locale": "tr",
        "pageId": 410,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              'maxWidth': '1270px',
              "margin": "auto"
            },
            "fields": [
              {
                fieldId: "dte-003",
                fieldType: "dataLayer-event",
                properties: {
                  eventName: 'consumerIdSend',
                  sendconsumerId: true
                }
              },
              { // Thank you Texts and Reference Number
                "fieldType": "empty-bucket",
                "fieldId": "05m65ds",
                "properties": {
                  "grid": {
                    columns: 8,
                    "marginTop": "40px",
                    "marginBottom": "50px"
                  },
                  "styles": {
                    "padding": "0",
                    "flexDirection": "column",
                    "alignItems": "center"
                  },
                  fields: [
                    { // Image
                      "fieldId": "vn0934kf",
                      "fieldType": "square-image",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "styles": {
                          "marginBottom": "20px",
                          "padding": "0"
                        },
                        "className": "thankyouImage",
                        "imageSource": "/assets/success.svg",
                        "imageSource2": "/assets/success.svg"
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "88z8gv",
                      "properties": {
                        "text": "Tebrikler, katılımınız tamamlandı!",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        "classes": ["heading", "text-center"]
                      }
                    },
                    { // Sub Heading
                      "fieldType": "paragraph",
                      "fieldId": "88z8gv",
                      "properties": {
                        "variables": {
                          "referenceNumber": "referenceNumber"
                        },
                        "text": `
                          Fişiniz/faturanız bize gönderildi ve şimdi kontrol ediliyor.
                            <br/><br/>
                          <strong>Referans numarası: {referenceNumber}</strong>
                            <br/><br/>
                          Bilgilerinizi kontrol ettikten sonra, eğer katılım koşullarımıza uygunsa bir onay e-postası alacaksınız. Başvurunuzda bir eksiklik olması halinde, sizinle tekrar iletişime geçeceğiz. Bu süreç 36 saate kadar sürebilir. Sizinle iletişime geçene kadar beklemenizi rica ederiz.
                            <br/><br/>
                          Satın alma fişini/faturasını ve diğer tüm bilgileri başarılı bir şekilde kontrol ettikten sonra eğer katılım koşullarına uyuyorsa, geri ödeme tutarını 4 ile 6 hafta içerisinde belirttiğiniz hesaba aktaracağız.
                        `,
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        classes: ["subHeading", "text-center"]
                      }
                    },
                    { // Key Visual
                      "fieldType": "key-visual",
                      "fieldId": "296eg1",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "noContainer": true,
                        "desktopImageUrl": "/assets/dishekimlerio/banner_desktop.png",
                        "tabletImageUrl": "/assets/dishekimlerio/banner_desktop.png",
                        "mobileImageUrl": "/assets/dishekimlerio/banner_mobile.png",
                      }
                    },
                    { // Button
                      "fieldType": "button-component",
                      "fieldId": "oql2de",
                      "properties": {
                        "text": "Başlangıç   sayfasına geri dön",
                        "grid": {
                          "columns": "8",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px",
                          "marginBottom": "90px"
                        },
                        "addClass": "nextBtn",
                        "styles": {
                          "backgroundColor": "var(--dishekimlerio-color)"
                        },
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.firsatyaninda.com/dishekimlerio"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Forgot Password
        "pageName": "dishekimlerio/parola",
        "locale": "tr",
        "pageId": 408,
        "sections": [

          { // Image
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "paddingTop": "50px"
            },
            fields: [
              {
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/images/forgotPassword.svg",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "4"
                  },
                  "className": "forgotImageMobile"
                }
              }
            ]
          },
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "gjd10",
                "properties": {
                  "text": "Parolanızı mı unuttunuz?",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "50px"
                  },
                  "classes": ["heading", "text-center"]
                }
              }
            ]
          },
          { // Form and Button
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Form
                "fieldType": "form-component",
                "fieldId": "9hj453vnv3e",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": 12
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "successAction": {
                    fn: "displayModal",
                    params: {
                      message: 'Şifrenizi değiştirmenize yardımcı olmak için bir e-posta gönderdik.',
                      type: 'success'
                    }
                  },
                  "endpoint": "/forgetPassword",
                  "fields": [
                    {
                      "fieldId": "f9234pg",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "4"
                        },
                        "className": "col-sm-8 col-md-6 justify-content-center",
                        "fields": [
                          { // Email
                            "fieldId": "h34gwb",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "email",
                              "id": "email",
                              "label": "E-posta adresi*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|email",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              }
                            }
                          },
                          { // Button
                            "fieldId": "f9234pg",
                            "fieldType": "empty-bucket",
                            "properties": {
                              "grid": {
                                "columns": "8",
                                "paddingBottom": "100px"
                              },
                              "className": "col-sm-8 col-md-6 btnMobile",
                              "fields": [
                                { // Button
                                  "fieldType": "button-component",
                                  "fieldId": "h34wevdss",
                                  "properties": {
                                    "text": "İleri",
                                    "grid": {
                                      "columns": "12",
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginTop": "40px",
                                      "marginBottom": "81px"
                                    },
                                    "addClass": "nextBtn",
                                    "styles": {
                                      "backgroundColor": "var(--dishekimlerio-color)"
                                    },
                                    "disabled": false,
                                    "action": {
                                      "fn": "sendForm",
                                      "params": "9hj453vnv3e"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // T&C
        "pageName": "dishekimlerio/katilim-kosullari",
        "locale": "tr",
        "pageId": 421,
        "isPublic": true,
        "sections": [
          {
            grid: {
              alignItems: "center",
              justifyContent: "center",
              columns: "12",
              marginBottom: "30px"
            },
            fields: [
              {
                fieldType: "pdf-viewer",
                fieldId: "vabd69",
                properties: {
                  grid: {
                    alignItems: "center",
                    justifyContent: "center",
                    columns: "12"
                  },
                  link: "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/firsatyaninda/dishekimlerio/terms.pdf"
                }
              }
            ]
          }
        ]
      },
      { // FAQ
        "pageName": "dishekimlerio/sss",
        "locale": "tr",
        "pageId": 422,
        "isPublic": true,
        "sections": [
          {
            grid: {
              alignItems: "center",
              justifyContent: "center",
              columns: "12",
              marginBottom: "30px"
            },
            fields: [
              {
                fieldType: "embedded-doc",
                fieldId: "vabd69",
                properties: {
                  grid: {
                    alignItems: "center",
                    justifyContent: "center",
                    columns: "12"
                  },
                  mainWrapperClass: 'embed-wrapper-no-padding',
                  campaign: "hills-pronomia",
                  docSource: "https://docs.google.com/document/d/e/2PACX-1vSCB7GAX08n_h9cmnKaCXm7x5UGhygkn4PotFCnxl9agCy8Wa1vsd45xCOkqTod_g/pub?embedded=true"
                }
              }
            ]
          }
        ]
      },
      { // DOI page
        "pageName": "dishekimlerio/doi",
        "locale": "tr",
        "pageId": 423,
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "maxWidth": "1440px",
              "margin": "auto"
            },
            "fields": [
              {
                "fieldType": "doi",
                "fieldId": "r3doi",
                "properties": {
                  "campaignName": "altin",
                  "colorSchema": "var(--dishekimlerio-color)",
                  "endpoint": "/checkDoi",
                  "title": "Katılımınız için teşekkür ederiz",
                  "text": "Bültenimize başarıyla kayıt oldunuz. Artık ücretsiz ürün testleri, yarışmalar ve ilham verici makaleler olduğunda ilk öğrenenlerden biri siz olacaksınız.",
                  "button": "Başlangıç   sayfasına geri dön"
                }
              }
            ]
          }
        ]
      },
      { // Error page
        "pageName": "dishekimlerio/hata-sayfasi",
        "locale": "tr",
        "pageId": 413,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "gjd10",
                "properties": {
                  "text": "Oops, bir şeyler hatalı oldu!",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px"
                  },
                  "classes": ["heading", "text-center"]
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "sfdjk2",
                "properties": {
                  "text": "Lütfen sayfayı yeniden yüklemeyi deneyin!",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "classes": ["subHeading", "text-center"]
                }
              }
            ]
          },
          { // Image and back button
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Image
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/images/404.svg",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px"
                  }
                }
              },
              { // Button
                "fieldType": "button-component",
                "fieldId": "oql2de",
                "properties": {
                  "text": "Başlangıç   sayfasına geri dön",
                  "styles": {
                    "background-color": "var(--dishekimlerio-color);",
                    "border-radius": "100px;"
                  },
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "100px",
                    "marginBottom": "70px"
                  },
                  "addClass": "nextBtn",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "https://www.firsatyaninda.com/dishekimlerio"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Expired page
        "pageName": "dishekimlerio/expired-page",
        "locale": "tr",
        "pageId": 414,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "gjd10",
                "properties": {
                  "text": `Maalesef, ulaşmaya çalıştığınız bağlantının süresi doldu. Lütfen tekrar kampanyaya katılın veya destek servisimize başvurun: <a href="mailto:destek@dishekimlerio.firsatyaninda.com"><u>destek@dishekimlerio.firsatyaninda.com</u></a>`,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "6",
                    "marginTop": "50px"
                  },
                  "classes": ["heading", "text-center"]
                }
              }
            ]
          },
          { // Image and back button
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Image
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/images/expired.svg",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px"
                  },
                  "styles": {
                    "max-width": "645px"
                  }
                }
              }
            ]
          },
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Button
                "fieldType": "button-component",
                "fieldId": "oql2de",
                "properties": {
                  "text": "Başlangıç   sayfasına geri dön",
                  "styles": {
                    "background-color": "var(--dishekimlerio-color);",
                    "border-radius": "100px;"
                  },
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "100px",
                    "marginBottom": "70px"
                  },
                  "addClass": "nextBtn",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "https://www.firsatyaninda.com/dishekimlerio"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Reset Password page
        "pageName": "dishekimlerio/yeni-parola",
        "locale": "tr",
        isPublic: true,
        "pageId": 415,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldId": "v09234i",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "onSuccess": "hide",
                  "styles": {
                    "flexDirection": "column",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "maxWidth": "1440px"
                  },
                  "fields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "gjd10",
                      "properties": {
                        "text": "<h2>Yeni bir parola seçiniz</h2>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "50px"
                        },
                        "styles": {
                          "font-family": "Montserrat;",
                          "font-style": "normal;",
                          "font-weight": "600;",
                          "font-size": "32px;",
                          "line-height": "39px;",
                          "color": "#1D1D1D;"
                        }
                      }
                    },
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "/assets/images/reset-pass.svg",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "5"
                        },
                        "styles": {
                          "max-width": "346px !important",
                          "objectFit": "contain"
                        }
                      }
                    },
                    {
                      "fieldId": "f9234pg",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "4"
                        },
                        "className": "col-sm-8 col-md-6",
                        "fields": [
                          {
                            "fieldType": "form-component",
                            "fieldId": "5h43gwe",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              },
                              "styles": {
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "successAction": {
                                "fn": "displaySuccess"
                              },
                              "endpoint": "/checkResetToken",
                              "fields": [
                                {
                                  "fieldId": "j45brev",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "newPassword",
                                    "id": "newPassword",
                                    "vid": "newPassword",
                                    "inputType": "password",
                                    "label": "Parola*",
                                    "placeholder": "",
                                    "text": "",
                                    "validation": "required|password",
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "styles": {
                                      "border-radius": "40px !important",
                                      "margin-top": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldId": "7666b4re",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "newPasswordConfirm",
                                    "id": "newPasswordConfirm",
                                    "inputType": "password",
                                    "label": "Parola Tekrarı*",
                                    "placeholder": "",
                                    "text": "",
                                    "validation": "required|confirmed:newPassword",
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "styles": {
                                      "border-radius": "40px !important",
                                      "margin-top": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldType": "query-string-to-state",
                                  "fieldId": "uwxo2o1",
                                  properties: {
                                    grid: {
                                      columns: 0
                                    },
                                    variables: [
                                      {
                                        query: "code"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          {
                            "fieldType": "button-component",
                            "fieldId": "h34wevdss",
                            "properties": {
                              "text": "Yeni parolanızı oluşturun",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center",
                                "marginTop": "40px",
                                "marginBottom": "81px"
                              },
                              "styles": {
                                "background-color": "var(--dishekimlerio-color);",
                                "border-radius": "100px;"
                              },
                              "textStyles": {
                                "font-family": "Montserrat;",
                                "color": "#FFFFFF;",
                                "fontWeight": "600;",
                                "font-size": "18px;",
                                "line-height": "22px;"
                              },
                              "disabled": false,
                              "action": {
                                "fn": "sendForm",
                                "params": "5h43gwe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                "fieldId": "v09234i",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "onSuccess": "show",
                  "styles": {
                    "flexDirection": "column",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "max-width": "1440px"
                  },
                  "fields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "fk2340ed",
                      "properties": {
                        "text": "<h2>Parolanız sıfırlandı ve değiştirildi.</h2>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "50px"
                        },
                        "styles": {
                          "font-family": "Montserrat;",
                          "font-style": "normal;",
                          "font-weight": "600;",
                          "font-size": "32px;",
                          "line-height": "39px;",
                          "color": "#1D1D1D;"
                        }
                      }
                    },
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/thankYou_tick.png",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "2"
                        },
                        "styles": {
                          "objectFit": "contain"
                        }
                      }
                    },
                    {
                      "fieldType": "paragraph",
                      "fieldId": "fk2340ed",
                      "properties": {
                        "text": "Artık kampanya web sitesinde tekrar giriş yapabilirsiniz.<br>Bunu yapmak için, istenen kampanya web sitesini ziyaret edin.",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "font-family": "Montserrat;",
                          "font-style": "normal;",
                          "font-size": "21px;",
                          "line-height": "39px;",
                          "color": "#1D1D1D;",
                          "margin": "20px 0 60px 0;"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Campaign Is Over
        "pageName": "dishekimlerio/kampanya-bitti",
        "locale": "tr",
        "pageId": 416,
        isPublic: true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "margin": "auto"
            },
            "fields": [
              { // Heading and Image and Button
                "fieldType": "empty-bucket",
                "fieldId": "05m65ds",
                "properties": {
                  "grid": {
                    columns: 12,
                    "marginTop": "40px",
                    "marginBottom": "50px"
                  },
                  "styles": {
                    "padding": "0",
                    "flexDirection": "column",
                    "alignItems": "center"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "88z8gv",
                      "properties": {
                        "text": "Kampanya süresi sona erdi",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        "classes": ["heading", "text-center"]
                      }
                    },
                    { // Sub Heading
                      "fieldType": "paragraph",
                      "fieldId": "88z8gv",
                      "properties": {
                        "text": `Kampanyaya göstermiş olduğunuz ilgiye teşekkür ederiz, maalesef kampanya dönemi sona ermiştir.`,
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        classes: ["subHeading", "text-center"]
                      }
                    },
                    { // Key Visual
                      "fieldType": "key-visual",
                      "fieldId": "296eg1",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "styles": {
                          "margin": "0 0 50px 0"
                        },
                        "noContainer": true,
                        "desktopImageUrl": "/assets/dishekimlerio/banner_desktop.png",
                        "tabletImageUrl": "/assets/dishekimlerio/banner_desktop.png",
                        "mobileImageUrl": "/assets/dishekimlerio/banner_mobile.png"
                      }
                    },
                    { // Button
                      "fieldType": "button-component",
                      "fieldId": "oql2de",
                      "properties": {
                        "text": "Başlangıç   sayfasına geri dön",
                        "styles": {
                          "background-color": "var(--dishekimlerio-color);",
                          "border-radius": "100px;"
                        },
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px",
                          "marginBottom": "90px"
                        },
                        "addClass": "nextBtn",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.firsatyaninda.com/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Reupload Receipt
        "pageName": "dishekimlerio/tekrar-fis-yukle",
        "locale": "tr",
        "isPublic": true,
        "pageId": 417,
        "sections": [
          { // Query and Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    }
                  ]
                }
              },
              { // Header
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "Lütfen fişinizi/faturanızı tekrar yükleyiniz",
                  "classes": ["heading", "text-center"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "Bu adımda, Oral-B alışverişinizi içeren fiş ya da faturanızı tekrar yükleyiniz.",
                  "classes": ["subHeading", "text-center"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              }
            ]
          },
          { // Receipt Info and Uploader
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "fields": [
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "10"
                  },
                  "styles": {
                    "justifyContent": "center !important"
                  },
                  "fields": [
                    { // Receipt Info
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fb",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-sm-9 xl-6 pb-4 receiptInfo desktopOnly",
                        "fields": [
                          {
                            "fieldType": "receipt-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                              "steps": [
                                {
                                  "text": "Satış noktası",
                                  "label": "A"
                                },
                                {
                                  "text": "Satın alma tarihi ve saati",
                                  "label": "B"
                                },
                                {
                                  "text": "Ürünler ve satın alma fiyatları",
                                  "label": "C"
                                },
                                {
                                  "text": "Toplam fiş/fatura ve KDV tutarı",
                                  "label": "D"
                                },
                                {
                                  "text": "Fiş/Fatura Numarası",
                                  "label": "E"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Receipt Upload
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fc",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-9 pb-4 receiptUpload",
                        "fields": [
                          {
                            "fieldType": "receipt-processor",
                            "fieldId": "klvakj",
                            "properties": {
                              texts: {
                                choose: 'Bir Dosya Seçin',
                                receiptUpload1: 'Fişinizi/Faturanızı yüklemek için sürükleyip bırakınız.',
                                receiptUpload2: 'Yalnızca JPEG, PNG ve PDF desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                                receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                                wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, .png, .pdf ve .tiff dosyaları kabul edilir.',
                                fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                              },
                              "subCampaignId": "oralb-dishekimleri-io",
                              "colorSchema": "var(--dishekimlerio-color)",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "reUpload": true,
                              "tokenName": "token",
                              "addClass": "p-0",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "dishekimlerio/kayit-tamamlandi"
                              },
                              "phrases": [
                                "Ihr Beleg wird jetzt hochgeladen und bearbeitet.",
                                "Der Beleg wird automatisch bearbeitet. Dies kann eine Minute dauern. Bitte haben Sie Geduld.",
                                "Sollten Sie eine Fehlermeldung erhalten, nehmen Sie bitte eine bessere Version des Belegs auf und laden Sie ihn erneut hoch."
                              ],
                              "cornerDetectionPhrases": [
                                "Lütfen gri dikdörtgeni fiş ile eşleşecek şekilde ayarlayın."
                              ],
                              "eraseColor": "#ffffff",
                              "eraseSize": 12,
                              "cropColor": "#3A96F3",
                              "cropBackground": "rgba(0,0,0,0.6)",
                              "cropTitle": "Kes",
                              "eraseTitle": "Düzenle",
                              "dragTitle": "Hareket ettir",
                              "zoomInTitle": "Yakınlaştır",
                              "zoomOutTitle": "Uzaklaştır",
                              "selectImageTitle": "Select an Image",
                              "selectAnotherTitle": "Select Another",
                              "controlsBackground": "#4193d3",
                              "backgroundColorBtn": "var(--dishekimlerio-color)"
                            }
                          }
                        ]
                      }
                    },
                    { // Receipt Info
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fh",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-sm-9 xl-6 pb-4 receiptInfo desktopNone",
                        "fields": [
                          {
                            "fieldType": "receipt-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                              "steps": [
                                {
                                  "text": "Satış noktası",
                                  "label": "A"
                                },
                                {
                                  "text": "Satın alma tarihi ve saati",
                                  "label": "B"
                                },
                                {
                                  "text": "Ürünler ve satın alma fiyatları",
                                  "label": "C"
                                },
                                {
                                  "text": "Toplam fiş/fatura ve KDV tutarı",
                                  "label": "D"
                                },
                                {
                                  "text": "Fiş/Fatura Numarası",
                                  "label": "E"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "marginBottom": "90px",
              "marginTop": "40px",
              "justifyContent": "center"
            },
            "fields": [
              {
                "fieldId": "chr23985",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "className": "col-md-12",
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "cwer23452q",
                      "properties": {
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "addClass": "col-sm-8 col-xl-3 nextBtn",
                        "text": "İleri",
                        "shouldCheckCrop": true,
                        "requires": ["klvakj"],
                        "action": {
                          "fn": "sendReceipt",
                          "params": ""
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Reupload Product
        "pageName": "dishekimlerio/tekrar-urun-yukle",
        "isPublic": true,
        "locale": "tr",
        "pageId": 418,
        "sections": [
          { // Query and Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "vgw364bh",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    },
                    {
                      query: "campaignId",
                      state: "campaignId"
                    }
                  ]
                }
              },
              { // Header
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "Ürünlerin fotoğrafını yükleyiniz",
                  "classes": ["heading", "text-center"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "Lütfen satın almış olduğunuz ürünlerin fotoğrafını barkod numarası görünecek şekilde ve fiş/fatura ile birlikte yükleyiniz.",
                  "classes": ["subHeading", "text-center"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              }
            ]
          },
          { // Product Upload
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "fields": [
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center !important;",
                    "padding-top": "30px"
                  },
                  "className": "col-sm-9 col-xl-6 col-lg-12",
                  "fields": [
                    {
                      "fieldType": "product-upload",
                      "fieldId": "cakl305",
                      "properties": {
                        texts: {
                          choose: 'Bir Dosya Seçin',
                          receiptUpload1: 'Ürünlerin fotoğrafını yüklemek için sürükleyip bırakın.',
                          receiptUpload2: 'Yalnızca JPEG veya PNG desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                          receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                          wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, ve .png dosyaları kabul edilir.',
                          fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                        },
                        "colorSchema": "var(--dishekimlerio-color)",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "reupload": {
                          "status": true
                        },
                        "reUpload": true,
                        "tokenName": "token",
                        "endpoint": "/uploadProductPhoto",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "dishekimlerio/kayit-tamamlandi"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "marginBottom": "90px",
              "marginTop": "40px"
            },
            buttonsWrap: true,
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "cwer23452q",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "addClass": "col-sm-8 col-xl-3 nextBtn",
                  "text": "İleri",
                  "shouldCheckCrop": true,
                  "requires": ["cakl305"],
                  "action": {
                    "fn": "sendProductPhotos",
                    "params": "cakl305"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "dishekimlerio/iban-yeniden-gonderme",
        "locale": "de",
        "isPublic": true,
        "pageId": 419,
        "requires": [
          1
        ],
        "sections": [
          { // HEADING AND SUBHEADING
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },

            "fields": [
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              {
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "<h2>Banka hesap bilgilerinizi giriniz</h2>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginTop": "100px"
                  },
                  "classes": ['col-md-10', "heading", 'text-center']
                }
              },
              {
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "Lütfen geri ödeme tutarının aktarılacağı hesabınızın bilgilerini giriniz.",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginBottom": "50px"
                  },
                  "classes": ['col-md-10', "subHeading", 'text-center']
                }
              }
            ]
          },
          { // FORMS
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "bank1Nf0",
                "properties": {
                  "successAction": {
                    "fn": "goToPage",
                    "params": "dishekimlerio/thank-you-iban"
                  },
                  "endpoint": "/updateIBAN",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "fields": [
                    { // form
                      "fieldType": "empty-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "paddingTop": "30px",
                          "paddingBottom": "30px"
                        },
                        "className": "col-md-6 col-xl-4 formLayout",
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": "2"
                              },
                              "className": "ibanLabelWrapper col-2",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "tw3qab",
                                  "properties": {
                                    "grid": {
                                      "columns": "12"
                                    },
                                    "text": "TR",
                                    "classes": ["ibanLabel"]
                                  }
                                }
                              ]
                            }
                          },
                          { // IBAN
                            "fieldId": "h9arlr",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "iban",
                              "id": "iban",
                              "validation": "required|iban",
                              "label": "IBAN*",
                              "placeholder": "",
                              "text": "",
                              "className": "col-10",
                              "grid": {
                                "columns": "10",
                                "paddingLeft": "5px !important"
                              }
                            }
                          },
                          { // trNumber
                            "fieldId": "7rNumb2r",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "tckn",
                              "id": "tckn",
                              "label": "T.C. Kimlik Numarası*",
                              "validation": "required|identityCard",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // trMobile
                            "fieldId": "7rMob2le",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "phoneNumber",
                              "id": "phoneNumber",
                              "label": "Telefon Numarası*",
                              "validation": "required|phoneNumber",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // First Name
                            "fieldId": "tp6zfb",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "firstname",
                              "id": "firstname",
                              "label": "Hesap Sahibi Adı*",
                              "validation": "required|ibanName",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // Last Name
                            "fieldId": "p55fao",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "lastname",
                              "id": "lastname",
                              "validation": "required|ibanName",
                              "label": "Hesap Sahibi Soyadı*",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // BUTTONS
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "marginBottom": "20px",
              "maxWidth": "1440px",
              "marginTop": "40px"
            },
            "buttonsWrap": true,
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "İleri",
                  "addClass": "col-sm-4 col-xl-3 nextBtn",
                  "disabled": false,
                  "action": {
                    "fn": "sendForm",
                    "params": "bank1Nf0"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "dishekimlerio/thank-you-iban",
        "locale": "de",
        "pageId": 420,
        isPublic: true,
        "sections": [
          {
            "grid": {
              "alignItems": "start",
              "justifyContent": "center",
              "columns": "6",
              "maxWidth": "1440px",
              "margin": "auto"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "4w3vw5b8",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center",
                    flexDirection: 'column',
                    alignItems: 'center',
                    "padding": "0"
                  },
                  "fields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "gjd10",
                      "properties": {
                        "text": "<h2>Teşekkürler</h2>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "classes": ["heading", 'text-center']
                      }
                    },
                    { // Image
                      "fieldId": "vn0934kf",
                      "fieldType": "square-image",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "styles": {
                          "marginBottom": "10px",
                          "marginTop": "-10px",
                          "padding": "0"
                        },
                        "className": "thankyouImage",
                        "imageSource": "/assets/success.svg",
                        "imageSource2": "/assets/success.svg"
                      }
                    },
                    {
                      "fieldType": "paragraph",
                      "fieldId": "sfdjk2",
                      "properties": {
                        "text": "Banka bilgilerinizi yeniden gönderdiğiniz için teşekkür ederiz. Başarılı bir transferin önünde hiçbir şey durmamalıdır.<br><br>Banka bilgilerinizle ilgili herhangi bir sorunuz varsa, lütfen <a href='mailto:iban@dishekimlerio.firsatyaninda.com'>iban@dishekimlerio.firsatyaninda.com</a> ile iletişime geçin.<br>Diğer konular için <a href='mailto:destek@dishekimlerio.firsatyaninda.com'>destek@dishekimlerio.firsatyaninda.com</a> adresinden müşteri hizmetimizle iletişime geçebilirsiniz.",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginBottom": "40px"
                        },
                        "classes": ["subHeading", 'text-center']
                      }
                    },
                    { // Key Visual
                      "fieldType": "key-visual",
                      "fieldId": "296eg1",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "noContainer": true,
                        "desktopImageUrl": "/assets/dishekimlerio/banner_desktop.png",
                        "tabletImageUrl": "/assets/dishekimlerio/banner_desktop.png",
                        "mobileImageUrl": "/assets/dishekimlerio/banner_mobile.png",
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "backgroundColor": "#fff",
        "title": "",
        "color": "#FFE082",
        "grid": {
          "columns": 12
        },
        "logo": {
          "title": "Fırsat Yanında",
          "imageSource": "/assets/images/logo@1x.png",
          "imageSource2x": "/assets/images/logo@2x.png",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "secondLogo": "/assets/images/oral-blogo-new.png",
        "goTo": "https://www.oralb.com.tr/tr-tr",
        "favicon": {
          "imageSource": "https://azcdn.pioneer.pgsitecore.com/en-gb/-/media/Downy_UK/Images/Common/favicon-32x32.jpg?v=1-201708041111"
        },
        "fields": [
          { // Terms
            "fieldId": "ikz8vc",
            "fieldType": "anchor",
            "properties": {
              "text": "KATILIM KOŞULLARI",
              "url": "/dishekimlerio/katilim-kosullari",
              "target": "_blank",
              "cssClass": "nav-link headerLink"
            }
          },
          { // FAQ
            "fieldId": "slnmdk",
            "fieldType": "anchor",
            "properties": {
              "text": "SIKÇA SORULAN SORULAR",
              "url": "/dishekimlerio/sss",
              "target": "_blank",
              "cssClass": "nav-link headerLink"
            }
          }
        ],
        "showLine": false,
        "meta": [
          { "name": "title", "content": "P&G Türkiye - Fırsat Yanında" },
          {
            "name": "description",
            "content": `P&G Türkiye - Fırsat Yanında Para İadesi Kampanyası. Belirli satış noktalarından alacağınız toplamda 100 TL P&G ürünü alışverişlerinize 25 TL para iadesi kampanyası.`
          },
          {
            "name": "keywords",
            "content": `Ariel, Alo, Head and Shoulders, Oral-B, Gillette, Firsat Yaninda, P&G Türkiye`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz",
      "properties": {
        "logo": {
          "imageSource": "/assets/images/logo@1x.png",
          "imageSource2x": "/assets/images/logo@2x.png",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "links": [
          { "name": "Hüküm ve Koşullar", "link": "http://www.pg.com/tr_TR/terms_conditions/index.shtml" },
          { "name": "P&G'ye ulaşın", "link": "https://pgconsumersupport.secure.force.com/CarehubStandalone?Country=tr&Language=tr&Brand=Kadinlar+Bilir#brandListDiv" },
          { "name": "Gizlilik", "link": "https://privacypolicy.pg.com/tr-TR/" },
          { "name": "Hakkımızda", "link": "https://www.kadinlarbilir.com/hakkimizda" },
          { "name": "Aydınlatma Metni", "link": "/pg-aydinlatma-metni"}
        ],
        pgText: "© 2023 Procter & Gamble. Tüm hakları saklıdır. Bu sitedeki içeriklere erişim ve içeriklerin kullanılması, yasal anlaşmada belirtilen sözleşme ve şartlara bağlıdır.<br/>Destek departmanımız 15 Temmuz 2025’e kadar hizmet verecektir: <a href='mailto:destek@dishekimlerio.firsatyaninda.com'>destek@dishekimlerio.firsatyaninda.com</a>"
      }
    },
    dictionary: {
      tr: {
        messages: {
          "required": (field) => `${field} alanı gereklidir`,
          "mandatory": "Bu alan gereklidir.",
          "alpha_spaces": (field) => `${field} sadece harf içerebilir.`,
          "email": "Geçerli bir e-posta adresi giriniz.",
          "password": "Şifreniz en az 8 karakterden oluşmalı, en az bir büyük harf, bir küçük harf ve bir sayı içermelidir.",
          "confirmPass": "Şifreler aynı değildir.",
          "postal": "Posta kodu 5 rakamdan oluşmalıdır.",
          "plz": "Posta kodu 5 rakamdan oluşmalıdır.",
          "street": "Yalnızca harf, boşluk, eğik çizgi ve kısa çizgi içerebilir",
          "iban": "Lütfen katılımcı ülkelerin geçerli bir IBAN'ını kullanınız",
          "phoneNumber": "Lütfen 5XXXXXXXXX formatı ile geçerli bir telefon numarası giriniz.",
          "hausnr": "Bina numarası yanlıştır.",
          "smsCodeLength": "Doğrulama kodu 4 basamaklı olmalıdır."
        },
        names: {
          iban: 'IBAN',
          gender: 'Cinisyet',
          bic: 'BIC',
          firstname: 'İsim',
          name: 'İsim',
          firstName: 'İsim',
          lastname: 'Soy isim',
          surname: 'Soy isim',
          lastName: 'Soy isim',
          email: 'E-mail',
          birthdate: 'Doğum tarihi',
          tckn: "TC Kimlik Numarası",
          password: 'Şifre',
          newPassword: 'Şifre',
          confirmPassword: 'Tekrar Şifre',
          newPasswordConfirm: 'Tekrar Şifre',
          strasse: 'Sokak',
          hausnr: 'No',
          plz: 'Posta kodu',
          ort: 'Şehir',
          city: 'İl',
          address: 'Adres',
          marital: 'Medeni hal',
          kids: 'Çocuk',
          area: 'Mahalle',
          district: 'İlçe',
          land: 'Ülke',
          phoneNumber: 'Telefon numarası',
          code: 'Kod',
          terms: "Katılım koşulları",
          receiveEmails: "Kullanım ve gizlilik politikası şartları",
        }
      }
    },
    "errors": {
      "tr": {
        default: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
        noEmail: "Bu e-posta adresine sahip bir hesap yok.",
        emailUsed: "Bu e-mail adresi zaten kullanımda.",
        incorrect: "Geçersiz kullanıcı adı veya yanlış şifre. Lütfen tekrar deneyin!",
        invalidCode: "Kullanmaya çalıştığınız bağlantının süresi zaten dolmuş. Lütfen yeni bir parola sıfırlama bağlantısı isteyin.",
        manyTries: 'Fiş çok fazla yüklendiğinden bir hata oluştu.',
        generateCode: 'Telefon numarasına izin verilmediğinden doğrulama kodu gönderilemedi. Lütfen girdiğiniz numarayı kontrol edin ve tekrar deneyin.',
        wrongCode: 'SMS kodu yanlış',
        network: 'Hata. Sunucuda bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
        invalidBarcode: 'Lütfen geçerli bir kod giriniz.',
        usedBarcode: 'Bu kod daha önce kullanılmıştır.',
      }
    },
    "campaignId": "oralb-dishekimleri-io",
    "url": "firsatyaninda.com/dishekimlerio",
    "name": "oralb-dishekimleri-io"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Oops. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  campaignId: 'oralb-dishekimleri-io',
  formData: new FormData(),
  campaignEndDate: '2025-07-15',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?",
  errorImageList: ["", ""],
  errorActivate: false,
  scannedProducts: [],
  types: ['ean_reader', 'code_128_reader', 'code_39_reader', 'ean_8_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader', 'codabar_reader'],
  pageToBack: '',
  receiptBarcode: '',
  tel: '',
  countryCode: ''
}
