
export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      // { // Home
      //   "pageName": "anasayfa",
      //   "pageId": 401,
      //   "sections": [
      //     {
      //       "sectionId": "a0us1n",
      //       "grid": {
      //         "columns": 12,
      //         "alignItems": "center",
      //         "justifyContent": "center"
      //       },
      //       fields: [
      //         {
      //           "fieldType": "campaign-grid",
      //           "fieldId": "x1p9hx",
      //           "properties": {
      //             "grid": {
      //               "columns": "12",
      //               "alignItems": "center",
      //               "justifyContent": "center"
      //             }
      //           }
      //         }
      //       ]
      //     }
      //   ]
      // },
      { // Home Page
        "pageName": "carrefoursa",
        "isPublic": true,
        "locale": "tr",
        "pageId": 402,
        "sections": [
          {
            "sectionId": "orbg8s",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "position": "relative"
            },
            "fields": [
              { // Refresh Token
                "fieldType": "refresh-token",
                "fieldId": "ij3433"
              },
              { // DataLayer
                fieldId: "dte-003",
                fieldType: "dataLayer-event",
                properties: {
                  eventName: "PageView",
                  fbEvent: "PageView"
                }
              },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "margin": "0 0 50px 0"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/f392186e-a27e-49ec-b7d3-a3fc1eee8413.jpeg",
                  "tabletImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/06321dd8-0d30-4e2d-90a0-ad1954929dd6.jpeg",
                  "mobileImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/803369c2-dcf5-4027-b2e7-923a26cd99a2.jpeg"
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "<center>Kampanya 1 Mart 2022 - 30 Nisan 2022 tarihleri arasında ve seçili mağazalardan yapılan alışverişlerde geçerlidir, detaylı bilgi için <a href='/carrefoursa/katilim-kosullari' target='_blank'><u>https://firsatyaninda.com/carrefoursa/katilim-kosullari</u></a> sayfasını ziyaret edebilirsiniz.</center>",
                  "classes": ["subHeading"],
                  "styles": {
                    "color": "var(--carrefour-color)"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "40px"
                  }
                }
              },
              { // Steps Card
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "10",
                    "marginTop": "40px",
                    "marginBottom": "80px"
                  },
                  "className": "col-md-12 stepsCardMobileWithSubHeader",
                  "fields": [
                    {
                      "fieldType": "step-card",
                      "fieldId": "05m65j",
                      "properties": {
                        "stepNumber": "1",
                        "stepStyles": {
                          "background": "rgb(230, 245, 235)",
                          "color": "#009639",
                          "fontFamily": "Jost"
                        },
                        "imageSource": "/assets/steps/carrefour/1.svg",
                        "arrowImageSource": "/assets/steps/carrefour/arrow.svg",
                        "grid": {
                          "columns": 4
                        },
                        "fields": [
                          {
                            "fieldType": "paragraph",
                            "fieldId": "psv9ff",
                            "properties": {
                              "color": "var(--carrefour-color)",
                              "text": "P&G ürünlerini <b>SATIN AL</b>",
                              "classes": ["stepCard"]
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "step-card",
                      "fieldId": "ec7jsf",
                      "properties": {
                        "stepNumber": "2",
                        "stepStyles": {
                          "background": "rgb(230, 245, 235)",
                          "color": "#009639",
                          "fontFamily": "Jost"
                        },
                        "imageSource": "assets/steps/carrefour/2.svg",
                        "arrowImageSource": "/assets/steps/carrefour/arrow.svg",
                        "grid": {
                          "columns": 4
                        },
                        "fields": [
                          {
                            "fieldType": "paragraph",
                            "fieldId": "6sjsfj",
                            "properties": {
                              "color": "var(--carrefour-color)",
                              "text": "Fişini/faturanı <b>YÜKLE</b>",
                              "classes": ["stepCard"]
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "step-card",
                      "fieldId": "16pk71",
                      "properties": {
                        "stepNumber": "3",
                        "stepStyles": {
                          "background": "rgb(230, 245, 235)",
                          "color": "#009639",
                          "fontFamily": "Jost"
                        },
                        "imageSource": "/assets/steps/carrefour/3.svg",
                        "arrowImageSource": "/assets/steps/carrefour/arrow.svg",
                        "grid": {
                          "columns": 4
                        },
                        "fields": [
                          {
                            "fieldType": "paragraph",
                            "fieldId": "qo8x4a",
                            "properties": {
                              "color": "var(--carrefour-color)",
                              "text": "%20 para iadesi <b>KAZAN</b>",
                              "classes": ["stepCard"]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              { // Buttons
                "fieldType": "button-component",
                "fieldId": "x1p9hx",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "90px"
                  },
                  "styles": {
                    "background-color": "var(--carrefour-color)"
                  },
                  "text": "BAŞLA",
                  "disabled": false,
                  "addClass": "nextBtn col-11",
                  "action": [
                    {
                      "fn": "goToPage",
                      "params": "carrefoursa/fis-yukleme"
                    }
                  ]
                }
              }

            ]
          }
        ]
      },
      { // Receipt Uploader
        "pageName": "carrefoursa/fis-yukleme",
        "locale": "tr",
        "pageId": 403,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "tj4j49",
                "properties": {
                  "activeIndex": 1,
                  "colorSchema": "var(--carrefour-color)",
                  "campaignName": "carrefour",
                  "steps": [
                    {
                      "title": "P&G ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%20 para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // Header
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "<center>Lütfen fişinizi/faturanızı yükleyiniz</center>",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "<center>Bu adımda, P&G alışverişinizi içeren fiş ya da faturanızı yükleyiniz.</center>",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              }
            ]
          },
          { // Receipt Info and Receipt Upload
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "10"
                  },
                  "styles": {
                    "justifyContent": "center !important"
                  },
                  "fields": [
                    { // Receipt Info Desktop
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fa",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-9 pb-4 receiptInfo desktopOnly",
                        "fields": [
                          {
                            "fieldType": "receipt-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                              "steps": [
                                {
                                  "text": "Satış noktası",
                                  "label": "A"
                                },
                                {
                                  "text": "Satın alma tarihi ve saati",
                                  "label": "B"
                                },
                                {
                                  "text": "Ürünler ve satın alma fiyatları",
                                  "label": "C"
                                },
                                {
                                  "text": "Toplam fiş/fatura ve KDV tutarı",
                                  "label": "D"
                                },
                                {
                                  "text": "Fiş/Fatura Numarası",
                                  "label": "E"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Receipt Processor
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fb",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-9 pb-4 receiptUpload",
                        "fields": [
                          {
                            "fieldType": "receipt-processor",
                            "fieldId": "klvakj",
                            "properties": {
                              texts: {
                                choose: 'Bir Dosya Seçin',
                                receiptUpload1: 'Fişinizi/Faturanızı yüklemek için sürükleyip bırakınız.',
                                receiptUpload2: 'Yalnızca JPEG, PNG ve PDF desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                                receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                                wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, .png, .pdf ve .tiff dosyaları kabul edilir.',
                                fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                              },
                              "subCampaignId": "carrefour-30-cb",
                              "colorSchema": "var(--carrefour-color)",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "addClass": "p-0",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "carrefoursa/banka-bilgileri"
                              },
                              "phrases": [
                                "Ihr Beleg wird jetzt hochgeladen und bearbeitet.",
                                "Der Beleg wird automatisch bearbeitet. Dies kann eine Minute dauern. Bitte haben Sie Geduld.",
                                "Sollten Sie eine Fehlermeldung erhalten, nehmen Sie bitte eine bessere Version des Belegs auf und laden Sie ihn erneut hoch."
                              ],
                              "cornerDetectionPhrases": [
                                "Lütfen gri dikdörtgeni fiş ile eşleşecek şekilde ayarlayın."
                              ],
                              "eraseColor": "#ffffff",
                              "eraseSize": 12,
                              "cropColor": "#3A96F3",
                              "cropBackground": "rgba(0,0,0,0.6)",
                              "cropTitle": "Kes",
                              "eraseTitle": "Düzenle",
                              "dragTitle": "Hareket ettir",
                              "zoomInTitle": "Yakınlaştır",
                              "zoomOutTitle": "Uzaklaştır",
                              "selectImageTitle": "Select an Image",
                              "selectAnotherTitle": "Select Another",
                              "controlsBackground": "#4193d3",
                              "backgroundColorBtn": "var(--carrefour-color)"
                            }
                          }
                        ]
                      }
                    },
                    { // Receipt Info Desktop
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fc",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-9 pb-4 receiptInfo desktopNone",
                        "fields": [
                          {
                            "fieldType": "receipt-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                              "steps": [
                                {
                                  "text": "Satış noktası",
                                  "label": "A"
                                },
                                {
                                  "text": "Satın alma tarihi ve saati",
                                  "label": "B"
                                },
                                {
                                  "text": "Ürünler ve satın alma fiyatları",
                                  "label": "C"
                                },
                                {
                                  "text": "Toplam fiş/fatura ve KDV tutarı",
                                  "label": "D"
                                },
                                {
                                  "text": "Fiş/Fatura Numarası",
                                  "label": "E"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "marginBottom": "35px",
              "marginTop": "40px"
            },
            buttonsWrap: true,
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "10"
                  },
                  "className": "btnWrapper",
                  "fields": [
                    { // Back
                      "fieldType": "button-component",
                      "fieldId": "gfdg32",
                      "properties": {
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "text": "Geri",
                        "styles": {
                          "borderColor": "var(--carrefour-color)",
                          "color": "var(--carrefour-color)"
                        },
                        "addClass": "col-sm-8 col-xl-3 backBtn btnDesktopOnly",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "carrefoursa"
                        }
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "cwer23452q",
                      "properties": {
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "addClass": "col-sm-8 col-xl-3 nextBtn",
                        "text": "İleri",
                        "styles": {
                          "backgroundColor": "var(--carrefour-color)"
                        },
                        "shouldCheckCrop": true,
                        "requires": ["klvakj"],
                        "action": {
                          "fn": "sendReceipt",
                          "params": ""
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Registration
        "pageName": "carrefoursa/uye-ol",
        "locale": "tr",
        "pageId": 404,
        "sections": [
          { // Steps, Image and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12,
              "paddingBottom": "20px"
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "6gx3um",
                "properties": {
                  "activeIndex": 2,
                  "colorSchema": "var(--carrefour-color)",
                  "campaignName": "carrefour",
                  "steps": [
                    {
                      "title": "P&G ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%20 para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // Image
                "fieldId": "vn0934kf",
                "fieldType": "square-image",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "marginTop": "81px",
                    "marginBottom": "20px",
                    "padding": "0",
                    "maxHeight": "50px",
                    "maxWidth": "125px"
                  },
                  "className": "imgMobile",
                  "imageSource": "/assets/logo/reg_logo@1x.png",
                  "imageSource2": "/assets/logo/reg_logo@2x.png"
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "fckzg7",
                "properties": {
                  "text": "<center>Fırsatlardan haberdar olmak için kayıt olunuz</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginTop": "10px"
                  },
                  "classes": ['heading']
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "ch2fio",
                "properties": {
                  "text": `<center>"Kadınlar Bilir", P&G'nin bir pazarlama platformudur. Bu platform içerisindeki diğer kampanyalar hakkında bilgi almak için şimdi kayıt olun ve fırsatları kaçırmayın.</center>`,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ['subHeading']
                }
              }
            ]
          },
          { // Forms
            "sectionId": "ci88zt",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 4
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "inzf4w",
                "properties": {
                  "styles": {
                    "margin": "0",
                    "alignItems": "center",
                    "flexDirection": "column"
                  },
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "requires": [
                    "1"
                  ],
                  "successAction": {
                    "fn": "goToPage",
                    "params": "carrefoursa/kayit-tamamlandi"
                  },
                  "endpoint": "/register",
                  "fields": [
                    { // Inputs
                      "fieldType": "empty-bucket",
                      "fieldId": "4h3gweb",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          { // Gender
                            "fieldType": "paragraph",
                            "fieldId": "yh54weg",
                            "properties": {
                              "text": "Cinsiyet *",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              classes: ["formTitle"]
                            }
                          },
                          { // Gender Radio Input
                            "fieldType": "radio-input",
                            "fieldId": "Geschlecht",
                            "name": "Cinsiyet*",
                            "properties": {
                              "text": "",
                              "name": "gender",
                              "colorSchema": "var(--carrefour-color)",
                              "grid": {
                                "columns": 12
                              },
                              "validation": "required",
                              "direction": "row",
                              "options": [
                                {
                                  "value": "female",
                                  "text": "Kadın",
                                  "disabled": false,
                                  "name": "firstRadio"
                                },
                                {
                                  "value": "male",
                                  "text": "Erkek",
                                  "disabled": false,
                                  "name": "secondRadio"
                                }
                              ]
                            }
                          },
                          { // First Name
                            "fieldId": "b4baqs",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "firstName",
                              "id": "firstName",
                              "label": "İsim*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|alpha",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              }
                            }
                          },
                          { // Last Name
                            "fieldId": "gofije",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "lastName",
                              "id": "lastName",
                              "label": "Soyisim*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|alpha",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // Birth day
                            "fieldId": "godate",
                            "fieldType": "date-input",
                            "properties": {
                              "name": "birthdate",
                              "id": "birthdate",
                              "label": "Doğum tarihi",
                              "validation": "birthdate",
                              "grid": {
                                "columns": "12"
                              },
                              "params": {
                                "disabledDates": {
                                  "from": "18years",
                                  "to": "01.01.1800"
                                }
                              }
                            }
                          },
                          { // Email
                            "fieldId": "h7kzf9",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "email",
                              "id": "email",
                              "label": "E-posta adresi*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|email",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // Password
                            "fieldId": "sgol2x",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "password",
                              "id": "password",
                              "label": "Parola*",
                              "inputType": "password",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|password",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Check Boxes
                      "fieldId": "8k2f023f",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          { // Check box Age Consent
                            "fieldType": "paragraph",
                            "fieldId": "wgc3j7",
                            "properties": {
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "styles": {
                                marginLeft: "50px",
                                "margin-top": "30px"
                              },
                              "text": `<a href="/pg-aydinlatma-metni" target="_blank"><b><u>İnternet Sitesi / Mobil Uygulama Kullanıcısı Aydınlatma Metni</u></b></a> kapsamında; kişisel verilerinizin Procter & Gamble Tüketim Malları Sanayi Anonim Şirketi (P&G) tarafından aşağıdaki amaçlarla işlenmesini kabul ediyorum.`
                            }
                          },
                          { // Second Checkbox
                            "fieldType": "checkbox",
                            "fieldId": "psyn1c",
                            "properties": {
                              "name": "receiveEmails",
                              "id": "receiveEmails",
                              "validation": {
                                "required": {
                                  "allowFalse": true
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "label": `Kimlik, iletişim, müşteri işlem ve pazarlama verilerimin ürün ve hizmetlerin pazarlama süreçlerinin planlanması ve icrası, profilleme ve analiz faaliyetlerinin yürütülmesi, sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıklarım ve ihtiyaçlarıma göre özelleştirilerek önerilmesi ve tanıtılması amacıyla işlenmesini kabul ediyorum.`
                            }
                          },
                          { // Third Checkbox
                            "fieldType": "checkbox",
                            "fieldId": "psyn1c2",
                            "properties": {
                              "name": "receiveEmails2",
                              "id": "receiveEmails2",
                              "validation": {
                                "required": {
                                  "allowFalse": true
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "label": `Kimlik, iletişim ve pazarlama verilerimin paylaştığım iletişim bilgilerime reklam, kutlama, promosyon vb. ticari elektronik ileti gönderilmesi amacıyla işlenmesini kabul ediyorum.`
                            }
                          },
                          { // Fourth Checkbox
                            "fieldType": "checkbox",
                            "fieldId": "psyn1c3",
                            "properties": {
                              "name": "receiveEmails3",
                              "id": "receiveEmails3",
                              "validation": {
                                "required": {
                                  "allowFalse": true
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "label": `Kimlik ve görüntü (fotoğraf, ses, video) verilerim ile yorum içeriklerimin, ilgili ürün ve hizmetlerin ve katılmam halinde ilgili yarışma, çekiliş, kampanyanın görselleştirilerek tanıtılması amacıyla kullanılması ve kamuya açık olarak P&G’nin sosyal medya hesaplarında, internet sitelerinde, Şirket içi ve dışı sair mecralarda yayımlanması amacıyla işlenmesini kabul ediyorum.`
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "yh453gg4",
            "grid": {
              "columns": "12",
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "40px",
              "marginTop": "40px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "y4h3gwh",
                "properties": {
                  grid: {
                    columns: "6"
                  },
                  "className": "col-md-6 col-xl-4",
                  fields: [
                    { // Next Button
                      "fieldType": "button-component",
                      "fieldId": "5n4b3vg4",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "text": "Kayıt Ol",
                        "styles": {
                          "backgroundColor": "var(--carrefour-color)"
                        },
                        "addClass": "nextBtn",
                        "disabled": false,
                        "action": {
                          "fn": "sendForm",
                          "params": "inzf4w"
                        }
                      }
                    },
                    { // Login
                      "fieldType": "button-component",
                      "fieldId": "9o8k7ujyth",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "0",
                          "marginTop": "40px"
                        },
                        "text": "Kadınlar Bilir hesabı ile Giriş Yap",
                        "addClass": "loginBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "carrefoursa/giris-yap"
                        }
                      }
                    },
                    { // Guest Login
                      "fieldType": "button-component",
                      "fieldId": "23vb4n5",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px"
                        },
                        "text": "Kayıt olmadan misafir kullanıcı olarak devam edin",
                        "addClass": "guestBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "carrefoursa/misafir-uye"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Guest Registration
        "pageName": "carrefoursa/misafir-uye",
        "locale": "tr",
        "pageId": 405,
        "sections": [
          { // Steps and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12,
              "paddingBottom": "20px"
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "6gx3um",
                "properties": {
                  "activeIndex": 2,
                  "colorSchema": "var(--carrefour-color)",
                  "campaignName": "carrefour",
                  "steps": [
                    {
                      "title": "P&G ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%20 para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "fckzg7",
                "properties": {
                  "text": "<center>Misafir kullanıcı olarak devam ediniz</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginTop": "10px"
                  },
                  "classes": ['heading']
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "ch2fio",
                "properties": {
                  "text": `<center>"Kadınlar Bilir" hesabı oluşturmak istememene çok üzüldük. Eğer fikrinizi değiştirirseniz, her zaman <a href="https://www.kadinlarbilir.com" target="_blank">Kadinlarbilir.com</a>’a girerek kayıt olabilirsiniz.<br/><br/>
                  Katılımınızı tamamlamak için aşağıdaki bilgileri doldurmanızı rica ederiz.
                  </center>`,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ['subHeading']
                }
              }
            ]
          },
          { // Form
            "sectionId": "ci88zt",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12"
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "j765n4b",
                "properties": {
                  "styles": {
                    "margin": "0",
                    "alignItems": "center",
                    "flexDirection": "column"
                  },
                  "grid": {
                    "alignItems": "center",
                    "columns": "12",
                    "justifyContent": "center"
                  },
                  "requires": [
                    "1"
                  ],
                  "successAction": {
                    "fn": "goToPage",
                    "params": "carrefoursa/kayit-tamamlandi"
                  },
                  "endpoint": "/registerAsGuest",
                  "fields": [
                    { // Input
                      "fieldType": "empty-bucket",
                      "fieldId": "4h3gweb",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          { // Gender
                            "fieldType": "paragraph",
                            "fieldId": "yh54weg",
                            "properties": {
                              "text": "Cinsiyet *",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              classes: ["formTitle"]
                            }
                          },
                          { // Gender Radio
                            "fieldType": "radio-input",
                            "fieldId": "Geschlecht",
                            "name": "Cinsiyet*",
                            "properties": {
                              "text": "",
                              "name": "gender",
                              "colorSchema": "var(--carrefour-color)",
                              "grid": {
                                "columns": 12
                              },
                              "validation": "required",
                              "direction": "row",
                              "options": [
                                {
                                  "value": "female",
                                  "text": "Kadın",
                                  "disabled": false,
                                  "name": "firstRadio"
                                },
                                {
                                  "value": "male",
                                  "text": "Erkek",
                                  "disabled": false,
                                  "name": "secondRadio"
                                }
                              ]
                            }
                          },
                          { // First Name
                            "fieldId": "b4baqs",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "firstName",
                              "id": "firstName",
                              "label": "İsim*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|alpha",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              }
                            }
                          },
                          { // Last Name
                            "fieldId": "gofije",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "lastName",
                              "id": "lastName",
                              "label": "Soyisim*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|alpha",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // Email
                            "fieldId": "h7kzf9",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "email",
                              "id": "email",
                              "label": "E-posta adresi*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|email",
                              "grid": {
                                "columns": 12
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Check Boxes
                      "fieldId": "8k2f023f",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          { // Check box Age Consent
                            "fieldType": "checkbox",
                            "fieldId": "wgc3j7",
                            "properties": {
                              "name": "terms",
                              "id": "terms",
                              "validation": {
                                "required": {
                                  "allowFalse": false
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "styles": {
                                "margin-top": "30px"
                              },
                              "label": `Kayıt olmak için 18 yaşından büyüğüm ve <a href="https://www.pg.com/tr_TR/terms_conditions/index.shtml" target="_blank"><u><b>Kullanım Koşullarını</u></b></a> okuyup, kabul ediyorum.`
                            }
                          },
                          { // Second Checkbox
                            "fieldType": "checkbox",
                            "fieldId": "psyn1c",
                            "properties": {
                              "name": "receiveEmails",
                              "id": "receiveEmails",
                              "validation": {
                                "required": {
                                  "allowFalse": true
                                }
                              },
                              "grid": {
                                "columns": "12",
                                "paddingTop": "10px"
                              },
                              "label": `<a href="https://www.kadinlarbilir.com/-/media/801c38ae896348a59cf5f727d3f60061.ashx" target="_blank"> <u><b>Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni</b></u></a> kapsamında kişisel verilerimin P&G markaları tarafından bilgilendirme, promosyon, kampanya, pazarlama süreçlerinin planlanması ve icrası amacıyla; ürün ve hizmetlerin beğenilerime, kullanım alışkanlıklarıma ve ihtiyaçlarıma göre özelleştirilmesi için işlenmesi ve yukarıda paylaştığım iletişim bilgilerime reklam, promosyon, kampanya ve benzeri ticari elektronik ileti gönderilmesini ve bu amaçla P&G’nin hizmet aldığı tedarikçilere paylaşılmasını kabul ediyorum.<br>`
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "yh453gg4",
            "grid": {
              "columns": "12",
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "40px",
              "marginTop": "40px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "y4h3gwh",
                "properties": {
                  grid: {
                    columns: "6"
                  },
                  "className": "col-md-6 col-xl-4",
                  fields: [
                    { // Next Button
                      "fieldType": "button-component",
                      "fieldId": "5n4b3vg4",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "text": "Kazan",
                        "styles": {
                          "backgroundColor": "var(--carrefour-color)"
                        },
                        "addClass": "nextBtn",
                        "disabled": false,
                        "action": {
                          "fn": "sendForm",
                          "params": "j765n4b"
                        }
                      }
                    },
                    { // Login
                      "fieldType": "button-component",
                      "fieldId": "9o8k7ujyth",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "0",
                          "marginTop": "40px"
                        },
                        "text": "Kadınlar Bilir hesabı ile Giriş Yap",
                        "addClass": "loginBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "carrefoursa/giris-yap"
                        }
                      }
                    },
                    { // Guest Login
                      "fieldType": "button-component",
                      "fieldId": "23vb4n5",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px"
                        },
                        "text": "Kadınlar Bilir’e kayıt olarak giriş yap",
                        "addClass": "guestBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "carrefoursa/uye-ol"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Login
        "pageName": "carrefoursa/giris-yap",
        "locale": "tr",
        "pageId": 406,
        "sections": [
          { // Steps and Headings
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12,
              "paddingBottom": "20px"
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "6gx3um",
                "properties": {
                  "activeIndex": 2,
                  "colorSchema": "var(--carrefour-color)",
                  "campaignName": "carrefour",
                  "steps": [
                    {
                      "title": "P&G ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%20 para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // Image
                "fieldId": "vn0934kf",
                "fieldType": "square-image",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "marginTop": "81px",
                    "marginBottom": "20px",
                    "padding": "0",
                    "maxHeight": "50px",
                    "maxWidth": "125px"
                  },
                  "className": "imgMobile",
                  "imageSource": "/assets/logo/reg_logo@1x.png",
                  "imageSource2": "/assets/logo/reg_logo@2x.png"
                }
              },
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "fckzg7",
                "properties": {
                  "text": `<center>"Kadınlar Bilir" hesabı ile giriş yap</center>`,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginTop": "10px"
                  },
                  "classes": ['heading']
                }
              }
            ]
          },
          { // Form
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "jc3v455",
                "properties": {
                  "styles": {
                    "margin": "0",
                    "justifyContent": "center"
                  },
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    columns: 12
                  },
                  "requires": [
                    "1"
                  ],
                  "successAction": {
                    "fn": "goToPage",
                    "params": "carrefoursa/kayit-tamamlandi"
                  },
                  "endpoint": "/login",
                  "fields": [
                    { // Inputs
                      "fieldId": "fu2309b",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4",
                        "fields": [
                          {
                            "fieldId": "n6b54v",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "email",
                              "id": "email",
                              "label": "E-posta adresi*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|email",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              }
                            }
                          },
                          {
                            "fieldId": "6h54bdd",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "password",
                              "id": "password",
                              "label": "Parola*",
                              "inputType": "password",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|password",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          {
                            "fieldType": "anchor",
                            "fieldId": "fckzg7",
                            "properties": {
                              "text": "Parolanızı mı unuttunuz?",
                              "action": {
                                "fn": "goToPage",
                                "params": "carrefoursa/parola"
                              },
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "cssClass": "forgotPass"
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Button and text
            "sectionId": "vbniw03",
            "grid": {
              "columns": "12",
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "40px",
              "marginTop": "32px"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "vb892nwq",
                "properties": {
                  grid: {
                    columns: "6"
                  },
                  "className": "col-md-6 col-xl-4",
                  "styles": {
                    "justifyContent": "center"
                  },
                  fields: [
                    { // Button
                      "fieldType": "button-component",
                      "fieldId": "5n4b3vg4",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "30px"
                        },
                        "text": "Giriş Yap",
                        "styles": {
                          "backgroundColor": "var(--carrefour-color)"
                        },
                        "addClass": "nextBtn",
                        "disabled": false,
                        "action": {
                          "fn": "sendForm",
                          "params": "jc3v455"
                        }
                      }
                    },
                    { // Text
                      "fieldType": "paragraph",
                      "fieldId": "fckzg7",
                      "properties": {
                        "text": `Henüz "Kadınlar Bilir" hesabınız yok mu?`,
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        "classes": ["loginText"]
                      }
                    },
                    { // Registration
                      "fieldType": "button-component",
                      "fieldId": "9o8k7ujyth",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginBottom": "0",
                          "marginTop": "40px"
                        },
                        "text": "Kadınlar Bilir’e kayıt olarak giriş yap",
                        "addClass": "loginBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "carrefoursa/uye-ol"
                        }
                      }
                    },
                    { // Guest Registration
                      "fieldType": "button-component",
                      "fieldId": "23vb4n5",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px"
                        },
                        "text": "Kayıt olmadan misafir olarak devam edin",
                        "addClass": "guestBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "carrefoursa/misafir-uye"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Address
        "pageName": "carrefoursa/adres",
        "locale": "tr",
        "pageId": 407,
        "requires": [
          1
        ],
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "<center>Adres bilgileriniz</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "paddingBottom": "90px"
                  },
                  "classes": ["heading"]
                }
              }
            ]
          },
          { // Form
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Form
                "fieldType": "form-component",
                "fieldId": "adres",
                "properties": {
                  "successAction": {
                    "fn": "goToPage",
                    "params": "carrefoursa/kayit-tamamlandi"
                  },
                  "endpoint": "/submitAddress",
                  "keepInState": ["referenceNumber"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "fields": [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "fw90342ol",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "6"
                        },
                        "className": "col-md-6 col-xl-4 justify-content-center",
                        "fields": [
                          { // City
                            "fieldId": "jdtxik",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "ort",
                              "id": "ort",
                              "label": "Şehir*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|street",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // Street
                            "fieldId": "b5q97u",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "strasse",
                              "id": "strasse",
                              "label": "Sokak*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|street",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },
                          { // House Number
                            "fieldId": "ql5mu4",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "hausnr",
                              "id": "hausnr",
                              "label": "Numara",
                              "placeholder": "",
                              "text": "",
                              "validation": "street",
                              "className": "col-6",
                              "grid": {
                                "columns": 6,
                                "paddingRight": "2.5px !important"
                              }
                            }
                          },
                          { // Postal code
                            "fieldId": "ql6mu5",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "plz",
                              "id": "plz",
                              "label": "Posta kodu*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|street",
                              "className": "col-6",
                              "grid": {
                                "columns": 6,
                                "paddingLeft": "2.5px !important"
                              }
                            }
                          },
                          { // Land
                            "fieldId": "x3d8oz",
                            "fieldType": "select-input",
                            "properties": {
                              "name": "land",
                              "id": "land",
                              "label": "Land",
                              "placeholder": "Ülke*",
                              "text": "",
                              "validation": "required",
                              "options": [
                                {
                                  "text": "Türkiye",
                                  "value": "Turkey"
                                }
                              ],
                              "selectedValue": "",
                              "grid": {
                                "columns": 12
                              }
                            }
                          },

                          { // Buttons
                            "fieldType": "empty-bucket",
                            "fieldId": "fw90342ol",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "8",
                                "paddingTop": "20px",
                                "paddingBottom": "100px"
                              },
                              "className": "btnMobile",
                              "fields": [
                                { // Next Button
                                  "fieldType": "button-component",
                                  "fieldId": "16tzm2",
                                  "properties": {
                                    "grid": {
                                      "columns": "12",
                                      "alignItems": "center",
                                      "justifyContent": "center"
                                    },
                                    "text": "İleri",
                                    "styles": {
                                      "backgroundColor": "var(--carrefour-color)"
                                    },
                                    "addClass": "nextBtn",
                                    "disabled": false,
                                    "action": {
                                      "fn": "sendForm",
                                      "params": "adres"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Forgot Password
        "pageName": "carrefoursa/parola",
        "locale": "tr",
        "pageId": 408,
        "sections": [

          { // Image
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "paddingTop": "50px"
            },
            fields: [
              {
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/images/forgotPassword.svg",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "4"
                  },
                  "className": "forgotImageMobile"
                }
              }
            ]
          },
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "gjd10",
                "properties": {
                  "text": "<center>Parolanızı mı unuttunuz?</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "50px"
                  },
                  "classes": ["heading"]
                }
              }
            ]
          },
          { // Form and Button
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Form
                "fieldType": "form-component",
                "fieldId": "9hj453vnv3e",
                "properties": {
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": 12
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "successAction": {
                    "fn": "forgotPasswordSuccessAction",
                    "params": "carrefoursa"
                  },
                  "endpoint": "/forgetPassword",
                  "fields": [
                    {
                      "fieldId": "f9234pg",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "4"
                        },
                        "className": "col-sm-8 col-md-6 justify-content-center",
                        "fields": [
                          { // Email
                            "fieldId": "h34gwb",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "email",
                              "id": "email",
                              "label": "E-posta adresi*",
                              "placeholder": "",
                              "text": "",
                              "validation": "required|email",
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              }
                            }
                          },
                          { // Button
                            "fieldId": "f9234pg",
                            "fieldType": "empty-bucket",
                            "properties": {
                              "grid": {
                                "columns": "8",
                                "paddingBottom": "100px"
                              },
                              "className": "col-sm-8 col-md-6 btnMobile",
                              "fields": [
                                { // Button
                                  "fieldType": "button-component",
                                  "fieldId": "h34wevdss",
                                  "properties": {
                                    "text": "İleri",
                                    "grid": {
                                      "columns": "12",
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginTop": "40px",
                                      "marginBottom": "81px"
                                    },
                                    "addClass": "nextBtn",
                                    "styles": {
                                      "backgroundColor": "var(--carrefour-color)"
                                    },
                                    "disabled": false,
                                    "action": {
                                      "fn": "sendForm",
                                      "params": "9hj453vnv3e"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Bank Info
        "pageName": "carrefoursa/banka-bilgileri",
        "locale": "tr",
        "pageId": 409,
        "requires": [
          1
        ],
        "sections": [
          { // Steps and Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },
            "fields": [
              { // Steps
                "fieldType": "step-counter",
                "fieldId": "wabb03",
                "properties": {
                  "activeIndex": 2,
                  "colorSchema": "var(--carrefour-color)",
                  "campaignName": "carrefour",
                  "steps": [
                    {
                      "title": "P&G ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%20 para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              { // heading
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "<center>Banka hesap bilgilerinizi giriniz</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ['heading']
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "<center>Lütfen geri ödeme tutarının aktarılacağı hesabınızın bilgilerini giriniz.</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  },
                  "classes": ['subHeading']
                }
              }
            ]
          },
          { // Form
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "bank1Nf0",
                "properties": {
                  "successAction": {
                    "fn": "goToPage",
                    "params": "carrefoursa/uye-ol"
                  },
                  "endpoint": "/submitIBAN",
                  "keepInState": ["referenceNumber"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "fields": [
                    { // form
                      "fieldType": "empty-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "paddingTop": "30px",
                          "paddingBottom": "30px"
                        },
                        "className": "col-md-6 col-xl-4 formLayout",
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": "2"
                              },
                              "className": "ibanLabelWrapper col-2",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "tw3qab",
                                  "properties": {
                                    "grid": {
                                      "columns": "12"
                                    },
                                    "text": "TR",
                                    "classes": ["ibanLabel"]
                                  }
                                }
                              ]
                            }
                          },
                          { // IBAN
                            "fieldId": "h9arlr",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "iban",
                              "id": "iban",
                              "validation": "required|iban",
                              "label": "IBAN*",
                              "placeholder": "",
                              "text": "",
                              "className": "col-10",
                              "grid": {
                                "columns": "10",
                                "paddingLeft": "5px !important"
                              }
                            }
                          },
                          { // trNumber
                            "fieldId": "7rNumb2r",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "tckn",
                              "id": "tckn",
                              "label": "T.C. Kimlik Numarası*",
                              "validation": "required|identityCard",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // trMobile
                            "fieldId": "7rMob2le",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "mobile",
                              "id": "mobile",
                              "label": "Telefon Numarası*",
                              "validation": "required|phoneNumber",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // First Name
                            "fieldId": "tp6zfb",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "firstname",
                              "id": "firstname",
                              "label": "Hesap Sahibi Adı*",
                              "validation": "required|ibanName",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // Last Name
                            "fieldId": "p55fao",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "lastname",
                              "id": "lastname",
                              "validation": "required|ibanName",
                              "label": "Hesap Sahibi Soyadı*",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12,
              "alignItems": "center"
            },
            buttonsWrap: true,
            "fields": [
              { // back Button
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px",
                    "marginBottom": "100px"
                  },
                  "text": "Geri",
                  "addClass": "col-sm-4 col-xl-3 backBtn",
                  "action": {
                    "fn": "goToPage",
                    "params": "carrefoursa/fis-yukleme"
                  }
                }
              },
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px",
                    "marginBottom": "100px"
                  },
                  "addClass": "col-sm-4 col-xl-3 nextBtn",
                  "text": "İleri",
                  "styles": {
                    "backgroundColor": "var(--carrefour-color)"
                  },
                  "disabled": false,
                  "action": {
                    "fn": "sendForm",
                    "params": "bank1Nf0"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Thank you page
        "pageName": "carrefoursa/kayit-tamamlandi",
        "locale": "tr",
        "pageId": 410,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              'maxWidth': '1270px',
              "margin": "auto"
            },
            "fields": [
              {
                fieldId: "dte-003",
                fieldType: "dataLayer-event",
                properties: {
                  eventName: 'consumerIdSend',
                  sendconsumerId: true
                }
              },
              { // Thank you Texts and Reference Number
                "fieldType": "empty-bucket",
                "fieldId": "05m65ds",
                "properties": {
                  "grid": {
                    columns: 8,
                    "marginTop": "40px",
                    "marginBottom": "50px"
                  },
                  "styles": {
                    "padding": "0",
                    "flexDirection": "column",
                    "alignItems": "center"
                  },
                  fields: [
                    { // Image
                      "fieldId": "vn0934kf",
                      "fieldType": "square-image",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "styles": {
                          "marginBottom": "20px",
                          "padding": "0"
                        },
                        "className": "thankyouImage",
                        "imageSource": "/assets/success.svg",
                        "imageSource2": "/assets/success.svg"
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "88z8gv",
                      "properties": {
                        "text": "<center>Tebrikler, katılımınız tamamlandı!</center>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        "classes": ["heading"]
                      }
                    },
                    { // Sub Heading
                      "fieldType": "paragraph",
                      "fieldId": "88z8gv",
                      "properties": {
                        "variables": {
                          "referenceNumber": "referenceNumber"
                        },
                        "text": `<center>
                          Fişiniz/faturanız bize gönderildi ve şimdi kontrol ediliyor.
                            <br/><br/>
                          <strong>Referans numarası: {referenceNumber}</strong>
                            <br/><br/>
                          Bilgilerinizi kontrol ettikten sonra, eğer katılım koşullarımıza uygunsa bir onay e-postası alacaksınız. Başvurunuzda bir eksiklik olması halinde, sizinle tekrar iletişime geçeceğiz. Bu süreç 36 saate kadar sürebilir. Sizinle iletişime geçene kadar beklemenizi rica ederiz.
                            <br/><br/>
                          Satın alma fişini/faturasını ve diğer tüm bilgileri başarılı bir şekilde kontrol ettikten sonra eğer katılım koşullarına uyuyorsa, geri ödeme tutarını 2 ile 4 hafta içerisinde belirttiğiniz hesaba aktaracağız.
                        </center>`,
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        classes: ["subHeading"]
                      }
                    },
                    { // Key Visual
                      "fieldType": "key-visual",
                      "fieldId": "296eg1",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "noContainer": true,
                        "desktopImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/f392186e-a27e-49ec-b7d3-a3fc1eee8413.jpeg",
                        "tabletImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/06321dd8-0d30-4e2d-90a0-ad1954929dd6.jpeg",
                        "mobileImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/803369c2-dcf5-4027-b2e7-923a26cd99a2.jpeg"
                      }
                    },
                    { // Button
                      "fieldType": "button-component",
                      "fieldId": "oql2de",
                      "properties": {
                        "text": "Başlangıç   sayfasına geri dön",
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px",
                          "marginBottom": "90px"
                        },
                        "addClass": "nextBtn",
                        "styles": {
                          "backgroundColor": "var(--carrefour-color)"
                        },
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.firsatyaninda.com/carrefoursa"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product Upload page
        "pageName": "carrefoursa/ürün-görseli-yükleme",
        "locale": "tr",
        "pageId": 412,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "vgw364bh",
            "fields": [
              {
                "fieldType": "step-counter",
                "fieldId": "q2f34yg",
                "properties": {
                  "activeIndex": 1,
                  "colorSchema": "var(--carrefour-color)",
                  "campaignName": "carrefour",
                  "steps": [
                    {
                      "title": "P&G ürünlerini <b>SATIN AL</b>",
                      "page": "ürün-ekle"
                    },
                    {
                      "title": "Fişini/faturanı <b>YÜKLE</b>",
                      "page": "fis-yukleme"
                    },
                    {
                      "title": "%20 para iadesi <b>KAZAN</b>",
                      "page": "banka-bilgileri"
                    }
                  ]
                }
              },
              {
                "fieldType": "paragraph",
                "fieldId": "2fvjh65",
                "properties": {
                  "text": "<center><h2>Lütfen ürününüzün fotoğrafını yükleyiniz</h2></center>",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              {
                "fieldType": "paragraph",
                "fieldId": "b893274rf",
                "properties": {
                  "text": "<center>Lütfen ürününüzün fotoğrafını çekip yükleyiniz, <br><br> Ürün görselinin net bir şekilde göründüğünden emin olunuz.</center>",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              }
            ]
          },
          {
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center !important",
                    "maxWidth": "871px !important",
                    padding: 0
                  },
                  "className": "col-sm-9 col-xl-6",
                  "fields": [
                    {
                      "fieldType": "product-upload",
                      "fieldId": "3sxumo",
                      "properties": {
                        texts: {
                          choose: 'Dosya Seç',
                          receiptUpload1: 'Ürünün fotoğrafını yükleyiniz.',
                          receiptUpload2: 'Yalnızca JPEG veya PNG formatları desteklenir.<br/><br/> Maksimum dosya boyutu: 16 MB',
                          wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg veya .png dosyaları kabul edilir.',
                          fileSizeLimit: 'Bu dosya çok büyük. Yalnızca 16 MB a kadar olan dosyalar yüklenebilir.'
                        },
                        "colorSchema": "var(--carrefour-color)",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "endpoint": "/uploadProductPhoto",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "carrefoursa/banka-bilgileri"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          {
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "marginBottom": "35px",
              "marginTop": "40px"
            },
            buttonsWrap: true,
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "10"
                  },
                  "className": "btnWrapper",
                  "fields": [
                    { // Back
                      "fieldType": "button-component",
                      "fieldId": "gfdg32",
                      "properties": {
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "text": "Geri",
                        "styles": {
                          "borderColor": "var(--carrefour-color)",
                          "color": "var(--carrefour-color)"
                        },
                        "addClass": "col-sm-8 col-xl-3 backBtn",
                        "disabled": false,
                        "action": {
                          "fn": "goToPage",
                          "params": "carrefoursa/fis-yukleme"
                        }
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "cwer23452q",
                      "properties": {
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "addClass": "col-sm-8 col-xl-3 nextBtn",
                        "text": "İleri",
                        "styles": {
                          "backgroundColor": "var(--carrefour-color)"
                        },
                        "shouldCheckCrop": true,
                        "requires": ["3sxumo"],
                        "action": {
                          "fn": "sendProductPhotos",
                          "params": "3sxumo"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // T&C
        "pageName": "carrefoursa/katilim-kosullari",
        "locale": "tr",
        "pageId": 421,
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": "12",
              "margin-bottom": "30px"
            },
            "fields": [
              {
                "fieldType": "text-list",
                "fieldId": "vabd69",
                "properties": {
                  "colorSchema": "#040404;",
                  "title": "<center>Katılım Koşulları</center>",
                  "items": [
                    `<b>P&G Altın Fırsat CarrefourSA'da</b> kampanyasının düzenleyicisi Procter & Gamble Tüketim Malları Sanayi A.Ş'dir ("P&G")'dir.`,
                    `Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter & Gamble Tüketim Malları Sanayi A.Ş'dir ("P&G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.`,
                    `Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.<br /><br />
                    5. maddede belirtilen P&G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan P&G ürünlerinin toplamının %20’si tutarında bir geri ödemeye hak kazanır. Maksimum 30 TL para iadesi yapılır. `,
                    `Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir. <br /><br />
                    Fişiniz/faturanız <b>32 saat içerisinde</b> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) <b>2-4 hafta</b> arasında aktarılır.<br /><br />
                    Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur. `,
                    `<b>Kampanya ürünleri:</b><br />
                    Kampanyaya tüm P&G ürünleri dahildir.`,
                    `<b>Kampanya satış noktaları:</b><br />
                    Promosyon ürünleri yalnızca Carrefour satış noktalarından alınabilir. Online yapılan alışverişler geçerlidir.`,
                    `Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.`,
                    `Satın alma işlemleri için kampanya dönemi 01.03.2022 - 30.04.2022 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 30-04-2022'dir. <b>Kampanyaya katılım 2.000 kişi ile sınırlıdır.</b>`,
                    `Kampanyaya katılmak için, kampanya dönemi boyunca CarrefourSa P&G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <b><a href="https://firsatyaninda.com/carrefoursa" target="_blank">https://firsatyaninda.com/carrefoursa</a></b> adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.`,
                    `Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde <a href="mailto:destek@carrefour.firsatyaninda.com"><b>destek@carrefour.firsatyaninda.com</b></a> destek hattımızla iletişim geçebilirsiniz. `,
                    `Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:<br /><br />
                    - Satış Noktası<br />
                    - Fiş/fatura numarası veya belge numarası<br />
                    - Fişin/Faturanın tarihi ve saati<br />
                    - Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı<br />
                    - Fişin/Faturanın toplam tutarı<br /><br />
                    Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.`,
                    `Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.`,
                    `Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve <b>P&G Altın Fırsat CarrefourSA'da</b> kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.`,
                    `Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.`,
                    `Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.`,
                    `IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır (böyle bir durumda lütfen <a href="mailto:iban@carrefour.firsatyaninda.com"><b>iban@carrefour.firsatyaninda.com</b></a> adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır.`,
                    `<b>P&G Altın Fırsat CarrefourSA'da</b> kampanyası, diğer P&G kampanyaları ile birleştirilemez.`,
                    `P&G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.`,
                    `Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.`,
                    `Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://privacypolicy.pg.com/tr-TR/" target="_blank"><b>https://privacypolicy.pg.com/tr-TR/</b></a>`,
                    `P&G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.`,
                    `Fiyatlandırma, promosyon aktiviteleri, raflandırma ve tanzim teşhir uygulamaları ile ilgili nihai karar CarrefourSa’ya aittir.`,
                    `İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.`
                  ],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  }
                }
              }
            ]
          }
        ]
      },
      { // FAQ
        "pageName": "carrefoursa/sss",
        "locale": "tr",
        "pageId": 422,
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginBottom": "40px"
            },
            "fields": [
              {
                "fieldType": "text-list",
                "fieldId": "a6prst",
                "properties": {
                  "colorSchema": "#040404",
                  "title": "<center>Sıkça Sorulan Sorular</center>",
                  "items": [
                    `<b>P&G Altın Fırsat CarrefourSA'da kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?</b>
                    <br />• Minimum Yaş: En az 18 yaşında olmalısınız.
                    <br />• İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.
                    <br />• IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.
                    <br />• T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası
                    <br />• Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.
                    <br />• E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.
                    <br />Not: Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.
                    `,
                    `<b>P&G Altın Fırsat CarrefourSA'da kampanyasına nasıl katılabilirim?</b>
                    <br />Yalnızca <a href="https://www.firsatyaninda.com/carrefoursa" target="_blank"><b>www.firsatyaninda.com/carrefoursa</b></a> kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:
                    <br />Kampanya ürününün satın alınmasından sonra <a href="https://www.firsatyaninda.com/carrefoursa" target="_blank"><b>www.firsatyaninda.com/carrefoursa</b></a>’a giriniz.
                    <br />Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).
                    <br />Banka bilgilerini ve kişisel verileri sağlayınız.
                    <br />Katılım + veri koruma koşullarını kabul ediniz.
                    `,
                    `<b>Geri ödememi ne zaman alabilirim?</b>
                    <br />Fişiniz/faturanız 32 saat içerisinde kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) 2-4 hafta arasında aktarılacaktır.
                    `,
                    `<b>P&G Altın Fırsat CarrefourSA'da kampanyasına katılmak için hangi ürünleri satın almam gerekiyor?</b>
                    <br />Kampanyaya tüm P&G ürünleri dahildir. 
                    `,
                    `<b>P&G Altın Fırsat CarrefourSA'da kampanyasına katılan ürünleri nereden satın alabilirim?</b>
                    <br />Carrefour. Online yapılan alışverişler geçerlidir. 
                    `,
                    `<b>Ne kadar geri ödeme alacağım?</b>
                    <br />Kampanya ürününü satın aldığını ispat eden kullanıcı fiş/faturasındaki P&G ürünlerinin toplamının %20’si kadarını geri alır. Maksimum 30 TL iade yapılır. 
                    `,
                    `<b>P&G Altın Fırsat CarrefourSA'da kampanyasına ne zaman katılabilirim?</b>
                    <br />Kampanya ürünlerini satın almak için kampanya dönemi 01.03.2022 ile 30.04.2022 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).
                    <br />Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 30.04.2022, 23:59.
                    `,
                    `<b>Kampanyaya ne sıklıkla katılabilirim?</b>
                    <br />Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir. 
                    `,
                    `<b>Kampanyaya kaç ürün ile katılabilirim?</b>
                    <br />P&G Altın Fırsat CarrefourSA'da kampanyasında yer alabileceğiniz minimum veya maksimum sayıda ürün bulunmamaktadır.
                    `,
                    `<b>Diğer P&G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?</b>
                    <br />Maalesef bu mümkün değildir. P&G Altın Fırsat CarrefourSA'da kampanyasına katılım, henüz başka P&G  kampanyalarına katılmamış ürünlerle sınırlıdır.
                    `,
                    `<b>Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de P&G Altın Fırsat CarrefourSA'da kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?</b>
                    <br />Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.
                    `,
                    `<b>Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?</b>
                    <br />Prensip olarak, bir satın alma kanıtı P&G Altın Fırsat CarrefourSA'da kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.
                    `,
                    `<b>Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?</b>
                    <br />Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.
                    <br />10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <a href="https://www.firsatyaninda.com/carrefoursa" target="_blank"><b>www.firsatyaninda.com/carrefoursa</b></a> üzerinden tekrar kayıt olunuz.
                    `,
                    `<b>Barkod numarası nedir ve onu nerede bulabilirim?</b>
                    <br />Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.
                    <br />Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.
                    `,
                    `<b>Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?</b>
                    <br />Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: <a href="mailto:destek@carrefour.firsatyaninda.com"><b>destek@carrefour.firsatyaninda.com</b></a>.
                    <br />Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.
                    `,
                    `<b>IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?</b>
                    <br />IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN' dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.
                    `,
                    `<b>Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?</b>
                    <br />Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.
                    `,
                    `<b>Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?</b>
                    <br />Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.
                    `,
                    `<b>Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?</b>
                    <br />Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.
                    `,
                    `<b>Doğru ürünleri almış olmama rağmen neden reddedildim?</b>
                    <br />Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.
                    <br />Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.
                    <br />Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (<a href="mailto:destek@carrefour.firsatyaninda.com"><b>destek@carrefour.firsatyaninda.com</b></a>) bizimle iletişime geçiniz.
                    `,
                    `<b>Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?</b>
                    <br />Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.
                    `,
                    `<b>Satın alma belgemi e-posta ile de gönderebilir miyim?</b>
                    <br />Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.
                    <br />Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.
                    `,
                    `<b>Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?</b>
                    <br />Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen <a href="mailto:iban@carrefour.firsatyaninda.com"><b>iban@carrefour.firsatyaninda.com</b></a> adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın P&G Altın Fırsat CarrefourSA'da kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.
                    `,
                    `<b>Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?</b>
                    <br />Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.
                    <br />Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.
                    `,
                    `<b>Kampanya web sitesine girdiğim verilerime ne olacak?</b>
                    <br />Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter & Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&G") promosyonu gerçekleştirmek için veriler saklanır.
                    <br />Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&G tarafından daha fazla reklam amacıyla kullanılacaktır. P&G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.
                    <br />Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&sub=9999" target="_blank"><b>https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&sub=9999</b></a> websitesinden).
                    <br />Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.
                    <br />Veri korumayla ilgili daha fazla bilgi <a href="https://privacypolicy.pg.com/tr-TR/" target="_blank"><b>https://privacypolicy.pg.com/tr-TR/</b></a> adresinde bulunabilir.
                    `,
                    `<b>P&G hakkında genel bir sorum var. Kiminle konuşmalıyım?</b>
                    <br />Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).
                    <br /><a href="https://www.pg.com.tr/bize-ulasin/" target="_blank"><b>https://www.pg.com.tr/bize-ulasin/</b></a>
                    `
                  ],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8"
                  }
                }
              }
            ]
          }
        ]
      },
      { // DOI page
        "pageName": "carrefoursa/doi",
        "locale": "tr",
        "pageId": 423,
        "isPublic": true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "maxWidth": "1440px",
              "margin": "auto"
            },
            "fields": [
              {
                "fieldType": "doi",
                "fieldId": "r3doi",
                "properties": {
                  "campaignName": "carrefour",
                  "colorSchema": "var(--carrefour-color)",
                  "endpoint": "/checkDoi",
                  "title": "Katılımınız için teşekkür ederiz",
                  "text": "Bültenimize başarıyla kayıt oldunuz. Artık ücretsiz ürün testleri, yarışmalar ve ilham verici makaleler olduğunda ilk öğrenenlerden biri siz olacaksınız.",
                  "button": "Başlangıç   sayfasına geri dön"
                }
              }
            ]
          }
        ]
      },
      { // Error page
        "pageName": "carrefoursa/hata-sayfasi",
        "locale": "tr",
        "pageId": 413,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "gjd10",
                "properties": {
                  "text": "<center>Oops, bir şeyler hatalı oldu!</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px"
                  },
                  "classes": ["heading"]
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "sfdjk2",
                "properties": {
                  "text": "<center>Lütfen sayfayı yeniden yüklemeyi deneyin!</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              }
            ]
          },
          { // Image and back button
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Image
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/images/404.svg",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px"
                  }
                }
              },
              { // Button
                "fieldType": "button-component",
                "fieldId": "oql2de",
                "properties": {
                  "text": "Başlangıç   sayfasına geri dön",
                  "styles": {
                    "background-color": "var(--carrefour-color);",
                    "border-radius": "100px;"
                  },
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "100px",
                    "marginBottom": "70px"
                  },
                  "addClass": "nextBtn",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "https://www.firsatyaninda.com/carrefoursa"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Expired page
        "pageName": "carrefoursa/expired-page",
        "locale": "tr",
        "pageId": 414,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "gjd10",
                "properties": {
                  "text": `<center>Maalesef, ulaşmaya çalıştığınız bağlantının süresi doldu. Lütfen tekrar kampanyaya katılın veya destek servisimize başvurun: <a href="mailto:destek@carrefour.firsatyaninda.com"><u>destek@carrefour.firsatyaninda.com</u></a></center>`,
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "6",
                    "marginTop": "50px"
                  },
                  "classes": ["heading"]
                }
              }
            ]
          },
          { // Image and back button
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Image
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/images/expired.svg",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px"
                  },
                  "styles": {
                    "max-width": "645px"
                  }
                }
              }
            ]
          },
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Button
                "fieldType": "button-component",
                "fieldId": "oql2de",
                "properties": {
                  "text": "Başlangıç   sayfasına geri dön",
                  "styles": {
                    "background-color": "var(--carrefour-color);",
                    "border-radius": "100px;"
                  },
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "100px",
                    "marginBottom": "70px"
                  },
                  "addClass": "nextBtn",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "https://www.firsatyaninda.com/carrefoursa"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Reset Password page
        "pageName": "carrefoursa/yeni-parola",
        "locale": "tr",
        isPublic: true,
        "pageId": 415,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldId": "v09234i",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "onSuccess": "hide",
                  "styles": {
                    "flexDirection": "column",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "maxWidth": "1440px"
                  },
                  "fields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "gjd10",
                      "properties": {
                        "text": "<center><h2>Yeni bir parola seçiniz</h2></center>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "50px"
                        },
                        "styles": {
                          "font-family": "Montserrat;",
                          "font-style": "normal;",
                          "font-weight": "600;",
                          "font-size": "32px;",
                          "line-height": "39px;",
                          "color": "#1D1D1D;"
                        }
                      }
                    },
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "/assets/images/reset-pass.svg",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "5"
                        },
                        "styles": {
                          "max-width": "346px !important",
                          "objectFit": "contain"
                        }
                      }
                    },
                    {
                      "fieldId": "f9234pg",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "4"
                        },
                        "className": "col-sm-8 col-md-6",
                        "fields": [
                          {
                            "fieldType": "form-component",
                            "fieldId": "5h43gwe",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              },
                              "styles": {
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "successAction": {
                                "fn": "displaySuccess"
                              },
                              "endpoint": "/checkResetToken",
                              "fields": [
                                {
                                  "fieldId": "j45brev",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "newPassword",
                                    "id": "newPassword",
                                    "vid": "newPassword",
                                    "inputType": "password",
                                    "label": "Parola*",
                                    "placeholder": "",
                                    "text": "",
                                    "validation": "required|password",
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "styles": {
                                      "border-radius": "40px !important",
                                      "margin-top": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldId": "7666b4re",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "newPasswordConfirm",
                                    "id": "newPasswordConfirm",
                                    "inputType": "password",
                                    "label": "Parola Tekrarı*",
                                    "placeholder": "",
                                    "text": "",
                                    "validation": "required|confirmed:newPassword",
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "styles": {
                                      "border-radius": "40px !important",
                                      "margin-top": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldType": "query-string-to-state",
                                  "fieldId": "uwxo2o1",
                                  properties: {
                                    grid: {
                                      columns: 0
                                    },
                                    variables: [
                                      {
                                        query: "code"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          {
                            "fieldType": "button-component",
                            "fieldId": "h34wevdss",
                            "properties": {
                              "text": "Yeni parolanızı oluşturun",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center",
                                "marginTop": "40px",
                                "marginBottom": "81px"
                              },
                              "styles": {
                                "background-color": "var(--carrefour-color);",
                                "border-radius": "100px;"
                              },
                              "textStyles": {
                                "font-family": "Montserrat;",
                                "color": "#FFFFFF;",
                                "fontWeight": "600;",
                                "font-size": "18px;",
                                "line-height": "22px;"
                              },
                              "disabled": false,
                              "action": {
                                "fn": "sendForm",
                                "params": "5h43gwe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                "fieldId": "v09234i",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "onSuccess": "show",
                  "styles": {
                    "flexDirection": "column",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "max-width": "1440px"
                  },
                  "fields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "fk2340ed",
                      "properties": {
                        "text": "<center><h2>Parolanız sıfırlandı ve değiştirildi.</h2></center>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "50px"
                        },
                        "styles": {
                          "font-family": "Montserrat;",
                          "font-style": "normal;",
                          "font-weight": "600;",
                          "font-size": "32px;",
                          "line-height": "39px;",
                          "color": "#1D1D1D;"
                        }
                      }
                    },
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/thankYou_tick.png",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "2"
                        },
                        "styles": {
                          "objectFit": "contain"
                        }
                      }
                    },
                    {
                      "fieldType": "paragraph",
                      "fieldId": "fk2340ed",
                      "properties": {
                        "text": "<center>Artık kampanya web sitesinde tekrar giriş yapabilirsiniz.<br>Bunu yapmak için, istenen kampanya web sitesini ziyaret edin.</center>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "font-family": "Montserrat;",
                          "font-style": "normal;",
                          "font-size": "21px;",
                          "line-height": "39px;",
                          "color": "#1D1D1D;",
                          "margin": "20px 0 60px 0;"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Campaign Is Over
        "pageName": "carrefoursa/kampanya-bitti",
        "locale": "tr",
        "pageId": 416,
        isPublic: true,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "margin": "auto"
            },
            "fields": [
              { // Heading and Image and Button
                "fieldType": "empty-bucket",
                "fieldId": "05m65ds",
                "properties": {
                  "grid": {
                    columns: 12,
                    "marginTop": "40px",
                    "marginBottom": "50px"
                  },
                  "styles": {
                    "padding": "0",
                    "flexDirection": "column",
                    "alignItems": "center"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "88z8gv",
                      "properties": {
                        "text": "<center>Kampanya süresi sona erdi</center>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        "classes": ["heading"]
                      }
                    },
                    { // Sub Heading
                      "fieldType": "paragraph",
                      "fieldId": "88z8gv",
                      "properties": {
                        "text": `<center>Kampanyaya göstermiş olduğunuz ilgiye teşekkür ederiz, maalesef kampanya dönemi sona ermiştir.</center>`,
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "10"
                        },
                        classes: ["subHeading"]
                      }
                    },
                    { // Key Visual
                      "fieldType": "key-visual",
                      "fieldId": "296eg1",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "styles": {
                          "margin": "0 0 50px 0"
                        },
                        "noContainer": true,
                        "desktopImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/f392186e-a27e-49ec-b7d3-a3fc1eee8413.jpeg",
                        "tabletImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/06321dd8-0d30-4e2d-90a0-ad1954929dd6.jpeg",
                        "mobileImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/803369c2-dcf5-4027-b2e7-923a26cd99a2.jpeg"
                      }
                    },
                    { // Button
                      "fieldType": "button-component",
                      "fieldId": "oql2de",
                      "properties": {
                        "text": "Başlangıç   sayfasına geri dön",
                        "styles": {
                          "background-color": "var(--carrefour-color);",
                          "border-radius": "100px;"
                        },
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginTop": "40px",
                          "marginBottom": "90px"
                        },
                        "addClass": "nextBtn",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.firsatyaninda.com/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Reupload Receipt
        "pageName": "carrefoursa/tekrar-fis-yukle",
        "locale": "tr",
        "isPublic": true,
        "pageId": 417,
        "sections": [
          { // Query and Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "formData"
                    }
                  ]
                }
              },
              { // Header
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "<center>Lütfen fişinizi/faturanızı tekrar yükleyiniz</center>",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "<center>Bu adımda, P&G alışverişinizi içeren fiş ya da faturanızı tekrar yükleyiniz.</center>",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              }
            ]
          },
          { // Receipt Info and Uploader
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "fields": [
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "10"
                  },
                  "styles": {
                    "justifyContent": "center !important"
                  },
                  "fields": [
                    { // Receipt Info
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fd",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-sm-9 xl-6 pb-4 receiptInfo desktopOnly",
                        "fields": [
                          {
                            "fieldType": "receipt-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                              "steps": [
                                {
                                  "text": "Satış noktası",
                                  "label": "A"
                                },
                                {
                                  "text": "Satın alma tarihi ve saati",
                                  "label": "B"
                                },
                                {
                                  "text": "Ürünler ve satın alma fiyatları",
                                  "label": "C"
                                },
                                {
                                  "text": "Toplam fiş/fatura ve KDV tutarı",
                                  "label": "D"
                                },
                                {
                                  "text": "Fiş/Fatura Numarası",
                                  "label": "E"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Receipt Upload
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fg",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-md-9 pb-4 receiptUpload",
                        "fields": [
                          {
                            "fieldType": "receipt-processor",
                            "fieldId": "klvakj",
                            "properties": {
                              texts: {
                                choose: 'Bir Dosya Seçin',
                                receiptUpload1: 'Fişinizi/Faturanızı yüklemek için sürükleyip bırakınız.',
                                receiptUpload2: 'Yalnızca JPEG, PNG ve PDF desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                                receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                                wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, .png, .pdf ve .tiff dosyaları kabul edilir.',
                                fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                              },
                              "subCampaignId": "carrefour-30-cb",
                              "colorSchema": "var(--carrefour-color)",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "reUpload": true,
                              "tokenName": "token",
                              "addClass": "p-0",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "carrefoursa/kayit-tamamlandi"
                              },
                              "phrases": [
                                "Ihr Beleg wird jetzt hochgeladen und bearbeitet.",
                                "Der Beleg wird automatisch bearbeitet. Dies kann eine Minute dauern. Bitte haben Sie Geduld.",
                                "Sollten Sie eine Fehlermeldung erhalten, nehmen Sie bitte eine bessere Version des Belegs auf und laden Sie ihn erneut hoch."
                              ],
                              "cornerDetectionPhrases": [
                                "Lütfen gri dikdörtgeni fiş ile eşleşecek şekilde ayarlayın."
                              ],
                              "eraseColor": "#ffffff",
                              "eraseSize": 12,
                              "cropColor": "#3A96F3",
                              "cropBackground": "rgba(0,0,0,0.6)",
                              "cropTitle": "Kes",
                              "eraseTitle": "Düzenle",
                              "dragTitle": "Hareket ettir",
                              "zoomInTitle": "Yakınlaştır",
                              "zoomOutTitle": "Uzaklaştır",
                              "selectImageTitle": "Select an Image",
                              "selectAnotherTitle": "Select Another",
                              "controlsBackground": "#4193d3",
                              "backgroundColorBtn": "var(--carrefour-color)"
                            }
                          }
                        ]
                      }
                    },
                    { // Receipt Info
                      "fieldType": "empty-bucket",
                      "fieldId": "vdf932fh",
                      "properties": {
                        "grid": {
                          "columns": "6"
                        },
                        "className": "col-sm-9 xl-6 pb-4 receiptInfo desktopNone",
                        "fields": [
                          {
                            "fieldType": "receipt-information",
                            "fieldId": "5mq1p6",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": "12"
                              },
                              "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                              "steps": [
                                {
                                  "text": "Satış noktası",
                                  "label": "A"
                                },
                                {
                                  "text": "Satın alma tarihi ve saati",
                                  "label": "B"
                                },
                                {
                                  "text": "Ürünler ve satın alma fiyatları",
                                  "label": "C"
                                },
                                {
                                  "text": "Toplam fiş/fatura ve KDV tutarı",
                                  "label": "D"
                                },
                                {
                                  "text": "Fiş/Fatura Numarası",
                                  "label": "E"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "marginBottom": "90px",
              "marginTop": "40px",
              "justifyContent": "center"
            },
            "fields": [
              {
                "fieldId": "chr23985",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "className": "col-md-12",
                  "fields": [
                    {
                      "fieldType": "button-component",
                      "fieldId": "cwer23452q",
                      "properties": {
                        "grid": {
                          "columns": "4",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "addClass": "col-sm-8 col-xl-3 nextBtn",
                        "text": "İleri",
                        "shouldCheckCrop": true,
                        "requires": ["klvakj"],
                        "action": {
                          "fn": "sendReceipt",
                          "params": ""
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Reupload Product
        "pageName": "carrefoursa/tekrar-urun-yukle",
        "isPublic": true,
        "locale": "tr",
        "pageId": 418,
        "sections": [
          { // Query and Heading
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "vgw364bh",
            "fields": [
              { // Query
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "formData"
                    }
                  ]
                }
              },
              { // Header
                "fieldType": "paragraph",
                "fieldId": "uwxo2o",
                "properties": {
                  "text": "<center>Ürünlerin fotoğrafını yükleyiniz</center>",
                  "classes": ["heading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              },
              { // Sub Header
                "fieldType": "paragraph",
                "fieldId": "vb309g",
                "properties": {
                  "text": "<center>Lütfen satın almış olduğunuz ürünlerin fotoğrafını barkod numarası görünecek şekilde ve fiş/fatura ile birlikte yükleyiniz.</center>",
                  "classes": ["subHeading"],
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginBottom": "26px"
                  }
                }
              }
            ]
          },
          { // Product Upload
            "sectionId": "dyx8uv",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "fields": [
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              {
                "fieldType": "empty-bucket",
                "fieldId": "h453eadaf",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center !important;",
                    "padding-top": "30px"
                  },
                  "className": "col-sm-9 col-xl-6 col-lg-12",
                  "fields": [
                    {
                      "fieldType": "product-upload",
                      "fieldId": "cakl305",
                      "properties": {
                        texts: {
                          choose: 'Bir Dosya Seçin',
                          receiptUpload1: 'Ürünlerin fotoğrafını yüklemek için sürükleyip bırakın.',
                          receiptUpload2: 'Yalnızca JPEG, PNG, PDF desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                          receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                          wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, .png, ve .tiff dosyaları kabul edilir.',
                          fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                        },
                        "colorSchema": "var(--carrefour-color)",
                        "grid": {
                          "columns": "12",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        "reupload": {
                          "status": true
                        },
                        "reUpload": true,
                        "tokenName": "token",
                        "endpoint": "/uploadProductPhoto",
                        "successAction": {
                          "fn": "goToPage",
                          "params": "carrefoursa/kayit-tamamlandi"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // Buttons
            "sectionId": "r0924fw",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "marginBottom": "90px",
              "marginTop": "40px"
            },
            buttonsWrap: true,
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "cwer23452q",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "addClass": "col-sm-8 col-xl-3 nextBtn",
                  "text": "İleri",
                  "shouldCheckCrop": true,
                  "requires": ["cakl305"],
                  "action": {
                    "fn": "sendProductPhotos",
                    "params": "cakl305"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "carrefoursa/iban-yeniden-gonderme",
        "locale": "de",
        "isPublic": true,
        "pageId": 419,
        "requires": [
          1
        ],
        "sections": [
          { // HEADING AND SUBHEADING
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "flex-direction": "column",
              "columns": 12
            },

            "fields": [
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              {
                "fieldType": "paragraph",
                "fieldId": "0bbo32",
                "properties": {
                  "text": "<center><h2>Banka hesap bilgilerinizi giriniz</h2></center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginTop": "100px"
                  },
                  "classes": ['col-md-10', 'heading']
                }
              },
              {
                "fieldType": "paragraph",
                "fieldId": "94xqe4",
                "properties": {
                  "text": "<center>Lütfen geri ödeme tutarının aktarılacağı hesabınızın bilgilerini giriniz.</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "8",
                    "marginBottom": "50px"
                  },
                  "classes": ['col-md-10', 'subHeading']
                }
              }
            ]
          },
          { // FORMS
            "sectionId": "mfh0h1",
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldType": "form-component",
                "fieldId": "bank1Nf0",
                "properties": {
                  "successAction": {
                    "fn": "goToPage",
                    "params": "carrefoursa/thank-you-iban"
                  },
                  "endpoint": "/updateIBAN",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center"
                  },
                  "fields": [
                    { // form
                      "fieldType": "empty-bucket",
                      "fieldId": "f934if",
                      "properties": {
                        "grid": {
                          "columns": "6",
                          "paddingTop": "30px",
                          "paddingBottom": "30px"
                        },
                        "className": "col-md-6 col-xl-4 formLayout",
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934if",
                            "properties": {
                              "grid": {
                                "columns": "2"
                              },
                              "className": "ibanLabelWrapper col-2",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "tw3qab",
                                  "properties": {
                                    "grid": {
                                      "columns": "12"
                                    },
                                    "text": "TR",
                                    "classes": ["ibanLabel"]
                                  }
                                }
                              ]
                            }
                          },
                          { // IBAN
                            "fieldId": "h9arlr",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "iban",
                              "id": "iban",
                              "validation": "required|iban",
                              "label": "IBAN*",
                              "placeholder": "",
                              "text": "",
                              "className": "col-10",
                              "grid": {
                                "columns": "10",
                                "paddingLeft": "5px !important"
                              }
                            }
                          },
                          { // trNumber
                            "fieldId": "7rNumb2r",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "tckn",
                              "id": "tckn",
                              "label": "T.C. Kimlik Numarası*",
                              "validation": "required|identityCard",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // trMobile
                            "fieldId": "7rMob2le",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "mobile",
                              "id": "mobile",
                              "label": "Telefon Numarası*",
                              "validation": "required|phoneNumber",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // First Name
                            "fieldId": "tp6zfb",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "firstname",
                              "id": "firstname",
                              "label": "Hesap Sahibi Adı*",
                              "validation": "required|ibanName",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          },
                          { // Last Name
                            "fieldId": "p55fao",
                            "fieldType": "text-input",
                            "properties": {
                              "name": "lastname",
                              "id": "lastname",
                              "validation": "required|ibanName",
                              "label": "Hesap Sahibi Soyadı*",
                              "placeholder": "",
                              "text": "",
                              "grid": {
                                "columns": "12"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // BUTTONS
            "sectionId": "hmgwhl",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "marginBottom": "20px",
              "maxWidth": "1440px",
              "marginTop": "40px"
            },
            "buttonsWrap": true,
            "fields": [
              {
                "fieldType": "button-component",
                "fieldId": "16tzm2",
                "properties": {
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  "text": "İleri",
                  "addClass": "col-sm-4 col-xl-3 nextBtn",
                  "disabled": false,
                  "action": {
                    "fn": "sendForm",
                    "params": "bank1Nf0"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "carrefoursa/thank-you-iban",
        "locale": "de",
        "pageId": 420,
        isPublic: true,
        "sections": [
          {
            "grid": {
              "alignItems": "start",
              "justifyContent": "center",
              "columns": "6",
              "maxWidth": "1440px",
              "margin": "auto"
            },
            "fields": [
              {
                "fieldType": "empty-bucket",
                "fieldId": "4w3vw5b8",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "styles": {
                    "justifyContent": "center",
                    flexDirection: 'column',
                    alignItems: 'center',
                    "padding": "0"
                  },
                  "fields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "gjd10",
                      "properties": {
                        "text": "<center><h2>Teşekkürler</h2></center>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "classes": ['heading']
                      }
                    },
                    { // Image
                      "fieldId": "vn0934kf",
                      "fieldType": "square-image",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "styles": {
                          "marginBottom": "10px",
                          "marginTop": "-10px",
                          "padding": "0"
                        },
                        "className": "thankyouImage",
                        "imageSource": "/assets/success.svg",
                        "imageSource2": "/assets/success.svg"
                      }
                    },
                    {
                      "fieldType": "paragraph",
                      "fieldId": "sfdjk2",
                      "properties": {
                        "text": "<center>Banka bilgilerinizi yeniden gönderdiğiniz için teşekkür ederiz. Başarılı bir transferin önünde hiçbir şey durmamalıdır.<br><br>Banka bilgilerinizle ilgili herhangi bir sorunuz varsa, lütfen <a href='mailto:iban@carrefour.firsatyaninda.com'>iban@carrefour.firsatyaninda.com</a> ile iletişime geçin.<br>Diğer konular için <a href='mailto:destek@carrefour.firsatyaninda.com'>destek@carrefour.firsatyaninda.com</a> adresinden müşteri hizmetimizle iletişime geçebilirsiniz.</center>",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginBottom": "40px"
                        },
                        "classes": ['subHeading']
                      }
                    },
                    { // Key Visual
                      "fieldType": "key-visual",
                      "fieldId": "296eg1",
                      "properties": {
                        "grid": {
                          "columns": "12"
                        },
                        "noContainer": true,
                        "desktopImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/f392186e-a27e-49ec-b7d3-a3fc1eee8413.jpeg",
                        "tabletImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/06321dd8-0d30-4e2d-90a0-ad1954929dd6.jpeg",
                        "mobileImageUrl": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/803369c2-dcf5-4027-b2e7-923a26cd99a2.jpeg"
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "backgroundColor": "#fff",
        "title": "",
        "color": "#FFE082",
        "grid": {
          "columns": 12
        },
        "logo": {
          "title": "Fırsat Yanında",
          "imageSource": "/assets/images/logo@1x.png",
          "imageSource2x": "/assets/images/logo@2x.png",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "favicon": {
          "imageSource": "https://azcdn.pioneer.pgsitecore.com/en-gb/-/media/Downy_UK/Images/Common/favicon-32x32.jpg?v=1-201708041111"
        },
        "fields": [
          { // Terms
            "fieldId": "ikz8vc",
            "fieldType": "anchor",
            "properties": {
              "text": "KATILIM KOŞULLARI",
              "url": "/carrefoursa/katilim-kosullari",
              "target": "_blank",
              "cssClass": "nav-link headerLink"
            }
          },
          { // FAQ
            "fieldId": "slnmdk",
            "fieldType": "anchor",
            "properties": {
              "text": "SIKÇA SORULAN SORULAR",
              "url": "/carrefoursa/sss",
              "target": "_blank",
              "cssClass": "nav-link headerLink"
            }
          }
        ],
        "showLine": false,
        "meta": [
          { "name": "title", "content": "P&G Türkiye - Fırsat Yanında" },
          {
            "name": "description",
            "content": `P&G Türkiye - Fırsat Yanında Para İadesi Kampanyası. Belirli satış noktalarından alacağınız toplamda 50 TL P&G ürünü alışverişlerinize 10 TL para iadesi kampanyası.`
          },
          {
            "name": "keywords",
            "content": `Ariel, Alo, Head and Shoulders, Oral-B, Gillette, Firsat Yaninda, P&G Türkiye`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz",
      "properties": {
        "logo": {
          "imageSource": "/assets/images/logo@1x.png",
          "imageSource2x": "/assets/images/logo@2x.png",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "links": [
          { "name": "Hüküm ve Koşullar", "link": "http://www.pg.com/tr_TR/terms_conditions/index.shtml" },
          { "name": "P&G'ye ulaşın", "link": "https://pgconsumersupport.secure.force.com/CarehubStandalone?Country=tr&Language=tr&Brand=Kadinlar+Bilir#brandListDiv" },
          { "name": "Gizlilik", "link": "https://privacypolicy.pg.com/tr-TR/" },
          { "name": "Hakkımızda", "link": "https://www.kadinlarbilir.com/hakkimizda" },
          { "name": "Aydınlatma Metni", "link": "/pg-aydinlatma-metni"}
        ],
        pgText: "© 2022 Procter & Gamble. Tüm hakları saklıdır. Bu sitedeki içeriklere erişim ve içeriklerin kullanılması, yasal anlaşmada belirtilen sözleşme ve şartlara bağlıdır.<br/>Destek departmanımız 30 Nisan 2022’e kadar hizmet verecektir: <a href='mailto:destek@carrefour.firsatyaninda.com'>destek@carrefour.firsatyaninda.com</a>"
      }
    },
    "products": [
      {
        "id": 3,
        "ean": 8001841672335,
        "title": "Ariel All-in-1 PODS +Extra Faserpflege 14 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_14WL.jpeg",
        "selectable": true
      },
      {
        "id": 4,
        "ean": 8001841672571,
        "title": "Ariel All-in-1 PODS +Extra Faserpflege 20 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_20WL.jpeg",
        "selectable": true
      },
      {
        "id": 1,
        "ean": 8001841672243,
        "title": "Ariel All-in-1 PODS +Extra Geruchsabwehr 14 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_G_14WL.jpeg",
        "selectable": true
      },
      {
        "id": 2,
        "ean": 8001841672519,
        "title": "Ariel All-in-1 PODS +Extra Geruchsabwehr 20 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_G_20WL.jpeg",
        "selectable": true
      },
      {
        "id": 5,
        "ean": 8001841672090,
        "title": "Ariel All-in-1 PODS Universal 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/PODS_Universal_12.jpeg",
        "selectable": true
      },
      {
        "id": 6,
        "ean": 8001841672038,
        "title": "Ariel All-in-1 PODS Color 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/PODS_Color_12_WL.jpeg",
        "selectable": true
      },
      {
        "id": 7,
        "ean": 8001841700793,
        "title": "Lenor All-in-1 PODS Aprilfrisch 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/Aprilfrisch_12_WL.jpeg",
        "selectable": true
      },
      {
        "id": 8,
        "ean": 8001841700755,
        "title": "Lenor All-in-1 PODS Amethyst Blütentraum 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/Amethyst_Blutentraum_12_WL.jpeg",
        "selectable": true
      }
    ],
    dictionary: {
      tr: {
        messages: {
          "required": (field) => `${field} alanı gereklidir`,
          "mandatory": "Bu alan gereklidir.",
          "alpha_spaces": (field) => `${field} sadece harf içerebilir.`,
          "email": "Geçerli bir e-posta adresi giriniz.",
          "password": "Şifreniz en az 8 karakterden oluşmalı, en az bir büyük harf, bir küçük harf ve bir sayı içermelidir.",
          "confirmPass": "Şifreler aynı değildir.",
          "postal": "Posta kodu 5 rakamdan oluşmalıdır.",
          "plz": "Posta kodu 5 rakamdan oluşmalıdır.",
          "street": "Yalnızca harf, boşluk, eğik çizgi ve kısa çizgi içerebilir",
          "iban": "Lütfen katılımcı ülkelerin geçerli bir IBAN'ını kullanınız",
          "phoneNumber": "Lütfen 5XXXXXXXXX formatı ile geçerli bir telefon numarası giriniz.",
          "hausnr": "Bina numarası yanlıştır.",
          "smsCodeLength": "Doğrulama kodu 4 basamaklı olmalıdır."
        },
        names: {
          iban: 'IBAN',
          gender: 'Cinisyet',
          bic: 'BIC',
          firstname: 'İsim',
          name: 'İsim',
          firstName: 'İsim',
          lastname: 'Soy isim',
          surname: 'Soy isim',
          lastName: 'Soy isim',
          email: 'E-mail',
          birthdate: 'Doğum tarihi',
          password: 'Şifre',
          newPassword: 'Şifre',
          confirmPassword: 'Tekrar Şifre',
          newPasswordConfirm: 'Tekrar Şifre',
          strasse: 'Sokak',
          hausnr: 'No',
          plz: 'Posta kodu',
          ort: 'Şehir',
          city: 'İl',
          address: 'Adres',
          marital: 'Medeni hal',
          kids: 'Çocuk',
          area: 'Mahalle',
          district: 'İlçe',
          land: 'Ülke',
          phoneNumber: 'Telefon numarası',
          code: 'Kod',
          terms: "Katılım koşulları",
          receiveEmails: "Kullanım ve gizlilik politikası şartları",
        }
      }
    },
    "errors": {
      "tr": {
        default: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
        noEmail: "Bu e-posta adresine sahip bir hesap yok.",
        emailUsed: "Bu e-mail adresi zaten kullanımda.",
        incorrect: "Geçersiz kullanıcı adı veya yanlış şifre. Lütfen tekrar deneyin!",
        invalidCode: "Kullanmaya çalıştığınız bağlantının süresi zaten dolmuş. Lütfen yeni bir parola sıfırlama bağlantısı isteyin.",
        manyTries: 'Fiş çok fazla yüklendiğinden bir hata oluştu.',
        generateCode: 'Telefon numarasına izin verilmediğinden doğrulama kodu gönderilemedi. Lütfen girdiğiniz numarayı kontrol edin ve tekrar deneyin.',
        wrongCode: 'SMS kodu yanlış',
        network: 'Hata. Sunucuda bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.'
      }
    },
    "campaignId": "carrefour-30-cb",
    "url": "firsatyaninda.com/carrefoursa",
    "name": "carrefour-30-cb"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Oops. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  campaignId: 'carrefour-30-cb',
  formData: new FormData(),
  campaignEndDate: '2022-04-30',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?",
  errorImageList: ["", ""],
  errorActivate: false,
  scannedProducts: [],
  types: ['ean_reader', 'code_128_reader', 'code_39_reader', 'ean_8_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader', 'codabar_reader'],
  pageToBack: '',
  receiptBarcode: ''
}
