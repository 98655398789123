<template>
  <div class="container" ref="questionsRef" :style="`--brand-color: ${colorSchema}`">
    <div class="row flex-column justify-content-center align-items-center">
      <div class="col-lg-12 question-wrapper" v-for="(question, idx) in questions" :key="idx">
        <div v-if="question.type == 'checkbox'">
          <div class="question" v-html="question.text"></div>
          <div class="subQuestion" v-html="question.subText"></div>
          <div class="inputWrapper" role="group">
            <div
              class="radio-input"
              v-for="(option, i) in question.options"
              :key="option.name"
              :class="questionModel[idx].indexOf(option) != -1 ? 'active' : ''"
            >
            <label class="box-container w-100" :for="`checkbox-group-${idx}-${i}`">
              <input
                v-model="questionModel[idx]"
                type="checkbox"
                :id="`checkbox-group-${idx}-${i}`"
                :name="`checkbox-group-${idx}`"
                :value="option"
                autocomplete="off"
                checked
                @change="updateCheckbox(idx)"
              >
              <span class="checkmark">
                <span class="checkmarkInner"></span>
              </span>
              {{option}}
            </label>
            </div>
          </div>
          <input v-if="question.otherOption" class="inputField w-100" :class="otherModelDisabled[idx] ? 'hide' : 'show'" :disabled="otherModelDisabled[idx]" :name="`checkbox-group-${idx}`" type="text" :placeholder="question.otherText" aria-label="Other option" value="" v-model="otherModel[idx]" @change="otherModelSelected(idx, 'checkbox')">
        </div>
        <div v-else-if="question.type=='radio'">
          <div class="question" v-html="question.text"></div>
          <div class="subQuestion" v-html="question.subText"></div>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              class="radioInput"
              :id="`radio-group-${idx}`"
              :name="`radio-group-${idx}`"
              v-model="questionModel[idx]"
              :options="question.options"
              :aria-describedby="ariaDescribedby"
              stacked
              size="md"
              @input="updateRadio(idx)"
              ></b-form-radio-group>
            </b-form-group>
            <input v-if="question.otherOption" class="inputField w-100" :class="otherModelDisabled[idx] ? 'hide' : 'show'" :disabled="otherModelDisabled[idx]" :name="`checkbox-group-${idx}`" type="text" :placeholder="question.otherText" aria-label="Other option" value="" v-model="otherModel[idx]" @change="otherModelSelected(idx, 'radio')">
        </div>
        <div v-else-if="question.type=='textarea'">
          <div class="question" v-html="question.text"></div>
          <input :type="question.inputType || 'text'" class="inputField w-100" v-model="questionModel[idx]" value="" :placeholder="question.placeholder" />
        </div>
      </div>
      <div v-if="this.error" class="my-5" style="font: normal normal 400 16px/20px avenir_lt_35_lightregular; color: red;">
        {{error}}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from "axios";

export default {
  name: "survey",
  components: {
  },
  props: {
    mandatory: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array,
      default: () => []
    },
    successAction: {
      type: Object,
      default: () => null
    },
    endpoint: {
      type: String,
      default: "https://js-backend.justsnap.eu/api/survey"
    },
    colorSchema: {
      type: String,
      default: "#000"
    }
  },
  data () {
    return {
      questionModel: [],
      otherModel: [],
      otherModelDisabled: [],
      error: ''
    }
  },
  created () {
    for (let i = 0; i < this.questions.length; i++) {
      this.questionModel[i] = [];
      this.otherModelDisabled[i] = true;
    }
  },
  // watch: {
  //   questionModel (val) {
  //     console.log("value: ", val)
  //   },
  //   otherModel (val) {
  //     console.log("other value: ", val)
  //   }
  // },
  mounted () {
    this.$eventHub.$on("getSurveyData-event", this.sendSurvey);
  },
  beforeDestroy () {
    this.$eventHub.$off("getSurveyData-event", this.sendSurvey);
  },
  computed: {
    ...mapGetters(['customerToken' , 'campaignId', 'receiptToken'])
  },
  methods: {
    ...mapActions(['sendSurveyData']),
    updateCheckbox (idx) {
      if (this.questions[idx].otherOption) {
        if (this.questionModel[idx].indexOf('Diğer') != -1) {
          this.otherModelDisabled[idx] = false
        } else {
          // console.log("other", this.questionModel[idx])
          let i = this.questionModel[idx].indexOf(this.otherModel[idx])
          if (i != -1) {
            this.questionModel[idx].splice(i, 1)
          }
          this.otherModel[idx] = ""
          this.otherModelDisabled[idx] = true
        }
      }
    },
    updateRadio (idx) {
      // console.log(this.questionModel[idx])
      if (this.questions[idx].otherOption) {
        if (this.questionModel[idx].includes("Diğer")) {
          this.otherModelDisabled[idx] = false
        } else {
          // console.log("other", this.questionModel[idx])
          if (this.questionModel[idx] === this.otherModel[idx]) {
            this.questionModel[idx]
          }
          this.otherModel[idx] = ""
          this.otherModelDisabled[idx] = true
        }
      }
    },
    otherModelSelected (idx, type) {
      if (type === 'checkbox') {
        this.questionModel[idx].push(this.otherModel[idx]);
      } else if (type === "radio") {
        this.questionModel[idx] = "Diğer: " + this.otherModel[idx];
      }
    },
    sendSurvey (skipSurvey) {
      if (this.mandatory) {
        if (this.questionModel.some(x => x.length == 0)) {
          this.error = 'Anket zorunlu'
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
          return;
        }
      }
      let data = {};
      data.questions = []
      if (!skipSurvey) {
        data.questions = this.questionModel;
      }
      // console.log("Data: ", data)
      // Send data to backend
      data.customerToken = this.customerToken;
      data.receiptToken = this.receiptToken;
      data.campaignId = this.campaignId;
      this.sendSurveyData( { formData: data, endpoint: this.endpoint }).then(res => {
        const fn = this.successAction.fn;
        const params = this.successAction.params;
        this[fn](params, this);
      }).catch(err => {
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      })
    }

  }
}
</script>
<style type="text/css" scoped>
.tg  {border-collapse:collapse;border-color:#C44D58;border-spacing:0;}
.tg td{background-color:#F9CDAD;border-color:#C44D58;border-style:solid;border-width:0px;color:#002b36;
  font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{background-color:#FE4365;border-color:#C44D58;border-style:solid;border-width:0px;color:#fdf6e3;
  font-family:Arial, sans-serif;font-size:14px;font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-zv4m{border-color:#ffffff;text-align:left;vertical-align:top}
.tg .tg-b6o2{background-color:#FFA4A0;border-color:#ffffff;text-align:left;vertical-align:top}
</style>
<style lang="scss" scoped>

$medium: 991px;
@media (max-width: 600px) {
  .tg td {
    font-size: 14px;
    padding: 1px;
  }
  .tg th {
    font-size: 14px;
    padding: 1px;
  }
}

@media (max-width: 450px) {
  table {
    position: relative;
    left: -1rem;
  }
  .tg td {
    font-size: 12px;
    padding: 1px;
  }
  .tg th {
    font-size: 12px;
    padding: 1px;
  }
}

.cursorPointer {
  cursor: pointer;
}

.question-wrapper {
  width: 50%;
  color: var(--text-color-2);
  @media (max-width: 991px) {
    width: 90%;
  }
}

.question {
	display: block;
	font-size: 1.2em;
	margin-top: 30px;
  margin-bottom: 20px;
	font-weight: 700;
}

.subQuestion {
  font-size: 12px;
  line-height: 15px;
  font-style: italic;
  margin-bottom: 20px;
}

.surveyBtnWrapper {
  display: flex;
  justify-content: center;
}


.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .radio-input {
    width: 100%;
    border: 1px solid var(--input-border-color);
    border-radius: 8px;
    background: #fff;
    margin-bottom: 5px;

    &.active {
      background-color: #fff;
      border: 1px solid var(--text-color-3) !important;
    }
  }
  // div:nth-child(odd) {
  //   border-right: none;
  //   border-top-right-radius: 0;
  //   border-bottom-right-radius: 0;
  // }
  // div:nth-child(even) {
  //   border-right: none;
  //   border-top-left-radius: 0;
  //   border-bottom-left-radius: 0;
  // }
}
/* The container */
.box-container {
  display: block;
  position: relative;
  padding: 5px 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 35%;
  left: 10px;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid var(--input-border-color);
  border-radius: 50%;
  margin-bottom: 0 !important;

  &Inner {
    position: absolute;
    left: 1px;
    top: 1px;
    height: 11px;
    width: 11px;
    background-color: transparent;
    border-radius: 50%;
  }
}

// /* On mouse-over, add a grey background color */
// .box-container:hover input ~ .checkmark {
//   background-color: #ccc;
// }

/* When the radio button is checked, add a blue background */
.box-container input:checked ~ .checkmark {
  border-color: #2e2e2e;
  > .checkmarkInner {
      background-color: #2e2e2e;
  }
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.box-container input:checked ~ .checkmark:after {
  display: none;
}

/* Style the indicator (dot/circle) */
.box-container .checkmark:after {
  top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.input {
	background: #FFFFFF;
	border: 1px solid var(--input-border-color) !important;
	box-sizing: border-box;
	border-radius: 8px;
	transition: border-color 0.15s;
	height: 40px;
  width: 100%;
  padding: 0 10px;
}
/deep/ .radioInput {
  .custom-control {
    @media only screen and (max-width: $medium) {
      padding-right:  5px;
    }
  }

  .custom-control-label {
    padding-bottom: 10px;
    text-align: center;
    font: normal normal 300 16px/22px Montserrat;
    letter-spacing: 0px;
    color: #1B1B1B;
    opacity: 1;

    @media only screen and (max-width: $medium) {
      padding-left:  0px;
    }
  }

  .custom-control-label::after,
  .custom-control-label::before {
    width: 20px; 
    height: 20px;
    top: 0;
    left: -35px;
    margin-left: 5px;
    margin-top: 3px;
  }
  .custom-control-label::after {
    background-image: none !important;
    background-color: var(--brand-color) ;
  }
  .custom-control-input[type="radio"]:checked ~ .custom-control-label::before {
    background-image: url('../../assets/icons/form/checkBoxTick.svg');
    background-position-x: -1px;
    background-position-y: -1px;
    background-color: var(--brand-color);
    border: 1px solid var(--brand-color) ;
  }
  .custom-control-input[type="radio"]:active ~ .custom-control-label::before {
      background-color: var(--brand-color);
      opacity: 0.4;
      border: 1px solid var(--brand-color) ;
    }

  .custom-checkbox-dop {
    position: absolute;
    bottom: 5px;
    right: 10px;
    .custom-control-label {
      &:after,
      &:before {
        left: -20px;
      }
    }
  }
  .custom-control-label::after,
  .custom-checkbox .custom-control-label::before,
  .custom-control-input[type="radio"] {
    background-color: transparent !important;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 0;
    &:focus {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.inputField {
  padding: 5px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  outline: none !important;
}
</style>
