<template>
  <div class="container justify-content-center">
    <div class="row">
      <h2 class="pageTitle w-100 mt-5 mb-5" v-html="title"></h2>
      <ul style="list-style-type: none; width: 100%;" v-if="ordered">
        <template v-for="(item,idx) in items">
          <li :key="idx">
            <div class="list-item-wrapper d-flex flex-row align-items-start">
              <span
                class="list-item"
                v-html="`<div class='number'><span style='color: ${colorSchema};'>${idx+1+'.'}</span></div>` + item">
              </span>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
export default {
  name: "text-list",
  props: {
    items: {
      type: Array,
      default: () => ["item 1", "item 2", "item 3"]
    },
    title: String,
    ordered: {
      type: Boolean,
      default: true
    },
    grid: {
      type: Object,
      default: () => ({
        backgroundColor: "#fff"
      })
    },
    actions: {
      type: Array
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    }
  },
  data () {
    return {};
  }
};
</script>

<style lang="scss">
p {
  outline: 0;
}
ul {
  padding: 0 15px 0 15px;
  margin: 0;
  .number {
    .numberBackground {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: black;
      opacity: 0.1;
      position: absolute;
      z-index: -1;
    }
    color: black;
    font-size: 14px;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    display: inline;
    margin-right: 5px;
  }
  .list-item-wrapper {
    margin-top: 24px;
    white-space: initial;
    .list-item {
      font-weight: 400;
      white-space: initial;
      font-size: 16px;
      line-height: 24px;
      max-width: calc(100% - 30px);
      word-wrap: break-word;
    }
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 23px !important;
  }
}

</style>
