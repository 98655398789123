<template>
  <div ref="embedContainer" class="embed-container" :class="mainWrapperClass">
    <div ref="pdfHolder" class="pdf-holder">
    </div>
  </div>
</template>

<script>
export default {
  name: "embedded-doc",
  data () {
    return {
      fetchingLocale: true,
      hasReplaced: false
    }
  },
  props: {
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    docSource: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted () {
    this.fetchDoc(this.docSource)
  },
  methods:  {
    fetchDoc (val){
      let divContainer = this.$refs.embedContainer
      let pdfHolder = this.$refs.pdfHolder
      let url = val;
      if (url.startsWith("https://docs.google.com/document/d/")) {
        // create div and replace iframe
        let d = document.createElement('div');
        d.classList.add("embedded-doc"); // optional
        if(!this.hasReplaced){
          divContainer.replaceChild(d, pdfHolder);
          this.hasReplaced = true
        } else {
          let childToReplace;
          if(divContainer?.childNodes){
            childToReplace = divContainer.childNodes[0]
          }
          console.log('HEY', childToReplace)
          divContainer.replaceChild(d, childToReplace)
        }
        // CORS request
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onload = function () {
          // display response
          let tempXHR = xhr.responseText.replaceAll('<img', '<img referrerpolicy="no-referrer" ')
          d.innerHTML = tempXHR;
        };
        xhr.send();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.embedded-doc{
  div{
    padding: 5px !important;
  }
}
.embed-container{
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  @media (max-width: 542px) {
    display: unset;
    width: 100% !important;
    justify-content: unset;
    align-items: unset;
  }
}
img{
  max-width: 100vw !important;
}
.doc-content{
  padding: 5px !important;
  min-width: 360px !important;
}
</style>
