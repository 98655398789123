<template>
  <div
    v-if="show"
    class="wrapper"
  >
    <div class="layout">
      <div class="container">
        <div
          id="canvas-container"
          class="canvas-container"
        >
          <image-drawer
            :image="image"
            :points="points"
            @input="$emit('input', $event)"
          >
            <template slot="crop">
              <slot name="crop" />
            </template>
            <template slot="erase">
              <slot name="erase" />
            </template>
            <template slot="drag">
              <slot name="drag" />
            </template>
            <template slot="zoomIn">
              <slot name="zoomIn" />
            </template>
            <template slot="zoomOut">
              <slot name="zoomOut" />
            </template>
            <template slot="otherBtn">
              <slot name="otherBtn" />
            </template>
          </image-drawer>
          <receipt-scan-loader
            v-if="isLoading"
            :phrases="image ? phrases : cornerDetectionPhrases"
          ></receipt-scan-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapMutations } from 'vuex'
import ImageDrawer from './ImageDrawer.vue'
import ReceiptScanLoader from './ReceiptScanLoader.vue'

export default {
  name: 'ReceiptScannerAz',
  components: {
    ReceiptScanLoader,
    ImageDrawer
  },
  props: {
    // main properties
    file: {
      type: [File, Blob],
      default: null
    },
    apiUrl: {
      type: String,
      default: "https://receipt.justsnap.eu"
    },
    showLoader: Boolean,
    show:Boolean,
    // options
    phrases: {
      type: Array,
      default: () => []
    },
    cornerDetectionPhrases: {
      type: Array,
      default: () => []
    },
    skipCornerDetection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      image: null,
      points: [],
      axiosCancelSource: null
    }
  },

  watch: {
    file (file) {
      if (!file) {
        if (this.image) {
          this.image = null
          this.points = null
        }
        if (this.axiosCancelSource) {
          this.axiosCancelSource.cancel('cancel')
        }
        if (this.isLoading) {
          this.isLoading = false
        }
      }
      this.onFileChanged(file)
    },
    showLoader (showLoader) {
      this.isLoading = showLoader
    }
  },

  methods: {
    ...mapActions(['handleError', 'sendReceiptToNewQRService']),
    ...mapMutations(['setReceiptImage']),
    async onFileChanged () {
      if (!this.file) return

      this.isLoading = true

      const formData = new FormData()
      formData.append('receipt', this.file)

      // Send the receipt image to new QR service
      try {
        const resp = await this.sendReceiptToNewQRService(this.file);
        // Create a Blob from the response data
        const blob = new Blob([resp.data], { type: 'image/jpeg' });
        const objectURL = URL.createObjectURL(blob);

        const img = new Image();
        img.onload = () => {
          this.points = [];
          this.image = img;
          this.isLoading = false;
        };
        img.src = objectURL;
      } catch (error) {
        // If the new QR service fails, use the old one
        this.axiosCancelSource = axios.CancelToken.source()

        axios.post(`${this.apiUrl}/getConvertedImageWithPoints`, formData, { cancelToken: this.axiosCancelSource.token })
          .then(resp => {
            if (resp.data.response === 'OK') {
              const img = new Image()
              img.addEventListener('load', () => {
                this.points = JSON.parse(resp.data.points)
                this.image = img
              });

              // Converting base64 to blob
              let ImageURL = resp.data.image
              var block = ImageURL.split(";");
              var contentType = block[0].split(":")[1];
              var realData = block[1].split(",")[1];
              var blob = this.b64toBlob(realData, contentType);
              var objectURL = URL.createObjectURL(blob);
              img.src = objectURL

              this.setReceiptImage(this.file)
            }
          })
          .catch(error => {
            this.handleError({ cause: 'manyTries' })
          }).finally (() => {
            this.isLoading = false;
            this.axiosCancelSource = null;
          })
      }
    },
    b64toBlob (b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
  }
}
</script>

<style scoped>
  .canvas-container {
    position: relative;
    background-color: #FFF;
    border: 1px solid #ccc;
  }

  .canvas-container img {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .container {
    padding: 5px;
    border: 1px solid #ccc;
  }

  .wrapper {
    position: relative;
  }
</style>
