import store from '../store'

export const translateError = e => {
  const errors = {
    "Email address is already in use.": `Bu e-postaya zaten bir "Kadınlar Bilir" hesabı kayıtlı. Lütfen giriş sayfasında bu e-posta ile giriş yapın.`,
    "IBAN already used": "IBAN bereits verwendet",
    "IBAN is alreaedy in use": "Şu anda girdiğiniz IBAN başka bir kullanıcı tarafından kullanılıyor.",
    "Incorrect username or password. Please try again.": "Geçerli olmayan bir kullanıcı adı veya parola girdiniz. Lütfen tekrar deneyin.",
    "Campaign not found!": "Kampanya bulunamadı",
    "That email is not allowed. Please enter a different one.": "Geçerli olmayan bir e-posta girdiniz. Lütfen başka bir e-posta ile devam ediniz."
  }
  return errors[e]
}

export let myHistory = [];

export default {
  methods: {
    goToPage: (pageName, self) => {
      if (pageName.includes('{{routeAfterReceipt}}')) {
        const route = self.$store.state.routeAfterReceipt
        if (self.$store.state.participantIsCompany) pageName = pageName.replace('{{routeAfterReceipt}}', route.company)
        else pageName = pageName.replace('{{routeAfterReceipt}}', route.individual)
      }
      self.$eventHub.$emit('goToPage-event', pageName);
      // change url when moving to another page
      if (location.pathname.split('/').join('') !== pageName) {
        if (!self.skipPushState) {
          myHistory.push(location.pathname.slice(1) || 'anasayfa');
        }

        history.replaceState(myHistory, null, '/' + pageName)
      }
      self.$eventHub.$emit('route-changed', location.pathname);
    },
    goNextStep: (name, self) => {
      store.state.currentSection = name
      self.scrollTo("#" + name)
    },
    navigateToPage: (params) => {
      if (params.delayed) {
        setTimeout(() => {
          window.location.href = params.link
        }, params.delayed);
      } else {
        window.location.href = params.link
      }
    },
    sendProductPhotos: (params, buttonComponent) => {
      let fieldId = params;
      // console.log("1. will SEND products:::", fieldId, buttonComponent)

      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));

      buttonComponent.$eventHub.$emit('sendProductPhotos-event', fieldId);
    },
    sendForm: (params, buttonComponent) => {
      if (params.indexOf(',') > -1) {
        params = params.split(',')
      }
      let formId = params;
      let neuStarTrigger = false
      if (self._.isArray(params)) {
        formId = params[0]
        neuStarTrigger = params.filter(param => param === 'neuStarTrigger')
        // console.log("1. will SEND FORM:::", formId, 'neuStarTrigger', neuStarTrigger.length > 0)
      }
      const triggers = {
        neuStarTrigger: neuStarTrigger.length > 0
      }
      // console.log("1. will SEND FORM:::", formId, "NO TRIGGER")
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      // console.log("2. will SEND FORM:::", formId, buttonComponent)

      buttonComponent.$eventHub.$emit('getFormData-event', formId, triggers);
    },
    sendProducts: (formId, buttonComponent) => {
      // console.log("SEND PRODUCTS:::", formId)
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('sendProducts-event', formId);
    },
    sendReward: (formId, buttonComponent) => {
      // console.log("SEND PRODUCTS:::", formId)
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('sendReward-event', formId);
    },
    doneTrigger: (component, eventResult) => {
      component.$eventHub.$off('done-event-trigger');
      component.loading = false
      // console.log('1. will Done Trigger', eventResult)
      // console.log('event Result',eventResult)
      // if (eventResult.status) {
      //     eventResult.parent.clear()
      // }
      if (!eventResult.status && eventResult.message) {
        // console.log("EVENT RESULT>>>", eventResult)
        // const lang = "de"

        // Oops. Something went wrong, please try again later.
        // const defaultErrorMessage = "Hoppla. Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
        // if (eventResult.invalid_fields && eventResult.invalid_fields.length) {
        //     const localErr = translateErrpr(eventResult.invalid_fields[0], lang)
        //     if (localErr) component.showModal(localErr)
        //     else component.showModal(defaultErrorMessage)
        //     return
        // }
        component.showModal(eventResult.message)
      }

    },
    sendReceipt: (component, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$emit('sendReceipt-event', buttonComponent)
    },
    sendEmail: (data, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('email-sent-trigger',
        (parent) => buttonComponent.emailSentTrigger(buttonComponent, parent));

      buttonComponent.$eventHub.$emit('sendmail-event', data);
    },
    emailSentTrigger: (component, eventResult) => {
      component.$eventHub.$off('email-sent-trigger');
      component.loading = false

      // Email was sent
      if (eventResult.success) {
        component.showModal(eventResult.message, 'success')
        eventResult.parent.clear()

        // Clear The product state
        component.$store.commit('resetProducts');
        component.$store.commit('setProductCode', '');
        component.$store.commit('setSearchStatus', null);

        // Move back to home page
        component.goToPage('startseite', component)
      }
      if (!eventResult.status && eventResult.message) component.showModal(eventResult.message)


    },
    isMobileOrTablet ( currentScreenSize ) {
      return currentScreenSize < 992 ? true : false
    },
    displaySuccess () {
      this.$eventHub.$emit('display-success');
    },
    forgotPasswordSuccessAction (params , component) {
      component.goToPage(`${params}/giris-yap` , component)
      component.showModal('Şifrenizi değiştirmenize yardımcı olmak için bir e-posta gönderdik.', 'success')
    },
    sendSurvey: (formId, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('getSurveyData-event', formId);
    },
    displayModal (params, component) {
      component.showModal(params.message, params.type)
    },
    sendSms: (component, buttonComponent) => {
      // console.log("1. will SEND SMS:::", formId, "NO TRIGGER")
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('sendSms-event');
    },
    sendCode: (component, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('sendCodeData-event');
    },
    verifySms: (component, buttonComponent) => {
      // console.log("1. will SEND SMS:::", formId, "NO TRIGGER")
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('verifySms-event');
    },
    scrollByClass(id){
      // need to be change to id 
      const element = document.querySelector("#"+id)

      console.log(element,id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    selectParticipantType: (formId, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('selectParticipantType-event', formId);
    },
    sendContactForm: (component, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('sendContactFormData-event');
    },
    changeCampaignId: (component, buttonComponent) => {
      buttonComponent.$eventHub.$emit('changeCampaignId-event');
    }
  }
}
