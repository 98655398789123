/* eslint-disable */

<template>
  <div>
    <router-view />
    <div v-if="showCookie" class="cookie_button consent-button" style="display: block">
      <button class="btn-cookie btn-transparent" @click.prevent="oneTrustModal">
        Çerez Onayı
      </button>
    </div>
  </div>
</template>
<script>
const version = process.env.VUE_APP_VERSION;

export default {
  data() {
    return {
      showCookie: true,
    };
  },
  created () {
    if (process.env.NODE_ENV === 'production') {
      console.log = function () {};
    }
    console.log("%cV" + version, "color:red; font-size:32px;");
    this.$eventHub.$on('route-changed', (newPath) => {
      this.showCookie = !newPath.includes('lotereya');
    }); 
  },
  beforeDestroy() {
    this.$eventHub.$off('route-changed'); 
  },
  methods: {
    oneTrustModal: function () {
      // eslint-disable-next-line no-undef
      Optanon.OnConsentChanged(() => {
        if (window.segmentaCookieHandler) window.segmentaCookieHandler();
        if (!window.nsId && !window.nsIdPending && window.nsUrl) {
          window.nsIdPending = true;
          // eslint-disable-next-line no-undef
          fetchJsonp(window.nsUrl, {
            jsonpCallbackFunction: "neustar_response"
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              window.nsId = json.nsid;
            })
            .catch(function (er) {
              console.log(er)
            });

          if (window._agknEchoTag) {
            var a = window._agknEchoTag;
            a.setBpId("firstp");
            a.addEchoKeyValue("event", "page_landing");
            a.generateTag();
          }
        }
      });
      // eslint-disable-next-line
      Optanon.ToggleInfoDisplay();
    }
    }
};
</script>
<style lang="scss">
@media (min-width: 1440px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1270px !important;
  }
  .wizard-navigation {
    max-width: 1270px !important;
    margin: auto !important;
  }
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: 700;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
@import "~@/assets/styles/_application.scss";
@import "~@/assets/styles/campaigns/OralBYorumum/oralbyorumum.scss";
#app {
  height: 100%;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.segmanta-loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(86, 164, 255, .2);
  border-right: 1.1em solid rgba(86, 164, 255, .2);
  border-bottom: 1.1em solid rgba(86, 164, 255, .2);
  border-left: 1.1em solid #56a4ff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear
}

.segmanta-loader,
.segmanta-loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.cookie_button button {
  font-size: 14px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #034c90;
  right: 0;
}

.cookie_button button:hover {
  color: #034c90;
  background: #fff;
}

.cookie_button {
  border: none;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  text-align: left;
  position: fixed;
  right: 0;
  z-index: 2;
  bottom: 0px;
  opacity: 0.9;
  background: var(--brand-color);
  color: #000000;
  display: none;
  overflow: auto;
}

.cookie_button.open {
  display: block;
}

.btn-cookie {
  font-size: 20px;
  letter-spacing: 1px;
  color: #000;
  transition: all 0.3s;
  white-space: normal;
  line-height: 1.36;
  text-align: center;
  padding: 9px 5px 9px;
  text-transform: uppercase;
  border-radius: 0rem;
  font-family: "BebasNeue", sans-serif;
  border: none;
  line-height: 1;

  @media (max-width: 374.98px) {
    // font-size: 18px;
  }

  &.disabled {
    opacity: 0.5;
  }

  &-gold {
    padding: 11px 29px 6px;
    background-color: #034c90;
    background: #034c90;
    width: 100%;

    .arrow {
      width: 0;
      height: 0;
      border: 10px solid transparent;
      margin-top: 5px;

      &.arrow-left {
        border-right: 0;
        border-left: 10px solid #ffffff;
        margin-left: 10px;
        float: right;
      }

      &.arrow-right {
        border-left: 0;
        border-right: 10px solid #ffffff;
        margin-right: 10px;
        float: left;
      }
    }
  }

  .btn-transparent {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
  }

  .btn-transparent:hover,
  .btn-transparent:focus {
    background-color: transparent;
    color: #ffffff;
  }

  &-transparent {
    background-color: transparent;
    color: #ffffff;
    line-height: 1.2;

    .arrow_right {
      float: right;
      padding-left: 15px;
    }
  }

  &-big {
    width: 315px;
    max-width: 100%;
  }
}
</style>
