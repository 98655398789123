<template>
  <div class="keyVisualWrapper" :style="styles">
    <div :class="{'container': !noContainer}">
      <div :class="{'row':addRow}">
        <img class="desktop_img img-fluid w-100" :src="desktopImageUrl" alt="Desktop Image"/>
        <img class="tablet_img img-fluid w-100" :src="tabletImageUrl" :style="tabletImgStyles" alt="Tablet Image"/>
        <img class="mobile_img img-fluid w-100" :src="mobileImageUrl" :style="mobileImgStyles" alt="Mobile Image"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "key-visual",
  props: {
    desktopImageUrl: {
      type: String,
      required: true
    },
    mobileImageUrl: {
      type: String,
      required: true
    },
    tabletImageUrl: {
      type: String,
      required: true
    },
    addRow: {
      type: Boolean
    },
    noContainer: {
      type: Boolean
    },
    mobileImgStyles: {
      type: Object,
      default: () => {}
    },
    tabletImgStyles: {
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped lang="scss">
  .keyVisualWrapper {
    position: relative;

    @media (max-width: 991px) {
      padding: 0px;
    }
  }

.desktop_img {
  @media (max-width: 991px) {
    display: none;
  }
}

.tablet_img {
  @media (max-width: 435px), (min-width: 992px) {
    display: none;
  }
}

.mobile_img {
  @media (min-width: 435px) {
    display: none;
  }
}

</style>
