<template>
  <div class="steps_container" :style="cssVars">
    <ul class="steps_ul progressbar">
      <li
        v-for="(step, idx) in steps"
        :key="idx"
        class="step_action"
        :data-step="idx + 1"
        :class="`${activeIndex >= idx ? 'active' : '' } ${campaignName}`"
      >
        <div class="bgbg">
          <img
            v-if="activeIndex <= idx"
            :src="getImageUrl(idx)"
            alt="icon"
          />
          <img
            v-if="activeIndex >= idx + 1"
            :src="`/assets/steps/${campaignName}/check${idx + 1}.svg`"
            alt="icon"
          />

        </div>
        <div class="step_text text-left" v-html="step.title" />
        <div class="inner_step_action"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "step-counter",
  props: {
    grid: {
      type: Object,
      default: () => {
        `backgroundColor: "#fff";
        `;
      }
    },
    steps: {
      type: Array,
      required: true
    },
    actions: {
      type: Object
    },
    activeIndex: {
      type: Number,
      required: false
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#1C3A68"
    },
    campaignName: {
      required: false,
      type: String,
      default: "altin"
    }
  },
  methods: {
    getImageUrl (id) {
      if(this.campaignName == "oralbyorumum"){
        if(id < 2){
          return `/assets/steps/${this.campaignName}/${id + 1}.svg`
        } else return `/assets/steps/${this.campaignName}/${id + 1}.png`
      } else return `/assets/steps/${this.campaignName}/${id + 1}.svg`
    },
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.step_text{
  @media(min-width: 768px){
    // height: 56px !important;
    min-height: 56px !important;
  }
}
.steps_ul {
  padding: 0;
  margin: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 32%;
  position: relative;
  text-align: center;
}
.steps {
  padding-top: 160px;
  padding-bottom: 110px;
}

.steps_container {
  margin-top: 50px;
}

.progressbar {
  counter-reset: step;
  position: relative;

  @media (max-width: 767px) {
    display: flex;
  }
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 32%;
  position: relative;
  text-align: center;

  @media (max-width: 767px) {
    width: 33.3%;
  }
}
.progressbar li:first-child .step_text {
  text-align: left;
}
.progressbar li:last-child .step_text {
  text-align: right;
}
.progressbar li .step_text {
  margin-top: 10px;
  font: normal normal 500 24px/28px Montserrat;
  letter-spacing: 0px;
  color: var(--color);
  opacity: 0.5;
  text-align: center !important;

  margin-left: 7%;

  @media (max-width: 991px) {
    display: none;
  }
}

.baharozel::after {
  background-image: url(@/assets/images/svg/arrow.svg);
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 30%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: calc(75px - 25px) ;
  height: 50px;
  margin-left: 40%;
  @media (max-width: 991px) {
    top: calc(40px - 5px) ;
    height: 20px;
  }
}
.step_action {
  .bgbg {
    font-size: 14px;
    width: 150px;
    height: 150px;
    border: 2px solid inherit;
    display: block;
    font-weight: 800;
    text-align: center;
    margin: 0 auto 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 2;
    padding-top: 4px;
    position: relative;
    opacity: 0.5;

    img {
      width: 100%;
    }

    @media (max-width: 991px) {
      width: 80px;
      height: 80px !important;
    }
  }
  .stepNumber {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    color: var(--brand-color);
    font-weight: 400;
    z-index: 2;
  }
}
.active {
  font-size: 14px;
  border: 2px solid inherit;
  display: block;
  font-weight: 800;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 2;
  padding-top: 4px;
  opacity: 1;
}
.active > div.bgbg {
  opacity: 1;
}
.active > div.step_text {
  opacity: 1;
}

.progressbar li:last-child:after {
  display: none;
}

@media (max-width: 400px) {
  .steps_container {
    padding: 0 !important;
  }
}

.stepMobile {display: none;}
@media (max-width: 991px) {
  .stepDesktop { display: none; }
  .stepMobile { display: grid;}
}

</style>
