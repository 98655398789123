<template>
  <div class="stepCardWrapper" :class="mainWrapperClass">
    <img class="arrowImg" v-if="showArrow" :src="arrowImageSource" alt="" />
    <div class="stepImage">
      <img :src="imageSource" alt="">
    </div>
    <div class="paragraph">
      <p v-for="field in fields" :key="field.fieldId">
        <componentRenderer v-bind:key="field.id" :field="field" />
        </p>
    </div>
  </div>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "step-card",
  components: { componentRenderer },
  props: {
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    imageSource: {
      required: true,
      type: String
    },
    arrowImageSource: {
      required: true,
      type: String
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    stepNumber: {
      required: true,
      type: [String, Number]
    },
    stepStyles: {
      required: false,
      type: Object
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    } 
  }
};
</script>

<style lang="scss" scoped>
.stepCardWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;

    @media (max-width: 991px) {
      justify-content: flex-start;
      margin-bottom: 50px;
    }

    .stepImage {
      position: relative;
      margin: 5px;
    }

    .stepNumber {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #D8D8D8;
        font-weight: 700;
        height: 35px;
        width: 35px;
        font-size: 14px;
        line-height: 24px;
        position: absolute;
        right: -40px;
        top: -8px;
    }
    .paragraph {
        // margin-top: 20px;
        font-weight: 700;
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: inherit;
        }
        @media (max-width: 500px) { margin: 0px; }
    }
    .stepImage img {
      height: 120px;
      width: 120px;
      @media (max-width: 991px) {
        height: 80px;
        width: 80px;
      }
      @media (max-width: 500px) {
        height: 80px;
        width: 80px;
      }
      @media (max-width: 400px) {
        height: 60px;
        width: 60px;
      }
    }
    .stepImage:after {
      @media (max-width: 991px) {
        height: 20px;
        background-size: contain;

        top: calc(50% - 5px);
        margin-left: 0;
      }
    }
}
.stepCardWrapper:last-child {
  .arrowImg {
    display: none;
  }
}

.arrowImg {
  position: absolute;
  width: 40px;
  height: 40px;

  top: calc(130px - 40px);
  right: calc(0% - 24.5px);

  @media (max-width: 991px) {
    width: 26px;
    height: 20px;

    top: 100%;
    left: 50%;
    transform: rotate(90deg);
  }
  @media (max-width: 500px) {
    width: 26px;
    height: 20px;
  }
}
.oralb-step-card{
  .stepImage{
    img{
      width: 120px !important;
      height: auto !important;
      @media (max-width: 991px) {
        height: auto !important;
        width: 80px !important;
      }
      @media (max-width: 500px) {
        height: auto !important;
        width: 80px !important;
      }
      @media (max-width: 400px) {
        height: auto !important;
        width: 120px !important;
      }
    }
  }
}
</style>
