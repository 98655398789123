<template>
  <div class="m-0 p-0 pb-3">
    <ValidationProvider v-if="validation" :rules="validation" v-slot="{ errors }">
      <div class="selectInputWrapper">
        <select
          v-model="localSelected"
          required
          ref="select"
          :name="placeholder"
          :id="field.fieldId"
          class="form-control"
          @change="updateComponent"
          :class="{'is-invalid': errors.length}"
          :style="styles"
        >
          <option value disabled :selected="!selectedValue" hidden></option>
          <option
            v-for="option in options"
            :key="option.value"
            :selected="option.value == selectedValue"
            :disabled="option.disabled"
            :value="option.value"
          >{{option.text}}</option>
        </select>
        <img
          v-if="errors.length"
          class="error_min inputError"
          src="../../assets/icons/risk-icon.svg"
          height="18px"
          width="18px"
          alt="Risk icon"
        />
        <span class="floating-label">{{placeholder}}</span>
      </div>
      <div class="validation-error" v-if="errors.length">{{errors[0]}}</div>
    </ValidationProvider>
    <template v-else>
      <div class="selectInputWrapper">
        <select
          class="form-control"
          v-model="localSelected"
          required
          ref="select"
          :name="name"
          :id="field.fieldId"
          @change="updateComponent"
        >
          <option value disabled :selected="!selectedValue" hidden></option>
          <option
            v-for="option in options"
            :key="option.value"
            :selected="option.value == selectedValue"
            :disabled="option.disabled"
            :value="option.value"
          >{{option.text}}</option>
        </select>
        <span class="floating-label">{{placeholder}}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "select-input",
  data () {
    return {
      localSelected: this.selectedValue || ""
    };
  },
  props: {
    field: {
      required: false,
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select Something"
    },
    validation: {
      type: String,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    updateComponent (event) {
      this.$refs.select.style.color = "#000";
      this.$emit("component-updated", {
        text: event.target.value
      });
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style scoped lang="scss">
$medium: 800px;

.selectInputWrapper {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url("../../assets/icons/form/dropdown.svg") no-repeat !important;
    background-size: 14px !important;
    background-position: right 10px center !important;
  }
  position: relative;
  margin: 5px;
  .error_min {
    width: 16px;
  }
  .inputError {
    position: absolute;
    top: 15px;
    right: 10px;
  }
}

select {
  background: transparent;
  outline: 0;
  box-shadow: none;
  transition: border-color 0.15s;
  box-sizing: border-box !important;
  padding-top: 12px !important;
  padding-left: 15px !important;
  height: 50px !important;
  cursor: pointer;
  width: 100%;
  color: #000;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 6px;

  &.is-invalid {
    border-color: #dc3545 !important;
    background: #fff0f1 !important;
  }
}

.floating-label {
  position: absolute;
  top: 50%;
  left: 10px;
  padding-left: 7px;
  padding-right: 7px;
  transition: transform 0.1s;
  pointer-events: none;
  transform-origin: 0 100%;
  transform: translateY(-50%);

  font: normal normal 300 16px/22px Montserrat;
  letter-spacing: 0px;
  color: #1B1B1B;
  opacity: 0.5;

}

select:focus + .floating-label,
select.visited + .floating-label,
select:not(:focus):valid ~ .floating-label {
  transform: translateY(-135%) scale(0.8);
}

</style>
