<template>
  <div class="footerWrapper">
    <div class="col-md-12 p-0 d-flex desktopOnly">
      <div class="col-md-8">
        <div class="d-flex flex-direction-row">
          <div class="links" v-for="link in links" v-bind:key="link.id">
            <a :href="link.link" target="_blank" v-html="link.name"></a>
          </div>
        </div>
        <div class="pgTextWrapper d-flex flex-direction-row">
          <a href="https://www.pg.com.tr/" target="_blank">
            <img class="pgTextImage"
            :src="logo.imageSource2x"
            :srcset="`${logo.imageSource} 1x, ${logo.imageSource2x} 2x`"
            :width="logo.width"
            :height="logo.height"
            :alt="logo.alternateText" />
          </a>
          <!-- <a href="https://www.kadinlarbilir.com" target="_blank">
            <img class="pgTextImageLarge" src="@/assets/images/png/reg_logo@2x.png" />
          </a> -->
          <div class="pgText" v-html="pgText"></div>
        </div>
      </div>
      <div class="col-md-4 pr-5">
        <div class="poweredWrapper">
          <span class="poweredText">
            Powered by
          </span>
          <a href="https://justsnap.de/" target="_blank">
            <img
              class="logo"
              src="../../assets/images/js-logo-big.png"
              alt="JustSnap"
            />
          </a>
        </div>
        <div class="socialMediaIcons d-flex flex-direction-row justify-content-end">
          <a target="_blank" href="https://www.facebook.com/pgturkiye">
            <img class="socialMediaIcons1" src="../../assets/images/svg/fb.svg" alt="Facebook icon"/>
          </a>
          <a target="_blank" href="https://www.youtube.com/user/pgturkiye">
            <img class="socialMediaIcons2" src="../../assets/images/svg/yt.svg" alt="Youtube icon"/>
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 p-0 d-flex flex-column desktopNone">
      <div class="d-flex flex-row pt-3">
        <div class="imgWrapper">
          <a class="pb-2" href="https://www.pg.com.tr/" target="_blank">
            <img class="pgTextImage"
            :src="logo.imageSource2x"
            :srcset="`${logo.imageSource} 1x, ${logo.imageSource2x} 2x`"
            :width="logo.width"
            :height="logo.height"
            :alt="logo.alternateText" />
          </a>
          <a href="https://www.kadinlarbilir.com" target="_blank">
            <img class="pgTextImageLarge" src="@/assets/images/png/reg_logo@2x.png" alt="Kadinlar Bilir logo"/>
          </a>
        </div>
        <div class="pgText" v-html="pgText"></div>
      </div>

      <div class="d-flex flex-row">
        <div class="d-flex flex-column col-6 p-0">
          <div class="links p-2 d-flex justify-content-start ml-3" v-for="link in firstHalfLinks" v-bind:key="link.id">
            <a :href="link.link" target="_blank" v-html="link.name"></a>
          </div>
        </div>

        <div class="d-flex flex-column col-6 p-0">
          <div class="links links2 p-2 d-flex justify-content-start ml-3" v-for="link in secondHalfLinks" v-bind:key="link.id">
            <a :href="link.link" target="_blank" v-html="link.name"></a>
          </div>
        </div>
      </div>

      <div class="d-flex col-12 p-0">
        <div class="d-flex col-6 p-0">
          <div class="poweredWrapper ml-3">
            <span class="poweredText pr-3">
              Powered by
            </span>
            <a class="d-flex" href="https://justsnap.de/" target="_blank">
              <img
                class="logo logoMobile"
                src="../../assets/images/js-logo-big.png"
                alt="JustSnap"
              />
            </a>
          </div>
        </div>

        <div class="d-flex col-6 justify-content-end">
          <div class="socialMediaIcons d-flex flex-direction-row justify-content-end">
            <a class="socialMediaIcons1A" target="_blank" href="https://www.facebook.com/pgturkiye">
              <img class="socialMediaIcons1" src="../../assets/images/svg/fb.svg" alt="Facebook icon"/>
            </a>
            <a target="_blank" href="https://www.youtube.com/user/pgturkiye">
              <img class="socialMediaIcons2" src="../../assets/images/svg/yt.svg" alt="Youtube icon"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-component",
  props: {
    links: {
      type: Array,
      default: () => []
    },
    pgText: {
      type: String
    },
    logo: {
      required: true,
      type: Object
    }
  },
  computed: {
    firstHalfLinks () {
      const linksLengthHalf = this.links.length / 2;
      return this.links.filter((link , id) => id < linksLengthHalf)
    },
    secondHalfLinks () {
      const linksLengthHalf = this.links.length / 2;
      return this.links.filter((link , id) => id >= linksLengthHalf)
    }
  }
};
</script>

<style lang="scss" scoped>

.links {
  margin: 0;
  padding: 20px;
  list-style: none;

  text-align: center;
  font: normal normal 500 20px/24px Montserrat !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  a {
    display: block;
    font-size: .85em;

    &:hover {
      color: #C4C4C4;
    }
  }
}

.footerWrapper {
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-top: 1px solid rgba(#000000 , 0.1);
  padding-bottom: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
}

.socialMediaIcons {
  padding-top: 10px;
  &1 {padding-right: 1px;}
  &2 {padding-left: 1px;}
}

.pgText {

  padding-left: 20px;

  text-align: left;
  font: normal normal 300 14px/22px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  &Image {
    width: 60px;
    height: 62px;

    margin-left: 20px;

    &Large {
      height: 40px;
      margin-left: 10px;
    }
  }

  &Wrapper {
    align-items: center;
    padding-top: 20px;
  }
}

.poweredWrapper {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  .poweredText {
    align-self: center;
    font: normal normal 500 14px/22px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .logo {
    width: 46px;
    height: 46px;
    margin-left: 5px;
  }
}

@media (max-width: 767px) {
  .link-wrapper {
    justify-content: flex-end;
  }
  .links li {
    font-size: 19px;
  }
  .link-wrapper .links {
    margin-bottom: 20px;
  }
}

@media (min-width: 991px) {
  .desktopOnly { display: flex !important; }
  .desktopNone { display: none !important; }
}
@media (max-width: 991px) {
  .desktopOnly { display: none !important; }
  .desktopNone { display: flex !important; }

  .pgTextImage {
    width: 50px;
    height: 50px;
  }

  .pgText {
    text-align: left;
    font: normal normal 300 12px/16px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;

    margin: 25px;
    margin-left: 0px !important;
  }

  .links {
    padding-left: 0px !important;

    text-align: left;
    font: normal normal 400 14px/14px Montserrat !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;

    &2 {
      text-align: right;
    }
  }

  .logoMobile {
    align-self: center;
    width: 40px !important;
    height: 40px !important;
  }

  .socialMediaIcons {
    align-items: center;
    &1A {
      right: 50px;
    }
    a {
      position: absolute;
    }
    img {
      width: 50px;
    }
  }

  .imgWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
