<template>
<div>
  <div class="navbar-wrapper col-lg-12 mx-auto p-0">
    <nav class="navbar navbar-expand-lg justify-content-between p-0">
      <!-- <a class="navbar-brand" href="https://www.pg.com.tr" target="_blank"> -->
      <div class="navbar-brand" :style="!fields.length ? `width: 100%` : ''">
        <div>
      <a href="/">
        {{logo.title}}
        <img
          class="navbarImage"
          :src="logo.imageSource2x"
          :srcset="`${logo.imageSource} 1x, ${logo.imageSource2x} 2x`"
          :width="logo.width"
          :height="logo.height"
          :alt="logo.alternateText"/>
      </a>
        </div>
      <a :href="goTo" target="_blank">
        <img
          class="kadinlarBilirLogo"
          :src="secondLogo"
          :alt="logo.alternateText"/>
      </a>
      </div>
    <div class=" justify-content-center d-none d-lg-flex d-xl-flex">
        <h1 class="m-0 p-0" :style="`line-height: 1; font-size: 30px; color: ${color};`">{{title}}</h1>
    </div>

      <button
        class="navbar-toggler"
        type="button"
        @click="checkBoxClicked"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse expandedDiv" ref='expandedDiv'>
        <ul class="navbar-nav">
          <li class="navbarLinks" v-for="field in fields" :key="field.fieldId">
            <componentRenderer v-bind:key="field.id" :field="field" />
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <hr v-if="showLine" class="hr">
</div>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "navbar",
  components: { componentRenderer },
  props: {
    showLine: {
      required: false,
      type: Boolean
    },
    backgroundColor: {
      required: false,
      type: String,
      default: "#fff"
    },
    title: {
      required: false,
      type: String,
      default: "Hello world"
    },
    logo: {
      required: true,
      type: Object
    },
    secondLogo: {
      type: String,
      default: "/assets/logo/reg_logo@2x.png"
    },
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    color: {
      required: false,
      type: String,
      default: "#000"
    },
    goTo: {
      type: String,
      default: "https://www.kadinlarbilir.com"
    }
  },
  methods: {
    checkBoxClicked () {
      const expandedDiv = this.$refs['expandedDiv'];
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + "px";
    }
  }
};
</script>

<style lang="scss" scoped>
.collapse.navbar-collapse {
}
.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-family: Montserrat,sans-serif;
  color: #0157ac;
  font-size: 24px;
  font-weight: 900;

  

}
.navbarLinks {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 200px;
}
.navbar-wrapper {
  position: relative;
}

.hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
.navbar-collapse{
  flex-grow:initial;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(13,34,84, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.headerLink {
  font: normal normal 500 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.kadinlarBilirLogo {
  height: 40px;
  margin-left: 20px;
}
.navbarImage {
  margin-left: 20px;
}
@media (max-width: 991px) {
  .navbarImage {
    height: 40px;
    width: 40px;
  }
  .navbar-brand {
    font-size: 22px;
  }
}
@media (max-width: 500px) {
  .navbarImage {
    height: 25px;
    width: 25px;
  }
  .navbar-brand {
    font-size: 18px;
  }
}

@media (max-width:437px) {
    .navbar-brand{
      flex-direction: column;
    }

    .navbar{
      justify-content: center !important;
    }
  }
.expandedDiv {
  @media (max-width: 991px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
    -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
    -o-transition: height 0.4s ease-out;;  /* Opera */
  }
}
</style>
