<template>
  <div :id="fieldId" style="margin-bottom: 40px; display: flex; align-items: center;flex-direction: column;">
    <div class="buttons col-lg-12" >
        <div class="button_div col-lg-4">
            <p class="kayit_text">Fırsat yanında olarak</p>
            <button class="kayit" @click="change_visibility(0)">
            Kayıt Ol
            </button>
        </div>
        
        <div class="button_div col-lg-4">
            <p class="kayit_text">Kadınlar Bilir'e Kayıt olarak</p>
            <button class="giris" @click="change_visibility(1)">
            Giriş Yap
            </button> 
        </div>
       
        <div class="button_div col-lg-4">
            <p class="kayit_text">Kayıt olmadan misafir olarak</p>
            <button class="guest" @click="change_visibility(2)">
            Devam Et
            </button>
        </div>
        
    </div>
    <div class="col-lg-9">
    <template>
      <componentRenderer
        :class="`col-lg-${headers[visibile_component].properties.grid.columns}`"
        :field="headers[visibile_component]"
        v-bind:key="headers[visibile_component].id"
        v-on:component-updated="updated"
        @click="clicked()"
      />
    </template>

    <template>
      <componentRenderer
        :class="`col-lg-${subheaders[visibile_component].properties.grid.columns}`"
        :field="subheaders[visibile_component]"
        v-bind:key="subheaders[visibile_component].id"
        v-on:component-updated="updated"
        @click="clicked()"
      />
    </template>
    
    <template>
      <componentRenderer
        :class="`col-lg-${fields[visibile_component].properties.grid.columns}`"
        :field="fields[visibile_component]"
        v-bind:key="fields[visibile_component].id"
        v-on:component-updated="updated"
        @click="clicked()"
      />
    </template>
    </div>
  </div>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
    name:'changer',
    components:{componentRenderer},
    data(){
        return {
            visibile_component:this.options[0]
        }
    },
    props:{
        fieldId:{
            type:String,
            required:false
        },
        options:{
            type: Array,
            required: false,
            default: ()=>{
                return [0,1,2]
            }
        },
        fields:{
            type: Array,
            required: true
        },
        headers:{
            type: Array,
            required: true
        },
        subheaders:{
            type: Array,
            required: true
        },
        grid: {
            type: Object
        },
        styles: {
            type: Object,
            default: () => {
                return {};
            }
        },
        buttons:{
            type: Array,
            required: false
        },
        buttonTexts:{
            type:Array,
            required: false
        },
    },
    methods: {
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });

      updatedField.properties = { ...updatedField.properties, ...properties };
      // let updatedField = __.findDeep(this.fields, fld => fld === field.fieldId);
      // console.log(
      //   "component renderer on updated::",
      //   field,
      //   "properties",
      //   properties,
      //   "FOUND",
      //   updatedField
      // );

      // TODO
      // some validation will go here
      // in case if all fields are valid we are happy to set truthy status
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    },
    change_visibility(index){
        this.visibile_component = this.options[index]
        // console.log(item)
        // console.log(index)
    },
  },
//   created(){
//     console.log("changer oluşturuldu")
//   },
//   beforeCreate(){
//     console.log("changer creating")
//   }
};
</script>

<style>
.changer_button{
    width: 50px;
    height: 20px;
    }

.buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button_div{
    padding: 10px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 16px rgb(2 68 142 / 35%);
    border-radius: 12px;
    justify-content: space-around;
}

.kayit{
    background: #1C3A68 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    font: normal normal 500 19px/19px Montserrat;
    letter-spacing: 0px;
    color: #FBFBFB;
    opacity: 1;
    height: 60px;
    width: 150px;
}
.kayit_text{
    margin: 0;

    font-size: 13px;
}
.giris{
    background: #4682C6 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    font: normal normal 500 19px/19px Montserrat;
    letter-spacing: 0px;
    color: #FBFBFB;
    opacity: 1;
    height: 60px;
    width: 150px;
}
.guest{
    background: #FBFBFB;
    color: #4682C6;
    border-radius: 6px;
    opacity: 1;
    font: normal normal 500 19px/19px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
    height: 60px;
}
</style>
