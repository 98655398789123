import axios from 'axios'
import router from '@/router'

// const testApi = "http://localhost:3000/api"
const testApi = process.env.VUE_APP_API_URL
const APIUrl = process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API_URL : testApi

export default {
  isPublic ({ state }, pageName) {
    if (pageName.toLowerCase() === 'anasayfa') return true
    const page = state.pageProperties.pages.find(page => page.pageName === pageName)
    return page && Boolean(page.isPublic);
  },
  handleError: ({ commit, state }, err = { message: '', code: '99' }) => {
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
      err.code = err.response.status
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(err.request);
    } else {
      // Something happened in setting up the request and triggered an err
      console.log('Unexpected Error', err.message);
    }
    const page = state.pageProperties;
    const errors = page.errors['tr'] || page.errors || []
    let defaultMsg = errors['default']
    console.error("Error:", err, errors)
    if (err.cause) {
      if (errors[err.cause]) {
        return commit('setModalOptions', { type: 'error', isActive: true, message: errors[err.cause] })
      } else {
        return commit('setModalOptions', { type: 'error', isActive: true, message: defaultMsg })
      }
    } else if (err.customMsg) {
      let message = err.customMsg + "<br>Code:: " + err.code
      return commit('setModalOptions', { type: 'error', isActive: true, message: message })
    } else {
      if (err.code == '404') {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '503') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '502') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.message == 'Network Error') {
        let msg = errors['network'] + `<br>Code:: 50X`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else {
        let msg = defaultMsg
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      }
    }
  },

  setPageProperties: ({
    commit
  }, properties) => {
    commit('setPageProperties', properties);
  },

  refreshTempId: ({ commit, dispatch, getters }, data) => {
    let query = data.query
    const source = query.source ? true : false
    const utm_source = query.utm_source ? query.utm_source : false
    const utm_medium = query.utm_medium ? query.utm_medium : false

    let api = APIUrl
    if (data.endpoint && data.endpoint != '') api = data.endpoint

    return new Promise((resolve, reject) => {
      axios.post(`${api}/tempId`, { campaignId: getters.campaignId, source: source, utm_source, utm_medium })
        .then(resp => {
          if (resp.data.response) {
            const data = {
              field: 'customerToken',
              value: resp.data.customerToken
            }
            if (!location.pathname.includes('iban-yeniden-gonderme')) commit('setField', data)
          } else {
            dispatch('handleError')
            reject(false)
          }
        }).catch(err => {
          dispatch('handleError')
          reject(false)
        })
    })
  },
  sendToken: ({ dispatch, getters }, data) => {
    let query = router.currentRoute.query
    const source = query.source ? true : false
    const utm_source = query.utm_source ? query.utm_source : false
    const utm_medium = query.utm_medium ? query.utm_medium : false
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, { customerToken: getters.customerToken, campaignId: getters.campaignId, source: source, utm_source, utm_medium })
        .then(resp => {
          if (resp.data.response) {
            resolve(resp.data)
          } else {
            dispatch('handleError', {
              message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
              cause: ` Send Token::  ${resp.data.message}`
            })
            reject(resp.data.message)
          }
        })
        .catch(ex => {
          dispatch('handleError', {
            message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
            cause: ` Send Token::  ${ex.message}`
          })
          reject(ex.message)
        })
    })
  },

  setComponentStatus: ({ commit }, { component, status }) => {
    commit('setComponentStatus', { component, status });
  },
  sendFormData ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {

      if (window.nsId) {
        data.formData.nsId = window.nsId;
      }

      let api = APIUrl + data.endpoint
      if (data.endpoint && data.endpoint.includes('https')) api = data.endpoint

      axios.post(api, data.formData)
        .then(res => {
          if (data.triggers && data.triggers.neuStarTrigger) {
            // console.log("3. will send::: neutrigger", data.triggers)
            let a = window._agknEchoTag;
            if (a && data.n) {
              a.setBpId('firstp');
              a.addEchoKeyValue('event', 'submit');
              a.generateTag();
            }
          }
          if (res.data.response) {
            resolve(res.data)
          } else {
            if (res.data.invalid_fields) {
              //janrain error
              if (res.data.invalid_fields[0] == 'Email address is already in use.') {
                dispatch('handleError', { cause: 'emailUsed' })
              } else if (res.data.invalid_fields[0] == 'Incorrect username or password. Please try again.') {
                dispatch('handleError', { cause: 'incorrect' })
              } else if (res.data.message && res.data.message == 'authorization_code is not valid') {
                dispatch('handleError', { cause: 'invalidCode' })
              } else {
                dispatch('handleError', { customMsg: res.data.invalid_fields })
              }
            } else if (res.data.message && res.data.message == 'The code does not exist!') {
              dispatch('handleError', { cause: 'invalidBarcode' })
            } else if (res.data.message && res.data.message == 'The code has been used before!') {
              dispatch('handleError', { cause: 'usedBarcode' })
            } else if (res.data.message && res.data.message != 'Campaign not found!' && !res.data.message.includes('This campaign has not')) {
              dispatch('handleError', { customMsg: res.data.message })
            } else {
              dispatch('handleError', { code: res.data })
            }
            reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  sendSurveyData ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError', {
              message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
              cause: ` Send Survey::  ${res.data.message}`
            })
            reject(res.data)
          }
        })
        .catch(ex => {
          dispatch('handleError', {
            message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
            cause: ` Send Survey::  ${ex.message}`
          })
          reject(ex)
        })
    })
  },
  uploadProductPhotos ({ getters, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      // console.log("DATRA", data)
      const formData = new FormData()
      formData.append('productPhoto', data.image, 'productPhoto.png')
      if(data.customerToken){
        formData.append('customerToken', data.customerToken)
      } else {
        formData.append('customerToken', getters.customerToken)
      }
      formData.append('campaignId', getters.campaignId)
      formData.append('receiptToken', data.receiptToken)

      if (formData.customerToken == 1) {
        formData.append('token', data.token)
      }

      axios.post(APIUrl + data.endpoint, formData)
        .then(res => {
          if (res.data.response) {
            commit('setReferenceNumber', res.data.referenceNumber)
            resolve(res.data)
          } else {
            dispatch('handleError', {
              message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
              cause: ` Upload Product::  ${res.message}`
            })
            reject (res.data)
          }
        })
        .catch(ex => {
          dispatch('handleError', {
            message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
            cause: ` Upload Product::  ${ex.message}`
          })
          reject (ex.message)
        })
    })
  },

  generateCode ({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      let api = APIUrl + data.endpoint
      if (data.endpoint && data.endpoint.includes('https')) api = data.endpoint
      axios.post(api, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { formErr: true, message: res.data.message })
            return reject(res.data)
          }
        })
        .catch(ex => {
          dispatch('handleError', {
            message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
            cause: ` Generate Code::  ${ex.message}`
          })
          return { success: false, error: ex.message }
        })
    })
  },

  verifyCode ({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      let api = APIUrl + data.endpoint
      if (data.endpoint && data.endpoint.includes('https')) api = data.endpoint

      axios.post(api, data.formData)
        .then(res => {
          if (res.data.response) {
            commit('setReferenceNumber', res.data.referenceNumber)
            resolve({ success: true })
          } else {
            dispatch('handleError', { cause: 'wrongCode' })
            reject(res.data)
          }
        })
        .catch(ex => {
          dispatch('handleError', { cause: 'wrongCode' })
          reject(ex.message)
        })
    })
  },

  sendProducts ({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) return resolve(res.data)
          else dispatch('handleError', { message: res.data.message })
        })
        .catch(ex => {
          dispatch('handleError', {
            message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
            cause: ` Send Products::  ${ex.message}`
          })
          return { success: false, error: ex.message }
        })
    })
  },

  sendReward ({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) return resolve(res.data)
          else dispatch('handleError', { message: res.data.message })
        })
        .catch(ex => {
          dispatch('handleError', {
            message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
            cause: ` Send Reward::  ${ex.message}`
          })
          return { success: false, error: ex.message }
        })
    })
  },

  uploadReceipt ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (data.receiptToken) {
        formData.append('receiptToken', data.receiptToken)

      }
      if (APIUrl.includes('localhost') || APIUrl.includes('192.168')) {
        formData.append('imageUrl', 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/0814c81a-0d8a-4f70-ae00-be7752de6846.png')
        data.apiUrl = 'http://192.168.1.114:3001/api/createReceipt'
        formData.append('campaignId', data.subCampaignId)
      } else {
        formData.append('receipt', data.image, 'receipt.png')
        formData.append('campaignId', data.campaignId)
      }
      formData.append('userToken', data.customerToken)
      formData.append('subCampaignId', data.subCampaignId)

      if (data.token) {
        formData.append('token', data.token)
      }

      return axios.post(`${data.apiUrl}`, formData, {
      })
        .then(resp => {
          if (resp.data.response !== 'ERR') {
            commit('setReferenceNumber', resp.data.referenceNumber)
            commit('setReceiptToken', resp.data.receiptToken)

            return resolve(resp.data.receiptToken);
          } else {
            dispatch('handleError', resp.data.error)
          }
        })
        .catch(resp => {
          let errorCode = '';
          if (resp.response && resp.response.data) {
            errorCode = resp.response.data.error;
          }
          dispatch('handleError', {
            message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
            cause: ` Upload Receipt::  ${errorCode}`
          })
          // dispatch('handleError', errorCode)
        })
        .finally(() => {
          // commit('toggleUploadingAnimation', false)
        })
    })
  },
  sendReceiptToNewQRService ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('receipt', data, 'receipt')

      return axios.post(`https://pg-turkey.justsnap.eu/api/azQRCode`, formData, {
        responseType: 'arraybuffer' // Ensure the response is handled as binary data
      })
        .then(resp => {
          if (resp.data) {
            resolve(resp);
          } else {
            reject(resp)
          }
        })
        .catch(resp => {
          reject(resp)
        })
    })
  },

  onReceiptUploadSuccess ({ commit }, data) {
    commit('setReceiptImage', data)
  },
  uploadBarcode ($, data) {
    // Make a put request to add the barcode data with the given receipt token
    console.log("Actions", data)
    const apiUrl = 'https://national-cashback.justsnap.eu/api/v2/dashboard/receipt'
    axios.put(apiUrl, data, { headers: { "Content-Type": "application/json" } })
      .then(res => console.log("ACTIONS", res))
      .catch(err => console.log("ACTIONS", err))
  },
  uploadFile ({ commit }, file) {
    if (file) commit('setFile', file);
  },
  setImgList ({ commit }, list) {
    commit('setImgList', list);
  },
  getBarCode ({ commit }, code) {
    commit('setBarCode', code)
  },
  handleProductCode ({ commit }, value) {
    commit('setProductCode', value)
  },
  handleSelectedRetailer ({ commit }, value) {
    commit('setSelectedRetailer', value)
  },
  handleCustomRetailer ({ commit }, value) {
    commit('setCustomRetailer', value)
  },
  updateSearchStatus ({ commit }, status) {
    commit('setSearchStatus', status)
  },
  updateScanner ({ commit }, status) {
    commit('setScannerStatus', status)
  },
  findProducts ({ commit }, array) {
    commit('setProducts', array)
  },
  removeProduct ({ commit }, productId) {
    commit('deleteProduct', productId)
  },
  editQuantityProduct ({ commit }, { product, action, maxProductCount }) {
    commit('setQuantityProduct', { product, action, maxProductCount })
  },
  editInputQuantityProduct ({ commit }, { productId, count, maxProductCount }) {
    if (count === '0') {
      commit('deleteProduct', productId)
    } else {
      if (maxProductCount && count > maxProductCount) {
        commit('setModalOptions', { type: 'error', isActive: true, message: `${maxProductCount} üründen fazlasını seçemezsiniz.` });
        commit('setInputQuantityProduct', { productId, count: maxProductCount });
        return
      }
      commit('setInputQuantityProduct', { productId, count })
    }
  },
  searchProductByBarcode ({ state, dispatch, commit }, { barcode, maxProductCount }) {
    let apiUrl = 'https://pg-turkey.justsnap.eu/api/searchBarcode';
    const isAllowed = maxProductCount ? maxProductCount > state.scannedProducts.length : true;

    // If barcode exists , exit method
    if (state.scannedProducts.find(item => item.ean === (barcode))) return;

    if (/^[0-9]+$/.test(barcode) && isAllowed) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}`, {
            campaignId: state.campaignId,
            barcode: barcode
          })
          .then(res => {
            let status = res.data.result?.length > 0 ? 'success' : 'not_found';
            dispatch("updateSearchStatus", status);

            // Check if the product has selected a valid item according to the selected product list
            // const findSameCategory = state.scannedProducts.find(item => item.category === (res.data.result[0]).category)
            // if (!findSameCategory)
            dispatch("findProducts", res.data.result);
            // else {
            //   commit('setModalOptions', { type: 'error', isActive: true, message: `Aynı kategoriden birden fazla ürün belirtemezsiniz.` });
            //   dispatch("updateSearchStatus", null);
            // }

            if (status === 'not_found') {
              // The barcode is not P&G
              dispatch('updateScannerErrorMessage', 'Bu barkod bir Orkide ürününe ait değil gibi görünüyor.')
              dispatch('setErrorActivate', true)
            } else {
              dispatch('setErrorActivate', false)
            }
            resolve(res.data);
          })
      });
    } else {
      if (!isAllowed) {
        commit('setModalOptions', { type: 'error', isActive: true, message: `${maxProductCount} üründen fazlasını seçemezsiniz.` });
        dispatch("updateSearchStatus", null);
      } else {
        dispatch("updateSearchStatus", 'not_found');
        // The barcode is invalid
        dispatch('updateScannerErrorMessage', 'Barkod geçersiz. Lütfen barkodu girmeyi veya taramayı tekrar deneyin.')
        dispatch('setErrorActivate', true)
      }
    }
  },
  updateScannerErrorMessage ({ commit }, error) {
    commit('setScannerError', error);
  },
  setErrorImages ({ commit }, data) {
    // console.log(data.posNum)
    commit('setErrorImages', data)
  },
  setErrorActivate ({ commit }, data) {
    commit('setErrorActivate', data)
  },
  setReceiptBarcode ({ commit }, data) {
    commit('setReceiptBarcode', data)
  },
  setIsReceiptImageChanged ({ commit }, data) {
    commit('setIsReceiptImageChanged', data)
  },
  setConvertedImagePointsData ({ commit }, data) {
    commit('setConvertedImagePointsData', data)
  },
  setFirstTimeUploading ({ commit }, data) {
    commit('setFirstTimeUploading', data)
  },
  isUpdateIban: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios.get(`https://pg-turkey.justsnap.eu/api/ibanUpdateCheck/${data.customerToken}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setCustomerToken: ({ commit }, data) => {
    commit('setCustomerToken', data)
  },
  check72Hours ({ getters }) {
    const formData = {}
    formData.receiptToken = getters.receiptToken
    formData.campaignId = getters.campaignId
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/check72Hours`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  checkDoi: ({ state }, data) => {
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl + data.endpoint}`, data.formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  uploadError ({ commit }) {
    commit('setModalOptions', { type: 'error', isActive: true, message: 'Fişiniz/faturanız çok fazla yüklendiğinden bir hata oluştu.' })

    setTimeout(() => {
      location.href = ''
    }, 3 * 1000)
  },
  sendRequest ({ getters }, data) {
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setUserFuelPhone: ({ commit }, data) => {
    commit('setUserFuelPhone', data)
  },
  selectParticipantType ({ dispatch, state }, data) {
    return new Promise((resolve) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) return resolve(res.data)
          else dispatch('handleError', { message: res.data.message })
        })
        .catch(ex => {
          dispatch('handleError', {
            message: `Hata. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.`,
            cause: ` Send Reward:  ${ex.message}`
          })
          return { success: false, error: ex.message }
        })
    })
  },
  setParticipantIsCompany: ({ commit }, data) => {
    commit('setParticipantIsCompany', data)
  },
  changeCampaignId: ({ commit }, data) => {
    commit('setCampaignId', data)
  }
}

export function updateFavicon (url) {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = url;
  document.getElementsByTagName("head")[0].appendChild(link);
}
