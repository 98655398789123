<template>
  <div :style="cssVars">
    <div
      class="clip-wrapper input-group"
      :style="styles"
      :class="[classes]"
    >
      <span class="title">{{ title }} </span>
      <input
        type="text"
        :disabled="disabledInput"
        class="form-control"
        id="copied-tooltip"
        ref="target"
        :value="value"
        aria-label="Value"
      >
      <img
        class="copy"
        src="/assets/images/copy.svg"
        alt="copy"
        @click="copy()"
      >
    </div>
    <b-tooltip
      ref="tooltip"
      target="copied-tooltip"
      noninteractive
    >
      Kopyalandı
    </b-tooltip>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "Clipboard",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    title: {
      type: String,
      default: "Empty paragraph"
    },
    variables: Object,
    disabledInput: {
      type: Boolean,
      default: true
    },
    colorSchema: {
      type: String,
      default: "#888888"
    },
    styles: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: String,
      default: ""
    },
  },
  computed: {
    value () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state[variable];
          return value
        }
      }
      return result;
    },
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    copy () {
      var copyText = this.$refs.target;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.value);
      this.$refs.tooltip.$emit('open')
      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 2000)
    },
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000)
  }
};
</script>

<style lang="scss" scoped>
.clip-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    padding: 5px 10px;
    .b-icon {
      font-size: 20px !important;
    }
  }
}

input {
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  border: none;
  background: transparent;
  padding: 5px;
  &:disabled {
    background: transparent;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.title {
  @media (max-width: 767px) {
    font-size: 11px;
  }
}

.copy {
  cursor: pointer;
  max-width: 40px;
  &:hover {
    scale: 1.05;
  }

  @media (max-width: 767px) {
    max-width: 20px;
  }
}

</style>
