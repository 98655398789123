<template>
  <div class="gtinComponentWrapper text-center">
    <!-- <textInput class="phoneNumber" label="Telefone" /> -->
    <h2 class="title">Cashback</h2>
    <p
      class="description mt-2"
    >Bitte teilen Sie uns mit, welches Aktionsprodukt auf Ihrem Kassenzettel ist?</p>
    <div class="container-min">
      <form class="main-form">
        <div class="form-group">
          <div class="clearfix">
            <div class="miniTitle">
              <h4 class="m-0 p-0">Hier Produkt EAN Nummer eingeben</h4>
              <img
                @click="helpModal()"
                class="information-img ml-4"
                src="../../assets/icons/form/information.svg"
                alt="Information icon"
              />
            </div>
          </div>

          <div
            v-for="(gtin, index) in gtinNumbers"
            :key="`gtinInput_${index}`"
            class="d-flex flex-column"
          >
            <div class="d-flex flex-row w-100 text-left position-relative">
              <textInput
                v-model.trim="gtinNumbers[index]"
                :name="`gtinNumber_${index}`"
                class="gtinInput"
                label="Code-barres*"
                validation="required|uniqueGTIN|gtinNumber"
              />
              <label
                class="removeIcon"
                v-if="gtinNumbers.length > 1"
                @click="deleteCodeInput(index)"
              >-</label>
            </div>
          </div>
          <div v-if="canAddMore" @click="addCodeInput()" class="addMoreWrapper">
            <img class="add-img" src="../../assets/icons/form/add.svg" alt="Plus icon" />
            <div class="addMoreText">Weitere Produkte hinzufügen</div>
          </div>
        </div>
      </form>
    </div>
    <buttonComponent
      class="sendCodeButton"
      @click.native="checkGtin"
      :backgroundColor="backgroundColorBtn"
      :borderColor="borderColorBtn"
      :textColor="textColorBtn"
      :text="textBtn"
      :borderRadius="borderRadiusBtn"
      :disabled="disabledBtn"
      :fullWidth="fullWidthBtn"
      :action="actionBtn"
    />
  </div>
</template>

<script>
import buttonComponent from "@/components/renderers/button-component.vue";
import textInput from "@/components/renderers/text-input.vue";
import { extend } from "vee-validate";

export default {
  name: "gtin-component",
  components: { textInput, buttonComponent },
  props: {
    backgroundColorBtn: {
      required: false,
      type: String,
      default: "#000"
    },
    borderColorBtn: {
      required: false,
      type: String,
      default: "#000"
    },
    textColorBtn: {
      required: false,
      type: String,
      default: "#fff"
    },
    textBtn: {
      required: false,
      type: String,
      default: "Button"
    },
    borderRadiusBtn: {
      required: false,
      type: String,
      default: "0.25rem"
    },
    disabledBtn: {
      required: false,
      type: Boolean,
      default: false
    },
    fullWidthBtn: {
      type: Boolean
    },
    actionBtn: {
      type: Object
    },
    gtinLimit: {
      required: false,
      type: [Number, String],
      default: 1
    },
    helpMessage: {
      required: false,
      type: String,
      default: "This is help message, you can customize with helpMessage prop."
    },
    onSuccess: {
      type: Object
    }
  },
  data () {
    return {
      canAddMore: false,
      gtinNumbers: [""]
    };
  },
  methods: {
    helpModal () {
      this.showModal(this.helpMessage, "custom");
    },
    addCodeInput () {
      if (this.gtinNumbers.length < this.gtinLimit) {
        this.gtinNumbers = [...this.gtinNumbers, ""];
        this.canAddMore = this.gtinNumbers.length < this.gtinLimit;
      }
    },
    deleteCodeInput (index) {
      this.gtinNumbers.splice(index, 1);
      this.canAddMore = this.gtinNumbers.length < this.gtinLimit;
    },
    checkGtin () {
      //check gtin and then proceed:
      // console.log("CHECK GTIN")
      const fn = this.onSuccess.fn;
      const params = this.onSuccess.params;
      this[fn](params, this);
    }
  },
  mounted () {
    if (this.gtinNumbers.length < this.gtinLimit) {
      this.canAddMore = true;
    }

    extend("gtinNumber", value => {
      const random = Math.floor(Math.random() * Math.floor(2));
      if (random % 2) {
        return true;
      }

      return "This is false GTIN number";
    });

    extend("uniqueGTIN", value => {
      value = value.trim();
      if (value) {
        const isUnique =
          this.gtinNumbers.filter(number => number === value).length <= 1;
        if (!isUnique) return "GTIN Codes must be unique";
      }
      return true;
    });
  }
};
</script>

<style lang="scss" scoped>
.gtinComponentWrapper {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    margin-top: 20px;
  }
  .description {
    font-size: 14px;
    padding: 0;
  }
  .miniTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    h4 {
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.16px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .gtinInput {
    width: 100%;
  }
  .information-img {
    width: 18px;
  }
  .addMoreWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .add-img {
      width: 30px;
      margin-right: 20px;
    }
    .addMoreText {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.12px;
      line-height: 14px;
    }
  }
}
.removeIcon {
  color: red;
  position: absolute;
  right: -12px;
  top: 15px;
}
</style>