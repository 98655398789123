
export default {
  isEditMode: true,
  pageProperties: {
    testing: false,
    "pages": [
      { // Home
        "pageName": "cashback-123",
        isPublic: true,
        pageId: 100,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
              "marginTop": "50px",
            },
            "classes": "container cashback-123",
            "sectionId": "smsSection",
            "tempSection": true,
            "fields": [
              { // Container
                "fieldId": "jakldklf",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par10011",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Confirm your identity and claim your refund",
                        classes: ['heading', 'text-left']
                      }
                    },
                    { // SubHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1002",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Verify your identity by entering your phone number here. It is only used once for verification and not for advertising purposes. After the action, your phone number will be deleted by us.",
                        classes: ['subHeading', 'text-left']
                      }
                    },
                    { // SMS
                      "fieldType": "sms-verification-123",
                      "fieldId": "c6yysh",
                      "properties": {
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": 12,
                          marginBottom: "50px"
                        },
                        "endpoint": "/mobileAuth",
                        "endpoint2": "/mobileVerification",
                        "inputWidth": "720px",
                        "successAction": {
                          "fn": "goNextStep",
                          "params": "ibanSection"
                        },
                        options: [
                          {
                            name: "Germany",
                            code: "de",
                            phoneCode: "+49"
                          }
                        ],
                      }
                    },
                  ]
                }
              }
            ]
          },
          { // IBAN Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginBottom": "50px",
            },
            "classes": "container cashback-123",
            "sectionId": "ibanSection",
            "requires": ['smsSection'],
            "tempSection": true,
            "fields": [
              { // IBAN Bucket
                "fieldId": "ibanSection",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "borderTop": "46px solid #5D5FEF",
                    "paddingTop": "50px"
                  },
                  fields: [
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "bank1Nf0",
                      "properties": {
                        "successAction": {
                          "fn": "goNextStep",
                          "params": "thankSection"
                        },
                        "endpoint": "/submitIBANV2",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934ifa",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                              },
                              "fields": [
                                { // Heading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1003",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "15px"
                                    },
                                    text: "Provide bank details",
                                    classes: ['title', 'text-left']
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1004",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "30px"
                                    },
                                    text: "Please let us know to which account the refund amount should be transferred.",
                                    classes: ['subTitle', 'text-left']
                                  }
                                },
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934ifb",
                                  "properties": {
                                    "grid": {
                                      "columns": 8,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "flexDirection": "column",
                                      marginBottom: "50px"
                                    },
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "firstname",
                                          "id": "firstname",
                                          "label": "Account holder first name",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "placeholder": "Account holder first name",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "lastname",
                                          "id": "lastname",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "label": "Account holder last name",
                                          "placeholder": "Account holder last name",
                                          "text": "",
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      },
                                      {
                                        "fieldId": "h9arlr",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "iban",
                                          "id": "iban",
                                          "validation": "required|ibanGeneral",
                                          "labelOnTop": true,
                                          "label": "IBAN",
                                          "placeholder": "Enter your IBAN number",
                                          "text": "",
                                          "isUpperCase": true,
                                          "grid": {
                                            "columns": 12
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Buttons
                                  "fieldId": "fu0923r",
                                  "fieldType": "empty-bucket",
                                  "properties": {
                                    "grid": {
                                      "columns": 6,
                                      "justifyContent": "center",
                                      "alignItems": "center"
                                    },
                                    fields: [
                                      { // Continue Button
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "Continue",
                                          addClass: "btn-123",
                                          requires: ['firstname', 'lastname', 'iban'],
                                          "action": {
                                            "fn": "sendForm",
                                            "params": "bank1Nf0"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          },
          { // THank you section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "classes": "container cashback-123",
            "sectionId": "thankSection",
            "requires": ['ibanSection'],
            "tempSection": true,
            "fields": [
              { // Thank you Bucket
                "fieldId": "thankBox",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "50px",
                    "borderTop": "46px solid #5D5FEF"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          "padding": '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: "/assets/cashback-123/banner.png",
                        tabletImageUrl: "/assets/cashback-123/banner.png",
                        mobileImageUrl: "/assets/cashback-123/banner.png"
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1005",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px"
                        },
                        text: "We got your bank information, and we’ll inform you via email when we’ll send the money to your bank account",
                        classes: ['text', 'text-left']
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "f934ifc",
                      "properties": {
                        "grid": {
                          "columns": 4,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "errorBackButton",
                            "properties": {
                              "text": "Back to campaign page",
                              "grid": {
                                "columns": 12,
                                "alignItems": "center",
                                "justifyContent": "center",
                                marginBottom: "40px"
                              },
                              "addClass": "btn-123",
                              "disabled": false,
                              "action": {
                                "anchor": true,
                                "link": "https://mujsvet-pg.cz/"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Re Upload
        "pageName": "cashback-123/receipt-reupload",
        isPublic: true,
        pageId: 119,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
            },
            "sectionId": "fu30zq",
            "classes": "container",
            "fields": [
              { // Query String
                "fieldType": "query-string-to-state",
                "fieldId": "uwxo2o1",
                properties: {
                  variables: [
                    {
                      query: "referenceNumber",
                      state: "referenceNumber"
                    },
                    {
                      query: "token",
                      state: "receiptToken"
                    }
                  ]
                }
              },
              { // 72 Hours check
                "fieldType": "checkReceiptToken",
                "fieldID": "dksnfsdkjf",
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par10012",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "Upload proof of purchase",
                        classes: ['heading', 'text-left']
                      }
                    },
                    { // subHeading
                      "fieldType": "paragraph",
                      "fieldId": "par1009",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "15px"
                        },
                        text: "In this step, please upload the proof of purchase of your products.",
                        classes: ['subHeading', 'text-left']
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px",
                          "marginTop": "50px"
                        },
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor-123",
                            "fieldId": "recReupload",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              },
                              texts: {
                                choose: 'UPLOAD',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'This file is too big. Only files up to 10MB can be uploaded.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              reUpload: true,
                              colorSchema: "#3E3D40",
                              "phrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ],
                              "cornerDetectionPhrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ]
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "fields": [
                                {
                                  "fieldType": "receipt-information-123",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "30px"
                        },
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 4
                              },
                              text: "Continue",
                              addClass: "btn-123",
                              "requires": ["recReupload"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Page
        "pageName": "cashback-123/thank-you",
        pageId: 113,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "40px"
                  },
                  fields: [
                    { // Key visual
                      "fieldType": "key-visual",
                      "fieldId": "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          "padding": '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: "/assets/cashback-123/banner.png",
                        tabletImageUrl: "/assets/cashback-123/banner.png",
                        mobileImageUrl: "/assets/cashback-123/banner.png"
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par10013",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "30px"
                        },
                        text: "Your receipt / invoice and your data have been successfully sent to us and are being checked at the moment.As soon as we have checked your data, you will receive either a confirmation e-mail or an e-mail asking us if your data was incomplete or your proof of purchase could not be recorded. Please keep your reference number.",
                        classes: ['text', 'text-center']
                      }
                    },
                    { // Clipboard
                      "fieldType": "clipboard",
                      "fieldId": "ref123",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "padding-left": "0",
                          "padding-right": "0",
                          "marginBottom": "30px"
                        },
                        "title": "Reference number",
                        "disabledInput": true,
                        "variables": {
                          "referenceNumber": "referenceNumber"
                        },
                        styles: {
                          "background": "#F2F2F2",
                          "border": "2px solid #5D5FEF",
                          "color": "var(--color-6)",
                          "padding": "1vw",
                          "border-radius": "10px",

                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par10014",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "The verification can take up to 72 hours. We ask for your patience.",
                        classes: ['text', 'text-center']
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to campaign page",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "40px"
                        },
                        "addClass": "btn-123",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://mujsvet-pg.cz/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        "pageName": "cashback-123/iban-reupload",
        isPublic: true,
        pageId: 123,
        "requires": [
          1
        ],
        "sections": [
          { // Section
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "40px"
            },
            "fields": [
              {
                "fieldType": "iban-reupload",
                "fieldId": "ij3411"
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par10015",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "30px"
                        },
                        text: "Provide bank details",
                        classes: ['heading', 'text-left']
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par10016",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Please let us know to which account the refund amount should be transferred.",
                        classes: ['subHeading', 'text-left']
                      }
                    },
                    { // Form
                      "fieldType": "form-component",
                      "fieldId": "bank1Nf0",
                      "properties": {
                        "successAction": {
                          "fn": "goToPage",
                          "params": "thank-you-iban"
                        },
                        "endpoint": "/updateIBAN",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          marginBottom: "20px"
                        },
                        "styles": {
                          "justifyContent": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "f934ifd",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                marginBottom: "25px"
                              },
                              "className": "form-wrapper",
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934ife",
                                  "properties": {
                                    "grid": {
                                      "columns": 10,
                                      "justifyContent": "center",
                                      "alignItems": "center"
                                    },
                                    "className": "inner-wrapper",
                                    "fields": [
                                      { // First name
                                        "fieldId": "tp6zfb",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "firstname",
                                          "id": "firstname",
                                          "label": "Account holder first name",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "placeholder": "Account holder first name",
                                          "text": "",
                                          "grid": {
                                            "columns": 6
                                          }
                                        }
                                      },
                                      { // Last name
                                        "fieldId": "p55fao",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "lastname",
                                          "id": "lastname",
                                          "validation": "required|ibanName",
                                          "labelOnTop": true,
                                          "label": "Account holder last name",
                                          "placeholder": "Account holder last name",
                                          "text": "",
                                          "grid": {
                                            "columns": 6
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "f934iff",
                                  "properties": {
                                    "grid": {
                                      "columns": 10,
                                      "justifyContent": "flex-start",
                                      "alignItems": "center",
                                      "paddingLeft": "0"
                                    },
                                    "fields": [
                                      {
                                        "fieldType": "empty-bucket",
                                        "fieldId": "f934if",
                                        "properties": {
                                          "grid": {
                                            "columns": 1,
                                            "marginBottom": "2rem"
                                          },
                                          "className": "ibanLabelWrapper col-2",
                                          "fields": [
                                            {
                                              "fieldType": "paragraph",
                                              "fieldId": "tw3qab",
                                              "properties": {
                                                "grid": {
                                                  "columns": "12"
                                                },
                                                "text": "TR",
                                                "classes": ["ibanLabel"]
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      { // IBAN
                                        "fieldId": "h9arlr",
                                        "fieldType": "text-input",
                                        "properties": {
                                          "name": "iban",
                                          "id": "iban",
                                          "validation": "required|iban",
                                          "label": "IBAN*",
                                          "placeholder": "IBAN",
                                          "text": "",
                                          "grid": {
                                            "columns": 5,
                                            "paddingLeft": "5px !important"
                                          }
                                        }
                                      },
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Text
                      "fieldType": "paragraph",
                      "fieldId": "par10018",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "Avoid changing the umlauts: Müller or Mueller. Avoid adding or omitting hyphens: Marie Luise or Marie-Luise. Only enter the middle name if the account is kept in this way. If the data does not match the account data, in most cases the bank will charge back and make it difficult for an uncomplicated and quick refund to be made.",
                        classes: ['text', 'text-center']
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "50px"
                        },
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              addClass: "btn-123",
                              requires: ['firstname', 'lastname', 'iban'],
                              "action": {
                                "fn": "sendForm",
                                "params": "bank1Nf0"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        "pageName": "cashback-123/thank-you-iban",
        pageId: 124,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12,
              "marginTop": "50px"
            },
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001a",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Thank you",
                        classes: ['heading', 'text-center']
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001b",
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: "50px"
                        },
                        text: "Thank you for resubmitting your bank details.<br>So there should now be a successful transfer. If you have any questions related to your bank details, please contact EMAIL. For other concerns you can contact our support service at EMAIL",
                        classes: ['text', 'text-center']
                      }
                    },
                    { // Continue Button
                      "fieldType": "button-component",
                      "fieldId": "but100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        text: "Back to campaign page",
                        addClass: "btn-123",
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "backgroundColor": "#fff",
        "title": "",
        "color": "#FFE082",
        "grid": {
          "columns": 12
        },
        "logo": {
          "title": "Fırsat Yanında",
          "imageSource": "/assets/images/logo@1x.png",
          "imageSource2x": "/assets/images/logo@2x.png",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "favicon": {
          "imageSource": "https://azcdn.pioneer.pgsitecore.com/en-gb/-/media/Downy_UK/Images/Common/favicon-32x32.jpg?v=1-201708041111"
        },
        "fields": [
          { // FAQ
            "fieldId": "slnmdk",
            "fieldType": "anchor",
            "properties": {
              "text": "SIKÇA SORULAN SORULAR",
              "url": "/orkidplatinum/sss",
              "target": "_blank",
              "cssClass": "nav-link headerLink"
            }
          }
        ],
        "showLine": false,
        "meta": [
          { "name": "title", "content": "PG Central Europe" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `PG Central Europe`
          }
        ]
      }
    },
    "footer": {
    },
    dictionary: {
      tr: {
        messages: {
          "required": (field) => `${field} alanı gereklidir`,
          "mandatory": "Bu alan gereklidir.",
          "alpha_spaces": (field) => `${field} sadece harf içerebilir.`,
          "email": "Geçerli bir e-posta adresi giriniz.",
          "password": "Şifreniz en az 8 karakterden oluşmalı, en az bir büyük harf, bir küçük harf ve bir sayı içermelidir.",
          "newPassword": "Şifreniz en az 8 karakterden oluşmalı, en az bir büyük harf, bir küçük harf ve bir sayı içermelidir.",
          "confirmPass": "Şifreler aynı değildir.",
          "newPasswordConfirm": "Şifreler aynı değildir.",
          "postal": "Posta kodu 5 rakamdan oluşmalıdır.",
          "plz": "Posta kodu 5 rakamdan oluşmalıdır.",
          "street": "Yalnızca harf, boşluk, eğik çizgi ve kısa çizgi içerebilir",
          "iban": "Lütfen katılımcı ülkelerin geçerli bir IBAN'ını kullanınız",
          "phoneNumber": "Lütfen 5XXXXXXXXX formatı ile geçerli bir telefon numarası giriniz.",
          "hausnr": "Bina numarası yanlıştır.",
          "smsCodeLength": "Doğrulama kodu 4 basamaklı olmalıdır."
        },
        names: {
          iban: 'IBAN',
          gender: 'Cinisyet',
          bic: 'BIC',
          firstname: 'İsim',
          name: 'İsim',
          firstName: 'İsim',
          lastname: 'Soy isim',
          surname: 'Soy isim',
          lastName: 'Soy isim',
          email: 'E-mail',
          birthdate: 'Doğum tarihi',
          tckn: "TC Kimlik Numarası",
          password: 'Şifre',
          newPassword: 'Şifre',
          confirmPassword: 'Tekrar Şifre',
          newPasswordConfirm: 'Tekrar Şifre',
          strasse: 'Sokak',
          hausnr: 'No',
          plz: 'Posta kodu',
          ort: 'Şehir',
          city: 'İl',
          address: 'Adres',
          marital: 'Medeni hal',
          kids: 'Çocuk',
          area: 'Mahalle',
          district: 'İlçe',
          land: 'Ülke',
          phoneNumber: 'Telefon numarası',
          code: 'Kod',
          terms: "Katılım koşulları",
          receiveEmails: "Kullanım ve gizlilik politikası şartları",
        }
      }
    },
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'An error occured.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  campaignId: '',
  formData: new FormData(),
  currentSection: 'smsSection'
}
