<template>
  <div class="retailer-dropdown__search d-flex center" :style="`--brand-color: ${colorSchema}`">
    <ValidationProvider name="Market" :rules="'required'" v-slot="{ errors }">
      <v-select
        :value="selectedRetailer"
        placeholder="Market"
        @input="handleDropdown($event)"
        :options="retailerList"
        class="retailer-dropdown"
        :class="{'is-invalid': errors.length}"
        autocomplete="off"
      >
        <div slot="no-options" style="font-size: 12px;">Aradığınız market listede yoksa lütfen "Diğer" seçeneğini seçiniz.</div>
      </v-select>
      <div class="validation-error" style="margin-left: 5px;" v-if="errors.length">
        <img class="error_min" src="../../assets/icons/form/error.svg" alt="Error icon" />
        {{errors[0]}}
      </div>
    </ValidationProvider>
    <ValidationProvider v-if="selectedRetailer.label === 'Diğer'" name="Market" :rules="'required'" v-slot="{ errors }">
      <b-form-input
        :value="customRetailer"
        @input="handleInput($event)"
        placeholder="Market İsmi"
        class="retailer-inputfield"
        :class="{'is-invalid': errors.length}"
        >
      </b-form-input>
      <div class="validation-error" style="margin-left: 5px;" v-if="errors.length">
        <img class="error_min" src="../../assets/icons/form/error.svg" alt="Error icon" />
        {{errors[0]}}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import Vue from "vue"
import vSelect from 'vue-select'
import { mapState, mapActions, mapGetters } from "vuex"
import 'vue-select/dist/vue-select.css';
import axios from "axios";

Vue.component("vSelect", vSelect)

export default {
  name: 'RetailerDropdown',
  data () {
    return {
      retailerList: [],
      disabled: false
    }
  },
  props: {
    options: {
      // type: Array,
      // default: () => [...this.retailerList, "Diğer"]
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#002458"
    }
  },
  computed: {
    ...mapState({
      selectedRetailer: state => state.selectedRetailer,
      customRetailer: state => state.customRetailer
    }),
    ...mapGetters(["campaignId"])
  },
  methods:
  {
    ...mapActions(["handleSelectedRetailer", "handleCustomRetailer" ]),
    handleDropdown (ev) {
      this.handleSelectedRetailer(ev)
    },
    handleInput (ev) {
      this.handleCustomRetailer(ev)
    },
    resetSelectedRetailer () {
      this.$store.commit('handleSelectedRetailer', '');
    }
  },
  mounted () {
    this.$eventHub.$on("resetRetailer-event", this.resetSelectedRetailer);

        axios.post('https://pg-turkey.justsnap.eu/api/getRetailerList', { campaignId: this.campaignId }).then(r => {
          this.loading = false;
          if (r.data) {
            this.retailerList = r.data.retailerList
            console.log(r.data)
          }
        });
  }
}
</script>

<style scoped lang="scss">
.retailer-dropdown {
  margin-top: 20px;
  background: #FFFFFF !important;
  border: 1px solid #DDDFE1 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  padding: 5px 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.is-invalid {
    border-color: #ff5a5a !important;
  }

  &__search {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 !important;
    position: relative !important;
    &-input {
      input {
        font-size: 14px;
        line-height: 32px;
        height: 48px;
        border-radius: 27px 0 0 27px !important;
        @media (max-width: 425px) {
          font-size: 10.5px;
        }
      }
    }
  }
  &__divider {
    width: 100%;
    margin-top: 50px;
    hr {
      border: 1px solid #939393;
    }
    .col-6 {
      font-size: 20px;
      line-height: 32px;
    }
  }
}

.retailer-inputfield {
  margin-top: 20px;
  background: #FFFFFF !important;
  border: 1px solid #DDDFE1 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  padding: 10px;
  height: 41px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.is-invalid {
    border-color: #ff5a5a !important;
  }
}
</style>
