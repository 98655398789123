<template>
  <a
    v-if="action"
    :class="cssClass"
    @click="handleClick"
    :target="target"
    :style="styles"
    v-html="text"
  ></a>
  <a v-else :class="cssClass" :href="url" :target="target" :style="styles" v-html="text"></a>
</template>

<script>
export default {
  name: "anchor",
  props: {
    text: {
      type: String,
      default: "Empty Link Text"
    },
    target: {
      required: false,
      type: String,
      default: "_self"
    },
    url: {
      required: false,
      type: String,
      default: "#"
    },
    cssClass: {
      required: false,
      type: String,
      default: ""
    },
    styles: {
      required: false,
      type: Object
    },
    action: {
      type: [Object, Array]
    }
  },
  methods: {
    handleClick () {
      if (this.action.length > 0) {
        // console.log("inside actions loop::");
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      this[fn](params, this);
    }
  }
};
</script>

<style lang="scss">

.forgotPass {
  font: normal normal 300 16px/22px Montserrat;
  letter-spacing: 0px;
  color: #1B1B1B;
  opacity: 1;
}

.loginAnchor {
  text-align: center;
  text-decoration: underline !important;
  font: normal normal 500 20px/24px Montserrat !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;

  padding-top: 20px;

  @media (max-width: 991px) { font: normal normal 500 14px/18px Montserrat !important; }
}
</style>