<template>
  <div class="share-info-container">
    <div
      class="contact-btn"
      @click="showModal"
    >
      <img
        src="/assets/images/contact.svg"
        alt="İletişime Geçin"
      >
    </div>
    <div
      class="d-none share-contact-modal"
      id="modal"
    >
      <div class="share-contact-modal-inner">
        <div class="modal-tab modal-tab-active">
          <span><b>İletişim Bilgileri</b></span>
          <ValidationObserver ref="contactForm">
            <form>
              <TextInput
                field-id="firstName"
                name="firstName"
                id="firstName"
                v-model="firstName"
                label="Ad*"
                :validation="`mandatory|nameWithSpaces`"
                :label-on-top="false"
                :no-label="true"
              />
              <TextInput
                field-id="lastName"
                name="lastName"
                id="lastName"
                v-model="lastName"
                label="Soyad*"
                :validation="`mandatory|lastNameWithSpaces`"
                :label-on-top="false"
                :no-label="true"
              />
              <TextInput
                field-id="phoneNumber"
                name="phoneNumber"
                id="phoneNumber"
                v-model="phoneNumber"
                label="Telefon Numarası*"
                max-length="10"
                :validation="`mandatory|phoneNumberHome`"
                :label-on-top="false"
                :no-label="true"
              />
              <TextInput
                field-id="uniqueCode"
                name="uniqueCode"
                id="uniqueCode"
                v-model="uniqueCode"
                label="Diş Hekimi Kodu*"
                :validation="`mandatory`"
                :label-on-top="false"
                :no-label="true"
              />
              <Checkbox
                field-id="termsAccepted"
                name="termsAccepted"
                id="termsAccepted"
                v-model="termsAccepted"
                :label="`<a href='assets/dishekimlerio/files/oral-b-kampanya-hatti-aydinlatma-metni.docx' download><b><u>Oral-B Kampanya Aydınlatma Metni</u></b></a>’ni okudum ve anladım.`"
                :validation="{ mandatory: { allowFalse: false } }"
                :check-on-click="true"
              />
            </form>
          </ValidationObserver>
          <ButtonComponent
            field-id="sendContactFormBtn"
            class="nextBtn"
            text="Gönder"
            :action="{ fn: 'sendContactForm', params: '' }"
            :requires="['firstName', 'lastName', 'phoneNumber', 'uniqueCode', 'termsAccepted']"
          />
        </div>
        <div class="modal-tab">
          Bilgi alma talebiniz için teşekkür ederiz. Sizinle kısa sürede iletişime geçeceğiz.
          <ButtonComponent
            field-id="closeBtn"
            class="nextBtn"
            text="Tamam"
            @click.native="closeModal"
          />
        </div>
      </div>
      <div class="close-btn">
        <button
          type="button"
          class="close"
          @click="closeModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/components/renderers/button-component'
import TextInput from '@/components/renderers/text-input'
import Checkbox from '@/components/renderers/checkbox'
import { mapGetters } from "vuex"

export default {
  name: 'ShareContactInfo',
  data () {
    return {
      modal: null,
      tabs: [],
      firstName: "",
      lastName: "",
      phoneNumber: "",
      uniqueCode: "",
      termsAccepted: false
    }
  },
  components: { ButtonComponent, TextInput, Checkbox },
  props: {
    endpoint: {
      type: String,
      default: '/userInfo'
    }
  },
  mounted() {
    this.$eventHub.$on("sendContactFormData-event", this.sendContactForm)
    this.modal = document.getElementById('modal')
    this.tabs = document.getElementsByClassName('modal-tab')
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId"])
  },
  methods: {
    clearForm() {
      this.firstName = ""
      this.lastName = ""
      this.phoneNumber = ""
      this.uniqueCode = ""
      this.termsAccepted = false
      this.$refs.contactForm.reset()
    },
    showModal() {
      this.modal.classList.remove('d-none')
      document.body.classList.add('modal-open')
    },
    closeModal() {
      this.modal.classList.add('d-none')
      document.body.classList.remove('modal-open')
      // Reset to tab 1
      if (this.tabs[1].classList.contains('modal-tab-active')) {
        this.tabs[1].classList.remove('modal-tab-active')
        this.tabs[0].classList.add('modal-tab-active')
      }
    },
    sendContactForm() {
      const closeBtn = document.querySelector('.close-btn')
      closeBtn.classList.add('d-none')

      const formData = {}

      if (this.locale) {
        formData.lang = this.locale
      }
      formData.customerToken = this.customerToken
      formData.campaignId = this.campaignId
      formData.firstName = this.firstName
      formData.lastName = this.lastName
      formData.phoneNumber = this.phoneNumber
      formData.uniqueCode = this.uniqueCode
      formData.termsAccepted = this.termsAccepted

      this.$refs.contactForm.validate().then(isValid => {
        if (isValid) {
          this.$store
            .dispatch("sendFormData", { formData, endpoint: this.endpoint })
            .then(r => {
              if (r.response) {
                this.tabs[0].classList.remove('modal-tab-active')
                this.tabs[1].classList.add('modal-tab-active')
                this.$eventHub.$emit("done-event-trigger", {
                  parent: this,
                  status: true
                })
                this.clearForm()
                closeBtn.classList.remove('d-none')
              }
            })
            .catch(e => {
              this.$eventHub.$emit("done-event-trigger", {
                parent: this,
                status: false
              })
              closeBtn.classList.remove('d-none')
            })
        }
      })
      return formData
    }
  },
  beforeDestroy() {
    this.clearForm()
    this.$eventHub.$off("sendContactFormData-event")
  }
}
</script>

<style scoped lang="scss">
.share-info-container {
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
  .contact-btn {
    width: 80px;
    height: 80px;
    background-color: #1359B6;
    
    position: fixed;
    bottom: 90px;
    right: 60px;
    z-index: 1000;
    
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    
    cursor: pointer;

    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
      position: static;
    }

    &::before {
      content: 'Kampanyayla ilgili bilgi almak ve sizinle iletişime geçilmesi için tıklayınız';
      position: absolute;
      color: #FFF;
      background-color: #1359B6;
      width: 330px;
      right: 100%;
      padding: 15px;
      clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
      
      @media (max-width: 767px) {
        content: 'Kampanyayla ilgili bilgi almak için benimle iletişime geçilmesini istiyorum';
        width: calc(100vw - 50px);
        left: 50px;
        clip-path: polygon(10% 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
        text-align: right;
        font-size: 14px;
      }
    }
    
    img {
      width: 50%;
      height: auto;
    }
  }

  .share-contact-modal {
    width: 100vw;
    height: 100vh;
    z-index: 1049;
    background-color: #4c4c4c1a;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &-inner {
      width: 40%;
      height: 60%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1100px) {
        width: 80%;
      }

      @media (max-height: 930px) {
        height: 70%;
      }

      @media (max-height: 767px) {
        height: 95%;
      }

      .modal-tab {
        &:not(.modal-tab-active) {
          display: none;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        width: 90%;
        text-align: center;

        form {
          width: 90%;
          margin: 0 auto;
        }
      }
    }

    .close-btn {
      position: absolute;
      top: 22.5%; // ((100 - inner height) / 2) + desired space
      right: 32.5%; // ((100 - inner width) / 2) + desired space

      @media (max-width: 1100px) {
        right: 12.5%;
      }

      @media (max-height: 930px) {
        top: 17.5%;
      }

      @media (max-height: 767px) {
        top: 5%;
      }
    }
  }
}
</style>