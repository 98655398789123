
export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home
        "pageName": "anasayfa",
        "pageId": 1,
        "sections": [
          {
            "sectionId": "a0us1n",
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            fields: [
              {
                "fieldType": "campaign-grid",
                "fieldId": "x1p9hx",
                "properties": {
                  "grid": {
                    "columns": "12",
                    "alignItems": "center",
                    "justifyContent": "center"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Reset Password page
        "pageName": "yeni-parola",
        "locale": "tr",
        isPublic: true,
        "pageId": 2,
        "sections": [
          {
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              {
                "fieldId": "v09234i",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "onSuccess": "hide",
                  "styles": {
                    "flexDirection": "column",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "maxWidth": "1440px"
                  },
                  "fields": [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "gjd10",
                      "properties": {
                        "text": "Yeni bir parola seçiniz",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "12",
                          "marginTop": "50px"
                        },
                        classes: ["text-center", "heading"]
                      }
                    },
                    {
                      "fieldType": "square-image",
                      "fieldId": "gb0wjv",
                      "properties": {
                        "imageSource": "/assets/images/reset-pass.svg",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "5"
                        },
                        "styles": {
                          "max-width": "346px !important",
                          "objectFit": "contain"
                        }
                      }
                    },
                    {
                      "fieldId": "f9234pg",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "4"
                        },
                        "className": "col-sm-8 col-md-6",
                        "fields": [
                          {
                            "fieldType": "form-component",
                            "fieldId": "5h43gwe",
                            "properties": {
                              "grid": {
                                "alignItems": "center",
                                "justifyContent": "center",
                                "columns": 12
                              },
                              "styles": {
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "successAction": [
                                {
                                  "fn": "displayModal",
                                  "params": {
                                    message: 'Parolanız sıfırlandı ve değiştirildi. Anasayfaya yönlendirileceksiniz.',
                                    type: 'success'
                                  }
                                },
                                {
                                  "fn": "navigateToPage",
                                  "params": {
                                    delayed: "3000",
                                    link: "/"
                                  }
                                }
                              ],
                              "endpoint": "/checkResetToken",
                              "fields": [
                                {
                                  "fieldId": "j45brev",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "newPassword",
                                    "id": "newPassword",
                                    "vid": "newPassword",
                                    "inputType": "password",
                                    "label": "Parola*",
                                    "placeholder": "",
                                    "text": "",
                                    "validation": "required|password",
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "styles": {
                                      "border-radius": "40px !important",
                                      "margin-top": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldId": "7666b4re",
                                  "fieldType": "text-input",
                                  "properties": {
                                    "name": "newPasswordConfirm",
                                    "id": "newPasswordConfirm",
                                    "inputType": "password",
                                    "label": "Parola Tekrarı*",
                                    "placeholder": "",
                                    "text": "",
                                    "validation": "required|confirmed:newPassword",
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "styles": {
                                      "border-radius": "40px !important",
                                      "margin-top": "20px"
                                    }
                                  }
                                },
                                {
                                  "fieldType": "query-string-to-state",
                                  "fieldId": "uwxo2o1",
                                  properties: {
                                    grid: {
                                      columns: 0
                                    },
                                    variables: [
                                      {
                                        query: "code"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          {
                            "fieldType": "button-component",
                            "fieldId": "h34wevdss",
                            "properties": {
                              "text": "Yeni parolanızı oluşturun",
                              "grid": {
                                "columns": "12",
                                "alignItems": "center",
                                "justifyContent": "center",
                                "marginTop": "40px",
                                "marginBottom": "81px"
                              },
                              "styles": {
                                "background-color": "var(--brand-color);",
                                "border-radius": "100px;"
                              },
                              "textStyles": {
                                "font-family": "Montserrat;",
                                "color": "#FFFFFF;",
                                "fontWeight": "600;",
                                "font-size": "18px;",
                                "line-height": "22px;"
                              },
                              "disabled": false,
                              "action": {
                                "fn": "sendForm",
                                "params": "5h43gwe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Error page
        "pageName": "hata-sayfasi",
        "locale": "tr",
        "pageId": 12,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Heading
                "fieldType": "paragraph",
                "fieldId": "gjd10",
                "properties": {
                  "text": "<center>Oops, bir şeyler hatalı oldu!</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px"
                  },
                  "classes": ["heading"]
                }
              },
              { // Sub Heading
                "fieldType": "paragraph",
                "fieldId": "sfdjk2",
                "properties": {
                  "text": "<center>Lütfen sayfayı yeniden yüklemeyi deneyin!</center>",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12"
                  }
                }
              }
            ]
          },
          { // Image and back button
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            fields: [
              { // Image
                "fieldType": "square-image",
                "fieldId": "gb0wjv",
                "properties": {
                  "imageSource": "/assets/images/404.svg",
                  "grid": {
                    "alignItems": "center",
                    "justifyContent": "center",
                    "columns": "12",
                    "marginTop": "50px"
                  }
                }
              },
              { // Button
                "fieldType": "button-component",
                "fieldId": "oql2de",
                "properties": {
                  "text": "Başlangıç ​​sayfasına geri dön",
                  "grid": {
                    "columns": "4",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "100px",
                    "marginBottom": "70px"
                  },
                  "addClass": "nextBtn",
                  "disabled": false,
                  "action": {
                    "anchor": true,
                    "link": "https://www.firsatyaninda.com/"
                  }
                }
              }
            ]
          }
        ]
      },
      { // Aydınlatma Metni
        "pageName": "pg-aydinlatma-metni",
        "locale": "tr",
        "pageId": 422123,
        "isPublic": true,
        "sections": [
          {
            grid: {
              alignItems: "center",
              justifyContent: "center",
              columns: "12",
              marginBottom: "30px"
            },
            fields: [
              {
                fieldType: "embedded-doc",
                fieldId: "vabd69",
                properties: {
                  grid: {
                    alignItems: "center",
                    justifyContent: "center",
                    columns: "12"
                  },
                  mainWrapperClass: 'embed-wrapper-no-padding',
                  docSource: "https://docs.google.com/document/d/e/2PACX-1vSV4v9BEH5DyQ1BfzfrkeRBY6U6o0YV23FncwrxfIrGpKmbGY3Bhq09pf_Xmx5Fcw/pub?embedded=true"
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "backgroundColor": "#fff",
        "title": "",
        "color": "#FFE082",
        "grid": {
          "columns": 12
        },
        "logo": {
          "imageSource": "/assets/images/logo@1x.png",
          "imageSource2x": "/assets/images/logo@2x.png",
          "width": 40,
          "height": 40,
          "alternateText": "Brand Logo",
          "paddingTop": "30px",
          "title": "Fırsat Yanında"
        },
        "favicon": {
          "imageSource": "https://azcdn.pioneer.pgsitecore.com/en-gb/-/media/Downy_UK/Images/Common/favicon-32x32.jpg?v=1-201708041111"
        },
        "showLine": false,
        "meta": [
          { "name": "title", "content": "P&G Türkiye - Fırsat Yanında" },
          {
            "name": "description",
            "content": `P&G Türkiye - Fırsat Yanında Para İadesi Kampanyası. Belirli satış noktalarından alacağınız toplamda 50 TL P&G ürünü alışverişlerinize 10 TL para iadesi kampanyası.`
          },
          {
            "name": "keywords",
            "content": `Ariel, Alo, Head and Shoulders, Oral-B, Gillette, Firsat Yaninda, P&G Türkiye`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz",
      "properties": {
        "logo": {
          "imageSource": "/assets/images/logo@1x.png",
          "imageSource2x": "/assets/images/logo@2x.png",
          "width": 75,
          "alternateText": "Brand Logo",
          "paddingTop": "30px"
        },
        "links": [
          { "name": "Hüküm ve Koşullar", "link": "http://www.pg.com/tr_TR/terms_conditions/index.shtml" },
          { "name": "P&G'ye ulaşın", "link": "https://pgconsumersupport.secure.force.com/CarehubStandalone?Country=tr&Language=tr&Brand=Kadinlar+Bilir#brandListDiv" },
          { "name": "Gizlilik", "link": "https://privacypolicy.pg.com/tr-TR/" },
          { "name": "Benim Verilerim", "link": "https://preferencecenter.pg.com/tr-tr/"},
          { "name": "Hakkımızda", "link": "https://www.kadinlarbilir.com/hakkimizda" },
          { "name": "Aydınlatma Metni", "link": "/pg-aydinlatma-metni"}
        ],
        pgText: "© 2022 Procter & Gamble. Tüm hakları saklıdır. Bu sitedeki içeriklere erişim ve içeriklerin kullanılması, yasal anlaşmada belirtilen sözleşme ve şartlara bağlıdır."
      }
    },
    "products": [
      {
        "id": 3,
        "ean": 8001841672335,
        "title": "Ariel All-in-1 PODS +Extra Faserpflege 14 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_14WL.jpeg",
        "selectable": true
      },
      {
        "id": 4,
        "ean": 8001841672571,
        "title": "Ariel All-in-1 PODS +Extra Faserpflege 20 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_20WL.jpeg",
        "selectable": true
      },
      {
        "id": 1,
        "ean": 8001841672243,
        "title": "Ariel All-in-1 PODS +Extra Geruchsabwehr 14 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_G_14WL.jpeg",
        "selectable": true
      },
      {
        "id": 2,
        "ean": 8001841672519,
        "title": "Ariel All-in-1 PODS +Extra Geruchsabwehr 20 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_G_20WL.jpeg",
        "selectable": true
      },
      {
        "id": 5,
        "ean": 8001841672090,
        "title": "Ariel All-in-1 PODS Universal 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/PODS_Universal_12.jpeg",
        "selectable": true
      },
      {
        "id": 6,
        "ean": 8001841672038,
        "title": "Ariel All-in-1 PODS Color 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/PODS_Color_12_WL.jpeg",
        "selectable": true
      },
      {
        "id": 7,
        "ean": 8001841700793,
        "title": "Lenor All-in-1 PODS Aprilfrisch 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/Aprilfrisch_12_WL.jpeg",
        "selectable": true
      },
      {
        "id": 8,
        "ean": 8001841700755,
        "title": "Lenor All-in-1 PODS Amethyst Blütentraum 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/Amethyst_Blutentraum_12_WL.jpeg",
        "selectable": true
      }
    ],
    dictionary: {
      tr: {
        messages: {
          "required": (field) => `${field} alanı gereklidir`,
          "mandatory": "Bu alan gereklidir.",
          "alpha_spaces": (field) => `${field} sadece harf içerebilir.`,
          "email": "Geçerli bir e-posta adresi giriniz.",
          "password": "Şifreniz en az 8 karakterden oluşmalı, en az bir büyük harf, bir küçük harf ve bir sayı içermelidir.",
          "newPassword": "Şifreniz en az 8 karakterden oluşmalı, en az bir büyük harf, bir küçük harf ve bir sayı içermelidir.",
          "confirmPass": "Şifreler aynı değildir.",
          "newPasswordConfirm": "Şifreler aynı değildir.",
          "postal": "Posta kodu 5 rakamdan oluşmalıdır.",
          "plz": "Posta kodu 5 rakamdan oluşmalıdır.",
          "street": "Yalnızca harf, boşluk, eğik çizgi ve kısa çizgi içerebilir",
          "iban": "Lütfen katılımcı ülkelerin geçerli bir IBAN'ını kullanınız",
          "phoneNumber": "Lütfen 5XXXXXXXXX formatı ile geçerli bir telefon numarası giriniz.",
          "hausnr": "Bina numarası yanlıştır.",
          "smsCodeLength": "Doğrulama kodu 4 basamaklı olmalıdır."
        },
        names: {
          iban: 'IBAN',
          gender: 'Cinisyet',
          bic: 'BIC',
          firstname: 'İsim',
          name: 'İsim',
          firstName: 'İsim',
          lastname: 'Soy isim',
          surname: 'Soy isim',
          lastName: 'Soy isim',
          email: 'E-mail',
          birthdate: 'Doğum tarihi',
          tckn: "TC Kimlik Numarası",
          password: 'Şifre',
          newPassword: 'Şifre',
          confirmPassword: 'Tekrar Şifre',
          newPasswordConfirm: 'Tekrar Şifre',
          strasse: 'Sokak',
          hausnr: 'No',
          plz: 'Posta kodu',
          ort: 'Şehir',
          city: 'İl',
          address: 'Adres',
          marital: 'Medeni hal',
          kids: 'Çocuk',
          area: 'Mahalle',
          district: 'İlçe',
          land: 'Ülke',
          phoneNumber: 'Telefon numarası',
          code: 'Kod',
          terms: "Katılım koşulları",
          receiveEmails: "Kullanım ve gizlilik politikası şartları",
        }
      }
    },
    "errors": {
      "tr": {
        default: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
        noEmail: "Bu e-posta adresine sahip bir hesap yok.",
        emailUsed: "Bu e-mail adresi zaten kullanımda.",
        incorrect: "Geçersiz kullanıcı adı veya yanlış şifre. Lütfen tekrar deneyin!",
        invalidCode: "Kullanmaya çalıştığınız bağlantının süresi zaten dolmuş. Lütfen yeni bir parola sıfırlama bağlantısı isteyin.",
        manyTries: 'Fiş çok fazla yüklendiğinden bir hata oluştu.',
        generateCode: 'Telefon numarasına izin verilmediğinden doğrulama kodu gönderilemedi. Lütfen girdiğiniz numarayı kontrol edin ve tekrar deneyin.',
        wrongCode: 'SMS kodu yanlış',
        network: 'Hata. Sunucuda bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.'
      }
    },
    "campaignId": "firsat-yaninda-olimpiyat",
    "url": "firsatyaninda.com",
    "name": "firsat-yaninda-olimpiyat"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Oops. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  campaignId: 'firsat-yaninda-olimpiyat',
  formData: new FormData(),
  campaignEndDate: '2025-09-15',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?",
  errorImageList: ["", ""],
  errorActivate: false,
  scannedProducts: [],
  types: ['ean_reader', 'code_128_reader', 'code_39_reader', 'ean_8_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader', 'codabar_reader'],
  pageToBack: '',
  receiptBarcode: ''
}
