<template>
  <div :class="mainWrapperClass" class="d-flex justify-content-center align-items-center">
    <img v-if="mobile" class="clock-image-mobile" src="@/assets/images/participation.svg" alt="Participation" >
    <p :class="classes" class="participation-text" :style="styles" @input="updateComponent" v-html="`${html} <b> ${participationNumber} </b>`" @click="triggerActions"></p>
    <img v-if="!mobile" class="clock-image" src="@/assets/images/participation.svg" alt="Participation" >
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "participation-count",
  props: {
    fieldId: {
      type: String
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      default: "Empty paragraph"
    },
    actions: {
      type: Array
    },
    mobile: {
      type: Boolean,
      default: false
    },
    variables: Object,
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    endpoint: {
      type: String,
      default: "/participationCount"
    }
  },
  data () {
    return {
      totalParticipations: "nan",
      isLoading: false,
      totalAllowedParticipations: 0
    };
  },
  computed: {
    ...mapGetters(['campaignId']),
    html () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state.formData[variable];
          if(variable === "referenceNumber") {
            value = this.$store.getters.getReferenceNumber
          }
          result = result.replace(new RegExp(`{${variable}}`, "g"), value);
        }
      }
      return result;
    },
    participationNumber () {
      console.log(this.totalParticipations)
      if (!isNaN(this.totalParticipations)) {
        let participationsLeft = (this.totalParticipations + "");

        if(participationsLeft <= 0){
          this.showModal('Bugünlük katılım limiti doldu, yarın yine bekleriz :)');
          this.$store.dispatch("setComponentStatus", {
            component: this.fieldId,
            status: false
          });
        }else {
          this.$store.dispatch("setComponentStatus", {
            component: this.fieldId,
            status: true
          });
        }

        // If participations are less than 1000, we do not need the dot in between for styling
        // Else Add a dot after the 1000
        return participationsLeft < 1000 ? participationsLeft : `${participationsLeft.slice(0, participationsLeft.length - 3)}.${participationsLeft.slice(participationsLeft.length - 3)}`
      }
      return this.totalAllowedParticipations
    }
  },
  methods: {
    ...mapActions(['sendRequest']),
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  },
  mounted () {
    const formData = {
      campaignId: this.campaignId
    }
    console.log(this.endpoint)
    this.sendRequest({ endpoint: this.endpoint, formData })
      .then((data) => {
        console.log(data)
        this.totalParticipations = data.remainingParticipation;
        this.isLoading = false
        this.$store.dispatch("setComponentStatus", {
          component: this.fieldId,
          status: data.remainingParticipation > 0
        });
      })
      // console.log(this.totalParticipations)
  }
};
</script>

<style lang="scss" scoped>
p {
  outline: 0;
}

.heading {
  margin: 0px 20px;
  color: var(--color2);
  text-align: center;
  font: normal normal 500 22px/25px Neutraface, SemiBold;
  letter-spacing: 0px;
  opacity: 1;
}


@media (max-width: 500px) {
  .heading {font-size: 15px; line-height: 15px; margin: 0px ;}
  img { width: 50px; height: 50px; }
}

.clock-image{
  
}

</style>
