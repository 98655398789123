<template>
  <div
    class="container"
    :style="cssVars"
  >
    <div class="row">
      <div class="page-title">
        {{ title }}
      </div>
      <template v-if="campaignName == 'rengarenk'">
        <ul class="list">
          <li>
            <p>P&amp;G Rengarenk Bahar Fırsatları kampanyasının düzenleyicisi Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G")'dir.</p>
          </li>
          <li>
            <p>Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.</p>
          </li>
          <li>
            <p>Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
            <p><br /> 5. maddede belirtilen P&amp;G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan 75 TL ve üzeri P&amp;G ürünlerine 20 TL geri ödemeye hak kazanır. Maksimum 20 TL para iadesi yapılır.</p>
          </li>
          <li>
            <p>Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.</p>
            <p><br /> Fişiniz/faturanız 32 saat içerisinde kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) 2-4 hafta arasında aktarılır.<br /><br /> Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&amp;G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.</p>
          </li>
          <li>
            <p>Kampanya ürünleri:</p>
            <p>Kampanyaya tüm P&amp;G ürünleri dahildir.</p>
          </li>
          <li>
            <p>Kampanya satış noktaları:</p>
            <p>SATIŞ NOKTALARI: Rossmann, Eve, Kozkar, Ali Özkan, Sine İtr., Platin, Yüncü Ali, T Shop, Yücel Parfümeri, Hazal Parfümeri, Emre Parfümeri, Deniz Kimya, Boğalar AVM, Komşu Mağazaları, Kağan Parfümeri. Online ve offline geçerlidir.</p>
          </li>
          <li>
            <p>Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.</p>
          </li>
          <li>
            <p>Satın alma işlemleri için kampanya dönemi 01.04.2022 - 13.06.2022 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 13.06.2022'dir. Kampanyaya katılım 1.500 kişi ile sınırlıdır.</p>
          </li>
          <li>
            <p>
              Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&amp;G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <u><a
                href="https://firsatyaninda.com/rengarenkbaharfirsatlari"
                target="_blank"
              >https://firsatyaninda.com/rengarenkbaharfirsatlari</a></u>adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.
            </p>
          </li>
          <li>
            <p>Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&amp;G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde <a href="mailto:destek@bahar.firsatyaninda.com">destek@bahar.firsatyaninda.com</a> destek hattımızla iletişim geçebilirsiniz.</p>
          </li>
          <li>
            <p>Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:</p>
            <p><br /> - Satış Noktası<br /> - Fiş/fatura numarası veya belge numarası<br /> - Fişin/Faturanın tarihi ve saati<br /> - Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı<br /> - Fişin/Faturanın toplam tutarı<br /><br /> Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.</p>
          </li>
          <li>
            <p>Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.</p>
          </li>
          <li>
            <p>Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve P&amp;G Rengarenk Bahar Fırsatları kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.</p>
          </li>
          <li>
            <p>Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&amp;G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır (böyle bir durumda lütfen <a href="mailto:iban@bahar.firsatyaninda.com">iban@bahar.firsatyaninda.com</a> adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır.</p>
          </li>
          <li>
            <p>P&amp;G Rengarenk Bahar Fırsatları kampanyası, diğer P&amp;G kampanyaları ile birleştirilemez.</p>
          </li>
          <li>
            <p>P&amp;G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.</p>
          </li>
          <li>
            <p>Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml">https://www.pg.com/privacy/turkish/privacy_statement.shtml</a></p>
          </li>
          <li>
            <p>P&amp;G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.</p>
          </li>
        </ul>
      </template>
      <template v-else-if="campaignName == 'bahar-ozel'">
        <ul class="list">
          <li>P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyasının düzenleyicisi Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G")'dir.</li>
          <li>Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.</li>
          <li>Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</li>
          <br> 5. maddede belirtilen P&amp;G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan 60 TL ve üzeri P&amp;G ürünlerine % 20 geri ödemeye hak kazanır. Maksimum 30 TL para iadesi yapılır.
          <li>Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.</li>
          <br>Fişiniz/faturanız 32 saat içerisinde kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) 2-4 hafta arasında aktarılır.<br /><br />Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&amp;G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.
          <li>
            Kampanya ürünleri:
            <br>
            Kampanyaya Alo, Ariel, Fairy, Febreze dahildir.
          </li>
          <li>
            Kampanya satış noktaları:
            <br>
            Tüm Türkiye’de,  Online ve offlinegeçerlidir.
          </li>
          <li>Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.</li>
          <li>Satın alma işlemleri için kampanya dönemi 18.04.2022 - 31.05.2022 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 31.05.2022'dir. </li>
          <li>Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&amp;G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <a href="https://firsatyaninda.com/migros">https://firsatyaninda.com/</a>baharevinizdeadresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.</li>
          <li>Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&amp;G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde destek@baharevinizde.firsatyaninda.com destek hattımızla iletişim geçebilirsiniz.</li>
          <li>
            Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:
            <br>
            - Satış Noktası<br />- Fiş/fatura numarası veya belge numarası<br />- Fişin/Faturanın tarihi ve saati<br />- Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı<br />- Fişin/Faturanın toplam tutarı<br /><br />Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.
          </li>
          <li>Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.</li>
          <li>Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.</li>
          <li>Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.</li>
          <li>Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&amp;G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.</li>
          <li>IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır (böyle bir durumda lütfen iban@bahar.firsatyaninda.com adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır.</li>
          <li>P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyası, diğer P&amp;G kampanyaları ile birleştirilemez.</li>
          <li>P&amp;G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.</li>
          <li>Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.</li>
          <li>Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml">https://www.pg.com/privacy/turkish/privacy_statement.shtml</a></li>
          <li>P&amp;G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.</li>
          <li>İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.</li>
        </ul>
      </template>
      <template v-else-if="campaignName == 'olimpiyat'">
        <p><strong> </strong></p>
        <ol>
          <li>
            <p><strong>Her Gün Daha İyiye </strong>kampanyasının düzenleyicisi Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G")'dir.</p>
          </li>
          <li>
            <p>Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.</p>
          </li>
          <li>
            <p>Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
          </li>
          <p>5. maddede belirtilen P&amp;G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan minimum 100 TL’lik P&amp;G ürünü için 25 TL geri ödemeye hak kazanır.</p>
          <li>
            <p>Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.</p>
          </li>
          <p>Fişiniz/faturanız<strong> 32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a)<strong> 2-4 hafta</strong> arasında aktarılır.</p>
          <p>Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&amp;G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.</p>
          <li>
            <p><strong>Kampanya ürünleri</strong></p>
          </li>
          <p>Kampanyaya tüm P&amp;G ürünleri dahildir.</p>
          <li>
            <p><strong>Kampanya satış noktaları</strong></p>
          </li>
          <p>Tüm satış noktalarından alınabilir. Online yapılan alışverişler de geçerlidir.</p>
          <li>
            <p>Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.</p>
          </li>
          <li>
            <p>Satın alma işlemleri için kampanya dönemi 19.05.2022 - 15.08.2022 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 15.08.2022'dir. <strong>Kampanyaya katılım 3600 kişi ile sınırlıdır.</strong></p>
          </li>
          <li>
            <p>
              Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&amp;G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <a
                href="http://www.firsatyaninda.com/olimpiyat"
                target="_blank"
              ><u><strong>www.firsatyaninda.com/olimpiyat</strong></u></a> adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.
            </p>
          </li>
          <li>
            <p>Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&amp;G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde <strong><a href="mailto:destek@olimpiyat.firsatyaninda.com">destek@olimpiyat.firsatyaninda.com</a></strong> destek hattımızla iletişim geçebilirsiniz.</p>
          </li>
          <li>
            <p>Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:</p>
          </li>
          <p>- Satış Noktası</p>
          <p>- Fiş/fatura numarası veya belge numarası</p>
          <p>- Fişin/Faturanın tarihi ve saati</p>
          <p>- Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı</p>
          <p>- Fişin/Faturanın toplam tutarı</p>
          <p>Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.</p>
          <li>
            <p>Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.</p>
          </li>
          <li>
            <p>Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve <strong>Her Gün Daha İyiye </strong>kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.</p>
          </li>
          <li>
            <p>Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&amp;G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır (böyle bir durumda lütfen <strong><a href="mailto:iban@olimpiyat.firsatyaninda.com">iban@olimpiyat.firsatyaninda.com</a></strong> adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır.</p>
          </li>
          <li>
            <p><strong>P&amp;G </strong>kampanyası, diğer P&amp;G kampanyaları ile birleştirilemez.</p>
          </li>
          <li>
            <p>P&amp;G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.</p>
          </li>
          <li>
            <p>Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml</u></a></p>
          </li>
          <li>
            <p>P&amp;G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.</p>
          </li>
        </ol>
      </template>
      <template v-else-if="campaignName == 'orkid'">
        <p><strong>KATILIM KOŞULLARI</strong></p>
        <ol>
          <li>
            <p><strong>P&amp;G Orkid Para İadesi </strong>kampanyasının düzenleyicisi Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G")'dir.</p>
          </li>
          <li>
            <p>Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.</p>
          </li>
          <li>
            <p>Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
            <p>5. maddede belirtilen P&amp;G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan Orkid ürünlerinden 10 TL para iadesi kazanır.</p>
          </li>
          <li>
            <p>Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.</p>
            <p>Fişiniz/faturanız<strong> 32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a)<strong> 2-4 hafta</strong> arasında aktarılır.</p>
            <p>Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&amp;G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.</p>
          </li>
          <li>
            <p><strong>Kampanya ürünleri</strong></p>
            <p>Kampanyaya Orkid ürünleri dahildir.</p>
          </li>
          <li>
            <p><strong>Kampanya satış noktaları</strong></p>
            <p>Promosyon ürünleri online ve offline tüm satış noktalarından alınabilir.</p>
          </li>
          <li>
            <p>Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.</p>
          </li>
          <li>
            <p>Satın alma işlemleri için kampanya dönemi 01.08.2022 - 30.09.2022 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 14/10/2022'dir. <strong>Kampanyaya katılım 2.000 kişi ile sınırlıdır.</strong></p>
          </li>
          <li>
            <p>Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&amp;G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <a href="http://www.firsatyaninda.com/orkid"><strong>www.firsatyaninda.com/orkid</strong></a> adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.</p>
          </li>
          <li>
            <p>Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&amp;G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde <strong><a href="mailto:destek@orkid.firsatyaninda.com">destek@orkid.firsatyaninda.com</a></strong> destek hattımızla iletişim geçebilirsiniz.</p>
          </li>
          <li>
            <p>Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:</p>
            <ul>
              <li>- Satış Noktası</li>
              <li>- Fiş/fatura numarası veya belge numarası</li>
              <li>- Fişin/Faturanın tarihi ve saati</li>
              <li>- Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı</li>
              <li>- Fişin/Faturanın toplam tutarı</li>
            </ul>
            <p>Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.</p>
          </li>
          <li>
            <p>Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.</p>
          </li>
          <li>
            <p>Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve <strong>P&amp;G Orkid Para İadesi </strong>kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.</p>
          </li>
          <li>
            <p>Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&amp;G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır, (böyle bir durumda lütfen <strong><a href="mailto:iban@orkid.firsatyaninda.com">iban@orkid.firsatyaninda.com</a></strong> adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid</strong> kampanyası, diğer P&amp;G kampanyaları ile birleştirilemez.</p>
          </li>
          <li>
            <p>P&amp;G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.</p>
          </li>
          <li>
            <p>Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml</u></a></p>
          </li>
          <li>
            <p>P&amp;G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.</p>
          </li>
          <!-- <li>
  <p>Amazon.com üzerinden yaptığınız alışveriş faturanızı görüntülemek için masaüstü ya da dizüstü bilgisayarda <a href="amazon.com.tr">amazon.com.tr</a> ana sayfasında sağ üst bölümdeki “Hesap ve Listeler”e tıklayın. Açılan sayfada “Siparişlerim” kutusuna tıklayın. Siparişinizi bulduktan sonra sağ üstte yer alan “Fatura” linkine tıklayın ve 1’i seçin.</p>
</li> -->
        </ol>
      </template>
      <template v-else-if="campaignName == 'pg35yil'">
        <ol>
          <li>
            P&amp;G 35. Yıl kampanyasının düzenleyicisi Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G")'dir.
          </li>
          <li>
            Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.
          </li>
          <li>
            Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.<br />5. maddede belirtilen P&amp;G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan minimum 150 TL’lik P&amp;G ürünü için 35 TL geri ödemeye hak kazanır.
          </li>
          <li>
            Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.<br />Fişiniz/faturanız 32 saat içerisinde kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) 2-4 hafta arasında aktarılır.<br />Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&amp;G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.
          </li>
          <li>
            Kampanya ürünleri<br />Kampanyaya tüm P&amp;G ürünleri dahildir.
          </li>
          <li>
            Kampanya satış noktaları<br />Yalnızca online yapılan alışverişler geçerlidir.
          </li>
          <li>
            Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.
          </li>
          <li>
            Satın alma işlemleri için kampanya dönemi 05.01.2023 - 31.03.2023 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 31.03.2023'dir.Kampanyaya katılım 2500 kişi ile sınırlıdır.
          </li>
          <li>
            Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&amp;G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <a href="https://www.firsatyaninda.com/pg35yil">www.firsatyaninda.com/pg35yil</a> adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.
          </li>
          <li>
            Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&amp;G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde destek@pg35yil.firsatyaninda.com destek hattımızla iletişim geçebilirsiniz.
          </li>
          <li>
            Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:<br />- Satış Noktası<br />- Fiş/fatura numarası veya belge numarası<br />- Fişin/Faturanın tarihi ve saati<br />- Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı<br />- Fişin/Faturanın toplam tutarı<br />Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.
          </li>
          <li>
            Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.
          </li>
          <li>
            Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve P&amp;G 35. Yıl kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.
          </li>
          <li>
            Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.
          </li>
          <li>
            Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&amp;G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.
          </li>
          <li>
            IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır (böyle bir durumda lütfen iban@pg35yil.firsatyaninda.com adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır.
          </li>
          <li>
            P&amp;G kampanyası, diğer P&amp;G kampanyaları ile birleştirilemez.
          </li>
          <li>
            P&amp;G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.
          </li>
          <li>
            Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.
          </li>
          <li>
            Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml">https://www.pg.com/privacy/turkish/privacy_statement.shtml</a>
          </li>
          <li>
            P&amp;G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.
          </li>
          <li>
            İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.
          </li>
        </ol>
      </template>
      <template v-else-if="campaignName == 'orkid-2'">
        <p><strong>KATILIM KOŞULLARI</strong></p>
        <ol>
          <li>
            <p><strong>P&amp;G Orkid Para İadesi </strong>kampanyasının düzenleyicisi Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G")'dir.</p>
          </li>
          <li>
            <p>Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.</p>
          </li>
          <li>
            <p>Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
            <p>5. maddede belirtilen P&G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan minimum 50 TL’lik P&G ürünü için 25 TL geri ödemeye hak kazanır.</p>
          </li>
          <li>
            <p>Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.</p>
            <p>Fişiniz/faturanız<strong> 32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a)<strong> 2-4 hafta</strong> arasında aktarılır.</p>
            <p>Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&amp;G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.</p>
          </li>
          <li>
            <p><strong>Kampanya ürünleri</strong></p>
            <p>Kampanyaya Orkid ürünleri dahildir.</p>
          </li>
          <li>
            <p><strong>Kampanya satış noktaları</strong></p>
            <p>Promosyon ürünleri online ve offline tüm satış noktalarından alınabilir.</p>
          </li>
          <li>
            <p>Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.</p>
          </li>
          <li>
            <p>Satın alma işlemleri için kampanya dönemi 01.09.2022 - 30.04.2023 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 14/05/2023'dir. <strong>Kampanyaya katılım 2.000 kişi ile sınırlıdır</strong>.</p>
          </li>
          <li>
            <p>Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&amp;G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <a href="http://www.firsatyaninda.com/orkid"><strong>www.firsatyaninda.com/orkid</strong></a> adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.</p>
          </li>
          <li>
            <p>Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&amp;G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde <strong><a href="mailto:destek@orkid.firsatyaninda.com">destek@orkid.firsatyaninda.com</a></strong> destek hattımızla iletişim geçebilirsiniz.</p>
          </li>
          <li>
            <p>Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:</p>
            <ul>
              <li>- Satış Noktası</li>
              <li>- Fiş/fatura numarası veya belge numarası</li>
              <li>- Fişin/Faturanın tarihi ve saati</li>
              <li>- Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı</li>
              <li>- Fişin/Faturanın toplam tutarı</li>
            </ul>
            <p>Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.</p>
          </li>
          <li>
            <p>Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.</p>
          </li>
          <li>
            <p>Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve <strong>P&amp;G Orkid Para İadesi </strong>kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.</p>
          </li>
          <li>
            <p>Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&amp;G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır, (böyle bir durumda lütfen <strong><a href="mailto:iban@orkid.firsatyaninda.com">iban@orkid.firsatyaninda.com</a></strong> adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid</strong> kampanyası, diğer P&amp;G kampanyaları ile birleştirilemez.</p>
          </li>
          <li>
            <p>P&amp;G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.</p>
          </li>
          <li>
            <p>Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml</u></a></p>
          </li>
          <li>
            <p>P&amp;G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.</p>
          </li>
          <!-- <li>
              <p>Amazon.com üzerinden yaptığınız alışveriş faturanızı görüntülemek için masaüstü ya da dizüstü bilgisayarda <a href="amazon.com.tr">amazon.com.tr</a> ana sayfasında sağ üst bölümdeki “Hesap ve Listeler”e tıklayın. Açılan sayfada “Siparişlerim” kutusuna tıklayın. Siparişinizi bulduktan sonra sağ üstte yer alan “Fatura” linkine tıklayın ve 1’i seçin.</p>
            </li> -->
        </ol>
      </template>
      <template v-else-if="campaignName == 'dishekimlerio'">
        <ol>
          <li>P&amp;G Diş Hekimleri Özel Para İadesi kampanyasının düzenleyicisi Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G")'dir.</li>
          <li>Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.</li>
          <li>
            Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.
            <br />5. maddede belirtilen P&amp;G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan P&amp;G ürünlerinden en fazla üç adet ürünün toplamının %15’i tutarında bir geri ödemeye hak kazanır. Fişinde/faturasında üçten fazla sayıda ürün bulunan başvurular için, geri ödeme tutarının belirlenmesinde fiyatı en yüksek üç ürün baz alınır.
          </li>
          <li>
            Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.
            <br />Fişiniz/faturanız 32 saat içerisinde kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) 4-5 hafta arasında aktarılır.<br /><br />Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&amp;G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.
          </li>
          <li>
            Kampanya ürünleri:
            <ul>
              <li>- Oral B iO Serisi Şarjlı Diş Fırçası</li>
              <li>- Oral B Pro 3 Serisi Şarjlı Diş Fırçası</li>
              <li>- Aquacare Ağız Duşu</li>
            </ul>
          </li>
          <li>Kampanya, Türkiye Cumhuriyeti bünyesindeki internet ve fiziksel satış noktalarının tamamı için geçerlidir. Yurtdışı satış kanalları vasıtasıyla yapılmış alışverişler için kampanyaya katılım mümkün değildir.</li>
          <li>Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.</li>
          <li>Satın alma işlemleri için kampanya dönemi 01.12.2022 - 30.06.2023 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 15.07.2023'tür. Kampanyaya katılım 60 kişi ve iade tutarı 40.500 TL ile sınırlıdır.</li>
          <li>Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&amp;G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <a href="https://firsatyaninda.com/dishekimlerio">https://firsatyaninda.com/dishekimlerio</a> adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.</li>
          <li>Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&amp;G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde <a href="mailto:destek@dishekimlerio.firsatyaninda.com">destek@dishekimlerio.firsatyaninda.com</a> destek hattımızla iletişim geçebilirsiniz.</li>
          <li>
            Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:
            <br />- Satış Noktası<br />- Fiş/fatura numarası veya belge numarası<br />- Fişin/Faturanın tarihi ve saati<br />- Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı<br />- Fişin/Faturanın toplam tutarı<br /><br />Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.
          </li>
          <li>Aynı fiş/fatura ile P&amp;G tarafından gerçekleştirilen diğer kampanyalara katılım mümkün değildir. Aynı fiş/fatura ile birden fazla kampanyaya yapılan başvurular hükümsüzdür.</li>
          <li>İade edilmiş ürünlere ait fişler/faturalar ile yapılan başvurular geçersizdir.</li>
          <li>Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.</li>
          <li>Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve P&amp;G Diş Hekimleri Özel Para İadesi kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.</li>
          <li>Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.</li>
          <li>Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&amp;G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.</li>
          <li>IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır (böyle bir durumda lütfen iban@dishekimlerio.firsatyaninda.com adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonraki katılım geçersiz olacaktır.</li>
          <li>Tüketicinin, 01.12.2022-30.06.2023 tarihleri arasında Oral B iO Şaarjlı Diş Fırçası, Oral B Pro3 Şarjlı Diş Fırçası ve Aquacare Ağız Duşu ürünleri bakımından geçerli olan <strong>ORAL-B MEMNUNİYET GARANTİSİ KAMPANYASI </strong>kapsamında para iadesi alması halinde ayrıca P&amp;G Diş Hekimleri Özel Para İadesi kampanyasına katılım sağlayamaz. Bu hususun kontrolü için Oral-B Memnuniyet Garantisi Kampanyası’na katılan tüketicilerin sadece fişleri ilgili ajanslar aracılığı ile kontrol edilecektir.</li>
          <li>P&amp;G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.</li>
          <li>Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.</li>
          <li>Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml">https://www.pg.com/privacy/turkish/privacy_statement.shtml</a></li>
          <li>P&amp;G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.</li>
          <li>İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.</li>
        </ol>
      </template>
      <template v-else-if="campaignName == 'gilletteparaiadesi'">
        <ol>
          <li><b>P&G Gillette Para İadesi</b> kampanyasının düzenleyicisi Procter & Gamble Tüketim Malları Sanayi A.Ş'dir ("P&G")'dir.</li>
          <li>Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter & Gamble Tüketim Malları Sanayi A.Ş'dir ("P&G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.</li>
          <li>
            Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.
            <br />5. maddede belirtilen P&G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan 200 TL ve üzeri Gillette ürünlerine 40 TL geri ödemeye hak kazanır. Maksimum 40 TL para iadesi yapılır.
          </li>
          <li>
            4.	Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.
            Fişiniz/faturanız <b>32 saat içerisinde</b> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) <b>2-4 hafta</b> arasındaakt arılır.
            Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.
          </li>
          <li>
            <strong>Kampanya ürünleri</strong>
            <br><br>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p><strong>&Uuml;Ürün İsmi</strong></p>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">FUS5 ProShield MNL RAZOR</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION MANUAL 8 CT FFU Pack</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION POWER 8 CT FFU Pack</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">FUSION 16CT FFU Pack</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">556 GIL SKINGUARD MNL CRT 2CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">556 GIL SKINGUARD MNL CRT 4CT ISD</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">FUSION PROGLIDE 2CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION PROGLIDE POWER 2CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION 4CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">M3 START 4CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">M3 START 2CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION5 MNL CRT 4CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">FUSION5 MNL CRT 2CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION5 MNL CRT 8CT ISD</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">SENSOR3 8CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 18 CT ECOMMERCE</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 8 CT ECOMMERCE</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 TURBO 8 CT ECOMMERCE</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">M3 BASE H+5 TFF</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">M3 TURBO H+5 TFF</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 FUSION5 MANUAL 8 CT ECOM</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">557 FUSION PROGLIDE MANUAL 8 CT ECOM</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">MACH3 START 15CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">SENSOR3 16CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">MACH3 START H+3 6CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">M3 START H+3</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 GIL SKINGUARD ALOE V. MNL CRT 4CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 CRT 2CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 CRT 4CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 CRT 8CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 CRT 12CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 TURBO CRT 5CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 TURBO CRT 8CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 TURBO CRT 2</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 TURBO CRT 4</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 TURBO CRT 12 ISD</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 CRT 2</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 CRT 4</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">560 MACH3 CRT 12 ISD</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">PROGLIDE H+4 TR FOOTBAL BESIKTAS</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">M3 START 2CT X 10SRP</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUS5 PROSHIELD CART 4CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">FUS5 PROSHIELD CHILL CART 4CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSIONPOW BASE CARTRIDGE 4CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION PROGLIDE MANUAL 8 CT FFU Pack</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">FUSION PROGLIDE POWER 8 CT FFU Pack</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUS5 PROGLIDE PWR CRT 4CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUS5 PROGLIDE MNL CRT 8CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">FUS5 PROGLIDE PWR CRT 2CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUS5 PROGLIDE MNL CRT 2CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION5 PWR 2CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">557 FUSION PROGLIDE POWER 8 CT ECOM</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 FUSION5 MANUAL 16 CT ECOM</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 FUS PROGLIDE MANUAL 14 CT ECOM</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 18 CT ECOM</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION5 START RAZOR 2UP</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">FUSION5 START CRT 4</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">FUSION5 START CRT 8</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 8 CT ECOM</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">SENSOR3 8CT SENSITIVE</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">SENSOR3 H+6 SENSITIVE</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">SENSOR3 H+6 RED RAZOR</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">SENSOR3 8CT+8CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span style="font-weight: 400;">SENSOR3 5CT</span></p>
                  </td>
                  <td>
                    <p><span>SENSOR3 H+6</span></p>
                  </td>
                  <td>
                    <p><span></span>FUS5 PROGLIDE MNL CRT 4CT</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>MACH3 START LIGHT RAZOR 2UP</span></p>
                  </td>
                  <td>
                    <p><span>SENSOR3 8CT CS6 BL</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">MACH3 START 10CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>557 FUSION MNL CRT 2CT</span></p>
                  </td>
                  <td>
                    <p><span>557 FUSION MNL CRT 4CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 FUSION MNL CRT 8CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>557 PROGLIDE MNL CRT 2CT</span></p>
                  </td>
                  <td>
                    <p><span>557 PROGLIDE MNL CRT 4CT</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 PROGLIDE MNL CRT 8CT</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>557 GIL SKINGUARD ALOE V. RAZOR 2UP</span></p>
                  </td>
                  <td>
                    <p><span>557 MACH3 BASE RAZOR 2UP</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 MACH3 TURBO RAZOR 2UP</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>557 PROGLIDE MNL RAZOR FRR</span></p>
                  </td>
                  <td>
                    <p><span>557 FUSION PWR RAZOR 1UP</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">557 FUSION5 MNL RAZOR 2UP</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>557 FUSION MNL H+4</span></p>
                  </td>
                  <td>
                    <p><span>557 PROGLIDE MNL H+4</span></p>
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">MACH3 START H+3 (RED) EM</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                  &nbsp;
                  </td>
                  <td>
                  &nbsp;
                  </td>
                  <td>
                    <p><span style="font-weight: 400;">MACH3 START H+3 (BLUE) EM</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <strong>Kampanya satış noktaları</strong>
            <br>
            Promosyon ürünleri yalnızca offline satış noktalarından alınabilir. 
          </li>
          <li>Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.</li>
          <li>Satın alma işlemleri için kampanya dönemi 01.02.2023 - 31.05.2023 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 31.05.2022'dir. <strong>Kampanyaya katılım 3.000 kişi ile sınırlıdır.</strong>.</li>
          <li>
            Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <a
              href="www.firsatyaninda.com/gilletteparaiadesi"
              target="_blank"
            ><u>www.firsatyaninda.com/gilletteparaiadesi</u></a> adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.
          </li>
          <li>
            Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde <a
              href="mailto:destek@gilletteparaiadesi.firsatyaninda.com"
              target="_blank"
            ><u>destek@gilletteparaiadesi.firsatyaninda.com</u></a> destek hattımızla iletişim geçebilirsiniz.
          </li>
          <li>
            Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:
            <br>
            <br>- Satış Noktası
            <br>- Fiş/fatura numarası veya belge numarası
            <br>- Fişin/Faturanın tarihi ve saati
            <br>- Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı
            <br>- Fişin/Faturanın toplam tutarı
            <br>Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.
          </li>
          <li>Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.</li>
          <li>Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve <b>P&G Gillette Para İadesi</b> kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir</li>
          <li>Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.</li>
          <li>Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.</li>
          <li>
            IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır, (böyle bir durumda lütfen <a
              href="mailto:iban@gilletteparaiadesi.firsatyaninda.com"
              target="_blank"
            ><b>iban@gilletteparaiadesi.firsatyaninda.com</b></a> adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır
          </li>
          <li><b>P&G Gillette Para İadesi</b>, kampanyası, diğer P&G kampanyaları ile birleştirilemez</li>
          <li>P&G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar</li>
          <li>Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.</li>
          <li>
            Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: 
            <br><a
              href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"
              target="_blank"
            ><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml</u></a>
          </li>
          <li>P&G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.</li>
          <li>İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.</li>
        </ol>
      </template>
      <template v-else-if="campaignName == 'fairyparaiadesi'">
        <ol>
          <li>
            <p><strong>P&amp;G Fairy Para İadesi </strong>kampanyasının düzenleyicisi Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G")'dir.</p>
          </li>
          <li>
            <p>Türkiye'de ikamet eden 18 yaş ve üstü tüketiciler katılabilir. Procter &amp; Gamble Tüketim Malları Sanayi A.Ş'dir ("P&amp;G") çalışanları ile birinci derece akrabaları kampanyaya katılamazlar. Kampanyaya dahil edilen market çalışanları, bu kampanyaya katılamaz.</p>
          </li>
          <li>
            <p>Katılmak için, katılımcının adına olması gereken geçerli bir IBAN banka hesabı, T.C. kimlik numarası, telefon numarası ve bir e-posta adresi gereklidir. Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
            <p>5. maddede belirtilen P&amp;G markalarını satın aldığını ispat eden katılımcı, bu kampanyaya en fazla 1 kez katılarak fişinde/faturasında yer alan Fairy ürünlerinden %20 geri ödemeye hak kazanır. Maksimum 50 TL para iadesi yapılır.</p>
          </li>
          <li>
            <p>Geri ödeme tutarının ödenmesi, yalnızca TL cinsinden bir ödeme hesabına havale ile mümkündür ve yalnızca eksiksiz ve doğru banka bilgileri (IBAN) ile yapılabilir.</p>
            <p>Fişiniz/faturanız<strong> 32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a)<strong> 2-4 hafta</strong> arasında aktarılır.</p>
            <p>Kampanyanın uygulanması ve geri ödeme tutarının aktarımı, bundan böyle P&amp;G adına “Justsnap” olarak anılacak olan Reşitpaşa Mah. Katar Cad. Arı 4 Binası No: 2/50 İç Kapı No: 3 - Sarıyer İSTANBUL adresinde bulunan Justsnap Teknoloji Tic. A.Ş. tarafından gerçekleştirilir. Katılımcıların sözleşmeye dayalı ilişkileri ve iddiaları münhasıran Justsnap ile Justsnap’e yönelik olarak kurulmuştur.</p>
          </li>
          <li>
            <p><strong>Kampanya ürünleri</strong></p>
            <p>Kampanyaya tüm Fairy ürünleri dahildir.</p>
          </li>
          <li>
            <p><strong>Kampanya satış noktaları</strong></p>
            <table class="dcf-table dcf-table-responsive dcf-table-bordered dcf-table-striped dcf-w-100%">
              <thead>
                <tr>
                  <th scope="col">
                    Satış Noktası Adı
                  </th>
                  <th scope="col">
                    Satış Noktası Açık Adresi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Bravo Pınar
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Gürselpaşa, Türkmenbaşı Blv. 28/A, 01120 Seyhan/Adana
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    SEYRANTEPE KARATAŞ AVM
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    KARATAŞ MAHALLESİ 103414 NOLU SOKAK SEBE SİTESİ ALTI NO 9/E ŞAHİNBEY /GAZİANTEP
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    EGE ÇARŞI/BORNOVA ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Kazımdirik, Mustafa Kemal Cd. No62, 35135 Bornova/İzmir
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    TAHTAKALE/ LİMAN ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Sarısu, Kemer Yolu , Akdeniz Blv. No624 DNo624, 07030 Konyaaltı
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÇELİKKAYALAR/MERKEZ AVM ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Nişantaşı Mah. Kemerli Cad. No:110 Z-01/B Selçuklu / Konya
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ACEM /CAMİALTI ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Gaziosmanpaşa Cd. No 179/A Eyüp Sultan Camii Altı Evka-1, 35100 Buca
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    TAHTAKALE/ GAZİ ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Gazi, 6583. Sk., 07320 Kepez/Antalya
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÇELİKKAYALAR/SELÇUKLU ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Dumlupınar, Lale Cd. No1, 42070 Horozluhan Osb/Selçuklu/Konya
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ALTUN GIDA İHT. TÜK. MAD. SAN. TİC.AŞ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Barış Mah.Sakarya Cad.No:2/10 BEYLİKDÜZÜ
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ANPA GROSS MAĞAZACILIK A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Güzelyurt Mah. Mustafa Kemal Cad. Carrefour Apt. 38.Sok. No:6/1 E-5 Karayolu Üzeri Haramidere ESENYURT
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    BİÇEN MAĞAZACILIK SAN. VE TİC. A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Büyükşehir mah.beylikdüzü cad.belicuim avm -1 zemin kat Beylikdüzü
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    BİÇEN MAĞAZACILIK SAN. VE TİC. A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mevlana Mah. Sultan Abdülhamid Cad. No:10A ESENYURT
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ULUKARDEŞLER GIDA İNŞ.SAN DIŞ. TİC. LTD. ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Alibeyköy Mah. Uludağ Cad. No:8 (İmar Blokları) EYÜP
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ULUKARDEŞLER GIDA İNŞ.SAN DIŞ. TİC. LTD. ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Hamidiye Mah. Girne Cad. No:25/A (Ulu Camii Altı) KAĞITHANE
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ANPA GROSS MAĞAZACILIK A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mahmutbey Merkez Mah. Taşocağı Yolu Cad. 212 İstanbul AVM BAĞCILAR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÖZEN ALIŞVERİŞ HİZMETLERİ A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Göztepe Mah. Batışehir Cad.G-1 Blok No:14 BAĞCILAR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ERSAN ALIŞVERİŞ HİZ.VE GIDA SAN.TİC.A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Fevzi Çakmak Mah. Osmangazi Cad. 2/17-C Sok. No:27 BAĞCILAR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ULUKARDEŞLER GIDA İNŞ.SAN DIŞ. TİC. LTD. ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Birlik Mah. Atışalanı Cad. No:165C-1 ESENLER
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÖZKURUŞLAR GIDA SAN. VE TİC. A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Esenşehir Mah. Kanuni Sok. No: 2 /127 - 51G ( SUR YAPI ŞEHİR KONAKLARI ) ÜMRANİYE
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    SEYHANLAR GIDA TEKSTİL TRİ.VE OTO SAN.TİC.LTD.ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mehmet Akif Mah.Şahin Cad.No:1 ÜMRANİYE
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ANPA GROSS MAĞAZACILIK A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Orta Mah. Yalnız Selvi Cad. Dış Kapı No:6 AVM İç Kapı No:23 Kartal
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ALTUN GIDA İHT. TÜK. MAD. SAN. TİC.AŞ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Feyzullah Mah. Atatürk Cad. No:35 MALTEPE
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÖZKURUŞLAR GIDA SAN. VE TİC. A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mevlana Mah. Kıbrıs Cad.No:2/1 ATAŞEHİR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    SEYHANLAR GIDA TEKSTİL TRİ.VE OTO SAN.TİC.LTD.ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Bulgurlu Mah.Bulgurlu Cad.No:115 ÜSKÜDAR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    HAKMAR GIDA TURZ HAY İNŞ SAN LTD ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Kurtköy Mah. Ankara Cad. No:305 PENDİK
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    HAKMAR GIDA TURZ HAY İNŞ SAN LTD ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Çınardere Mah. Bergüzar Sok. No:2/A PENDİK
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    YUNUS MARKET İŞLETMELERİ TİC.A.Ş.(ANKARA)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    30 Ağustos Mah. Şehit Hasan Öztürk Cad. No:1/1-A Etimesgut / Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ALDEMİR TOPTAN GIDA PAZ.SAN.TİC.LTD.ŞTİ. (TURUNCU AVM)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Battalgazi Mah. Yavuz Cad. No:20/A-B Melikgazi / Kayseri
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    GÜLMAR GIDA SAN.TİC.TAAH.LTD.ŞTİ.(CİTY GROSS)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Uğur Mumcu, Fatih Sultan Mehmet Blv No:308 06105 Ostim Osb/Yenimahalle/Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    NEV GIDA SAN.VE TİC.LTD.ŞTİ. (NEVGROSS)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    204. Cadde No:74 06370 - Macunköy / ANKARA
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    GROSS 38 TOP. VE PERAKENDE SAT. HİZ. SAN. TİC. LTD. ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mimarsinan Mah. Kocasinan Bulvarı No:144 Kocasinan / Kayseri
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    DURMAZLAR GIDA İNŞ.TEM.SAN.TİC.LTD.ŞTİ. (ELVANKENT)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Oğuzlar Mah. 1562 Cad. No:16/A Etimesgut / Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    DURMAZLAR GIDA İNŞ.TEM.SAN.TİC.LTD.ŞTİ. (HÜRRİYET)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Osmanlı Mah. Hürriyet Cad. No:69 Elvankent / Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    MİMARSİNAN PAZARI GIDA İHT.MAD.SAN. TİC. A.Ş. (ALP)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    M. Gazi Mah. M. Şimşek Cad. No:122/A Kayseri
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    İMES GROSS GIDA MAĞAZACILIK TİC.LTD.ŞTİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Andiçen Mah. Göktüğ Cad. No:2/4 Sincan / Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Özdilek (Kaplıkaya)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Siteler, Kanuni Cd., 16310 Yıldırım/Bursa
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Özdilek (Gemlik)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Hisar, Bursa Yalova Yolu 1. km, 16600 Gemlik/Bursa
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Güvendik Istasyon
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    İstasyon, İstasyon Cd. No1, 25100 Yakutiye/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Güvendik Yenişehir
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Palandöken, 1. Sk., 25080 Palandöken/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Burak Gross
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Batman, Gap, 72070 Batman Merkez/Batman(Gap mah.Turgut Özal bulvarı No:348)
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Güvendik Kayakyolu
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Yunusemre, Tomurcuk Sk. No8, 25080 Palandöken/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Düzgün Şükrüpaşa-1
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Çaykur cad. Şükrüpaşa mah. No:42 Palandöken/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Worldmar
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Pınarbaşı,Turgut Özal Bulv. No:259, Merkez/Batman
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Düzgün Bölge Eğitim
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Alparslan Türkeş Bulvarı Adnan Menderes Mahallesi No142
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Öz Coşkunlar
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    ŞAFAK MAH. YENİ PTT BİNASI YANI NO:45 BATMAN/MERKEZ
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Düzgün Dadaşkent
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Saltuklu, Şht. Onbaşı Ömer Budak Cd., 25090 Gez Osb/Aziziye/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Sabah Market
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Gap, 2503. Sk., 72070 Batman Merkez/Batman
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Tema Dadaşkent
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Saltuklu, Prof. Dr. İhsan Doğramacı Blv., 25090 Gez Osb/Aziziye/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Yunmar
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    DİCLE KENT MAH. KAYAPINAR CAD. 260.SOKAK GAP SİTESİ C BLOK ALTI NO:55/A
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Tema Lalapaşa
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Lalapaşa, No, Kemeraltı Sk. No4, 25030 Yakutiye/Erzurum
                  </td>
                </tr>
              </tbody>
            </table>
          </li>


          <li>
            <p>Çevrimiçi sipariş onayı veya teslimat notu satın alma fişinin/faturanın yerini almaz. "Mali değeri" olmayan fişler ile katılım sağlanamaz. Online müzayede vb. yoluyla yapılan özel satışlar katılım dışıdır. İndirimler satın alma fiyatından düşülür.</p>
          </li>
          <li>
            <p>Satın alma işlemleri için kampanya dönemi 02.05.2023 - 31.05.2023 (satın alma fişinin tarihi) arasındadır, çevrimiçi kayıt formunun gönderilmesi ve fiş ya da faturanın fotoğrafının yüklenmesi için son tarih 15/06/2023'dir. <strong>Kampanyaya katılım 3.000 kişi ile sınırlıdır.</strong></p>
          </li>
          <li>
            <p>Kampanyaya katılmak için, kampanya dönemi boyunca katılımcı perakendecilerden P&amp;G kampanya ürününü satın almanız ve ilgili bir fişe sahip olmanız gerekir. Daha sonra <u><strong><a href="http://www.firsatyaninda.com/fairy">www.firsatyaninda.com/fairyparaiadesi</a></strong></u> adresinde çevrimiçi katılım formunu eksiksiz doldurmanız ve kendi çektiğiniz satın alma belgenizin bir fotoğrafını yüklemeniz gerekir. Katılım sürecinde sadece bir fiş dikkate alınabilir. 2 veya daha fazla fiş gösteren bir fotoğrafın yüklenmesine izin verilmez.</p>
          </li>
          <li>
            <p>Sisteme yüklenen satın alma belgelerinin anlaşılamaması halinde, üzerinde hem satın aldığınız P&amp;G ürününün arkasının hem de satın alma belgenizin açıkça gösterildiği başka bir fotoğraf yükleyebilirsiniz. Fişin/faturanın açık ve net bir şekilde gösterildiğinden emin olmalısınız, aksi takdirde katılımınız işleme alınamaz. Kampanya ilgili herhangi bir sorunuz olması halinde <strong><a href="mailto:destek@fairyparaiadesi.firsatyaninda.com">destek@fairyparaiadesi.firsatyaninda.com</a></strong> destek hattımızla iletişim geçebilirsiniz.</p>
          </li>
          <li>
            <p>Aşağıdaki noktalar, fişin fotoğrafında açıkça tanımlanmalıdır:</p>
            <p>- Satış Noktası</p>
            <p>- Fiş/fatura numarası veya belge numarası</p>
            <p>- Fişin/Faturanın tarihi ve saati</p>
            <p>- Fişteki/Faturadaki kampanyaya dahil ürünlerin tutarı</p>
            <p>- Fişin/Faturanın toplam tutarı</p>
            <p>Ayrıca fişin/faturanın fotoğrafı fazla pozlanmış, bulanık veya çok karanlık olmamalıdır. Taranan fişler/faturalar katılamaz, sadece ayrıntılı fotoğraflar kabul edilebilir.</p>
          </li>
          <li>
            <p>Geri ödemenin transfer edilebilmesi için IBAN banka bilgilerinizi giriniz.</p>
          </li>
          <li>
            <p>Katılımcı, çevrimiçi kayıt formunu göndererek veri koruma ve katılım koşullarını kabul eder ve <strong>P&amp;G Fairy Para İadesi </strong>kampanyasına katılım sağlamak için kendisiyle e-posta ile iletişime geçilebileceğini beyan eder. Her fiş/fatura yalnızca bir kez katılabilir. Fişte/faturada listelenen satın alma değerini bölmek mümkün değildir.</p>
          </li>
          <li>
            <p>Özellikle veri aktarımı sırasında ve diğer teknik kusurlarda veri kaybı için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>Orijinal satın alma kanıtı, iade tutarı transfer edilene kadar saklanmalıdır. P&amp;G, bazı durumlarda, geri ödeme tutarını transfer etmeden önce orijinal satın alma fişini talep etme hakkını saklı tutar. Satın alma fişinin iadesi mümkün değildir. Postadaki kayıplar için sorumluluk kabul edilmez.</p>
          </li>
          <li>
            <p>IBAN numarasının yanlış bildirilmesinden dolayı sorumluluk kabul edilmez. Geçersiz bir IBAN'ı değiştirmek için tek seferlik bir fırsat vardır, (böyle bir durumda lütfen <strong><a href="mailto:iban@fairyparaiadesi.firsatyaninda.com">iban@fairyparaiadesi.firsatyaninda.com</a></strong> adresine e-posta göndererek bizimle iletişime geçiniz), bundan sonra katılım geçersiz olacaktır.</p>
          </li>
          <li>
            <p><strong>P&amp;G Fairy Para İadesi</strong> kampanyası, diğer P&amp;G kampanyaları ile birleştirilemez.</p>
          </li>
          <li>
            <p>P&amp;G, kayıt olurken yanlış bilgi veren, kampanyayı manipüle eden veya kampanyayı manipüle etmeye çalışan veya bu katılım koşullarını başka şekilde ihlal eden herhangi bir katılımcıyı kampanyanın dışında bırakma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>Kampanyadan doğabilecek ihtilaflarda Türkiye Cumhuriyeti kanunları geçerlidir.</p>
          </li>
          <li>
            <p>Bize sağladığınız kişisel verileriniz yalnızca geri ödeme işlemleri ve bu süreçteki bilgilendirmeler için kullanılacaktır. Sürecin ilerlemesi için destek veren iş ortaklarımız ile paylaşılacaktır. Geri ödemenin tamamlanması üzerine kayıtlarımızdan silinecektir. Ancak pazarlama izni verdiğiniz takdirde Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni kapsamında işlenecektir. Detaylı bilgi için: <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml</u></a></p>
          </li>
          <li>
            <p>P&amp;G istediği takdirde bu kampanyayı uzatma ya da durdurma ve kampanya şartlarını değiştirme ve kampanyayı tek taraflı olarak sonlandırma hakkını saklı tutar.</p>
          </li>
          <li>
            <p>İşbu kampanya katılım koşulları, başka bir yarışma, çekiliş, kampanya, ikramiye ya da kampanya için kullanılamaz. Bu kampanya, Milli Piyango İdaresi’nce düzenlenen yarışma, çekiliş, ikramiye ya da kampanyalar kapsamı dışındadır.</p>
          </li>
        </ol>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "Conditions",
  props: {
    campaignName: {
      type: String
    },
    title: {
      type: String
    },
    colorSchema: {
      type: String,
      default: "#000"
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 5px;
  word-wrap: anywhere !important;
}
.page-title {
  width: 100%;
  text-align: center;
  color: var(--color);
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  margin: 30px;
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.box-notice {
  background-color: #DFDFDF;
  border: 1px solid black;
  padding: 15px;
  margin: 5px;
  color: #3D3D41;
}
.list {
  list-style-type: decimal;
  word-break: break-word;
  margin: 10px 4px;
  li::marker {
    font-size: 26px;
    font-weight: 700;
    color: var(--color);
  }
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding-left: 20px;
  padding-right: 20px;
}
.number {
  color: var(--color);
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  display: inline;
  margin-right: 5px;
  p {
      display: inline;
    }
}
p {
  margin-bottom: 5px;
}
.list-item-wrapper {
  margin-top: 24px;
  margin-right: 5px;
  white-space: initial;
  color: var(--color);
  .list-item {
    font-weight: 400;
    white-space: initial;
    font-size: 16px;
    line-height: 24px;
  }
  .list-item-details {
    margin-top: 10px;
    text-justify: auto;
  }
}
a {
  text-decoration: underline !important;
}
</style>
