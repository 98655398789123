<template>
  <div class="smsVerificationWrapper text-center col-sm-10">
    <ValidationObserver id="numberForm" ref="numberForm">
      <div class="inputWrapper">
        <div ref="selectWrapper" class="col-lg-3">
          <v-select :options="options" label="name" value="code" :clearable="false" :searchable="false"
            v-model="selectedCountry">
            <template #option="{ code, phoneCode }">
              <div class="country-item">
                <div>
                  <country-flag :country="code" size="normal" />
                </div>
                <div>
                  <b>{{ phoneCode }}</b>
                </div>
              </div>
            </template>
            <template #selected-option="{ code, phoneCode }">
              <div style="display: flex; align-items: center; column-gap: 10px;">
                <div>
                  <country-flag :country="code ? code : options[0].code" size="normal" />
                </div>
                <div><b>{{ phoneCode ? phoneCode : options[0].phoneCode }}</b></div>
              </div>
            </template>
          </v-select>
        </div>
        <div class="col-lg-9">
          <textInput id="phoneNumber" v-model="tel" name="phoneNumber" class="phoneNumber col-lg-12" label=""
            placeholder="Mobile phone number" :validation="validation" :label-on-top="false" :no-label="true"
            :styles="inputStyles" :slide-up="tel != ''" />
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import textInput from "@/components/renderers/text-input.vue";
import CountryFlag from 'vue-country-flag'
import { mapMutations, mapActions } from 'vuex';

export default {
  name: "SmsVerification",
  components: {
    textInput,
    // CoolSelect,
    CountryFlag
  },
  props: {
    successAction: {
      type: Object,
      default: () => { }
    },
    endpoint: {
      type: String,
      default: '/mobileAuth'
    },
    onSuccess: {
      type: Object,
      default: () => { }
    },
    inputStyles: {
      type: Object,
      required: false,
      default: () => { }
    },
    options: {
      type: Array,
      default: () => []
    },
    fieldId: {
      type: String,
      default: ''
    },
    validation: {
      type: String,
      default: 'required|phoneNumber'
    }
  },
  data() {
    return {
      // countryCode: "",
      selectedCountry: "",
      tel: ""
    };
  },
  computed: {
    countryCode() {
      return this.selectedCountry.phoneCode ? this.selectedCountry.phoneCode : this.options[0].phoneCode;
    }
  },
  methods: {
    ...mapMutations(['setCountryCode', 'setTel']),
    ...mapActions(['setUserFuelPhone']),
    async goto() {
      const isValid = await this.$refs.numberForm.validate();
      if (!isValid) {
        this.scrollTo(`[id=numberForm`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
        return
      }

      const formData = {
        mobile: `${this.countryCode}${this.tel}`
      };
      if (this.fieldId === 'flc6yysh') {
        this.setUserFuelPhone(`${this.countryCode}${this.tel}`)
        this.setCountryCode(this.countryCode);
        this.setTel(this.tel);
        const fn = this.successAction.fn;
        const params = this.successAction.params;
        this[fn](params, this);
        return;
      }
      this.$store.dispatch("generateCode", { formData, endpoint: this.endpoint })
        .then(() => {
          this.setCountryCode(this.countryCode);
          this.setTel(this.tel);
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
        })
        .catch(() => {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        })
        ;
    },
    onCountrySelected(item) {
      if (item.code === "other") {
        this.$refs.customInput.focus()
        this.countryCode = ""
      }
      if (item.phoneCode) {
        this.countryCode = +item.phoneCode
      }
      this.hideOptions();
    },
    showOptions() {
      this.$refs.selectWrapper.classList.remove('hide')
    },
    hideOptions() {
      this.$refs.selectWrapper.classList.add('hide')
    }
  },
  mounted() {
    this.$eventHub.$on('sendSms-event', this.goto)
    this.selectedCountry = this.options[0].code
  },
  beforeDestroy() {
    this.$eventHub.$off('sendSms-event')
  }
};
</script>
<style lang="scss" scoped>
.v-select {
  border: 1px solid #707070 !important;
  border-radius: 6px;
  padding-bottom: 2px !important;
  margin-right: 5px !important;

  @media(max-width : 767px) {
    margin-right: 0 !important;
  }
}

.inputWrapper {
  display: flex;
  justify-content: center;
  row-gap: 1rem;

  div {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }

  /deep/ .IZ-select__input.IZ-select__input--selection-slot {
    height: 46px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.country-selected-item {
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D1E7FF !important;
  cursor: pointer;
  border-radius: var(--field-radius);

  &.is-invalid {
    border-color: #dc3545;
    background-color: #fff0f1;
  }

  .custom-input-wrap {
    padding-left: .75rem;
    display: inline-flex;
    align-items: center;

    .custom-input {
      padding-left: 4px;
      padding-right: 28px;
    }

    .custom-input-warn {
      position: absolute;
      right: 15px;
      width: 16px;
    }
  }
}

.country-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

:deep(.vs__search) {
  display: none;
}
</style>
