export default {
    isEditMode: true,
    pageProperties: {
      testing: true,
      pages: [
        { // Home Page
          pageName: 'lotereya',
          isPublic: true,
          locale: 'az',
          pageId: 402,
          sections: [
            {
              sectionId: 'orbg8s',
              grid: {
                columns: 12,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              },
              fields: [
                { // Refresh Token
                  fieldType: 'refresh-token',
                  fieldId: 'ij3433',
                },
                { // DataLayer
                  fieldId: 'dte-003',
                  fieldType: 'dataLayer-event',
                  properties: {
                    eventName: 'PageView',
                    fbEvent: 'PageView'
                  }
                },
                { // Key Visual
                  fieldType: 'key-visual',
                  fieldId: '296eg1',
                  properties: {
                    grid: {
                      columns: '10'
                    },
                    styles: {
                      margin: '0 0 50px 0'
                    },
                    noContainer: true,
                    desktopImageUrl: '/assets/lotereya/desktop-banner.jpg',
                    tabletImageUrl: '/assets/lotereya/desktop-banner.jpg',
                    mobileImageUrl: '/assets/lotereya/mobile-banner.jpg'
                  }
                },
                // { // Sub Header
                //   fieldType: 'paragraph',
                //   fieldId: 'vb309g',
                //   properties: {
                //     text: "Detaylı bilgi için <a href='/lotereya/katilim-kosullari' target='_blank'><u>https://firsatyaninda.com/lotereya/katilim-kosullari</u></a> sayfasını ziyaret edebilirsiniz.",
                //     classes: ['subHeading', 'text-center'],
                //     styles: {
                //       color: 'var(--orkid-color)'
                //     },
                //     grid: {
                //       alignItems: 'center',
                //       justifyContent: 'center',
                //       columns: '12',
                //       marginBottom: '40px'
                //     }
                //   }
                // },
                { // Steps Card
                  fieldId: 'fu0923r',
                  fieldType: 'empty-bucket',
                  properties: {
                    grid: {
                      columns: '10',
                      marginTop: '40px',
                      marginBottom: '60px'
                    },
                    className: 'col-md-12 stepsCardMobileWithSubHeader',
                    fields: [
                      {
                        fieldType: 'step-card',
                        fieldId: '05m65j',
                        properties: {
                          stepNumber: '1',
                          stepStyles: {
                            background: 'rgb(230, 245, 235)',
                            color: '#009639',
                            fontFamily: 'Jost'
                          },
                          imageSource: '/assets/steps/lotereya/1.svg',
                          arrowImageSource: '/assets/steps/lotereya/arrow.svg',
                          grid: {
                            columns: 4
                          },
                          fields: [
                            {
                              fieldType: 'paragraph',
                              fieldId: 'psv9ff',
                              properties: {
                                color: 'var(--orkid-color)',
                                text: 'Minimum 10 AZN dəyərində P&G <b>məhsulları al<b>',
                                classes: ['stepCard']
                              }
                            }
                          ]
                        }
                      },
                      {
                        fieldType: 'step-card',
                        fieldId: 'ec7jsf',
                        properties: {
                          stepNumber: '2',
                          stepStyles: {
                            background: 'rgb(230, 245, 235)',
                            color: '#009639',
                            fontFamily: 'Jost'
                          },
                          imageSource: '/assets/steps/lotereya/2.svg',
                          arrowImageSource: '/assets/steps/lotereya/arrow.svg',
                          grid: {
                            columns: 4
                          },
                          fields: [
                            {
                              fieldType: 'paragraph',
                              fieldId: '6sjsfj',
                              properties: {
                                color: 'var(--orkid-color)',
                                text: 'Qəbzin şəklini <b>Yüklə<b>',
                                classes: ['stepCard']
                              }
                            }
                          ]
                        }
                      },
                      {
                        fieldType: 'step-card',
                        fieldId: '16pk71',
                        properties: {
                          stepNumber: '3',
                          stepStyles: {
                            background: 'rgb(230, 245, 235)',
                            color: '#009639',
                            fontFamily: 'Jost'
                          },
                          imageSource: '/assets/steps/altin/gift.svg',
                          arrowImageSource: '/assets/steps/lotereya/arrow.svg',
                          grid: {
                            columns: 4
                          },
                          fields: [
                            {
                              fieldType: 'paragraph',
                              fieldId: 'qo8x4a',
                              properties: {
                                color: 'var(--orkid-color)',
                                text: '<b>Hədiyyə qazanmaq şansı əldə et<b>',
                                classes: ['stepCard']
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { // Buttons
                  fieldType: 'button-component',
                  fieldId: 'x1p9hx',
                  properties: {
                    grid: {
                      columns: '4',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '20px'
                    },
                    styles: {
                      'background-color': 'var(--orkid-color)'
                    },
                    text: 'BAŞLA',
                    disabled: false,
                    addClass: 'nextBtn col-11',
                    action: [
                      {
                        fn: 'goToPage',
                        params: 'lotereya/fis-yukleme'
                      }
                    ]
                  }
                },
                { // Winners
                  fieldId: 'fu0923r',
                  fieldType: 'empty-bucket',
                  properties: {
                    grid: {
                      columns: '12',
                      justifyContent: 'center',
                      marginBottom: '40px'
                    },
                    className: 'col-md-12',
                    fields: [
                      { // Winners
                        fieldId: 'ikz8vc',
                        fieldType: 'anchor',
                        properties: {
                          grid: {
                            columns: 12
                          },
                          text: 'Çempionlar Lotereyasının 1-ci tirajında qazananlar!',
                          url: '/lotereya/tiraj1-qazananlar',
                          target: '_blank',
                          styles: {
                            fontWeight: 'bold',
                            fontSize: '22px'
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Receipt Uploader
          pageName: 'lotereya/fis-yukleme',
          locale: 'az',
          pageId: 403,
          sections: [
            { // Steps and Header
              grid: {
                columns: 12,
                alignItems: 'center',
                justifyContent: 'center'
              },
              sectionId: 'fu30zq',
              fields: [
                { // Steps
                  fieldType: 'step-counter',
                  fieldId: 'tj4j49',
                  properties: {
                    activeIndex: 1,
                    colorSchema: 'var(--orkid-color)',
                    campaignName: 'lotereya',
                    steps: [
                      {
                        title: 'Minimum 10 AZN dəyərində P&G məhsulları <b>AL<b>',
                        page: 'ürün-ekle'
                      },
                      {
                        title: 'Qəbzin şəklini <b>YÜKLƏ<b>',
                        page: 'fis-yukleme'
                      },
                      {
                        title: '<b>Hədiyyə qazanmaq şansı əldə et<b>',
                        page: 'banka-bilgileri'
                      }
                    ]
                  }
                },
                { // Header
                  fieldType: 'paragraph',
                  fieldId: 'uwxo2o',
                  properties: {
                    text: '<center>Zəhmət olmasa, qəbzin şəklini yükləyin</center>',
                    classes: ['heading'],
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '12'
                    }
                  }
                },
                { // Sub Header
                  fieldType: 'paragraph',
                  fieldId: 'vb309g',
                  properties: {
                    text: '<center>Bu mərhələdə P&G məhsullarının alış-veriş qəbzinin şəklini yükləyin.</center>',
                    classes: ['subHeading'],
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '12',
                      marginBottom: '26px'
                    }
                  }
                }
              ]
            },
            { // Receipt Info and Receipt Upload
              sectionId: 'dyx8uv',
              grid: {
                columns: 8,
                alignItems: 'center',
                justifyContent: 'center'
              },
              fields: [
                {
                  fieldType: 'empty-bucket',
                  fieldId: 'h453eadaf',
                  properties: {
                    grid: {
                      columns: 12
                    },
                    styles: {
                      justifyContent: 'center !important'
                    },
                    fields: [
                      { // Receipt Info Desktop
                        fieldType: 'empty-bucket',
                        fieldId: 'vdf932fa',
                        properties: {
                          grid: {
                            columns: '6'
                          },
                          className: 'col-md-9 pb-4 receiptInfo desktopOnly',
                          fields: [
                            {
                              fieldType: 'receipt-information',
                              fieldId: '5mq1p6',
                              properties: {
                                grid: {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  columns: '12'
                                },
                                descriptionText:
                                  'Zəhmət olmasa, P&G məhsullarının alış-veriş qəbzinin şəklini çəkin.  Aşağıdakı məlumatların aydın göründüyündən əmin olun:',
                                // receiptText: 
                                //   '*Qəbzin şəklini yüklədikdən sonra yuxarıdakı məlumatlardan başqa paylaşmaq istəmədiyiniz məlumatları sağ tərəfdəki tənzimləmə düymələrini istifadə edərək silə bilərsiniz.',
                                styles: 'display:none;',
                                steps: [
                                  {
                                    text: 'Satış məntəqəsi',
                                    label: 'A'
                                  },
                                  {
                                    text: 'Alış tarixi və saatı',
                                    label: 'B'
                                  },
                                  {
                                    text: 'Məhsullar və qiymətləri',
                                    label: 'C'
                                  },
                                  {
                                    text: 'Qəbzin ümumi məbləği',
                                    label: 'D'
                                  },
                                  {
                                    text: 'Fiskal kod',
                                    label: 'E'
                                  },
                                  {
                                    text: 'QR-kod',
                                    label: 'F'
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { // Receipt Processor
                        fieldType: 'empty-bucket',
                        fieldId: 'vdf932fb',
                        properties: {
                          grid: {
                            columns: '6'
                          },
                          className: 'col-md-9 pb-4 receiptUpload align-items-center',
                          fields: [
                            {
                              fieldType: 'receipt-processor-az',
                              fieldId: 'recUpload',
                              properties: {
                                texts: {
                                  choose: 'Bir Fayl Seçin',
                                  receiptUpload1:
                                    'Qəbzin şəklini yükləmək üçün buraya klikləyin',
                                  receiptUpload2:
                                    'Sadəcə JPEG, PNG və PDF formatda şəkillər dəstəklənir. <br/><br/> Maksimum fayl ölçüsü: 16 MB',
                                  receiptUpload3:
                                    'Qəbz/qaimə-fakturanızın şəklini yükləmək üçün kameranızdan istifadə edə və ya qalereyanızdan bir şəkil seçə bilərsiniz.',
                                  wrongFormat:
                                    'Bu fayl formatı dəstəklənmir. Yalnız .jpeg, .png, .pdf və .tiff faylları qəbul edilir',
                                  fileSizeLimit:
                                    'Bu fayl çok böyükdür. Yalnız 16 MB-a qədər faylları yükləmək olar.'
                                },
                                subCampaignId: 'aze-lottery-pg2',
                                colorSchema: 'var(--orkid-color)',
                                grid: {
                                  columns: '8',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                },
                                className: 'p-0 az-receipt-processor',
                                successAction: {
                                  fn: 'sendForm',
                                  params: 'fiscalForm'
                                },
                                phrases: [
                                  'Ihr Beleg wird jetzt hochgeladen und bearbeitet.',
                                  'Der Beleg wird automatisch bearbeitet. Dies kann eine Minute dauern. Bitte haben Sie Geduld.',
                                  'Sollten Sie eine Fehlermeldung erhalten, nehmen Sie bitte eine bessere Version des Belegs auf und laden Sie ihn erneut hoch.'
                                ],
                                cornerDetectionPhrases: [
                                  'Lütfən, qəbzi tam və aydın görünəcək formada yerləşdirin.'
                                ],
                                eraseColor: '#ffffff',
                                eraseSize: 12,
                                cropColor: '#3A96F3',
                                cropBackground: 'rgba(0,0,0,0.6)',
                                cropTitle: 'Kes',
                                eraseTitle: 'Düzenle',
                                dragTitle: 'Hareket ettir',
                                zoomInTitle: 'Yakınlaştır',
                                zoomOutTitle: 'Uzaklaştır',
                                selectImageTitle: 'Select an Image',
                                selectAnotherTitle: 'Select Another',
                                controlsBackground: '#4193d3',
                                backgroundColorBtn: 'var(--orkid-color)'
                              }
                            },
                            { // Fiscal ID
                              fieldType: 'form-component',
                              fieldId: 'fiscalForm',
                              properties: {
                                grid: {
                                  columns: 8,
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                },
                                styles: {
                                  justifyContent: 'center'
                                },
                                endpoint: '/receiptInfo',
                                successAction: {
                                  fn: 'goToPage',
                                  params: 'lotereya/uye'
                                },
                                fields: [
                                  {
                                    fieldType: 'text-input',
                                    fieldId: 'fiscal',
                                    properties: {
                                      grid: {
                                        columns: 12
                                      },
                                      name: 'fiscalId',
                                      id: 'fiscalId',
                                      label: 'Fiscal ID',
                                      validation: 'fiscalId',
                                      maxlength: 12
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { // Receipt Info Mobile
                        fieldType: 'empty-bucket',
                        fieldId: 'vdf932fc',
                        properties: {
                          grid: {
                            columns: '6'
                          },
                          className: 'col-md-9 pb-4 receiptInfo desktopNone',
                          fields: [
                            {
                              fieldType: 'receipt-information',
                              fieldId: '5mq1p6',
                              properties: {
                                grid: {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  columns: '12'
                                },
                                descriptionText:
                                  'Zəhmət olmasa, P&G məhsullarının alış-veriş qəbzinin şəklini çəkin.  Aşağıdakı məlumatların aydın göründüyündən əmin olun:',
                                  // receiptText: 
                                  // '*Qəbzin şəklini yüklədikdən sonra yuxarıdakı məlumatlardan başqa paylaşmaq istəmədiyiniz məlumatları sağ tərəfdəki tənzimləmə düymələrini istifadə edərək silə bilərsiniz.',
                                  styles: 'display:none;',
                                steps: [
                                  {
                                    text: 'Satış məntəqəsi',
                                    label: 'A'
                                  },
                                  {
                                    text: 'Alış tarixi və saatı',
                                    label: 'B'
                                  },
                                  {
                                    text: 'Məhsullar və qiymətləri',
                                    label: 'C'
                                  },
                                  {
                                    text: 'Qəbzin ümumi məbləği',
                                    label: 'D'
                                  },
                                  {
                                    text: 'Fiskal kod',
                                    label: 'E'
                                  },
                                  {
                                    text: 'QR-kod',
                                    label: 'F'
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Buttons
              sectionId: 'r0924fw',
              grid: {
                columns: 12,
                marginBottom: '35px',
                marginTop: '40px',
                alignItems: 'center'
              },
              buttonsWrap: true,
              fields: [
                { // Back Button
                  fieldType: 'button-component',
                  fieldId: '16tzm2',
                  properties: {
                    grid: {
                      columns: 4,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '40px',
                      marginBottom: '100px'
                    },
                    text: 'Geri',
                    addClass: 'col-sm-4 col-xl-3 backBtn',
                    action: {
                      fn: 'goToPage',
                      params: 'lotereya'
                    }
                  }
                },
                {
                  fieldType: 'button-component',
                  fieldId: 'cwer23452q',
                  properties: {
                    grid: {
                      columns: 4,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '40px',
                      marginBottom: '100px'
                    },
                    addClass: 'col-sm-4 col-xl-3 nextBtn',
                    text: 'İrəli',
                    shouldCheckCrop: true,
                    requires: ['recUpload'],
                    action: {
                      fn: 'sendReceipt',
                      params: 'recUpload'
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Company Registration
          pageName: 'lotereya/uye',
          locale: 'az',
          pageId: 405123,
          sections: [
            { // Steps and Heading
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                'flex-direction': 'column',
                columns: 12,
                paddingBottom: '80px'
              },
              fields: [
                { // Steps
                  fieldType: 'step-counter',
                  fieldId: '6gx3um',
                  properties: {
                    activeIndex: 2,
                    colorSchema: 'var(--orkid-color)',
                    campaignName: 'lotereya',
                    steps: [
                      {
                        title: 'Minimum 10 AZN dəyərində P&G məhsulları <b>AL<b>',
                        page: 'ürün-ekle'
                      },
                      {
                        title: 'Qəbzin şəklini <b>YÜKLƏ<b>',
                        page: 'fis-yukleme'
                      },
                      {
                        title: '<b>Hədiyyə qazanmaq şansı əldə et<b>',
                        page: 'banka-bilgileri'
                      }
                    ]
                  }
                },
                // { // Heading
                //   fieldType: 'paragraph',
                //   fieldId: 'fckzg7',
                //   properties: {
                //     text: '<center>Misafir kullanıcı olarak devam ediniz</center>',
                //     grid: {
                //       alignItems: 'center',
                //       justifyContent: 'center',
                //       columns: '8',
                //       marginTop: '10px'
                //     },
                //     classes: ['heading']
                //   }
                // },
                // { // Sub Heading
                //   fieldType: 'paragraph',
                //   fieldId: 'ch2fio',
                //   properties: {
                //     text: `<center>Katılımınızı tamamlamak için aşağıdaki bilgileri doldurmanızı rica ederiz.</center>`,
                //     grid: {
                //       alignItems: 'center',
                //       justifyContent: 'center',
                //       columns: '8'
                //     },
                //     classes: ['subHeading']
                //   }
                // }
              ]
            },
            { // Form
              sectionId: 'ci88zt',
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: '12'
              },
              fields: [
                {
                  fieldType: 'form-component',
                  fieldId: 'azRegForm',
                  properties: {
                    styles: {
                      margin: '0',
                      alignItems: 'center',
                      flexDirection: 'column'
                    },
                    grid: {
                      alignItems: 'center',
                      columns: '12',
                      justifyContent: 'center'
                    },
                    requires: ['1'],
                    successAction: {
                      fn: 'sendSms',
                      params: 'azRegForm'
                    },
                    endpoint: '/register',
                    fields: [
                      { // Input
                        fieldType: 'empty-bucket',
                        fieldId: '4h3gweb',
                        properties: {
                          grid: {
                            columns: '6'
                          },
                          className: 'col-md-6 col-xl-4',
                          fields: [
                            { // First Name
                              fieldId: 'b4baqs',
                              fieldType: 'text-input',
                              properties: {
                                name: 'firstName',
                                id: 'firstName',
                                label: 'Ad*',
                                placeholder: '',
                                text: '',
                                validation: 'required|nameWithSpaces',
                                grid: {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  columns: 12
                                }
                              }
                            },
                            { // Last name
                              fieldId: 'h7kzf9',
                              fieldType: 'text-input',
                              properties: {
                                name: 'lastName',
                                id: 'lastName',
                                label: 'Soyad*',
                                placeholder: '',
                                text: '',
                                validation: 'required|nameWithSpaces',
                                grid: {
                                  columns: 12
                                }
                              }
                            }
                          ]
                        }
                      },
                      { // Check Boxes
                        fieldId: '8k2f023f',
                        fieldType: 'empty-bucket',
                        properties: {
                          grid: {
                            columns: '6'
                          },
                          className: 'col-md-6 col-xl-4',
                          fields: [
                            { // Check box Age Consent
                              fieldType: 'checkbox',
                              fieldId: 'wgc3j7',
                              properties: {
                                name: 'termsAccepted',
                                id: 'termsAccepted',
                                validation: {
                                  required: {
                                    allowFalse: false
                                  }
                                },
                                grid: {
                                  columns: '12',
                                  paddingTop: '10px'
                                },
                                styles: {
                                  'margin-top': '30px'
                                },
                                label: `<a href="/lotereya/katilim-kosullari" target="_blank"><u><b>Lotereya şərtlərini</u></b></a> qəbul edirəm.` 
                              }
                            },
                            { // Second Checkbox
                              fieldType: 'checkbox',
                              fieldId: 'psyn1c',
                              properties: {
                                name: 'isOverEighteen',
                                id: 'isOverEighteen',
                                validation: {
                                  required: {
                                    allowFalse: false
                                  }
                                },
                                grid: {
                                  columns: '12',
                                  paddingTop: '10px'
                                },
                                label: `18 yaşından böyük olduğumu qəbul edirəm.`
                              }
                            },
                            { // Third Checkbox
                              fieldType: 'checkbox',
                              fieldId: 'psyn1c',
                              properties: {
                                name: 'isOverEighteen',
                                id: 'isOverEighteen',
                                validation: {
                                  required: {
                                    allowFalse: false
                                  }
                                },
                                grid: {
                                  columns: '12',
                                  paddingTop: '10px'
                                },
                                label: `<a href="/lotereya/privacy-notice" target="_blank"><u><b>Fərdi məlumatların istifadə qaydaları</u></b></a> ve <a href="/lotereya/consent" target="_blank"><u><b> Məxfilik Bildirişi </u></b></a> ilə tanış oldum, razılıq verirəm.`
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            // { // Buttons
            //   sectionId: 'yh453gg4',
            //   grid: {
            //     columns: '12',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            //     marginBottom: '40px',
            //     marginTop: '40px'
            //   },
            //   fields: [
            //     {
            //       fieldType: 'empty-bucket',
            //       fieldId: 'y4h3gwh',
            //       properties: {
            //         grid: {
            //           columns: '6'
            //         },
            //         className: 'col-md-6 col-xl-4',
            //         fields: [
            //           { // Next Button
            //             fieldType: 'button-component',
            //             fieldId: '5n4b3vg4',
            //             properties: {
            //               grid: {
            //                 columns: '12',
            //                 alignItems: 'center',
            //                 justifyContent: 'center'
            //               },
            //               text: 'Müraciət et',
            //               styles: {
            //                 backgroundColor: 'var(--orkid-color)'
            //               },
            //               addClass: 'nextBtn',
            //               disabled: false,
            //               action: {
            //                 fn: 'sendForm',
            //                 params: 'regForm'
            //               }
            //             }
            //           }
            //         ]
            //       }
            //     }
            //   ]
            // },
            { // Form
              sectionId: 'mfh0h1',
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: 12
              },
              fields: [
                { // SMS
                  fieldType: 'sms-verification',
                  fieldId: 'c6yysh',
                  properties: {
                    endpoint: '/mobileAuth',
                    inputWidth: '720px',
                    successAction: {
                      fn: 'goToPage',
                      params: 'lotereya/sms-onay'
                    },
                    options: [
                      {
                        name: 'Azerbaycan',
                        code: 'AZ',
                        phoneCode: '+994'
                      }
                    ],
                    validation: 'required|phoneNumberAz',
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: 6,
                      marginBottom: '50px',
                      marginTop: '30px'
                    },
                  }
                }
              ]
            },
            { // Buttons
              sectionId: 'r0924fw',
              grid: {
                columns: 10,
                marginBottom: '35px',
                //marginTop: '20px',
                alignItems: 'center'
              },
              buttonsWrap: true,
              fields: [
                { // Back Button
                  fieldType: 'button-component',
                  fieldId: '16tzm2',
                  properties: {
                    grid: {
                      columns: 4,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '40px',
                      marginBottom: '100px'
                    },
                    text: 'Geri',
                    addClass: 'col-sm-4 col-xl-3 backBtn',
                    action: {
                      fn: 'goToPage',
                      params: 'lotereya/fis-yukleme'
                    }
                  }
                },
                {
                  fieldType: 'button-component',
                  fieldId: 'cwer23452q',
                  properties: {
                    grid: {
                      columns: 4,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '40px',
                      marginBottom: '100px'
                    },
                    addClass: 'col-sm-4 col-xl-3 nextBtn',
                    text: 'İrəli',
                    shouldCheckCrop: true,
                    //requires: ['klvakj'],
                    action: [
                      {
                        fn: 'sendForm',
                        params: 'azRegForm'
                      },
                    ]
                  }
                }
              ]
            }
            
          ]
        },
        { // Sms Receive
          pageName: 'lotereya/sms-onay',
          locale: 'az',
          pageId: 4091,
          sections: [
            { // Steps and Heading
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                'flex-direction': 'column',
                columns: 12
              },
              fields: [
                { // Steps
                  fieldType: 'step-counter',
                  fieldId: '6gx3um',
                  properties: {
                    activeIndex: 2,
                    colorSchema: 'var(--orkid-color)',
                    campaignName: 'lotereya',
                    steps: [
                      {
                        title: 'Minimum 10 AZN dəyərində P&G məhsulları <b>AL<b>',
                        page: 'ürün-ekle'
                      },
                      {
                        title: 'Qəbzin şəklini <b>YÜKLƏ<b>',
                        page: 'fis-yukleme'
                      },
                      {
                        title: '<b>Hədiyyə qazanmaq şansı əldə et<b>',
                        page: 'banka-bilgileri'
                      }
                    ]
                  }
                },
                { // heading
                  fieldType: 'paragraph',
                  fieldId: '0bbo32',
                  properties: {
                    text: 'Zəhmət olmasa, telefon nömrənizi təsdiq edin',
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '8'
                    },
                    classes: ['heading', 'text-center']
                  }
                },
                { // Sub Heading
                  fieldType: 'paragraph',
                  fieldId: '94xqe4',
                  properties: {
                    text: `Zəhmət olmasa, sizə SMS ilə göndərdiyimiz təsdiq kodunu daxil edin.`,
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '8'
                    },
                    classes: ['subHeading', 'text-center']
                  }
                }
              ]
            },
            { // Form
              sectionId: 'mfh0h1',
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: 12
              },
              fields: [
                { // SMS
                  fieldType: 'sms-receiving',
                  fieldId: 'c6yysh',
                  properties: {
                    endpoint: '/mobileVerification',
                    inputWidth: '720px',
                    successAction: {
                      fn: 'goToPage',
                      params: 'lotereya/kayit-tamamlandi'
                    },
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: 4
                    }
                  }
                }
              ]
            },
            { // Buttons
              sectionId: 'hmgwhl',
              grid: {
                columns: 12,
                alignItems: 'center'
              },
              buttonsWrap: true,
              fields: [
                { // back Button
                  fieldType: 'button-component',
                  fieldId: '16tzm2',
                  properties: {
                    grid: {
                      columns: '4',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '40px',
                      marginBottom: '100px'
                    },
                    text: 'Geri',
                    addClass: 'col-sm-4 col-xl-3 backBtn',
                    action: {
                      fn: 'goToPage',
                      params: 'lotereya/uye'
                    }
                  }
                },
                {
                  fieldType: 'button-component',
                  fieldId: '16tzm2',
                  properties: {
                    grid: {
                      columns: '4',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '40px',
                      marginBottom: '100px'
                    },
                    addClass: 'col-sm-4 col-xl-3 nextBtn',
                    text: 'İrəli',
                    styles: {
                      backgroundColor: 'var(--orkid-color)'
                    },
                    requires: ['code'],
                    action: {
                      fn: 'verifySms',
                      params: 'lotereya/kayit-tamamlandi'
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Thank you page
          pageName: 'lotereya/kayit-tamamlandi',
          locale: 'az',
          pageId: 410,
          sections: [
            {
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: 12,
                maxWidth: '1270px',
                margin: 'auto'
              },
              fields: [
                {
                  fieldId: 'dte-003',
                  fieldType: 'dataLayer-event',
                  properties: {
                    eventName: 'consumerIdSend',
                    sendconsumerId: true
                  }
                },
                { // Thank you Texts and Reference Number
                  fieldType: 'empty-bucket',
                  fieldId: '05m65ds',
                  properties: {
                    grid: {
                      columns: 8,
                      marginTop: '40px',
                      marginBottom: '50px'
                    },
                    styles: {
                      padding: '0',
                      flexDirection: 'column',
                      alignItems: 'center'
                    },
                    fields: [
                      { // Image
                        fieldId: 'vn0934kf',
                        fieldType: 'square-image',
                        properties: {
                          grid: {
                            columns: '12'
                          },
                          styles: {
                            marginBottom: '20px',
                            padding: '0'
                          },
                          className: 'thankyouImage',
                          imageSource: '/assets/success2.svg',
                          imageSource2: '/assets/success2.svg'
                        }
                      },
                      { // Heading
                        fieldType: 'paragraph',
                        fieldId: '88z8gv',
                        properties: {
                          text: '<center>Təbriklər, müraciətiniz qeydə alındı!</center>',
                          grid: {
                            alignItems: 'center',
                            justifyContent: 'center',
                            columns: '10'
                          },
                          classes: ['heading']
                        }
                      },
                      { // Sub Heading
                        fieldType: 'paragraph',
                        fieldId: '88z8gv',
                        properties: {
                          variables: {
                            referenceNumber: 'referenceNumber'
                          },
                          text: `<center>
                              Qəbziniz bizə göndərildi və hal-hazırda nəzərdən keçirilir.
                                <br/><br/>
                              <strong>İdentifikasiya Kodu: {referenceNumber}</strong>
                                <br/><br/>
                                Daxil etdiyiniz məlumatlar 48 saat ərzində yoxlanılacaq. Lotereya şərtlərinə uyğun olarsa lotereyada iştirakınızla bağlı təsdiq mesajını SMS vasitəsilə alacaqsız. Müraciətinizdə əksik məlumat olarsa sizə bununla bağlı məlumat veriləcək. Sizinlə əlaqə saxlayana qədər gözləməyinizi xahiş edirik.
                                <br/><br/>
                            </center>`,
                          grid: {
                            alignItems: 'center',
                            justifyContent: 'center',
                            columns: '10'
                          },
                          classes: ['subHeading']
                        }
                      },
                      { // Button
                        fieldType: 'button-component',
                        fieldId: 'oql2de',
                        properties: {
                          text: 'Ana səhifəyə geri dönün',
                          grid: {
                            columns: '6',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '40px',
                            marginBottom: '90px'
                          },
                          addClass: 'nextBtn',
                          styles: {
                            backgroundColor: 'var(--orkid-color)'
                          },
                          disabled: false,
                          action: {
                            anchor: true,
                            link: '/lotereya'
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Kazananlar
          pageName: 'lotereya/tiraj1-qazananlar',
          locale: 'az',
          pageId: 4101,
          isPublic: true,
          sections: [
            {
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: 12,
                maxWidth: '1270px',
                margin: 'auto'
              },
              fields: [
                { // Winners List
                  fieldType: 'empty-bucket',
                  fieldId: '05m65ds',
                  properties: {
                    grid: {
                      columns: 12,
                      marginTop: '40px',
                      marginBottom: '50px'
                    },
                    styles: {
                      padding: '0',
                      flexDirection: 'column',
                      alignItems: 'center'
                    },
                    fields: [
                      { // Sub Heading
                        fieldType: 'tiraj1-qazananlar',
                        fieldId: '88z8gv',
                        properties: {
                          grid: {
                            alignItems: 'center',
                            justifyContent: 'center',
                            columns: '12'
                          },
                          classes: ['subHeading']
                        }
                      },
                      { // Sub Heading
                        fieldType: 'tiraj2-qazananlar',
                        fieldId: '88z8gv',
                        properties: {
                          grid: {
                            alignItems: 'center',
                            justifyContent: 'center',
                            columns: '12'
                          },
                          classes: ['subHeading']
                        }
                      },
                      { // Button
                        fieldType: 'button-component',
                        fieldId: 'oql2de',
                        properties: {
                          text: 'Ana səhifəyə geri dönün',
                          grid: {
                            columns: '6',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '40px',
                            marginBottom: '90px'
                          },
                          addClass: 'nextBtn',
                          styles: {
                            backgroundColor: 'var(--orkid-color)'
                          },
                          disabled: false,
                          action: {
                            anchor: true,
                            link: '/lotereya'
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // 2. Tur Kazananlar
          pageName: 'lotereya/tiraj2-qazananlar',
          locale: 'az',
          pageId: 4101,
          isPublic: true,
          sections: [
            {
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: 12,
                maxWidth: '1270px',
                margin: 'auto'
              },
              fields: [
                { // Winners List
                  fieldType: 'empty-bucket',
                  fieldId: '05m65ds',
                  properties: {
                    grid: {
                      columns: 12,
                      marginTop: '40px',
                      marginBottom: '50px'
                    },
                    styles: {
                      padding: '0',
                      flexDirection: 'column',
                      alignItems: 'center'
                    },
                    fields: [
                      { // Sub Heading
                        fieldType: 'tiraj2-qazananlar',
                        fieldId: '88z8gv',
                        properties: {
                          grid: {
                            alignItems: 'center',
                            justifyContent: 'center',
                            columns: '12'
                          },
                          classes: ['subHeading']
                        }
                      },
                      { // Button
                        fieldType: 'button-component',
                        fieldId: 'oql2de',
                        properties: {
                          text: 'Ana səhifəyə geri dönün',
                          grid: {
                            columns: '6',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '40px',
                            marginBottom: '90px'
                          },
                          addClass: 'nextBtn',
                          styles: {
                            backgroundColor: 'var(--orkid-color)'
                          },
                          disabled: false,
                          action: {
                            anchor: true,
                            link: '/lotereya'
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Kampanya bitti
          pageName: 'lotereya/kampanya-bitti',
          locale: 'az',
          pageId: 4101,
          isPublic: true,
          sections: [
            {
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: 12,
                maxWidth: '1270px',
                margin: 'auto'
              },
              fields: [
                { // Winners List
                  fieldType: 'empty-bucket',
                  fieldId: '05m65ds',
                  properties: {
                    grid: {
                      columns: 12,
                      marginTop: '40px',
                      marginBottom: '50px'
                    },
                    styles: {
                      padding: '0',
                      flexDirection: 'column',
                      alignItems: 'center'
                    },
                    fields: [
                      { // Heading
                        "fieldType": "paragraph",
                        "fieldId": "88z8gv",
                        "properties": {
                          "text": "<center>Kampaniya müddəti bitdi</center>",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "10"
                          },
                          "classes": ["heading"]
                        }
                      },
                      { // Winners
                        fieldId: 'fu0923r',
                        fieldType: 'empty-bucket',
                        properties: {
                          grid: {
                            columns: '12',
                            justifyContent: 'center',
                            marginBottom: '40px'
                          },
                          className: 'col-md-12',
                          fields: [
                            { // Winners
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: 'Çempionlar Lotereyasının 2-ci tirajında qazananlar!',
                                url: '/lotereya/tiraj2-qazananlar',
                                target: '_blank',
                                styles: {
                                  fontWeight: 'bold',
                                  fontSize: '22px',
                                  color: '#0d6efd'
                                }
                              }
                            }
                          ]
                        }
                      },
                      { // Key Visual
                        "fieldType": "key-visual",
                        "fieldId": "296eg1",
                        "properties": {
                          "grid": {
                            "columns": "12"
                          },
                          "styles": {
                            "margin": "0 0 50px 0"
                          },
                          "noContainer": true,
                          "desktopImageUrl": "/assets/lotereya/desktop-banner.jpg",
                          "tabletImageUrl": "/assets/lotereya/desktop-banner.jpg",
                          "mobileImageUrl": "/assets/lotereya/mobile-banner.jpg"
                        }
                      },
                      { // Button
                        "fieldType": "button-component",
                        "fieldId": "oql2de",
                        "properties": {
                          "text": "Başlanğıc səhifəsinə qayıt",
                          "styles": {
                            "background-color": "var(--orkid-color);",
                            "border-radius": "100px;"
                          },
                          "grid": {
                            "columns": "4",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px",
                            "marginBottom": "90px"
                          },
                          "addClass": "nextBtn",
                          "disabled": false,
                          "action": {
                            "anchor": true,
                            "link": "https://www.firsatyaninda.com/"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Error page
          "pageName": "lotereya/hata-sayfasi",
          "locale": "tr",
          "pageId": 313,
          isPublic: true,
          "sections": [
            { // Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "gjd10",
                  "properties": {
                    "text": "<center>Oops, bir şeyler hatalı oldu!</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginTop": "50px"
                    },
                    "classes": ["heading"]
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "sfdjk2",
                  "properties": {
                    "text": "<center>Lütfen sayfayı yeniden yüklemeyi deneyin!</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    }
                  }
                }
              ]
            },
            { // Image and back button
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              fields: [
                { // Button
                  "fieldType": "button-component",
                  "fieldId": "oql2de",
                  "properties": {
                    "text": "Başlangıç   sayfasına geri dön",
                    "styles": {
                      "background-color": "var(--altinfirsat-color);",
                      "border-radius": "100px;"
                    },
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "100px",
                      "marginBottom": "70px"
                    },
                    "addClass": "nextBtn",
                    "disabled": false,
                    "action": {
                      "anchor": true,
                      "link": "https://www.firsatyaninda.com/altinfirsat"
                    }
                  }
                }
              ]
            }
          ]
        },
        { // T&C
          pageName: 'lotereya/katilim-kosullari',
          locale: 'az',
          pageId: 421,
          isPublic: true,
          sections: [
            {
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: '12',
                'margin-bottom': '30px'
              },
              fields: [
                { // Header
                  fieldType: 'paragraph',
                  fieldId: 'uwxo2o',
                  properties: {
                    text: '<center>Lotereya şərtləri</center>',
                    classes: ['heading'],
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '12'
                    }
                  }
                },
                {
                  fieldType: 'pdf-viewer',
                  fieldId: 'vabd69',
                  properties: {
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '8'
                    },
                    link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/firsatyaninda/lotereya/terms.pdf'
                  }
                }
              ]
            }
          ]
        },
        { // Privacy Notice
          pageName: 'lotereya/privacy-notice',
          locale: 'az',
          pageId: 422,
          isPublic: true,
          sections: [
            {
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: '12',
                'margin-bottom': '30px',  
              },
              fields: [
                { // Header
                  fieldType: 'paragraph',
                  fieldId: 'uwxo2o',
                  properties: {
                    text: '<center>Fərdi məlumatların istifadə qaydaları</center>',
                    classes: ['heading'],
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '12'
                    },
                  }
                },
                {
                  fieldType: 'pdf-viewer',
                  fieldId: 'vabd69',
                  properties: {
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '8'
                    },
                    link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/firsatyaninda/lotereya/privacy-notice.pdf'
                  }
                }
              ]
            }
          ]
        },
        { // Consent
          pageName: 'lotereya/consent',
          locale: 'az',
          pageId: 423,
          isPublic: true,
          sections: [
            {
              grid: {
                alignItems: 'center',
                justifyContent: 'center',
                columns: '12',
                'margin-bottom': '30px'
              },
              fields: [
                { // Header
                  fieldType: 'paragraph',
                  fieldId: 'uwxo2o',
                  properties: {
                    text: '<center>Məxfilik Bildirişi</center>',
                    classes: ['heading'],
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '12'
                    }
                  }
                },
                {
                  fieldType: 'pdf-viewer',
                  fieldId: 'vabd69',
                  properties: {
                    grid: {
                      alignItems: 'center',
                      justifyContent: 'center',
                      columns: '8'
                    },
                    link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/firsatyaninda/lotereya/consent.pdf'
                  }
                }
              ]
            }
          ]
        },
        // { // Error page
        //   pageName: 'lotereya/hata-sayfasi',
        //   locale: 'az',
        //   pageId: 413,
        //   isPublic: true,
        //   sections: [
        //     { // Heading
        //       grid: {
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         columns: 12
        //       },
        //       fields: [
        //         { // Heading
        //           fieldType: 'paragraph',
        //           fieldId: 'gjd10',
        //           properties: {
        //             text: '<center>Oops, bir şeyler hatalı oldu!</center>',
        //             grid: {
        //               alignItems: 'center',
        //               justifyContent: 'center',
        //               columns: '12',
        //               marginTop: '50px'
        //             },
        //             classes: ['heading']
        //           }
        //         },
        //         { // Sub Heading
        //           fieldType: 'paragraph',
        //           fieldId: 'sfdjk2',
        //           properties: {
        //             text: '<center>Lütfen sayfayı yeniden yüklemeyi deneyin!</center>',
        //             grid: {
        //               alignItems: 'center',
        //               justifyContent: 'center',
        //               columns: '12'
        //             }
        //           }
        //         }
        //       ]
        //     },
        //     { // Image and back button
        //       grid: {
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         columns: 12
        //       },
        //       fields: [
        //         { // Image
        //           fieldType: 'square-image',
        //           fieldId: 'gb0wjv',
        //           properties: {
        //             imageSource: '/assets/images/404.svg',
        //             grid: {
        //               alignItems: 'center',
        //               justifyContent: 'center',
        //               columns: '12',
        //               marginTop: '50px'
        //             }
        //           }
        //         },
        //         { // Button
        //           fieldType: 'button-component',
        //           fieldId: 'oql2de',
        //           properties: {
        //             text: 'Başlangıç sayfasına geri dön',
        //             styles: {
        //               'background-color': 'var(--orkid-color);',
        //               'border-radius': '100px;'
        //             },
        //             grid: {
        //               columns: '4',
        //               alignItems: 'center',
        //               justifyContent: 'center',
        //               marginTop: '100px',
        //               marginBottom: '70px'
        //             },
        //             addClass: 'nextBtn',
        //             disabled: false,
        //             action: {
        //               anchor: true,
        //               link: '/lotereya'
        //             }
        //           }
        //         }
        //       ]
        //     }
        //   ]
        // },
        // { // Expired page
        //   pageName: 'lotereya/expired-page',
        //   locale: 'az',
        //   pageId: 414,
        //   isPublic: true,
        //   sections: [
        //     { // Heading
        //       grid: {
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         columns: 12
        //       },
        //       fields: [
        //         { // Heading
        //           fieldType: 'paragraph',
        //           fieldId: 'gjd10',
        //           properties: {
        //             text: `<center>Maalesef, ulaşmaya çalıştığınız bağlantının süresi doldu. Lütfen tekrar kampanyaya katılın veya destek servisimize başvurun: <a href="mailto:pgprofesyonel@firsatyaninda.com"><u>pgprofesyonel@firsatyaninda.com</u></a></center>`,
        //             grid: {
        //               alignItems: 'center',
        //               justifyContent: 'center',
        //               columns: '6',
        //               marginTop: '50px'
        //             },
        //             classes: ['heading']
        //           }
        //         }
        //       ]
        //     },
        //     { // Image and back button
        //       grid: {
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         columns: 12
        //       },
        //       fields: [
        //         { // Image
        //           fieldType: 'square-image',
        //           fieldId: 'gb0wjv',
        //           properties: {
        //             imageSource: '/assets/images/expired.svg',
        //             grid: {
        //               alignItems: 'center',
        //               justifyContent: 'center',
        //               columns: '12',
        //               marginTop: '50px'
        //             },
        //             styles: {
        //               'max-width': '645px'
        //             }
        //           }
        //         }
        //       ]
        //     },
        //     {
        //       grid: {
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         columns: 12
        //       },
        //       fields: [
        //         { // Button
        //           fieldType: 'button-component',
        //           fieldId: 'oql2de',
        //           properties: {
        //             text: 'Başlangıç sayfasına geri dön',
        //             styles: {
        //               'background-color': 'var(--orkid-color);',
        //               'border-radius': '100px;'
        //             },
        //             grid: {
        //               columns: '4',
        //               alignItems: 'center',
        //               justifyContent: 'center',
        //               marginTop: '100px',
        //               marginBottom: '70px'
        //             },
        //             addClass: 'nextBtn',
        //             disabled: false,
        //             action: {
        //               anchor: true,
        //               link: '/lotereya'
        //             }
        //           }
        //         }
        //       ]
        //     }
        //   ]
        // },
        // { // Campaign Is Over
        //   pageName: 'lotereya/kampanya-bitti',
        //   locale: 'az',
        //   pageId: 416,
        //   isPublic: true,
        //   sections: [
        //     {
        //       grid: {
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         columns: 12,
        //         margin: 'auto'
        //       },
        //       fields: [
        //         { // Heading and Image and Button
        //           fieldType: 'empty-bucket',
        //           fieldId: '05m65ds',
        //           properties: {
        //             grid: {
        //               columns: 12,
        //               marginTop: '40px',
        //               marginBottom: '50px'
        //             },
        //             styles: {
        //               padding: '0',
        //               flexDirection: 'column',
        //               alignItems: 'center'
        //             },
        //             fields: [
        //               { // Heading
        //                 fieldType: 'paragraph',
        //                 fieldId: '88z8gv',
        //                 properties: {
        //                   text: '<center>Kampanya süresi sona erdi</center>',
        //                   grid: {
        //                     alignItems: 'center',
        //                     justifyContent: 'center',
        //                     columns: '10'
        //                   },
        //                   classes: ['heading']
        //                 }
        //               },
        //               { // Sub Heading
        //                 fieldType: 'paragraph',
        //                 fieldId: '88z8gv',
        //                 properties: {
        //                   text: `<center>Kampanyaya göstermiş olduğunuz ilgiye teşekkür ederiz, maalesef kampanya dönemi sona ermiştir.</center>`,
        //                   grid: {
        //                     alignItems: 'center',
        //                     justifyContent: 'center',
        //                     columns: '10'
        //                   },
        //                   classes: ['subHeading']
        //                 }
        //               },
        //               { // Key Visual
        //                 fieldType: 'key-visual',
        //                 fieldId: '296eg1',
        //                 properties: {
        //                   grid: {
        //                     columns: '12'
        //                   },
        //                   styles: {
        //                     margin: '0 0 50px 0'
        //                   },
        //                   noContainer: true,
        //                   desktopImageUrl: '/assets/lotereya/desktop-banner.jpg',
        //                   tabletImageUrl: '/assets/lotereya/desktop-banner.jpg',
        //                   mobileImageUrl: '/assets/lotereya/mobile-banner.jpg'
        //                 }
        //               },
        //               { // Button
        //                 fieldType: 'button-component',
        //                 fieldId: 'oql2de',
        //                 properties: {
        //                   text: 'Başlangıç sayfasına geri dön',
        //                   styles: {
        //                     'background-color': 'var(--orkid-color);',
        //                     'border-radius': '100px;'
        //                   },
        //                   grid: {
        //                     columns: '4',
        //                     alignItems: 'center',
        //                     justifyContent: 'center',
        //                     marginTop: '40px',
        //                     marginBottom: '90px'
        //                   },
        //                   addClass: 'nextBtn',
        //                   disabled: false,
        //                   action: {
        //                     anchor: true,
        //                     link: 'https://www.firsatyaninda.com/'
        //                   }
        //                 }
        //               }
        //             ]
        //           }
        //         }
        //       ]
        //     }
        //   ]
        // },
      ],
      header: {
        fieldType: 'navbar',
        fieldId: 'cbx34t',
        properties: {
          backgroundColor: '#fff',
          title: '',
          color: '#FFE082',
          grid: {
            columns: 6
          },
          logo: {
            title: 'Fırsat Yanında',
            imageSource: '/assets/images/logo@1x.png',
            imageSource2x: '/assets/images/logo@2x.png',
            width: 75,
            alternateText: 'Brand Logo',
            paddingTop: '30px'
          },
          favicon: {
            imageSource: 'https://azcdn.pioneer.pgsitecore.com/en-gb/-/media/Downy_UK/Images/Common/favicon-32x32.jpg?v=1-201708041111'
          },
          fields: [
            { // Terms
              fieldId: 'ikz8vc',
              fieldType: 'anchor',
              properties: {
                text: 'Lotereya şərtləri',
                url: '/lotereya/katilim-kosullari',
                target: '_blank',
                cssClass: 'nav-link headerLink'
              },
            },
            { // Winners
              fieldId: 'ikz8vc',
              fieldType: 'anchor',
              properties: {
                text: 'Qazananlar',
                url: '/lotereya/tiraj1-qazananlar',
                target: '_blank',
                cssClass: 'nav-link headerLink'
              },
            },
            // { // Privacy Notice
            //   fieldId: 'slnmdk',
            //   fieldType: 'anchor',
            //   properties: {
            //     text: 'Fərdi məlumatların istifadə qaydaları',
            //     url: '/lotereya/privacy-notice',
            //     target: '_blank',
            //     cssClass: 'nav-link headerLink'
            //   }
            // },
            // { // Consent
            //   fieldId: 'ikz8vc',
            //   fieldType: 'anchor',
            //   properties: {
            //     text: 'Məxfilik Bildirişi',
            //     url: '/lotereya/consent',
            //     target: '_blank',
            //     cssClass: 'nav-link headerLink'
            //   }
            // },
            // { // FAQ
            //   fieldId: 'slnmdk',
            //   fieldType: 'anchor',
            //   properties: {
            //     text: 'Tez-tez verilən suallar',
            //     url: '/lotereya/sss',
            //     target: '_blank',
            //     cssClass: 'nav-link headerLink'
            //   }
            // }
          ],
          showLine: false,
          meta: [
            {
              name: 'title',
              content: 'P&G Türkiye - Fırsat Yanında'
            },
            {
              name: 'description',
              content: `P&G Türkiye - Fırsat Yanında Para İadesi Kampanyası. Belirli satış noktalarından alacağınız toplamda 100 TL P&G ürünü alışverişlerinize 100 TL para iadesi kampanyası.`
            },
            {
              name: 'keywords',
              content: `PG, P&G, Firsat Yaninda, P&G Türkiye`
            }
          ]
        }
      },
      footer: {
        fieldType: 'footer-component',
        fieldId: '4tfbzz',
        properties: {
          logo: {
            imageSource: '/assets/images/logo@1x.png',
            imageSource2x: '/assets/images/logo@2x.png',
            width: 75,
            alternateText: 'Brand Logo',
            paddingTop: '30px'
          },
          // links: [
          //   {
          //     name: 'Hüküm ve Koşullar',
          //     link: 'http://www.pg.com/tr_TR/terms_conditions/index.shtml'
          //   },
          //   {
          //     name: "P&G'ye ulaşın",
          //     link: 'https://pgconsumersupport.secure.force.com/CarehubStandalone?Country=tr&Language=tr&Brand=Kadinlar+Bilir#brandListDiv'
          //   },
          //   {
          //     name: 'Gizlilik',
          //     link: 'https://privacypolicy.pg.com/tr-TR/'
          //   },
          //   {
          //     name: 'Benim Verilerim',
          //     link: 'https://preferencecenter.pg.com/tr-tr/'
          //   },
          //   {
          //     name: 'Hakkımızda',
          //     link: 'https://www.kadinlarbilir.com/hakkimizda'
          //   },
          //   {
          //     name: "Aydınlatma Metni",
          //     link: "/pg-aydinlatma-metni"
          //   }
          // ],
           pgText: "Dəstək şöbəmiz sizə xidmət göstərəcək: <a href='mailto:info@firsatyaninda.com'>info@firsatyaninda.com</a>"
        }
      },
      dictionary: {
        az: {
          messages: {
            required: (field) => `${field} Bu xananın qeyd olunması zəruridir.`,
            mandatory: 'Bu xananın qeyd olunması zəruridir.',
            alpha_spaces: (field) => `${field} sadece harf içerebilir.`,
            email: 'Geçerli bir e-posta adresi giriniz.',
            password: 'Şifreniz en az 8 karakterden oluşmalı, en az bir büyük harf, bir küçük harf ve bir sayı içermelidir.',
            confirmPass: 'Şifreler aynı değildir.',
            postal: 'Posta kodu 5 rakamdan oluşmalıdır.',
            plz: 'Posta kodu 5 rakamdan oluşmalıdır.',
            street: 'Yalnızca harf, boşluk, eğik çizgi ve kısa çizgi içerebilir',
            iban: "Lütfen katılımcı ülkelerin geçerli bir IBAN'ını kullanınız",
            phoneNumber: 'Lütfen 5XXXXXXXXX formatı ile geçerli bir telefon numarası giriniz.',
            phoneNumberAz: 'Zəhmət olmasa 9 rəqəmli telefon nömrənizi yazın.',
            hausnr: 'Bina numarası yanlıştır.',
            smsCodeLength: 'Doğrulama kodu 4 basamaklı olmalıdır.',
            fiscalId: 'Fiscal ID 12 haneli olmalıdır.'
          },
          names: {
            iban: 'IBAN',
            gender: 'Cinsiyet',
            bic: 'BIC',
            firstname: '',
            name: '',
            firstName: '',
            lastname: '',
            surname: '',
            lastName: '',
            email: 'E-mail',
            birthdate: 'Doğum tarihi',
            tckn: 'TC Kimlik Numarası',
            password: 'Şifre',
            newPassword: 'Şifre',
            confirmPassword: 'Tekrar Şifre',
            newPasswordConfirm: 'Tekrar Şifre',
            strasse: 'Sokak',
            hausnr: 'Bina numarası',
            plz: 'Posta kodu',
            ort: 'Şehir',
            city: 'İl',
            address: 'Adres',
            marital: 'Medeni hal',
            kids: 'Çocuk',
            area: 'Mahalle',
            district: 'İlçe',
            land: 'Ülke',
            phoneNumber: '',
            code: 'Kod',
            terms: '',
            termsAccepted : '',
            isOverEighteen: '',
            receiveEmails: 'Kullanım ve gizlilik politikası şartları',
            fiscalId: 'Fiscal ID'
          }
        }
      },
      errors: {
        tr: {
          default: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
          noEmail: 'Bu e-posta adresine sahip bir hesap yok.',
          emailUsed: 'Bu e-mail adresi zaten kullanımda.',
          incorrect: 'Geçersiz kullanıcı adı veya yanlış şifre. Lütfen tekrar deneyin!',
          invalidCode: 'Kullanmaya çalıştığınız bağlantının süresi zaten dolmuş. Lütfen yeni bir parola sıfırlama bağlantısı isteyin.',
          manyTries: 'Fiş çok fazla yüklendiğinden bir hata oluştu.',
          generateCode: 'Telefon numarasına izin verilmediğinden doğrulama kodu gönderilemedi. Lütfen girdiğiniz numarayı kontrol edin ve tekrar deneyin.',
          wrongCode: 'SMS kodu yanlış',
          network: 'Hata. Sunucuda bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.'
        }
      },
      url: 'firsatyaninda.com/lotereya',
      name: 'pg-professional-cashback'
    },
    componentStatus: {},
    modal: {
      type: 'error',
      message: 'Oops. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
      isActive: false
    },
    customerToken: '',
    receiptToken: '',
    receiptImage: '',
    campaignId: 'aze-lottery-pg2',
    formData: new FormData(),
    campaignEndDate: '2024-09-13',
    scannerActive: false,
    file: {},
    barcode: {
      code: '',
      format: ''
    },
    productCode: '',
    searchStatus: null,
    imgList: [],
    errorMessage: 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?',
    errorImageList: ['', ''],
    errorActivate: false,
    scannedProducts: [],
    types: [
      'ean_reader',
      'code_128_reader',
      'code_39_reader',
      'ean_8_reader',
      'upc_reader',
      'upc_e_reader',
      'i2of5_reader',
      '2of5_reader',
      'code_93_reader',
      'codabar_reader'
    ],
    pageToBack: '',
    receiptBarcode: '',
    participantIsCompany: false,
    routeAfterReceipt: {
      company: 'sirket-banka-bilgileri',
      individual: 'banka-bilgileri'
    },
    
  }
  