<template>
  <div>
    <div v-if="list.length !== 0" class="barcode-scanner__products d-flex center">
      <div  class="barcode-scanner__products-list" :style="styles">
        <div class="barcode-scanner__products-list_item d-flex"
          v-for="item in list"
          :key="item.id"
        >
          <div class="row" style="width: 100%">
            <div class="col-sm-6 my-2">
              <div class="d-flex">
                <img v-if="item.logo" :src="item.logo" class="short-logo" alt="Logo"/>
                <img v-else src="../../assets/P_G_Logo_RGB.svg" class="short-logo" alt="PG logo"/>
                <div class="item-content d-flex">
                  <div>{{item.name}}</div>
                  <div>EAN: {{item.ean}}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 my-2">
              <b-input-group>
                <template #prepend>
                  <b-button
                    v-show="list.length < maxProductCount"
                    size="sm"
                    variant="info"
                    @click="editProduct(item, 'remove')"/>
                </template>
                <b-form-input
                  type="number"
                  max="99"
                  size="sm"
                  v-model="item.count"
                  variant="info"
                  @change="editInputQuantityProduct({ productId: item.id, count: item.count, maxProductCount })"
                />
                <template #append>
                  <b-button
                    v-show="list.length < maxProductCount"
                    size="sm"
                    variant="info"
                    @click="editProduct(item, 'add')"/>
                  <b-button
                    v-if="showEditButton"
                    size="sm"
                    @click="goToSelectProducts()"
                    class="btn-edit"
                  >
                    <img src="@/assets/icons/pencil.svg" alt="icon">
                  </b-button>
                  <b-button
                    size="sm"
                    @click="removeProduct(item.id)"
                    variant="danger"
                  >
                    <b-icon icon="trash-fill" />
                  </b-button>
                </template>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  v-if="showReturnBackButton && list.length === 0" :style="grid" style="justify-content: center; display:flex">
      <buttonComponent
        class="btn next-btn"
        @click.native="goToSelectProducts()"
        :textColor="white"
        text="Produkte hinzufügen"
        backgroundColor="var(--brand-color)"
        borderColor="var(--brand-color)"
        :borderRadius='"50rem"'
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import buttonComponent from "@/components/renderers/button-component.vue";

export default {
  name: 'productsList',
  components: {
    buttonComponent
  },
  props: {
    styles: {
      type: Object,
      required: false
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    showReturnBackButton: {
      type: Boolean,
      default: false
    },
    maxProductCount: Number
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      list: state => state.scannedProducts
    })
  },
  methods: {
    ...mapActions(["removeProduct", "editQuantityProduct", "editInputQuantityProduct"]),
    editProduct (product, action) {
      let data = { product, action };
      this.editQuantityProduct(data);
      if (!product.count) {
        this.removeProduct(product.id);
      }
    },
    goToSelectProducts () {
      this.goToPage({
        pageName: 'productSelection',
        shouldBack: true
      }, this)
    }
  }
}
</script>

<style scoped lang="scss">
.barcode-scanner__products-list {
  width: 100%;
  padding: 30px 0 0 40px;

  @media (max-width: 767px) {
    padding: 0;
  }
  &_item {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    div {
      align-items: center;
    }
    .item-content {
      flex-direction: column;
      padding-left: 10px;
      align-items: flex-start;
      font-size: 12px;
    }
  }
  .circle {
    display: flex;
    width: 44px;
    height: 44px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #9ADFE6;
    border-radius: 2em;
  }
}

.select-none {
  user-select: none;
}

.short-logo {
  width: 36px;
}

.btn-info {
  width: 44px;
  height: 44px;
  border-radius: 100% !important;
  font-size: 0;
  line-height: 1;
  background-color: #B3D4FC;
  border: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }

  .input-group-prepend &{
    background-image: url("../../assets/icons/minus.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .input-group-append &{
    background-image: url("../../assets/icons/plus.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.btn-danger {
  background: 0;
  border: 0;
  border-radius: 0;
  color: #6F6F6F;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
}
.btn-edit {
  background: 0;
  border: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
  img {
    width: 70%;
  }
}

.input-group {
  justify-content: flex-end;
  .form-control {
    border: 0;
    padding: 0;
    margin: 0 5px;
    text-align: center;
    flex: 0 1 26%;
    &:focus {
      outline: 0 !important;
      box-shadow: none;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
