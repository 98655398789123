<template>
  <div class="radioInputWrapper pb-3" :style="`--brand-color: ${colorSchema}`">
    <ValidationProvider :rules="validation" v-slot="{ errors }">
      <div class="inputWrapper" :style="styles">
        <div
          class="custom-control custom-radio radio-input pl-5"
          v-for="option in options"
          :key="option.name"
        >
          <input
            v-model="localSelected"
            class="custom-control-input"
            type="radio"
            :name="name"
            :id="option.name"
            :value="option.value"
            checked
            :disabled="option.disabled"
          />
          <label class="custom-control-label" :for="option.name">{{option.text}}</label>
        </div>
      </div>
      <div class="validation-error radio-button-error" v-if="errors.length">
        <img class="error_min" src="../../assets/icons/form/error.svg" alt="Error icon" />
        {{errors[0]}}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: "radio-input",
  data () {
    return {
      localSelected: this.selectedValue || ""
    };
  },
  props: {
    field: {
      required: false,
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      required: true
    },
    fieldId: {
      type: String,
      required: true
    },
    selectedValue: {
      type: String,
      required: false,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    direction: {
      type: String,
      required: false,
      default: "row"
    },
    colorSchema: {
      type: String,
      default: "#000"
    },
    styles:{
      type: Object,
      default: ()=>{}
    },
    name: {
      type: String,
      default: ""
    }
  },
  watch: {
    localSelected (value) {
      this.$emit("component-updated", {
        selectedValue: value
      });
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss" scoped>
$medium: 991px;
.radioInputWrapper {
  @media only screen and (max-width: $medium) {
    // margin-left: 30px;
    // margin-right: 20px;
  }
  padding: 5px;
  .inputWrapper {
    display: flex;
    .radio-input {
      margin: 3px;
    }
  }
}
input[type="radio"] + label {
  // color: #C4C4C4;
}
.custom-radio {
  padding-left: 35px;
  margin-right: 20px;

  @media only screen and (max-width: $medium) {
    padding-left:  30px !important;
    margin-right: 0px;
  }
}
input[type="radio"] + label:before {
  border: 1px solid var(--brand-color) ;
  box-shadow: none !important;
  width: 20px;
  height: 20px;
  top: 0;
  left: -35px;
}

.custom-control {
  @media only screen and (max-width: $medium) {
    padding-right:  5px;
  }
}

.custom-control-label {
  text-align: center;
  font: normal normal 300 16px/22px Montserrat;
  letter-spacing: 0px;
  color: #1B1B1B;
  opacity: 1;

  @media only screen and (max-width: $medium) {
    padding-left:  0px;
  }
}

.custom-control-label::after,
.custom-control-label::before {
  width: 20px;
  height: 20px;
  top: 0;
  left: -35px;
  margin-left: 5px;
  margin-top: 3px;
}
.custom-control-label::after {
  background-image: none !important;
  background-color: var(--brand-color) ;
}
.custom-control-input[type="radio"]:checked ~ .custom-control-label::before {
  background-image: url('../../assets/icons/form/checkBoxTick.svg');
  background-position-x: -1px;
  background-position-y: -1px;
  background-color: var(--brand-color);
  border: 1px solid var(--brand-color) ;
}
.custom-control-input[type="radio"]:active ~ .custom-control-label::before {
    background-color: var(--brand-color);
    opacity: 0.4;
    border: 1px solid var(--brand-color) ;
  }

.custom-checkbox-dop {
  position: absolute;
  bottom: 5px;
  right: 10px;
  .custom-control-label {
    &:after,
    &:before {
      left: -20px;
    }
  }
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.custom-control-input[type="radio"] {
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 0;
}

.radio-button-error {
  position: static !important;
  margin-left: 35px !important;
}
</style>
