<template>
  <div class="sms-wrapper">
    <ValidationObserver
      id="numberForm" 
      ref="numberForm"
      class="col-6"
      :class="{ 'active': state == 'number' }"
    >
      <div class="inner">
        <div class="label">
          Enter phone number*
        </div>
        <div class="inputWrapper">
          <div
            ref="selectWrapper"
            class="col-lg-4"
          >
            <v-select
              :options="options"
              label="name"
              value="code"
              :clearable="false"
              :searchable="false"
              v-model="selectedCountry"
            >
              <template
                #option="{ code, phoneCode }"
              >
                <div class="country-item">
                  <div>
                    <country-flag
                      :country="code"
                      size="normal"
                    />
                  </div>
                  <div>
                    <b>{{ phoneCode }}</b>
                  </div>
                </div>
              </template>
              <template
                #selected-option="{ code, phoneCode }"
              >
                <div style="display: flex; align-items: center; column-gap: 10px;">
                  <div>
                    <country-flag
                      :country="code"
                      size="normal"
                    />
                  </div>
                  <div><b>{{ phoneCode }}</b></div>
                </div>
              </template>
            </v-select>
          </div>
          <div class="col-lg-8">
            <textInput
              id="phoneNumber"
              v-model="tel"
              name="phoneNumber"
              class="phoneNumber col-lg-12"
              label=""
              placeholder="Mobile phone number"
              :validation="`required|phoneNumber`"
              :label-on-top="false"
              :no-label="true"
              :styles="inputStyles"
              :slide-up="tel != ''"
            />
          </div>
        </div>
        <div class="btn-wrapper">
          <button-component
            text="Get SMS Code"
            addClass="btn-123"
            :requires="['phoneNumber']"
            :action="{
              fn: 'sendSms',
              params: ''
            }"
          />
        </div>
      </div>
    </ValidationObserver>
    <ValidationObserver
      ref="codeForm"
      id="codeForm"
      class="col-6"
      :class="{ 'active': state == 'code' }"
    >
      <div class="inner">
        <div class="label">
          Enter verification code*
        </div>
        <v-otp-input
          input-classes="otp-input"
          :num-inputs="4"
          separator=""
          :should-auto-focus="true"
          @on-change="handleOnChange"
        />
        <div
          class="label"
        >
          We sent you a verification code via sms
        </div>
        <div
          class="label"
        >
          Didnt receive it? <a @click="state = 'number'">Click here</a>
        </div>
        <div class="btn-wrapper">
          <button-component
            text="SUBMIT"
            addClass="btn-123"
            :requires="['code']"
            :action="{
              fn: 'verifySms',
              params: ''
            }"
          />
        </div>
        <div
          class="label"
        >
          <a @click="state = 'number'">Change sms number</a>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import textInput from "@/components/renderers/text-input.vue";
import CountryFlag from 'vue-country-flag'
import { mapMutations } from 'vuex';
import VOtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "SmsVerification123",
  components: {
    textInput,
    // CoolSelect,
    VOtpInput,
    CountryFlag
  },
  props: {
    successAction: {
      type: Object,
      default: () => {}
    },
    endpoint: {
      type: String,
      default: '/mobileAuth'
    },
    endpoint2: {
      type: String,
      default: '/mobileVerification'
    },
    onSuccess: {
      type: Object,
      default: () => {}
    },
    inputStyles:{
      type:Object,
      required:false,
      default:()=>{}
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedCountry: "",
      tel: "",
      code: "",
      state: "number"
    };
  },
  watch: {
    selectedCountry (val) {
      this.countryCode = val.phoneCode;
    }
  },
  methods: {
    ...mapMutations(['setCountryCode', 'setTel']),
    async sendSMS () {
      if (this.$route.query.version == 'cashback-123') {
        return this.state = "code"
      }
      const isValid = await this.$refs.numberForm.validate();
      if(!isValid) {
        this.scrollTo(`[id=numberForm`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      }
      const formData = {
        mobile: `${this.countryCode}${this.tel}`
      };
      this.$store.dispatch("generateCode", { formData, endpoint: this.endpoint })
        .then(() => {
          this.setCountryCode(this.countryCode);
          this.setTel(this.tel);
          this.state = "code";
          this.code = ''
        })
        .catch (() => {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        })
    },
    async verify () {
      if (this.$route.query.version == 'cashback-123') {
        this.$emit("component-updated")
        const fn = this.successAction.fn;
        const params = this.successAction.params;
        return this[fn](params, this);
      }
      const isValid = await this.$refs.codeForm.validate();
      if (!isValid) {
        this.scrollTo(`[id=codeForm`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      }

      const formData = {
        mobile: `${this.countryCode}${this.tel}`,
        code: this.code
      };
      this.$store.dispatch("verifyCode", { formData, endpoint: this.endpoint2 })
        .then(r => {
          if (this.keepInState) {
            this.keepInState.forEach(element => {
              this.$store.commit("setField", { field: element, value: r[element] })
            });
          }
          this.$emit("component-updated")
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
        })
        .catch(err => {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        });
    },
    handleOnChange(value) {
      this.code = value;
      this.$store.dispatch("setComponentStatus", {
        component: 'code',
        status: value && value.length == 4
      });
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
  mounted () {
    this.$eventHub.$on('sendSms-event', this.sendSMS)
    this.$eventHub.$on('verifySms-event', this.verify)
    this.selectedCountry = this.options[0]
  },
  beforeDestroy () {
    this.$eventHub.$off('sendSms-event')
    this.$eventHub.$off('verifySms-event')
  }
};
</script>
<style lang="scss" scoped>

.sms-wrapper {
  display: flex;
  justify-content: center;
  > span {
    border-top: 46px solid #5D5FEF;
    opacity: .5;
    display: flex;
    justify-content: center;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 50px 0 20px 0;
      max-width: 80%;

      .label {
        margin: 10px;
      }
      
      .btn-wrapper {
        margin-top: 40px;
        width: 100%;
        text-align: center;
        padding: 10px;
      }
    }
  }
}

.inputWrapper {
  display: flex;
  justify-content: center;
  div {
    margin: 0;
    padding: 0;
  }
}

.country-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
:deep(.vs__search) {
  display: none;
}

:deep(.otp-input) {
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #7879F1;
  border-radius: 4px;
  text-align: center;
  margin: 5px;
}

a {
  color: #7879F1 !important;
  text-decoration: underline !important;
}
</style>
