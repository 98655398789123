<template>
  <div class="rewards-container">
    <reward
      v-for="reward in rewards"
      :reward="reward"
      :type="reward.type"
      @itemClick="itemClick(reward)"
      :key="reward.id"
      :selected="isSelected(reward)"
      :class="{
        active: isSelected(reward),
        'not-active': selectedItems.length > 0 && !isSelected(reward),
      }"
      :color-schema="colorSchema != '' ? colorSchema : '#8B8B8B'"
      :css-string="cssString"
      style
    />
    <div
      class="scroll-btn"
      v-if="selectedItems.length > 0"
      @click.prevent="scrollDown"
    >
      <span id="scroll-btn" class="material-icons md-48 d-md-flex d-none"
        >arrow_circle_down</span
      >
    </div>    
    <section data-section="r0924fw" class="section-container row col-10 col-lg-12 col-sm-12 col-md-12" style="margin-bottom: 35px; margin-top: 40px; justify-content: center;">
      <div style="justify-content: center; align-items: center; column-gap: 30px;;" class="row col-lg-10 col-lg-10 component-wrapper">
        <button-component class="btn  col-sm-4 col-xl-3 backBtn col-lg-4" text="Geri" :action="{fn: 'goToPage', params: this.backUrl}" />
        <button-component class="btn  col-sm-4 col-xl-3 nextBtn col-lg-4" text="İleri" :disabled="!this.selected.length" :action="{fn: 'sendReward', params: ''}" />
      </div>
    </section>

  </div>
  
</template>

<script>
import reward from "./reward.vue";

export default {
  name: "reward-selection",
  components: { reward },
  props: {
    rewards: {
      type: Array,
      required: true,
    },
    endpoint: {
      type: String,
      default: "/chooseReward",
    },
    backUrl : {
      type: String,
      default: "/",
      required: true,
    },
    successAction: {
      type: Object,
      required: true,
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B",
    },
    maxSelectable: {
      required: false,
      type: [String, Number],
      default: -1,
    },
    texts: {
      required: false,
      type: Object,
      default: () => {},
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selected: {
      type: [Array, Object],
      default: () => [],
    },
    fieldId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cssString: "",
      selectedItems: this.selected,
      isActive: false
    };
  },
  watch: {
    styles: {
      immediate: true,
      handler() {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      },
    },
  },
  methods: {
    clear() {
      this.selectedItems = [];
    },
    itemClick(reward) {
      let maxSelectableItem = parseInt(this.maxSelectable);
      let isSelectedBefore = this.selectedItems.findIndex((selectedItem) => {
        return selectedItem.id == reward.id;
      });
      if (reward.selectable) {
        if (isSelectedBefore != -1) {
          this.selectedItems.splice(isSelectedBefore, 1);
        } else {
          // This will be use if user selected before (NOT READY YET!)
          // this.selectedItems.forEach(selectedItem => {
          //   if(maxSelectableItem != -1 && selectedItem.selectable == false) {
          //     maxSelectableItem = maxSelectableItem + 1
          //   }
          // });
          if (
            maxSelectableItem != -1 &&
            this.selectedItems.length >= maxSelectableItem
          ) {
            return this.showModal(this.texts.maxSelectable);
          }
          this.selectedItems.push(reward);
          this.$emit("component-updated", {
            selected: this.selectedItems,
          });
        }
      }
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: !!this.selectedItems.length,
      });
      // this.$emit("input", this.selectedItems);
    },
    isSelected(reward) {
      const isSelected = this.selectedItems.find((item) => {
        return item.id == reward.id;
      });
      return !!isSelected;
    },
    sendReward() {
      if (!this.selectedItems.length) {
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false,
          message: `<div class='pb-5'>${this.texts.required}</div>`,
        });
        return;
      }
      const endpoint = this.endpoint;
      const formData = {
        type: this.selectedItems[0].type
      };
      this.$store.dispatch("sendReward", { formData, endpoint }).then((r) => {
        if (r.response) {
          // console.log(this.successAction, this);
          const fn = this.successAction.fn;
          const params = this.selectedItems[0].type === 'cashback' ? this.successAction.params : 'gillettetirasineniyilerinde/fuel-sms-sayfasi';
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true,
          });
          return;
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false,
        });
      });
      // console.log("inside send products", this.selectedItems);
    },
    scrollDown() {
      window.scrollTo({
        top: document.getElementsByClassName("rewards-container")[0]
          .clientHeight,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    /*
       add get products call to api and set the products to selectable or not.
    */
    this.$eventHub.$on("sendReward-event", this.sendReward);
  },
  beforeDestroy() {
    this.clear();
    this.$eventHub.$off("sendReward-event");
  },
};
</script>

<style lang="scss" scoped>
.rewards-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 30px;
  margin-top: 30px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
  div {
    flex: 1 1 20%;
    box-sizing: border-box;
    align-self: stretch;
  }
}

.scroll-btn {
  @media (min-width: 767px) {
    display: none;
  }
  transition-delay: 1s;
  span {
    cursor: pointer;
  }
  position: fixed;
  bottom: 35vh;
  left: 80vw;
  text-align: right;
}
</style>
