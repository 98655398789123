<template>
  <span></span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: "check-token",
  props: {
    variable: Object,
    endpoint: String,
    exceptionPages: Array
  },
  mounted () {
    if (this.$route.query[this.variable.query]) {
      let field = this.variable.state
      let value = this.$route.query[this.variable.query]
      this.setField({ field , value })
      this.sendToken({ endpoint: this.endpoint, value })
        .catch(ex => {
          setTimeout(() => {
            window.location.href = '/'
          }, 3000);
        })
    } else {
      if (!this.exceptionPages.some(x => location.pathname.includes(x))) {
        window.location.href = '/'
      }
    }
  },
  methods: {
    ...mapMutations(['setField']),
    ...mapActions(['sendToken'])
  }
};
</script>
