<template>
  <span></span>
</template>

<script>
export default {
  name: "refresh-token",
  mounted() {
    this.$store.dispatch("refreshTempId", { query: this.$route.query, endpoint: this.endpoint });
  },
  props: {
    endpoint: {
      type: String,
      default: ''
    }
  }
};
</script>
