<template>
  <div
    class="receipt-uploader "
    :style="[styles, cssVars]"
    :class="className"
  >
    <div>
      <div
        class="wrapper_file_upload"
        :style="{ display: selectedFile ? 'none' : 'flex'}"
        :class="{ highlight: highlight }"
        @dragover.prevent
        @drop.stop.prevent="handleDrop"
        @dragenter.stop.prevent="onDragEnter"
        @dragleave.stop.prevent="onDragLeave"
      >
        <label
          class="file_upload"
          tabindex="0"
          @keypress.enter="selectAnother"
        >
          <input
            :disabled="hasUnresolvedDependencies"
            accept="image/*, application/pdf"
            id="uploaded-file"
            class="uploaded-file"
            type="file"
            name="file"
            ref="image"
            @change="onFilePicked"
          />
          <img
            src="@/assets/images/svg/upload_icon.svg"
            alt=""
          >


          <div
            class="text1"
            v-html="this.texts.receiptUpload1"
          ></div>
          <div
            class="text2"
            v-html="this.texts.receiptUpload2"
          ></div>
        </label>
      </div>

      <div
        v-show="!!selectedFile"
        class="receipt-widget"
      >
        <Receipt-Scanner
          :key="attempts"
          v-model="receipt"
          class="receipt-scanner"
          :api-url="apiUrl"
          ref="receiptScanner"
          :file="selectedFile"
          @input="onInput($event)"
          v-bind="$props"
          :show="!!selectedFile"
          :color-schema="colorSchema"
        >
          <template slot="crop">
            <span
              :title="cropTitle"
              v-b-tooltip
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 14 14"
                :fill="colorSchema"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.499086 4.23583C-0.166362 4.23583 -0.166362 3.21207 0.499086 3.21207H3.21207V0.499086C3.21207 -0.166362 4.23583 -0.166362 4.23583 0.499086V3.21207H10.2249C10.532 3.21207 10.7367 3.46801 10.7367 3.72395V9.71298H13.5009C14.1664 9.71298 14.1664 10.7367 13.5009 10.7367H10.7367V13.5009C10.7367 14.1664 9.71298 14.1664 9.71298 13.5009V10.7367H3.72395C3.46801 10.7367 3.21207 10.532 3.21207 10.2249V4.23583H0.499086ZM9.71298 9.71298V4.23583H4.23583V9.71298H9.71298Z" />
              </svg>
            </span>
          </template>
          <template slot="drag">
            <span
              :title="eraseTitle"
              v-b-tooltip
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 14 14"
                :fill="colorSchema"
                xmlns="http://www.w3.org/2000/svg"
                class="feather feather-move"
              >
                <path
                  d="M14 6.99998C14 6.99998 14 6.99998 14 6.9655C14 6.82757 13.931 6.70688 13.8448 6.62067L11.6897 4.46551C11.4655 4.25861 11.1379 4.27585 10.931 4.49999C10.7414 4.70688 10.7414 5.03447 10.931 5.24137L12.1724 6.48274H7.53448V1.82758L8.77586 3.06896C8.98276 3.29309 9.32759 3.29309 9.53448 3.10344C9.75862 2.89654 9.75862 2.55172 9.56897 2.34482C9.55172 2.32758 9.55172 2.32758 9.53448 2.31034L7.37931 0.155172C7.17241 -0.051724 6.82759 -0.051724 6.62069 0.155172L4.46552 2.31034C4.24138 2.51723 4.24138 2.84482 4.43103 3.06896C4.63793 3.29309 4.98276 3.29309 5.18966 3.10344C5.2069 3.0862 5.2069 3.0862 5.22414 3.06896L6.46552 1.82758V6.44826H1.82759L3.06897 5.20688C3.27586 4.98275 3.25862 4.65516 3.03448 4.44826C2.82759 4.25861 2.5 4.25861 2.2931 4.44826L0.155172 6.62067C-0.0517241 6.82757 -0.0517241 7.17239 0.155172 7.37929L2.31034 9.53446C2.53448 9.74135 2.86207 9.72411 3.06897 9.49997C3.25862 9.29308 3.25862 8.96549 3.06897 8.7586L1.82759 7.53446H6.44828V12.1724L5.2069 10.931C4.98276 10.7241 4.65517 10.7414 4.44828 10.9655C4.25862 11.1724 4.25862 11.5 4.44828 11.7069L6.60345 13.862L6.67241 13.8965C6.7069 13.931 6.74138 13.9482 6.77586 13.9655C6.84483 14 6.91379 14 6.98276 14C7.12069 14 7.24138 13.931 7.32759 13.8448L9.48276 11.6896C9.68966 11.4655 9.67241 11.1379 9.44828 10.931C9.24138 10.7414 8.91379 10.7414 8.7069 10.931L7.51724 12.1724V7.53446H12.1379L10.8966 8.77584C10.6724 8.98273 10.6724 9.32756 10.8621 9.53446C11.069 9.75859 11.4138 9.75859 11.6207 9.56894C11.6379 9.5517 11.6379 9.5517 11.6552 9.53446L13.8103 7.37929C13.8276 7.34481 13.8621 7.32757 13.8793 7.29308C13.8966 7.27584 13.9138 7.24136 13.931 7.20688C13.9828 7.13791 14 7.06895 14 6.99998Z"
                  :fill="colorSchema"
                />
              </svg>
            </span>
          </template>
          <template slot="erase">
            <span
              :title="dragTitle"
              v-b-tooltip
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 15 15"
                :fill="colorSchema"
                xmlns="http://www.w3.org/2000/svg"
                class="feather feather-edit-2"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.0205 1L1.96954 10.051L1.03782 13.6447L4.63159 12.713L13.6826 3.66205L11.0205 1Z"
                  :stroke="colorSchema"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </template>
          <template slot="zoomIn">
            <span
              :title="zoomInTitle"
              v-b-tooltip
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                :fill="colorSchema"
                xmlns="http://www.w3.org/2000/svg"
                class="feather feather-zoom-in"
              >
                <path
                  d="M1 7H13M7 1V13V1Z"
                  :stroke="colorSchema"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </template>
          <template slot="zoomOut">
            <span
              :title="zoomOutTitle"
              v-b-tooltip
            >
              <svg
                width="14"
                height="4"
                viewBox="0 0 14 2"
                :fill="colorSchema"
                xmlns="http://www.w3.org/2000/svg"
                class="feather feather-zoom-out"
              >
                <path
                  d="M1 1L13 1"
                  :stroke="colorSchema"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </template>
          <template slot="otherBtn">
            <button
              v-if="receiptImage"
              @click.prevent="selectAnother"
              class="btn btn-upload"
            >
              {{ this.texts.choose }}
            </button>
          </template>
        </Receipt-Scanner>
      </div>
    </div>
  </div>
</template>

<script>
import { ReceiptScanner } from "../receipt-editor";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "ReceiptProcessor",
  components: {
    ReceiptScanner
  },
  data () {
    return {
      receipt: null,
      apiUrl: "https://receipt.justsnap.eu", // api that will be used to retrieve "image points"
      selectedFile: null,
      processedFile: null,
      uploadSectionHover: false,
      highlight: false,
      attempts: 0,
      selectedValidate: false,
      processedValidate: false,
      isTestVersion: false
    };
  },
  props: {
    successAction: {
      type: Object,
      default: () => {}
    },
    fieldId: {
      type: String,
      default: ""
    },
    requires: {
      type: Array,
      default: () => []
    },
    phrases: {
      type: Array,
      default: () => []
    },
    cornerDetectionPhrases: {
      type: Array,
      default: () => []
    },
    cropTitle: {
      type: String,
      default: "Crop"
    },
    eraseTitle: {
      type: String,
      default: "Erase"
    },
    dragTitle: {
      type: String,
      default: "Drag"
    },
    selectAnotherTitle: {
      type: String,
      default: "Select another"
    },
    zoomInTitle: {
      type: String,
      default: "Zoom in"
    },
    zoomOutTitle: {
      type: String,
      default: "Zoom out"
    },
    skipCornerDetection: {
      type: Boolean,
      default: false
    },
    postBillUrl: {
      type: String,
      default: "https://receipt.justsnap.eu/postBill"
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    reUpload: {
      required: false,
      type: Boolean
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    selected: [Blob,File],
    styles: {
      type: Object,
      default: () => {}
    },
    subCampaignId: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  watch: {
    processedFile (value) {
      this.processedValidate = !!value;
    },
    selectedFile (value) {
      this.selectedValidate = !!value;
    },
    isImageCorrect (val) {
      this.$eventHub.$emit('canProceedNext', val)
    }
  },
  mounted () {
    this.selectedFile = this.receiptImage === '' ? null : (this.selected || null)
    this.$eventHub.$on("sendReceipt-event", this.send);
    this.isTestVersion = this.$route.query.version == 'test';
  },
  computed: {
    ...mapGetters(['receiptImage', 'campaignId', 'customerToken', 'receiptToken']),
    isImageCorrect () {
      return (this.processedValidate && this.selectedValidate);
    },
    hasUnresolvedDependencies () {
      return this.$store.getters.hasUnresolvedDependencies(this.requires);
    },
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  },
  methods: {
    ...mapActions(['sendRequest']),
    send () {
      let data = {
        image: this.receipt,
        apiUrl: this.postBillUrl,
        customerToken: this.customerToken,
        campaignId: "pg-turkey",
        subCampaignId: this.campaignId ? this.campaignId : this.subCampaignId
      }; // receipt info

      if (this.reUpload) {
        data["receiptToken"] = this.receiptToken;
        data["customerToken"] = 1;
        data["campaignId"] = "pg-turkey-re";
      }
      this.$store.dispatch("uploadReceipt", data).then(r => {
        const fn = this.successAction.fn;
        const params = this.successAction.params;
        this[fn](params, this);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: true
        });
      }).catch(err => {
        console.log("Upload Receipt error: ", err);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      })
    },
    onFilePicked (event) {
      const files = event.target.files || event.dataTransfer.files;
      this.handleFiles(files);
    },
    handleDrop (e) {
      let dt = e.dataTransfer;
      let files = dt.files;
      this.handleFiles(files);
    },
    handleFiles (files) {
      const maxfilesize = 1024 * 1024 * 10; // 10 MB

      if (files && files[0]) {
        let filename = files[0].name;

        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }

        if (files[0].size > maxfilesize) {
          this.showModal(this.texts.fileSizeLimit)
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg", "pdf"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          this.showModal(this.texts.wrongFormat)
          return;
        }
        this.attempts++;
        this.$nextTick().then(() => {
          this.selectedFile = files[0];
          this.processedFile = null;
        });
      }
    },
    onDragEnter () {
      this.highlight = true;
    },
    onDragLeave () {
      this.highlight = false;
    },
    onInput (data) {
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
      this.processedFile = data;
      this.$emit("component-updated", {
        selected: data
      });
    },
    selectAnother () {
      this.$refs.image.click();
    }
  },
  beforeDestroy () {
    this.$eventHub.$off("sendReceipt-event");
  }
};
</script>

<style scoped lang="scss">

.az-receipt-processor {
  //max-height: 400px;
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.file_upload input[type="file"] {
  display: none;
}
.receipt-uploader {
  border: 2px solid var(--color);
  border-radius: 8px;
  padding: 0;

  > div {
    overflow: hidden;
    //min-height: 500px;
    height: 100%;
    width: 100%;
    padding: 4vw 2vw;
  }
  @media (max-width: 991px) {
    max-width: 300px;
  }
}

.receipt-scanner /deep/ {
  width: 100%;
  .layout > .container {
    border: 0;
    padding: 0;
  }
  .canvas-container {
    > div {
      display: flex;
      flex-direction: column;

      .controls {
        background: transparent;
        order: -1;

        .btn-group {
          display: flex;
          justify-content: space-around;
          width: 100%;

          @media (max-width: 767px) {
            width: 100%;
          }

          label {
            padding: 0;
            flex-grow: 1;
            background: none;
            border: none;
            cursor: pointer;
          }
        }
      }
      .scanner-bar {
        background: #4f4f4f;
        box-shadow: 0 0 10px #4f4f4f;
      }
      .text {
        p {
          transform: translate(-50%, 0%);
        }
      }
    }
  }
}

.receipt-widget {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
}
.wrapper_file_upload {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  &_prog {
    border-style: solid;
  }
  .file_upload {
    width: 100%;
    text-align: center;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;
    .text {
      &1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
        color: var(--color-1);
        opacity: 1;
        padding-bottom: 15px;
      }

      &2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        color: var(--color-4);
        opacity: 1;
      }
    }
  }
}
.file_upload input[type="file"] {
  display: none;
}
.btn {
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  z-index: 1000;
  @media (max-width: 767px) {
    width: 100%;
    padding: 10px 30px;
  }
  &.btn-upload {
    position: relative;
    bottom: 10px;
    background: #8183F0;
    border-radius: 10px;
    padding: 10px 35px;
    margin-top: 60px;
    color: #fff;

    @media (max-width: 1280px) {
      margin-top: 50px;
    }

    @media (max-width: 1024px) {
      margin-top: 40px;
    }

    @media (max-width: 991px) {
      margin-top: 30px;
    }

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }
}
.highlight {
  background-color: #c4f3f3;
}
.upload-icon {
  margin-bottom: 20px;
}
</style>
