var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sms-wrapper"},[_c('ValidationObserver',{ref:"numberForm",staticClass:"col-6",class:{ 'active': _vm.state == 'number' },attrs:{"id":"numberForm"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"label"},[_vm._v(" Enter phone number* ")]),_c('div',{staticClass:"inputWrapper"},[_c('div',{ref:"selectWrapper",staticClass:"col-lg-4"},[_c('v-select',{attrs:{"options":_vm.options,"label":"name","value":"code","clearable":false,"searchable":false},scopedSlots:_vm._u([{key:"option",fn:function({ code, phoneCode }){return [_c('div',{staticClass:"country-item"},[_c('div',[_c('country-flag',{attrs:{"country":code,"size":"normal"}})],1),_c('div',[_c('b',[_vm._v(_vm._s(phoneCode))])])])]}},{key:"selected-option",fn:function({ code, phoneCode }){return [_c('div',{staticStyle:{"display":"flex","align-items":"center","column-gap":"10px"}},[_c('div',[_c('country-flag',{attrs:{"country":code,"size":"normal"}})],1),_c('div',[_c('b',[_vm._v(_vm._s(phoneCode))])])])]}}]),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('div',{staticClass:"col-lg-8"},[_c('textInput',{staticClass:"phoneNumber col-lg-12",attrs:{"id":"phoneNumber","name":"phoneNumber","label":"","placeholder":"Mobile phone number","validation":`required|phoneNumber`,"label-on-top":false,"no-label":true,"styles":_vm.inputStyles,"slide-up":_vm.tel != ''},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1)]),_c('div',{staticClass:"btn-wrapper"},[_c('button-component',{attrs:{"text":"Get SMS Code","addClass":"btn-123","requires":['phoneNumber'],"action":{
            fn: 'sendSms',
            params: ''
          }}})],1)])]),_c('ValidationObserver',{ref:"codeForm",staticClass:"col-6",class:{ 'active': _vm.state == 'code' },attrs:{"id":"codeForm"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"label"},[_vm._v(" Enter verification code* ")]),_c('v-otp-input',{attrs:{"input-classes":"otp-input","num-inputs":4,"separator":"","should-auto-focus":true},on:{"on-change":_vm.handleOnChange}}),_c('div',{staticClass:"label"},[_vm._v(" We sent you a verification code via sms ")]),_c('div',{staticClass:"label"},[_vm._v(" Didnt receive it? "),_c('a',{on:{"click":function($event){_vm.state = 'number'}}},[_vm._v("Click here")])]),_c('div',{staticClass:"btn-wrapper"},[_c('button-component',{attrs:{"text":"SUBMIT","addClass":"btn-123","requires":['code'],"action":{
            fn: 'verifySms',
            params: ''
          }}})],1),_c('div',{staticClass:"label"},[_c('a',{on:{"click":function($event){_vm.state = 'number'}}},[_vm._v("Change sms number")])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }