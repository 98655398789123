<template>
  <div class="container">
    <div
      :key="idx"
        v-for="(campaign, idx) in campaigns"
        class="visual"
        @click="campaign.url ? openUrl(campaign.url) : ()=>{}"
      >
      <img
        :style="`transform: translateX(calc(-${index * 100}% - ${index * 30}px - 15px)); cursor: ${campaign.url ? 'pointer' : 'default'}`"
        class="desktop"
        :src="campaign.visual"
        alt="Desktop banner"
      />
      <img
        :style="`transform: translateX(calc(-${index * 100}% - ${index * 30}px - 15px)); cursor: ${campaign.url ? 'pointer' : 'default'}`"
        class="mobile"
        :src="campaign.visualMobile"
        alt="Mobile banner"
      />

    </div>
    <div @click="next()" class="arrow arrow-right">
      <img src="/assets/images/rightArrow.svg" alt="Next">
    </div>
    <div @click="prev()" class="arrow arrow-left">
      <img src="/assets/images/leftArrow.svg" alt="Back">
    </div>
    <div class="dotsContainer">
      <div
        class="dot"
        :key="idx"
        @click="selectIndex(idx)"
        :class="{'active': idx === index}"
        v-for="(campaign, idx) in campaigns"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: "campaign-carousel",
  mounted () {
    this.autoScroll();
  },
  data: () => {
    return {
      index: 0,
      autoScrollTime: 5000,
      autoScrollIntervalId: null
    }
  },
  props: {
    campaigns: {
      type: Array,
      required: true
    }
  },
  methods: {
    autoScroll () {
      const intervalId = setInterval(() => {
        this.next()
      }, this.autoScrollTime);
      this.autoScrollIntervalId = intervalId
    },
    resetScroll () {
      clearInterval(this.autoScrollIntervalId)
      this.autoScroll();
    },
    next () {
      if (this.index < this.campaigns.length - 1) {
        this.index = this.index + 1
      } else {
        this.index = 0
      }
      this.resetScroll()
    },
    prev () {
      if (this.index > 0) {
        this.index = this.index - 1
      } else {
        this.index = this.campaigns.length - 1
      }
      this.resetScroll()
    },
    selectIndex (index) {
      this.index = index
      this.resetScroll()
    },
    openUrl (url) {
      window.open(url,"_blank")
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.visual {
  margin: 15px;
  min-width: 100%;
  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    cursor: pointer;
    &.desktop {
      // @media (min-width: 767px) {
      //   display: block;
      // }
      @media (max-width: 766px) {
        display: none;
      }
    }
    &.mobile {
      display: none;
      @media (max-width: 766px) {
        display: block;
      }
    }
  }

}
.arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0,0,0,.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: rgba(0,0,0,.6);
  }
  img {
    width: 25px;
    height: 25px;
  }
}
.arrow-right {
  right: calc(4% + 1px);
  padding-left: 4px;
}
.arrow-left {
  left: calc(4% + 1px);
  padding-right: 4px;
}
.dotsContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
  bottom: 20px;
}
.dot {
  width: 10px;
  height: 10px;
  background-color: var(--brand-light-color);
  border-radius: 50%;
  margin: 5px;
  opacity: 0.5;
  transition: opacity 0.3s;
  transition: transform 0.3s;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
  &.active {
    transform: scale(1.5);
    border: 1px solid #fff;
    opacity: 1;
  }
}
@media (max-width: 991px) {
  .arrow {
    min-width: 35px;
    min-height: 35px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
@media (max-width: 500px) {
  .arrow {
    min-width: 30px;
    min-height: 30px;
    img {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
