
export default {
    isEditMode: true,
    pageProperties: {
      testing: true,
      "pages": [
        { // Home Page
          "pageName": "gillettetirasineniyilerinde",
          "isPublic": true,
          "locale": "tr",
          "pageId": 402,
          "sections": [
            {
              "sectionId": "orbg8s",
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "justifyContent": "center",
                "position": "relative"
              },
              "fields": [
                { // Refresh Token
                  "fieldType": "refresh-token",
                  "fieldId": "ij3433"
                },
                { // DataLayer
                  fieldId: "dte-003",
                  fieldType: "dataLayer-event",
                  properties: {
                    eventName: "PageView",
                    fbEvent: "PageView"
                  }
                },
                { // Key Visual
                  "fieldType": "key-visual",
                  "fieldId": "296eg1",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "styles": {
                      "margin": "0 0 50px 0"
                    },
                    "noContainer": true,
                    "desktopImageUrl": "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
                    "tabletImageUrl": "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
                    "mobileImageUrl": "/assets/gillettetirasineniyilerinde/mobile-banner.jpg"
                  }
                },
                { // Sub Header
                  "fieldType": "paragraph",
                  "fieldId": "vb309g",
                  "properties": {
                    "text": "Kampanya 31 Ağustos 2023 - 01 Temmuz 2024 tarihleri arasında online ve offline tüm alışverişlerde geçerlidir. Detaylı bilgi için <a href='/gillettetirasineniyilerinde/katilim-kosullari' target='_blank'><u>https://firsatyaninda.com/gillettetirasineniyilerinde/katilim-kosullari</u></a> sayfasını ziyaret edebilirsiniz.",
                    "classes": ["subHeading", "text-center"],
                    "styles": {
                      "color": "var(--orkid-color)"
                    },
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginBottom": "40px"
                    }
                  }
                },
                { // Steps Card
                  "fieldId": "fu0923r",
                  "fieldType": "empty-bucket",
                  "properties": {
                    "grid": {
                      "columns": "10",
                      "marginTop": "40px",
                      "marginBottom": "80px"
                    },
                    "className": "col-md-12 stepsCardMobileWithSubHeader",
                    "fields": [
                      {
                        "fieldType": "step-card",
                        "fieldId": "05m65j",
                        "properties": {
                          "stepNumber": "1",
                          "stepStyles": {
                            "background": "rgb(230, 245, 235)",
                            "color": "#009639",
                            "fontFamily": "Jost"
                          },
                          "imageSource": "/assets/steps/altin/1.svg",
                          "arrowImageSource": "/assets/steps/altin/arrow.svg",
                          "grid": {
                            "columns": 4
                          },
                          "fields": [
                            {
                              "fieldType": "paragraph",
                              "fieldId": "psv9ff",
                              "properties": {
                                "color": "var(--orkid-color)",
                                "text": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                                "classes": ["stepCard"]
                              }
                            }
                          ]
                        }
                      },
                      {
                        "fieldType": "step-card",
                        "fieldId": "ec7jsf",
                        "properties": {
                          "stepNumber": "2",
                          "stepStyles": {
                            "background": "rgb(230, 245, 235)",
                            "color": "#009639",
                            "fontFamily": "Jost"
                          },
                          "imageSource": "/assets/steps/altin/2.svg",
                          "arrowImageSource": "/assets/steps/altin/arrow.svg",
                          "grid": {
                            "columns": 4
                          },
                          "fields": [
                            {
                              "fieldType": "paragraph",
                              "fieldId": "6sjsfj",
                              "properties": {
                                "color": "var(--orkid-color)",
                                "text": "Fişini/faturanı <b>YÜKLE</b>",
                                "classes": ["stepCard"]
                              }
                            }
                          ]
                        }
                      },
                      {
                        "fieldType": "step-card",
                        "fieldId": "16pk71",
                        "properties": {
                          "stepNumber": "3",
                          "stepStyles": {
                            "background": "rgb(230, 245, 235)",
                            "color": "#009639",
                            "fontFamily": "Jost"
                          },
                          "imageSource": "/assets/steps/altin/3.svg",
                          "arrowImageSource": "/assets/steps/altin/arrow.svg",
                          "grid": {
                            "columns": 4
                          },
                          "fields": [
                            {
                              "fieldType": "paragraph",
                              "fieldId": "qo8x4a",
                              "properties": {
                                "color": "var(--orkid-color)",
                                "text": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                                "classes": ["stepCard"]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { // Buttons
                  "fieldType": "button-component",
                  "fieldId": "x1p9hx",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginBottom": "90px"
                    },
                    "styles": {
                      "background-color": "var(--orkid-color)"
                    },
                    "text": "BAŞLA",
                    "disabled": false,
                    "addClass": "nextBtn col-11",
                    "action": [
                      {
                        "fn": "goToPage",
                        "params": "gillettetirasineniyilerinde/fis-yukleme"
                      }
                    ]
                  }
                }
    
              ]
            }
          ]
        },
        { // Receipt Uploader
          "pageName": "gillettetirasineniyilerinde/fis-yukleme",
          "locale": "tr",
          "pageId": 403,
          "sections": [
            { // Steps and Header
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "justifyContent": "center"
              },
              "sectionId": "fu30zq",
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "tj4j49",
                  "properties": {
                    "activeIndex": 1,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // Header
                  "fieldType": "paragraph",
                  "fieldId": "uwxo2o",
                  "properties": {
                    "text": "<center>Lütfen fişinizi/faturanızı yükleyiniz</center>",
                    "classes": ["heading"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    }
                  }
                },
                { // Sub Header
                  "fieldType": "paragraph",
                  "fieldId": "vb309g",
                  "properties": {
                    "text": "<center>Bu adımda, Gillette alışverişinizi içeren fiş ya da faturanızı yükleyiniz.</center>",
                    "classes": ["subHeading"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginBottom": "26px"
                    }
                  }
                }
              ]
            },
            { // Receipt Info and Receipt Upload
              "sectionId": "dyx8uv",
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "justifyContent": "center"
              },
              "fields": [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "h453eadaf",
                  "properties": {
                    "grid": {
                      "columns": 12
                    },
                    "styles": {
                      "justifyContent": "center !important"
                    },
                    "fields": [
                      { // Receipt Info Desktop
                        "fieldType": "empty-bucket",
                        "fieldId": "vdf932fa",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-9 pb-4 receiptInfo desktopOnly",
                          "fields": [
                            {
                              "fieldType": "receipt-information",
                              "fieldId": "5mq1p6",
                              "properties": {
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                                "steps": [
                                  {
                                    "text": "Satış noktası",
                                    "label": "A"
                                  },
                                  {
                                    "text": "Satın alma tarihi ve saati",
                                    "label": "B"
                                  },
                                  {
                                    "text": "Ürünler ve satın alma fiyatları",
                                    "label": "C"
                                  },
                                  {
                                    "text": "Toplam fiş/fatura ve KDV tutarı",
                                    "label": "D"
                                  },
                                  {
                                    "text": "Fiş/Fatura Numarası",
                                    "label": "E"
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { // Receipt Processor
                        "fieldType": "empty-bucket",
                        "fieldId": "vdf932fb",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-9 pb-4 receiptUpload",
                          "fields": [
                            {
                              "fieldType": "receipt-processor",
                              "fieldId": "klvakj",
                              "properties": {
                                texts: {
                                  choose: 'Bir Dosya Seçin',
                                  receiptUpload1: 'Fişinizi/Faturanızı yüklemek için sürükleyip bırakınız.',
                                  receiptUpload2: 'Yalnızca JPEG, PNG ve PDF desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                                  receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                                  wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, .png, .pdf ve .tiff dosyaları kabul edilir.',
                                  fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                                },
                                "subCampaignId": "firsatyaninda-gillette-cashback",
                                "colorSchema": "var(--orkid-color)",
                                "grid": {
                                  "columns": "12",
                                  "alignItems": "center",
                                  "justifyContent": "center"
                                },
                                "addClass": "p-0",
                                "successAction": {
                                  "fn": "goToPage",
                                  "params": "gillettetirasineniyilerinde/odul-secimi"
                                },
                                "phrases": [
                                  "Ihr Beleg wird jetzt hochgeladen und bearbeitet.",
                                  "Der Beleg wird automatisch bearbeitet. Dies kann eine Minute dauern. Bitte haben Sie Geduld.",
                                  "Sollten Sie eine Fehlermeldung erhalten, nehmen Sie bitte eine bessere Version des Belegs auf und laden Sie ihn erneut hoch."
                                ],
                                "cornerDetectionPhrases": [
                                  "Lütfen gri dikdörtgeni fiş ile eşleşecek şekilde ayarlayın."
                                ],
                                "eraseColor": "#ffffff",
                                "eraseSize": 12,
                                "cropColor": "#3A96F3",
                                "cropBackground": "rgba(0,0,0,0.6)",
                                "cropTitle": "Kes",
                                "eraseTitle": "Düzenle",
                                "dragTitle": "Hareket ettir",
                                "zoomInTitle": "Yakınlaştır",
                                "zoomOutTitle": "Uzaklaştır",
                                "selectImageTitle": "Select an Image",
                                "selectAnotherTitle": "Select Another",
                                "controlsBackground": "#4193d3",
                                "backgroundColorBtn": "var(--orkid-color)"
                              }
                            }
                          ]
                        }
                      },
                      { // Receipt Info Desktop
                        "fieldType": "empty-bucket",
                        "fieldId": "vdf932fc",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-9 pb-4 receiptInfo desktopNone",
                          "fields": [
                            {
                              "fieldType": "receipt-information",
                              "fieldId": "5mq1p6",
                              "properties": {
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                                "steps": [
                                  {
                                    "text": "Satış noktası",
                                    "label": "A"
                                  },
                                  {
                                    "text": "Satın alma tarihi ve saati",
                                    "label": "B"
                                  },
                                  {
                                    "text": "Ürünler ve satın alma fiyatları",
                                    "label": "C"
                                  },
                                  {
                                    "text": "Toplam fiş/fatura ve KDV tutarı",
                                    "label": "D"
                                  },
                                  {
                                    "text": "Fiş/Fatura Numarası",
                                    "label": "E"
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "r0924fw",
              "grid": {
                "columns": 12,
                "marginBottom": "35px",
                "marginTop": "40px",
                justifyContent: "center"
              },
              "fields": [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "h453eadaf",
                  "properties": {
                    "grid": {
                      "columns": 10,
                      justifyContent: "center",
                      alignItems: "center"
                    },
                    "fields": [
                      {
                        "fieldType": "button-component",
                        "fieldId": "cwer23452q",
                        "properties": {
                          "grid": {
                            "columns": "4",
                          },
                          "addClass": "col-sm-8 col-xl-3 nextBtn",
                          "text": "İleri",
                          "styles": {
                            "backgroundColor": "var(--orkid-color)"
                          },
                          "shouldCheckCrop": true,
                          "requires": ["klvakj"],
                          "action": {
                            "fn": "sendReceipt",
                            "params": ""
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Reward Select
            "pageName": "gillettetirasineniyilerinde/odul-secimi",
            "locale": "tr",
            "pageId": 403,
            "sections": [
              { // Steps and Header
                "grid": {
                  "columns": 12,
                  "alignItems": "center",
                  "justifyContent": "center"
                },
                "sectionId": "fu30zq",
                "fields": [
                  { // Steps
                    "fieldType": "step-counter",
                    "fieldId": "tj4j49",
                    "properties": {
                      "activeIndex": 1,
                      "colorSchema": "var(--orkid-color)",
                      "campaignName": "altin",
                      "steps": [
                        {
                          "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                          "page": "ürün-ekle"
                        },
                        {
                          "title": "Fişini/faturanı <b>YÜKLE</b>",
                          "page": "fis-yukleme"
                        },
                        {
                          "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                          "page": "banka-bilgileri"
                        }
                      ]
                    }
                  },
                  { // Header
                    "fieldType": "paragraph",
                    "fieldId": "uwxo2o",
                    "properties": {
                      "text": "<center>Kampanya avantajınızı seçin.</center>",
                      "classes": ["heading"],
                      "grid": {
                        "alignItems": "center",
                        "justifyContent": "center",
                        "columns": "12",
                        marginBottom: "20px"
                      }
                    }
                  },
                  { // Subheading
                    fieldType: "paragraph",
                    fieldId: "par1001",
                    properties: {
                      grid: {
                        columns: 12,
                        marginBottom: "20px"
                      },
                      text: "<center>Faydalanmak istediğiniz kampanya avantajını seçin.</center>",
                      classes: ["subHeading","rewardSub"]
                    }
                  }
                ]
              },
              {
                "sectionId": "dyx8uv",
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "justifyContent": "center"
              },
              fields:[
                { // Reward Selection
                    fieldType: "empty-bucket",
                    fieldId: "h453eadaf",
                    properties: {
                      grid: {
                        columns: 12,
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "60px",
                        columnGap: "40px",
                        rowGap: "20px"
                      },
                      fields: [
                        {
                          fieldType: "reward-selection",
                          fieldId: "prod100",
                          properties: {
                            grid: {
                              columns: 12,
                              marginBottom: "50px"
                            },
                            "maxSelectable": 1,
                            successAction: {
                              fn: "goToPage",
                              params: "gillettetirasineniyilerinde/banka-bilgileri"
                            },
                            backUrl: 'gillettetirasineniyilerinde/fis-yukleme',
                            endpoint: "/chooseReward",
                            rewards: [
                              {
                                "id": 0,
                                type: "cashback",
                                "text": {
                                  title: `Para İadesi Al`,
                                },
                                "backgroundImage": "/assets/gillettetirasineniyilerinde/rewards/cashback.png",
                                "selectable": true,
                                
                              },
                              {
                                "id": 1,
                                type: "fuel",
                                "text": {
                                  title: `Opet Akaryakıt Kuponu Al`,
                                  
                                },
                                "backgroundImage": "/assets/gillettetirasineniyilerinde/rewards/opet.png",
                                "selectable": true,
                                
                              }
                            ]
                          }
                        }
                      ]
                    }
                  },
              ]
              },
            ]
        },
        { // Bank Info
          "pageName": "gillettetirasineniyilerinde/banka-bilgileri",
          "locale": "tr",
          "pageId": 409,
          "requires": [
            1
          ],
          "sections": [
            { // Steps and Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "wabb03",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // heading
                  "fieldType": "paragraph",
                  "fieldId": "0bbo32",
                  "properties": {
                    "text": "<center>Banka hesap bilgilerinizi giriniz</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ['heading']
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "94xqe4",
                  "properties": {
                    "text": "<center>Lütfen geri ödeme tutarının aktarılacağı hesabınızın bilgilerini giriniz.</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ['subHeading']
                  }
                }
              ]
            },
            { // Form
              "sectionId": "mfh0h1",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                {
                  "fieldType": "form-component",
                  "fieldId": "bank1Nf0",
                  "properties": {
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/uye-ol"
                    },
                    "endpoint": "/submitIBAN",
                    "keepInState": ["referenceNumber"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    },
                    "styles": {
                      "justifyContent": "center"
                    },
                    "fields": [
                      { // form
                        "fieldType": "empty-bucket",
                        "fieldId": "f934if",
                        "properties": {
                          "grid": {
                            "columns": "6",
                            "paddingTop": "30px",
                            "paddingBottom": "30px"
                          },
                          "className": "col-md-6 col-xl-4 formLayout",
                          "fields": [
                            {
                              "fieldType": "empty-bucket",
                              "fieldId": "f934if",
                              "properties": {
                                "grid": {
                                  "columns": "12"
                                },
                                "className": "ibanLabelWrapper",
                                "fields": [
                                  {
                                    "fieldType": "paragraph",
                                    "fieldId": "tw3qab",
                                    "properties": {
                                      "grid": {
                                        "columns": "2"
                                      },
                                      "text": "TR",
                                      "classes": ["ibanLabel", "col-2"]
                                    }
                                  },
                                  { // IBAN
                                    "fieldId": "h9arlr",
                                    "fieldType": "text-input",
                                    "properties": {
                                      "name": "iban",
                                      "id": "iban",
                                      "validation": "required|iban",
                                      "label": "IBAN*",
                                      "placeholder": "",
                                      "text": "",
                                      "className": "col-10",
                                      "grid": {
                                        "columns": "10",
                                        "paddingLeft": "5px !important"
                                      }
                                    }
                                  },
                                ]
                              }
                            },
                            { // trMobile
                              "fieldId": "7rMob2le",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "phoneNumber",
                                "id": "phoneNumber",
                                "label": "Telefon Numarası*",
                                "validation": "required|phoneNumber",
                                "placeholder": "",
                                "text": "",
                                maxlength: 10,
                                "grid": {
                                  "columns": "12"
                                }
                              }
                            },
                            { // First Name
                              "fieldId": "tp6zfb",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "firstname",
                                "id": "firstname",
                                "label": "Hesap Sahibi Adı*",
                                "validation": "required|ibanName",
                                "placeholder": "",
                                "text": "",
                                "grid": {
                                  "columns": "12"
                                }
                              }
                            },
                            { // Last Name
                              "fieldId": "p55fao",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "lastname",
                                "id": "lastname",
                                "validation": "required|ibanName",
                                "label": "Hesap Sahibi Soyadı*",
                                "placeholder": "",
                                "text": "",
                                "grid": {
                                  "columns": "12"
                                }
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "hmgwhl",
              "grid": {
                "columns": 12,
                "alignItems": "center"
              },
              buttonsWrap: true,
              "fields": [
                { // back Button
                  "fieldType": "button-component",
                  "fieldId": "16tzm2",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "40px",
                      "marginBottom": "100px"
                    },
                    "text": "Geri",
                    "addClass": "col-sm-4 col-xl-3 backBtn",
                    "action": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/odul-secimi"
                    }
                  }
                },
                {
                  "fieldType": "button-component",
                  "fieldId": "16tzm2",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "40px",
                      "marginBottom": "100px"
                    },
                    "addClass": "col-sm-4 col-xl-3 nextBtn",
                    "text": "İleri",
                    "styles": {
                      "backgroundColor": "var(--orkid-color)"
                    },
                    "disabled": false,
                    "action": {
                      "fn": "sendForm",
                      "params": "bank1Nf0"
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Registration
          "pageName": "gillettetirasineniyilerinde/uye-ol",
          "locale": "tr",
          "pageId": 404,
          "sections": [
            { // Steps, Image and Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12,
                "paddingBottom": "20px"
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "6gx3um",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // Image
                  "fieldId": "vn0934kf",
                  "fieldType": "square-image",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "styles": {
                      "marginTop": "81px",
                      "marginBottom": "20px",
                      "padding": "0",
                      "maxHeight": "50px",
                      "maxWidth": "125px"
                    },
                    "className": "imgMobile",
                    "imageSource": "/assets/logo/reg_logo@1x.png",
                    "imageSource2": "/assets/logo/reg_logo@2x.png"
                  }
                },
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "fckzg7",
                  "properties": {
                    "text": "<center>Fırsatlardan haberdar olmak için kayıt olunuz</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "marginTop": "10px"
                    },
                    "classes": ['heading']
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "ch2fio",
                  "properties": {
                    "text": `<center>"Kadınlar Bilir", P&G'nin bir pazarlama platformudur. Bu platform içerisindeki diğer kampanyalar hakkında bilgi almak için şimdi kayıt olun ve fırsatları kaçırmayın.</center>`,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ['subHeading']
                  }
                }
              ]
            },
            { // Forms
              "sectionId": "ci88zt",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 4
              },
              "fields": [
                {
                  "fieldType": "form-component",
                  "fieldId": "inzf4w",
                  "properties": {
                    "styles": {
                      "margin": "0",
                      "alignItems": "center",
                      "flexDirection": "column"
                    },
                    "grid": {
                      "columns": "12",
                      "alignItems": "center",
                      "justifyContent": "center"
                    },
                    "requires": [
                      "1"
                    ],
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/sms-sayfasi"
                    },
                    "endpoint": "/register",
                    "fields": [
                      { // Inputs
                        "fieldType": "empty-bucket",
                        "fieldId": "4h3gweb",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4",
                          "fields": [
                            { // Gender
                              "fieldType": "paragraph",
                              "fieldId": "yh54weg",
                              "properties": {
                                "text": "Cinsiyet *",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                classes: ["formTitle"]
                              }
                            },
                            { // Gender Radio Input
                              "fieldType": "radio-input",
                              "fieldId": "Geschlecht",
                              "name": "Cinsiyet*",
                              "properties": {
                                "text": "",
                                "name": "gender",
                                "colorSchema": "var(--orkid-color)",
                                "grid": {
                                  "columns": 12
                                },
                                "validation": "required",
                                "direction": "row",
                                "options": [
                                  {
                                    "value": "female",
                                    "text": "Kadın",
                                    "disabled": false,
                                    "name": "firstRadio"
                                  },
                                  {
                                    "value": "male",
                                    "text": "Erkek",
                                    "disabled": false,
                                    "name": "secondRadio"
                                  }
                                ]
                              }
                            },
                            { // First Name
                              "fieldId": "b4baqs",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "firstName",
                                "id": "firstName",
                                "label": "İsim*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|nameWithSpaces",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": 12
                                }
                              }
                            },
                            { // Last Name
                              "fieldId": "gofije",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "lastName",
                                "id": "lastName",
                                "label": "Soyisim*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|lastNameWithSpaces",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            { // Birth day
                              "fieldId": "godate",
                              "fieldType": "date-input",
                              "properties": {
                                "name": "birthdate",
                                "id": "birthdate",
                                "label": "Doğum tarihi",
                                "validation": "birthdate",
                                "grid": {
                                  "columns": "12"
                                },
                                "params": {
                                  "disabledDates": {
                                    "from": "18years",
                                    "to": "01.01.1800"
                                  }
                                }
                              }
                            },
                            { // Email
                              "fieldId": "h7kzf9",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "email",
                                "id": "email",
                                "label": "E-posta adresi*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|email",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            { // Password
                              "fieldId": "sgol2x",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "password",
                                "id": "password",
                                "label": "Parola*",
                                "inputType": "password",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|password",
                                "grid": {
                                  "columns": "12"
                                }
                              }
                            }
                          ]
                        }
                      },
                      { // Check Boxes
                        "fieldId": "8k2f023f",
                        "fieldType": "empty-bucket",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4",
                          "fields": [
                            { // Check box Age Consent
                              "fieldType": "paragraph",
                              "fieldId": "wgc3j7",
                              "properties": {
                                "grid": {
                                  "columns": "12",
                                  "paddingTop": "10px"
                                },
                                "styles": {
                                  marginLeft: "50px",
                                  "margin-top": "30px"
                                },
                                "text": `<a href="/pg-aydinlatma-metni" target="_blank"><b><u>İnternet Sitesi / Mobil Uygulama Kullanıcısı Aydınlatma Metni</u></b></a> kapsamında; kişisel verilerinizin Procter & Gamble Tüketim Malları Sanayi Anonim Şirketi (P&G) tarafından aşağıdaki amaçlarla işlenmesini kabul ediyorum.`
                              }
                            },
                            { // Second Checkbox
                              "fieldType": "checkbox",
                              "fieldId": "psyn1c",
                              "properties": {
                                "name": "receiveEmails",
                                "id": "receiveEmails",
                                "validation": {
                                  "required": {
                                    "allowFalse": true
                                  }
                                },
                                "grid": {
                                  "columns": "12",
                                  "paddingTop": "10px"
                                },
                                "label": `Kimlik, iletişim, müşteri işlem ve pazarlama verilerimin ürün ve hizmetlerin pazarlama süreçlerinin planlanması ve icrası, profilleme ve analiz faaliyetlerinin yürütülmesi, sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıklarım ve ihtiyaçlarıma göre özelleştirilerek önerilmesi ve tanıtılması amacıyla işlenmesini kabul ediyorum.`
                              }
                            },
                            { // Third Checkbox
                              "fieldType": "checkbox",
                              "fieldId": "psyn1c2",
                              "properties": {
                                "name": "receiveEmails2",
                                "id": "receiveEmails2",
                                "validation": {
                                  "required": {
                                    "allowFalse": true
                                  }
                                },
                                "grid": {
                                  "columns": "12",
                                  "paddingTop": "10px"
                                },
                                "label": `Kimlik, iletişim ve pazarlama verilerimin paylaştığım iletişim bilgilerime reklam, kutlama, promosyon vb. ticari elektronik ileti gönderilmesi amacıyla işlenmesini kabul ediyorum.`
                              }
                            },
                            { // Fourth Checkbox
                              "fieldType": "checkbox",
                              "fieldId": "psyn1c3",
                              "properties": {
                                "name": "receiveEmails3",
                                "id": "receiveEmails3",
                                "validation": {
                                  "required": {
                                    "allowFalse": true
                                  }
                                },
                                "grid": {
                                  "columns": "12",
                                  "paddingTop": "10px"
                                },
                                "label": `Kimlik ve görüntü (fotoğraf, ses, video) verilerim ile yorum içeriklerimin, ilgili ürün ve hizmetlerin ve katılmam halinde ilgili yarışma, çekiliş, kampanyanın görselleştirilerek tanıtılması amacıyla kullanılması ve kamuya açık olarak P&G’nin sosyal medya hesaplarında, internet sitelerinde, Şirket içi ve dışı sair mecralarda yayımlanması amacıyla işlenmesini kabul ediyorum.`
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "yh453gg4",
              "grid": {
                "columns": "12",
                "alignItems": "center",
                "justifyContent": "center",
                "marginBottom": "40px",
                "marginTop": "40px"
              },
              "fields": [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "y4h3gwh",
                  "properties": {
                    grid: {
                      columns: "6"
                    },
                    "className": "col-md-6 col-xl-4",
                    fields: [
                      { // Next Button
                        "fieldType": "button-component",
                        "fieldId": "5n4b3vg4",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center"
                          },
                          "text": "Kayıt Ol",
                          "styles": {
                            "backgroundColor": "var(--orkid-color)"
                          },
                          "addClass": "nextBtn",
                          "disabled": false,
                          "action": {
                            "fn": "sendForm",
                            "params": "inzf4w"
                          }
                        }
                      },
                      { // Login
                        "fieldType": "button-component",
                        "fieldId": "9o8k7ujyth",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginBottom": "0",
                            "marginTop": "40px"
                          },
                          "text": "Kadınlar Bilir hesabı ile Giriş Yap",
                          "addClass": "loginBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/giris-yap"
                          }
                        }
                      },
                      { // Guest Login
                        "fieldType": "button-component",
                        "fieldId": "23vb4n5",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px"
                          },
                          "text": "Kayıt olmadan misafir kullanıcı olarak devam edin",
                          "addClass": "guestBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/misafir-uye"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Fuel Registration
            "pageName": "gillettetirasineniyilerinde/fuel-uye-ol",
            "locale": "tr",
            "pageId": 404,
            "sections": [
              { // Steps, Image and Heading
                "grid": {
                  "alignItems": "center",
                  "justifyContent": "center",
                  "flex-direction": "column",
                  "columns": 12,
                  "paddingBottom": "20px"
                },
                "fields": [
                  { // Steps
                    "fieldType": "step-counter",
                    "fieldId": "6gx3um",
                    "properties": {
                      "activeIndex": 2,
                      "colorSchema": "var(--orkid-color)",
                      "campaignName": "altin",
                      "steps": [
                        {
                          "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                          "page": "ürün-ekle"
                        },
                        {
                          "title": "Fişini/faturanı <b>YÜKLE</b>",
                          "page": "fis-yukleme"
                        },
                        {
                          "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                          "page": "banka-bilgileri"
                        }
                      ]
                    }
                  },
                  { // Image
                    "fieldId": "vn0934kf",
                    "fieldType": "square-image",
                    "properties": {
                      "grid": {
                        "columns": "12"
                      },
                      "styles": {
                        "marginTop": "81px",
                        "marginBottom": "20px",
                        "padding": "0",
                        "maxHeight": "50px",
                        "maxWidth": "125px"
                      },
                      "className": "imgMobile",
                      "imageSource": "/assets/logo/reg_logo@1x.png",
                      "imageSource2": "/assets/logo/reg_logo@2x.png"
                    }
                  },
                  { // Heading
                    "fieldType": "paragraph",
                    "fieldId": "fckzg7",
                    "properties": {
                      "text": "<center>Fırsatlardan haberdar olmak için kayıt olunuz</center>",
                      "grid": {
                        "alignItems": "center",
                        "justifyContent": "center",
                        "columns": "8",
                        "marginTop": "10px"
                      },
                      "classes": ['heading']
                    }
                  },
                  { // Sub Heading
                    "fieldType": "paragraph",
                    "fieldId": "ch2fio",
                    "properties": {
                      "text": `<center>"Kadınlar Bilir", P&G'nin bir pazarlama platformudur. Bu platform içerisindeki diğer kampanyalar hakkında bilgi almak için şimdi kayıt olun ve fırsatları kaçırmayın.</center>`,
                      "grid": {
                        "alignItems": "center",
                        "justifyContent": "center",
                        "columns": "8"
                      },
                      "classes": ['subHeading']
                    }
                  }
                ]
              },
              { // Forms
                "sectionId": "ci88zt",
                "grid": {
                  "alignItems": "center",
                  "justifyContent": "center",
                  "columns": 4
                },
                "fields": [
                  {
                    "fieldType": "form-component",
                    "fieldId": "fltinzf4w",
                    "properties": {
                      "styles": {
                        "margin": "0",
                        "alignItems": "center",
                        "flexDirection": "column"
                      },
                      "grid": {
                        "columns": "12",
                        "alignItems": "center",
                        "justifyContent": "center"
                      },
                      "requires": [
                        "1"
                      ],
                      "successAction": {
                        "fn": "goToPage",
                        "params": "gillettetirasineniyilerinde/fuel-sms-onay"
                      },
                      "endpoint": "/register",
                      "fields": [
                        { // Inputs
                          "fieldType": "empty-bucket",
                          "fieldId": "4h3gweb",
                          "properties": {
                            "grid": {
                              "columns": "6"
                            },
                            "className": "col-md-6 col-xl-4",
                            "fields": [
                              { // Gender
                                "fieldType": "paragraph",
                                "fieldId": "yh54weg",
                                "properties": {
                                  "text": "Cinsiyet *",
                                  "grid": {
                                    "alignItems": "center",
                                    "justifyContent": "center",
                                    "columns": "12"
                                  },
                                  classes: ["formTitle"]
                                }
                              },
                              { // Gender Radio Input
                                "fieldType": "radio-input",
                                "fieldId": "Geschlecht",
                                "name": "Cinsiyet*",
                                "properties": {
                                  "text": "",
                                  "name": "gender",
                                  "colorSchema": "var(--orkid-color)",
                                  "grid": {
                                    "columns": 12
                                  },
                                  "validation": "required",
                                  "direction": "row",
                                  "options": [
                                    {
                                      "value": "female",
                                      "text": "Kadın",
                                      "disabled": false,
                                      "name": "firstRadio"
                                    },
                                    {
                                      "value": "male",
                                      "text": "Erkek",
                                      "disabled": false,
                                      "name": "secondRadio"
                                    }
                                  ]
                                }
                              },
                              { // First Name
                                "fieldId": "b4baqs",
                                "fieldType": "text-input",
                                "properties": {
                                  "name": "firstName",
                                  "id": "firstName",
                                  "label": "İsim*",
                                  "placeholder": "",
                                  "text": "",
                                  "validation": "required|nameWithSpaces",
                                  "grid": {
                                    "alignItems": "center",
                                    "justifyContent": "center",
                                    "columns": 12
                                  }
                                }
                              },
                              { // Last Name
                                "fieldId": "gofije",
                                "fieldType": "text-input",
                                "properties": {
                                  "name": "lastName",
                                  "id": "lastName",
                                  "label": "Soyisim*",
                                  "placeholder": "",
                                  "text": "",
                                  "validation": "required|lastNameWithSpaces",
                                  "grid": {
                                    "columns": 12
                                  }
                                }
                              },
                              { // Birth day
                                "fieldId": "godate",
                                "fieldType": "date-input",
                                "properties": {
                                  "name": "birthdate",
                                  "id": "birthdate",
                                  "label": "Doğum tarihi",
                                  "validation": "birthdate",
                                  "grid": {
                                    "columns": "12"
                                  },
                                  "params": {
                                    "disabledDates": {
                                      "from": "18years",
                                      "to": "01.01.1800"
                                    }
                                  }
                                }
                              },
                              { // Email
                                "fieldId": "h7kzf9",
                                "fieldType": "text-input",
                                "properties": {
                                  "name": "email",
                                  "id": "email",
                                  "label": "E-posta adresi*",
                                  "placeholder": "",
                                  "text": "",
                                  "validation": "required|email",
                                  "grid": {
                                    "columns": 12
                                  }
                                }
                              },
                              { // Password
                                "fieldId": "sgol2x",
                                "fieldType": "text-input",
                                "properties": {
                                  "name": "password",
                                  "id": "password",
                                  "label": "Parola*",
                                  "inputType": "password",
                                  "placeholder": "",
                                  "text": "",
                                  "validation": "required|password",
                                  "grid": {
                                    "columns": "12"
                                  }
                                }
                              }
                            ]
                          }
                        },
                        { // Check Boxes
                          "fieldId": "8k2f023f",
                          "fieldType": "empty-bucket",
                          "properties": {
                            "grid": {
                              "columns": "6"
                            },
                            "className": "col-md-6 col-xl-4",
                            "fields": [
                              { // Check box Age Consent
                                "fieldType": "paragraph",
                                "fieldId": "wgc3j7",
                                "properties": {
                                  "grid": {
                                    "columns": "12",
                                    "paddingTop": "10px"
                                  },
                                  "styles": {
                                    marginLeft: "50px",
                                    "margin-top": "30px"
                                  },
                                  "text": `<a href="/pg-aydinlatma-metni" target="_blank"><b><u>İnternet Sitesi / Mobil Uygulama Kullanıcısı Aydınlatma Metni</u></b></a> kapsamında; kişisel verilerinizin Procter & Gamble Tüketim Malları Sanayi Anonim Şirketi (P&G) tarafından aşağıdaki amaçlarla işlenmesini kabul ediyorum.`
                                }
                              },
                              { // Second Checkbox
                                "fieldType": "checkbox",
                                "fieldId": "psyn1c",
                                "properties": {
                                  "name": "receiveEmails",
                                  "id": "receiveEmails",
                                  "validation": {
                                    "required": {
                                      "allowFalse": true
                                    }
                                  },
                                  "grid": {
                                    "columns": "12",
                                    "paddingTop": "10px"
                                  },
                                  "label": `Kimlik, iletişim, müşteri işlem ve pazarlama verilerimin ürün ve hizmetlerin pazarlama süreçlerinin planlanması ve icrası, profilleme ve analiz faaliyetlerinin yürütülmesi, sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıklarım ve ihtiyaçlarıma göre özelleştirilerek önerilmesi ve tanıtılması amacıyla işlenmesini kabul ediyorum.`
                                }
                              },
                              { // Third Checkbox
                                "fieldType": "checkbox",
                                "fieldId": "psyn1c2",
                                "properties": {
                                  "name": "receiveEmails2",
                                  "id": "receiveEmails2",
                                  "validation": {
                                    "required": {
                                      "allowFalse": true
                                    }
                                  },
                                  "grid": {
                                    "columns": "12",
                                    "paddingTop": "10px"
                                  },
                                  "label": `Kimlik, iletişim ve pazarlama verilerimin paylaştığım iletişim bilgilerime reklam, kutlama, promosyon vb. ticari elektronik ileti gönderilmesi amacıyla işlenmesini kabul ediyorum.`
                                }
                              },
                              { // Fourth Checkbox
                                "fieldType": "checkbox",
                                "fieldId": "psyn1c3",
                                "properties": {
                                  "name": "receiveEmails3",
                                  "id": "receiveEmails3",
                                  "validation": {
                                    "required": {
                                      "allowFalse": true
                                    }
                                  },
                                  "grid": {
                                    "columns": "12",
                                    "paddingTop": "10px"
                                  },
                                  "label": `Kimlik ve görüntü (fotoğraf, ses, video) verilerim ile yorum içeriklerimin, ilgili ürün ve hizmetlerin ve katılmam halinde ilgili yarışma, çekiliş, kampanyanın görselleştirilerek tanıtılması amacıyla kullanılması ve kamuya açık olarak P&G’nin sosyal medya hesaplarında, internet sitelerinde, Şirket içi ve dışı sair mecralarda yayımlanması amacıyla işlenmesini kabul ediyorum.`
                                }
                              }
                            ]
                          }
                        }
                      ]
                    }
                  }
                ]
              },
              { // Buttons
                "sectionId": "yh453gg4",
                "grid": {
                  "columns": "12",
                  "alignItems": "center",
                  "justifyContent": "center",
                  "marginBottom": "40px",
                  "marginTop": "40px"
                },
                "fields": [
                  {
                    "fieldType": "empty-bucket",
                    "fieldId": "y4h3gwh",
                    "properties": {
                      grid: {
                        columns: "6"
                      },
                      "className": "col-md-6 col-xl-4",
                      fields: [
                        { // Next Button
                          "fieldType": "button-component",
                          "fieldId": "5n4b3vg4",
                          "properties": {
                            "grid": {
                              "columns": "12",
                              "alignItems": "center",
                              "justifyContent": "center"
                            },
                            "text": "Kayıt Ol",
                            "styles": {
                              "backgroundColor": "var(--orkid-color)"
                            },
                            "addClass": "nextBtn",
                            "disabled": false,
                            "action": {
                                 "fn": "sendForm",
                                 "params": "fltinzf4w"
                                 },
                             
                          }
                        },
                        { // Login
                          "fieldType": "button-component",
                          "fieldId": "9o8k7ujyth",
                          "properties": {
                            "grid": {
                              "columns": "12",
                              "alignItems": "center",
                              "justifyContent": "center",
                              "marginBottom": "0",
                              "marginTop": "40px"
                            },
                            "text": "Kadınlar Bilir hesabı ile Giriş Yap",
                            "addClass": "loginBtn",
                            "disabled": false,
                            "action": {
                              "fn": "goToPage",
                              "params": "gillettetirasineniyilerinde/fuel-giris-yap"
                            }
                          }
                        },
                        { // Guest Login
                          "fieldType": "button-component",
                          "fieldId": "23vb4n5",
                          "properties": {
                            "grid": {
                              "columns": "12",
                              "alignItems": "center",
                              "justifyContent": "center",
                              "marginTop": "40px"
                            },
                            "text": "Kayıt olmadan misafir kullanıcı olarak devam edin",
                            "addClass": "guestBtn",
                            "disabled": false,
                            "action": {
                              "fn": "goToPage",
                              "params": "gillettetirasineniyilerinde/fuel-misafir-uye"
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              }
            ]
        },
        { // Guest Registration
          "pageName": "gillettetirasineniyilerinde/misafir-uye",
          "locale": "tr",
          "pageId": 405,
          "sections": [
            { // Steps and Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12,
                "paddingBottom": "20px"
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "6gx3um",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "fckzg7",
                  "properties": {
                    "text": "<center>Misafir kullanıcı olarak devam ediniz</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "marginTop": "10px"
                    },
                    "classes": ['heading']
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "ch2fio",
                  "properties": {
                    "text": `<center>"Kadınlar Bilir" hesabı oluşturmak istememene çok üzüldük. Eğer fikrinizi değiştirirseniz, her zaman <a href="https://www.kadinlarbilir.com" target="_blank">Kadinlarbilir.com</a>’a girerek kayıt olabilirsiniz.<br/><br/>
                      Katılımınızı tamamlamak için aşağıdaki bilgileri doldurmanızı rica ederiz.
                      </center>`,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ['subHeading']
                  }
                }
              ]
            },
            { // Form
              "sectionId": "ci88zt",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": "12"
              },
              "fields": [
                {
                  "fieldType": "form-component",
                  "fieldId": "j765n4b",
                  "properties": {
                    "styles": {
                      "margin": "0",
                      "alignItems": "center",
                      "flexDirection": "column"
                    },
                    "grid": {
                      "alignItems": "center",
                      "columns": "12",
                      "justifyContent": "center"
                    },
                    "requires": [
                      "1"
                    ],
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/sms-sayfasi"
                    },
                    "endpoint": "/registerAsGuest",
                    "fields": [
                      { // Input
                        "fieldType": "empty-bucket",
                        "fieldId": "4h3gweb",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4",
                          "fields": [
                            { // Gender
                              "fieldType": "paragraph",
                              "fieldId": "yh54weg",
                              "properties": {
                                "text": "Cinsiyet *",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                classes: ["formTitle"]
                              }
                            },
                            { // Gender Radio
                              "fieldType": "radio-input",
                              "fieldId": "Geschlecht",
                              "name": "Cinsiyet*",
                              "properties": {
                                "text": "",
                                "name": "gender",
                                "colorSchema": "var(--orkid-color)",
                                "grid": {
                                  "columns": 12
                                },
                                "validation": "required",
                                "direction": "row",
                                "options": [
                                  {
                                    "value": "female",
                                    "text": "Kadın",
                                    "disabled": false,
                                    "name": "firstRadio"
                                  },
                                  {
                                    "value": "male",
                                    "text": "Erkek",
                                    "disabled": false,
                                    "name": "secondRadio"
                                  }
                                ]
                              }
                            },
                            { // First Name
                              "fieldId": "b4baqs",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "firstName",
                                "id": "firstName",
                                "label": "İsim*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|nameWithSpaces",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": 12
                                }
                              }
                            },
                            { // Last Name
                              "fieldId": "gofije",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "lastName",
                                "id": "lastName",
                                "label": "Soyisim*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|lastNameWithSpaces",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            { // Email
                              "fieldId": "h7kzf9",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "email",
                                "id": "email",
                                "label": "E-posta adresi*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|email",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            }
                          ]
                        }
                      },
                      { // Check Boxes
                        "fieldId": "8k2f023f",
                        "fieldType": "empty-bucket",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4",
                          "fields": [
                            { // Check box Age Consent
                              "fieldType": "checkbox",
                              "fieldId": "wgc3j7",
                              "properties": {
                                "name": "terms",
                                "id": "terms",
                                "validation": {
                                  "required": {
                                    "allowFalse": false
                                  }
                                },
                                "grid": {
                                  "columns": "12",
                                  "paddingTop": "10px"
                                },
                                "styles": {
                                  "margin-top": "30px"
                                },
                                "label": `Kayıt olmak için 18 yaşından büyüğüm ve <a href="https://www.pg.com/tr_TR/terms_conditions/index.shtml" target="_blank"><u><b>Kullanım Koşullarını</u></b></a> okuyup, kabul ediyorum.`
                              }
                            },
                            { // Second Checkbox
                              "fieldType": "checkbox",
                              "fieldId": "psyn1c",
                              "properties": {
                                "name": "receiveEmails",
                                "id": "receiveEmails",
                                "validation": {
                                  "required": {
                                    "allowFalse": true
                                  }
                                },
                                "grid": {
                                  "columns": "12",
                                  "paddingTop": "10px"
                                },
                                "label": `<a href="https://www.kadinlarbilir.com/-/media/801c38ae896348a59cf5f727d3f60061.ashx" target="_blank"> <u><b>Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni</b></u></a> kapsamında kişisel verilerimin P&G markaları tarafından bilgilendirme, promosyon, kampanya, pazarlama süreçlerinin planlanması ve icrası amacıyla; ürün ve hizmetlerin beğenilerime, kullanım alışkanlıklarıma ve ihtiyaçlarıma göre özelleştirilmesi için işlenmesi ve yukarıda paylaştığım iletişim bilgilerime reklam, promosyon, kampanya ve benzeri ticari elektronik ileti gönderilmesini ve bu amaçla P&G’nin hizmet aldığı tedarikçilere paylaşılmasını kabul ediyorum.<br>`
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "yh453gg4",
              "grid": {
                "columns": "12",
                "alignItems": "center",
                "justifyContent": "center",
                "marginBottom": "40px",
                "marginTop": "40px"
              },
              "fields": [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "y4h3gwh",
                  "properties": {
                    grid: {
                      columns: "6"
                    },
                    "className": "col-md-6 col-xl-4",
                    fields: [
                      { // Next Button
                        "fieldType": "button-component",
                        "fieldId": "5n4b3vg4",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center"
                          },
                          "text": "Kayıt Ol",
                          "styles": {
                            "backgroundColor": "var(--orkid-color)"
                          },
                          "addClass": "nextBtn",
                          "disabled": false,
                          "action": {
                            "fn": "sendForm",
                            "params": "j765n4b"
                          }
                        }
                      },
                      { // Login
                        "fieldType": "button-component",
                        "fieldId": "9o8k7ujyth",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginBottom": "0",
                            "marginTop": "40px"
                          },
                          "text": "Kadınlar Bilir hesabı ile Giriş Yap",
                          "addClass": "loginBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/giris-yap"
                          }
                        }
                      },
                      { // Guest Login
                        "fieldType": "button-component",
                        "fieldId": "23vb4n5",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px"
                          },
                          "text": "Kadınlar Bilir’e kayıt olarak giriş yap",
                          "addClass": "guestBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/uye-ol"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Fuel Guest Registration
          "pageName": "gillettetirasineniyilerinde/fuel-misafir-uye",
          "locale": "tr",
          "pageId": 405,
          "sections": [
            { // Steps and Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12,
                "paddingBottom": "20px"
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "6gx3um",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "fckzg7",
                  "properties": {
                    "text": "<center>Misafir kullanıcı olarak devam ediniz</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "marginTop": "10px"
                    },
                    "classes": ['heading']
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "ch2fio",
                  "properties": {
                    "text": `<center>"Kadınlar Bilir" hesabı oluşturmak istememene çok üzüldük. Eğer fikrinizi değiştirirseniz, her zaman <a href="https://www.kadinlarbilir.com" target="_blank">Kadinlarbilir.com</a>’a girerek kayıt olabilirsiniz.<br/><br/>
                      Katılımınızı tamamlamak için aşağıdaki bilgileri doldurmanızı rica ederiz.
                      </center>`,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ['subHeading']
                  }
                }
              ]
            },
            { // Form
              "sectionId": "ci88zt",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": "12"
              },
              "fields": [
                {
                  "fieldType": "form-component",
                  "fieldId": "flgj765n4b",
                  "properties": {
                    "styles": {
                      "margin": "0",
                      "alignItems": "center",
                      "flexDirection": "column"
                    },
                    "grid": {
                      "alignItems": "center",
                      "columns": "12",
                      "justifyContent": "center"
                    },
                    "requires": [
                      "1"
                    ],
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/fuel-sms-onay"
                    },
                    "endpoint": "/registerAsGuest",
                    "fields": [
                      { // Input
                        "fieldType": "empty-bucket",
                        "fieldId": "4h3gweb",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4",
                          "fields": [
                            { // Gender
                              "fieldType": "paragraph",
                              "fieldId": "yh54weg",
                              "properties": {
                                "text": "Cinsiyet *",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                classes: ["formTitle"]
                              }
                            },
                            { // Gender Radio
                              "fieldType": "radio-input",
                              "fieldId": "Geschlecht",
                              "name": "Cinsiyet*",
                              "properties": {
                                "text": "",
                                "name": "gender",
                                "colorSchema": "var(--orkid-color)",
                                "grid": {
                                  "columns": 12
                                },
                                "validation": "required",
                                "direction": "row",
                                "options": [
                                  {
                                    "value": "female",
                                    "text": "Kadın",
                                    "disabled": false,
                                    "name": "firstRadio"
                                  },
                                  {
                                    "value": "male",
                                    "text": "Erkek",
                                    "disabled": false,
                                    "name": "secondRadio"
                                  }
                                ]
                              }
                            },
                            { // First Name
                              "fieldId": "b4baqs",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "firstName",
                                "id": "firstName",
                                "label": "İsim*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|nameWithSpaces",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": 12
                                }
                              }
                            },
                            { // Last Name
                              "fieldId": "gofije",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "lastName",
                                "id": "lastName",
                                "label": "Soyisim*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|lastNameWithSpaces",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            { // Email
                              "fieldId": "h7kzf9",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "email",
                                "id": "email",
                                "label": "E-posta adresi*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|email",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            }
                          ]
                        }
                      },
                      { // Check Boxes
                        "fieldId": "8k2f023f",
                        "fieldType": "empty-bucket",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4",
                          "fields": [
                            { // Check box Age Consent
                              "fieldType": "checkbox",
                              "fieldId": "wgc3j7",
                              "properties": {
                                "name": "terms",
                                "id": "terms",
                                "validation": {
                                  "required": {
                                    "allowFalse": false
                                  }
                                },
                                "grid": {
                                  "columns": "12",
                                  "paddingTop": "10px"
                                },
                                "styles": {
                                  "margin-top": "30px"
                                },
                                "label": `Kayıt olmak için 18 yaşından büyüğüm ve <a href="https://www.pg.com/tr_TR/terms_conditions/index.shtml" target="_blank"><u><b>Kullanım Koşullarını</u></b></a> okuyup, kabul ediyorum.`
                              }
                            },
                            { // Second Checkbox
                              "fieldType": "checkbox",
                              "fieldId": "psyn1c",
                              "properties": {
                                "name": "receiveEmails",
                                "id": "receiveEmails",
                                "validation": {
                                  "required": {
                                    "allowFalse": true
                                  }
                                },
                                "grid": {
                                  "columns": "12",
                                  "paddingTop": "10px"
                                },
                                "label": `<a href="https://www.kadinlarbilir.com/-/media/801c38ae896348a59cf5f727d3f60061.ashx" target="_blank"> <u><b>Müşteri Kişisel Verilerin İşlenmesi Aydınlatma Metni</b></u></a> kapsamında kişisel verilerimin P&G markaları tarafından bilgilendirme, promosyon, kampanya, pazarlama süreçlerinin planlanması ve icrası amacıyla; ürün ve hizmetlerin beğenilerime, kullanım alışkanlıklarıma ve ihtiyaçlarıma göre özelleştirilmesi için işlenmesi ve yukarıda paylaştığım iletişim bilgilerime reklam, promosyon, kampanya ve benzeri ticari elektronik ileti gönderilmesini ve bu amaçla P&G’nin hizmet aldığı tedarikçilere paylaşılmasını kabul ediyorum.<br>`
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "yh453gg4",
              "grid": {
                "columns": "12",
                "alignItems": "center",
                "justifyContent": "center",
                "marginBottom": "40px",
                "marginTop": "40px"
              },
              "fields": [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "y4h3gwh",
                  "properties": {
                    grid: {
                      columns: "6"
                    },
                    "className": "col-md-6 col-xl-4",
                    fields: [
                      { // Next Button
                        "fieldType": "button-component",
                        "fieldId": "5n4b3vg4",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center"
                          },
                          "text": "Kayıt Ol",
                          "styles": {
                            "backgroundColor": "var(--orkid-color)"
                          },
                          "addClass": "nextBtn",
                          "disabled": false,
                          "action": {
                            "fn": "sendForm",
                            "params": "flgj765n4b"
                          }
                        }
                      },
                      { // Login
                        "fieldType": "button-component",
                        "fieldId": "9o8k7ujyth",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginBottom": "0",
                            "marginTop": "40px"
                          },
                          "text": "Kadınlar Bilir hesabı ile Giriş Yap",
                          "addClass": "loginBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/fuel-giris-yap"
                          }
                        }
                      },
                      { // Guest Login
                        "fieldType": "button-component",
                        "fieldId": "23vb4n5",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px"
                          },
                          "text": "Kadınlar Bilir’e kayıt olarak giriş yap",
                          "addClass": "guestBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/fuel-uye-ol"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Login
          "pageName": "gillettetirasineniyilerinde/giris-yap",
          "locale": "tr",
          "pageId": 406,
          "sections": [
            { // Steps and Headings
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12,
                "paddingBottom": "20px"
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "6gx3um",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // Image
                  "fieldId": "vn0934kf",
                  "fieldType": "square-image",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "styles": {
                      "marginTop": "81px",
                      "marginBottom": "20px",
                      "padding": "0",
                      "maxHeight": "50px",
                      "maxWidth": "125px"
                    },
                    "className": "imgMobile",
                    "imageSource": "/assets/logo/reg_logo@1x.png",
                    "imageSource2": "/assets/logo/reg_logo@2x.png"
                  }
                },
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "fckzg7",
                  "properties": {
                    "text": `<center>"Kadınlar Bilir" hesabı ile giriş yap</center>`,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "marginTop": "10px"
                    },
                    "classes": ['heading']
                  }
                }
              ]
            },
            { // Form
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                {
                  "fieldType": "form-component",
                  "fieldId": "jc3v455",
                  "properties": {
                    "styles": {
                      "margin": "0",
                      "justifyContent": "center"
                    },
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      columns: 12
                    },
                    "requires": [
                      "1"
                    ],
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/adres"
                    },
                    "endpoint": "/login",
                    "fields": [
                      { // Inputs
                        "fieldId": "fu2309b",
                        "fieldType": "empty-bucket",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4",
                          "fields": [
                            {
                              "fieldId": "n6b54v",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "email",
                                "id": "email",
                                "label": "E-posta adresi*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|email",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": 12
                                }
                              }
                            },
                            {
                              "fieldId": "6h54bdd",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "password",
                                "id": "password",
                                "label": "Parola*",
                                "inputType": "password",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|password",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            {
                              "fieldType": "anchor",
                              "fieldId": "fckzg7",
                              "properties": {
                                "text": "<span class='forgotPassword'>Parolanızı mı unuttunuz?</span>",
                                "action": {
                                  "fn": "goToPage",
                                  "params": "gillettetirasineniyilerinde/parola"
                                },
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                "cssClass": "forgotPass"
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Button and text
              "sectionId": "vbniw03",
              "grid": {
                "columns": "12",
                "alignItems": "center",
                "justifyContent": "center",
                "marginBottom": "40px",
                "marginTop": "32px"
              },
              "fields": [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "vb892nwq",
                  "properties": {
                    grid: {
                      columns: "6"
                    },
                    "className": "col-md-6 col-xl-4",
                    "styles": {
                      "justifyContent": "center"
                    },
                    fields: [
                      { // Button
                        "fieldType": "button-component",
                        "fieldId": "5n4b3vg4",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginBottom": "30px"
                          },
                          "text": "Giriş Yap",
                          "styles": {
                            "backgroundColor": "var(--orkid-color)"
                          },
                          "addClass": "nextBtn",
                          "disabled": false,
                          "action": {
                            "fn": "sendForm",
                            "params": "jc3v455"
                          }
                        }
                      },
                      { // Text
                        "fieldType": "paragraph",
                        "fieldId": "fckzg7",
                        "properties": {
                          "text": `Henüz "Kadınlar Bilir" hesabınız yok mu?`,
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "10"
                          },
                          "classes": ["loginText"]
                        }
                      },
                      { // Registration
                        "fieldType": "button-component",
                        "fieldId": "9o8k7ujyth",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginBottom": "0",
                            "marginTop": "40px"
                          },
                          "text": "Kadınlar Bilir’e kayıt olarak giriş yap",
                          "addClass": "loginBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/uye-ol"
                          }
                        }
                      },
                      { // Guest Registration
                        "fieldType": "button-component",
                        "fieldId": "23vb4n5",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px"
                          },
                          "text": "Kayıt olmadan misafir olarak devam edin",
                          "addClass": "guestBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/misafir-uye"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Fuel Login
          "pageName": "gillettetirasineniyilerinde/fuel-giris-yap",
          "locale": "tr",
          "pageId": 406,
          "sections": [
            { // Steps and Headings
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12,
                "paddingBottom": "20px"
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "6gx3um",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // Image
                  "fieldId": "vn0934kf",
                  "fieldType": "square-image",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "styles": {
                      "marginTop": "81px",
                      "marginBottom": "20px",
                      "padding": "0",
                      "maxHeight": "50px",
                      "maxWidth": "125px"
                    },
                    "className": "imgMobile",
                    "imageSource": "/assets/logo/reg_logo@1x.png",
                    "imageSource2": "/assets/logo/reg_logo@2x.png"
                  }
                },
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "fckzg7",
                  "properties": {
                    "text": `<center>"Kadınlar Bilir" hesabı ile giriş yap</center>`,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "marginTop": "10px"
                    },
                    "classes": ['heading']
                  }
                }
              ]
            },
            { // Form
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                {
                  "fieldType": "form-component",
                  "fieldId": "jc3v455",
                  "properties": {
                    "styles": {
                      "margin": "0",
                      "justifyContent": "center"
                    },
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      columns: 12
                    },
                    "requires": [
                      "1"
                    ],
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/fuel-adres"
                    },
                    "endpoint": "/login",
                    "fields": [
                      { // Inputs
                        "fieldId": "fu2309b",
                        "fieldType": "empty-bucket",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4",
                          "fields": [
                            {
                              "fieldId": "n6b54v",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "email",
                                "id": "email",
                                "label": "E-posta adresi*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|email",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": 12
                                }
                              }
                            },
                            {
                              "fieldId": "6h54bdd",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "password",
                                "id": "password",
                                "label": "Parola*",
                                "inputType": "password",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|password",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            {
                              "fieldType": "anchor",
                              "fieldId": "fckzg7",
                              "properties": {
                                "text": "Parolanızı mı unuttunuz?",
                                "action": {
                                  "fn": "goToPage",
                                  "params": "gillettetirasineniyilerinde/parola"
                                },
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                "cssClass": "forgotPass"
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Button and text
              "sectionId": "vbniw03",
              "grid": {
                "columns": "12",
                "alignItems": "center",
                "justifyContent": "center",
                "marginBottom": "40px",
                "marginTop": "32px"
              },
              "fields": [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "vb892nwq",
                  "properties": {
                    grid: {
                      columns: "6"
                    },
                    "className": "col-md-6 col-xl-4",
                    "styles": {
                      "justifyContent": "center"
                    },
                    fields: [
                      { // Button
                        "fieldType": "button-component",
                        "fieldId": "5n4b3vg4",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginBottom": "30px"
                          },
                          "text": "Giriş Yap",
                          "styles": {
                            "backgroundColor": "var(--orkid-color)"
                          },
                          "addClass": "nextBtn",
                          "disabled": false,
                          "action": {
                            "fn": "sendForm",
                            "params": "jc3v455"
                          }
                        }
                      },
                      { // Text
                        "fieldType": "paragraph",
                        "fieldId": "fckzg7",
                        "properties": {
                          "text": `Henüz "Kadınlar Bilir" hesabınız yok mu?`,
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "10"
                          },
                          "classes": ["loginText"]
                        }
                      },
                      { // Registration
                        "fieldType": "button-component",
                        "fieldId": "9o8k7ujyth",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginBottom": "0",
                            "marginTop": "40px"
                          },
                          "text": "Kadınlar Bilir’e kayıt olarak giriş yap",
                          "addClass": "loginBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/fuel-uye-ol"
                          }
                        }
                      },
                      { // Guest Registration
                        "fieldType": "button-component",
                        "fieldId": "23vb4n5",
                        "properties": {
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px"
                          },
                          "text": "Kayıt olmadan misafir olarak devam edin",
                          "addClass": "guestBtn",
                          "disabled": false,
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/fuel-misafir-uye"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Address
          "pageName": "gillettetirasineniyilerinde/adres",
          "locale": "tr",
          "pageId": 407,
          "requires": [
            1
          ],
          "sections": [
            { // Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "0bbo32",
                  "properties": {
                    "text": "<center>Adres bilgileriniz</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "paddingBottom": "90px"
                    },
                    "classes": ["heading"]
                  }
                }
              ]
            },
            { // Form
              "sectionId": "mfh0h1",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // Form
                  "fieldType": "form-component",
                  "fieldId": "adres",
                  "properties": {
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/sms-sayfasi"
                    },
                    "endpoint": "/submitAddress",
                    "keepInState": ["referenceNumber"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    },
                    "styles": {
                      "justifyContent": "center"
                    },
                    "fields": [
                      {
                        "fieldType": "empty-bucket",
                        "fieldId": "fw90342ol",
                        "properties": {
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4 justify-content-center",
                          "fields": [
                            { // City
                              "fieldId": "jdtxik",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "ort",
                                "id": "ort",
                                "label": "Şehir*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|street",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            { // Street
                              "fieldId": "b5q97u",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "strasse",
                                "id": "strasse",
                                "label": "Sokak*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|street",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            { // House Number
                              "fieldId": "ql5mu4",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "hausnr",
                                "id": "hausnr",
                                "label": "Numara*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|street",
                                "className": "col-6",
                                "grid": {
                                  "columns": 6,
                                  "paddingRight": "2.5px !important"
                                }
                              }
                            },
                            { // Postal code
                              "fieldId": "ql6mu5",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "plz",
                                "id": "plz",
                                "label": "Posta kodu*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|street",
                                "className": "col-6",
                                "grid": {
                                  "columns": 6,
                                  "paddingLeft": "2.5px !important"
                                }
                              }
                            },
                            { // Land
                              "fieldId": "x3d8oz",
                              "fieldType": "select-input",
                              "properties": {
                                "name": "land",
                                "id": "land",
                                "label": "Land",
                                "placeholder": "Ülke*",
                                "text": "",
                                "validation": "required",
                                "options": [
                                  {
                                    "text": "Türkiye",
                                    "value": "Turkey"
                                  }
                                ],
                                "selectedValue": "",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
    
                            { // Buttons
                              "fieldType": "empty-bucket",
                              "fieldId": "fw90342ol",
                              "properties": {
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "8",
                                  "paddingTop": "20px",
                                  "paddingBottom": "100px"
                                },
                                "className": "btnMobile",
                                "fields": [
                                  { // Next Button
                                    "fieldType": "button-component",
                                    "fieldId": "16tzm2",
                                    "properties": {
                                      "grid": {
                                        "columns": "12",
                                        "alignItems": "center",
                                        "justifyContent": "center"
                                      },
                                      "text": "İleri",
                                      "styles": {
                                        "backgroundColor": "var(--orkid-color)"
                                      },
                                      "addClass": "nextBtn",
                                      "disabled": false,
                                      "action": {
                                        "fn": "sendForm",
                                        "params": "adres"
                                      }
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Fuel Address
          "pageName": "gillettetirasineniyilerinde/fuel-adres",
          "locale": "tr",
          "pageId": 407,
          "requires": [
            1
          ],
          "sections": [
            { // Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "0bbo32",
                  "properties": {
                    "text": "<center>Adres bilgileriniz</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "paddingBottom": "90px"
                    },
                    "classes": ["heading"]
                  }
                }
              ]
            },
            { // Form
              "sectionId": "mfh0h1",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // Form
                  "fieldType": "form-component",
                  "fieldId": "fueladres",
                  "properties": {
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/fuel-sms-onay"
                    },
                    "endpoint": "/submitAddress",
                    "keepInState": ["referenceNumber"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    },
                    "styles": {
                      "justifyContent": "center"
                    },
                    "fields": [
                      {
                        "fieldType": "empty-bucket",
                        "fieldId": "fw90342ol",
                        "properties": {
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "6"
                          },
                          "className": "col-md-6 col-xl-4 justify-content-center",
                          "fields": [
                            { // City
                              "fieldId": "jdtxik",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "ort",
                                "id": "ort",
                                "label": "Şehir*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|street",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            { // Street
                              "fieldId": "b5q97u",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "strasse",
                                "id": "strasse",
                                "label": "Sokak*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|street",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
                            { // House Number
                              "fieldId": "ql5mu4",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "hausnr",
                                "id": "hausnr",
                                "label": "Numara",
                                "placeholder": "",
                                "text": "",
                                "validation": "street",
                                "className": "col-6",
                                "grid": {
                                  "columns": 6,
                                  "paddingRight": "2.5px !important"
                                }
                              }
                            },
                            { // Postal code
                              "fieldId": "ql6mu5",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "plz",
                                "id": "plz",
                                "label": "Posta kodu*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|street",
                                "className": "col-6",
                                "grid": {
                                  "columns": 6,
                                  "paddingLeft": "2.5px !important"
                                }
                              }
                            },
                            { // Land
                              "fieldId": "x3d8oz",
                              "fieldType": "select-input",
                              "properties": {
                                "name": "land",
                                "id": "land",
                                "label": "Land",
                                "placeholder": "Ülke*",
                                "text": "",
                                "validation": "required",
                                "options": [
                                  {
                                    "text": "Türkiye",
                                    "value": "Turkey"
                                  }
                                ],
                                "selectedValue": "",
                                "grid": {
                                  "columns": 12
                                }
                              }
                            },
    
                            { // Buttons
                              "fieldType": "empty-bucket",
                              "fieldId": "fw90342ol",
                              "properties": {
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "8",
                                  "paddingTop": "20px",
                                  "paddingBottom": "100px"
                                },
                                "className": "btnMobile",
                                "fields": [
                                  { // Next Button
                                    "fieldType": "button-component",
                                    "fieldId": "16tzm2",
                                    "properties": {
                                      "grid": {
                                        "columns": "12",
                                        "alignItems": "center",
                                        "justifyContent": "center"
                                      },
                                      "text": "İleri",
                                      "styles": {
                                        "backgroundColor": "var(--orkid-color)"
                                      },
                                      "addClass": "nextBtn",
                                      "disabled": false,
                                      "action": {
                                        "fn": "sendForm",
                                        "params": "fueladres"
                                      }
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Fuel Sms
            "pageName": "gillettetirasineniyilerinde/fuel-sms-sayfasi",
            "locale": "tr",
            "pageId": 4090,
            "sections": [
              { // Steps and Heading
                "grid": {
                  "alignItems": "center",
                  "justifyContent": "center",
                  "flex-direction": "column",
                  "columns": 12
                },
                "fields": [
                  { // Steps
                    "fieldType": "step-counter",
                    "fieldId": "6gx3um",
                    "properties": {
                      "activeIndex": 2,
                      "colorSchema": "var(--orkid-color)",
                      "campaignName": "altin",
                      "steps": [
                        {
                          "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                          "page": "ürün-ekle"
                        },
                        {
                          "title": "Fişini/faturanı <b>YÜKLE</b>",
                          "page": "fis-yukleme"
                        },
                        {
                          "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                          "page": "banka-bilgileri"
                        }
                      ]
                    }
                  },
                  { // heading
                    "fieldType": "paragraph",
                    "fieldId": "0bbo32",
                    "properties": {
                      "text": "Lütfen ödülünüzün tanımlanacağı telefon numarasını giriniz",
                      "grid": {
                        "alignItems": "center",
                        "justifyContent": "center",
                        "columns": "8"
                      },
                      "classes": ["heading", 'text-center']
                    }
                  },
                  { // Sub Heading
                    "fieldType": "paragraph",
                    "fieldId": "94xqe4",
                    "properties": {
                      "text": `Gireceğiniz telefon  numarası ilerleyen aşamalarda SMS ile doğrulanacaktır.`,
                      "grid": {
                        "alignItems": "center",
                        "justifyContent": "center",
                        "columns": "8"
                      },
                      "classes": ["subHeading", 'text-center']
                    }
                  }
                ]
              },
              { // Form
                "sectionId": "mfh0h1",
                "grid": {
                  "alignItems": "center",
                  "justifyContent": "center",
                  "columns": 12
                },
                "fields": [
                  { // SMS
                    "fieldType": "sms-verification",
                    "fieldId": "flc6yysh",
                    "properties": {
                      "endpoint": "/mobileAuth",
                      "inputWidth": "720px",
                      "successAction": {
                        "fn": "goToPage",
                        "params": "gillettetirasineniyilerinde/fuel-uye-ol"
                      },
                      options: [
                        {
                          "name": "Türkiye",
                          "code": "TR",
                          "phoneCode": "+90"
                        }
                      ],
                      "grid": {
                        "alignItems": "center",
                        "justifyContent": "center",
                        "columns": 7,
                      }
                    }
                  },
                  { // Bilgilendirme
                    fieldId: 'sms1001zt',
                    fieldType: 'paragraph',
                    "properties": {
                        "text": "Kampanyadan faydalanmak isteyen müşteriler cep telefonu numaralarının Opet Petrolcülük A.Ş. ile paylaşılacağını kabul etmiş sayılırlar.",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "6"
                        },
                        "classes": ['disclaimer']
                      }
                  },
                 
                ]
              },
              
              { // Buttons
                "sectionId": "hmgwhl",
                "grid": {
                  "columns": 12,
                  "alignItems": "center"
                },
                buttonsWrap: true,
                "fields": [
                    { // Back Button
                        "fieldType": "button-component",
                        "fieldId": "16tzm2",
                        "properties": {
                          "grid": {
                            "columns": "4",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px",
                            "marginBottom": "100px"
                          },
                          "text": "Geri",
                          "addClass": "col-sm-4 col-xl-3 backBtn",
                          "action": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/odul-secimi"
                          }
                        }
                      },
                  { // Next Button
                    "fieldType": "button-component",
                    "fieldId": "16tzm2",
                    "properties": {
                      "grid": {
                        "columns": "4",
                        "alignItems": "center",
                        "justifyContent": "center",
                        "marginTop": "40px",
                        "marginBottom": "100px"
                      },
                      "addClass": "col-sm-4 col-xl-3 nextBtn",
                      "text": "İleri",
                      "styles": {
                        "backgroundColor": "var(--orkid-color)"
                      },
                      "requires": ["phoneNumber"],
                      "action": {
                        "fn": "sendSms",
                        "params": ""
                      }
                    }
                  }
                ]
              }
            ]
        },
        { // Sms Code
          "pageName": "gillettetirasineniyilerinde/sms-sayfasi",
          "locale": "tr",
          "pageId": 4090,
          "sections": [
            { // Steps and Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "6gx3um",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // heading
                  "fieldType": "paragraph",
                  "fieldId": "0bbo32",
                  "properties": {
                    "text": "Lütfen telefon numaranızı onaylayınız",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ["heading", 'text-center']
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "94xqe4",
                  "properties": {
                    "text": `Katılımınızı doğrulamak için cep telefonu numarasının belirtilmesi gereklidir.Vermiş olduğunuz telefon numarası sadece tek seferlik, reklamsız, doğrulama için kullanılacak ve kampanya sonrasında tarafımızca silinecektir.
                    <br>
                    Lütfen cep telefonu numaranızı aşağıdaki alana giriniz. Daha sonra size SMS ile katılımınızı onaylamanız için bir kod göndereceğiz, ardından bir sonraki sayfaya girebilirsiniz.
                    <br>
                    Talebinizin durumuyla ilgili SMS ve Mail yoluyla bilgilendirileceksiniz. SMS bilgilendirmesi Oral-B adına kampanyayı yürüten yetkili ajans JustSnap aracılığı ile yapılacaktır.
                    `,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ["subHeading", 'text-center']
                  }
                }
              ]
            },
            { // Form
              "sectionId": "mfh0h1",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // SMS
                  "fieldType": "sms-verification",
                  "fieldId": "c6yysh",
                  "properties": {
                    "endpoint": "/mobileAuth",
                    "inputWidth": "720px",
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/sms-onay"
                    },
                    options: [
                      {
                        "name": "Türkiye",
                        "code": "TR",
                        "phoneCode": "+90"
                      }
                    ],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": 6,
                      marginBottom: "50px"
                    }
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "hmgwhl",
              "grid": {
                "columns": 12,
                "alignItems": "center"
              },
              buttonsWrap: true,
              "fields": [
                {
                  "fieldType": "button-component",
                  "fieldId": "16tzm2",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "40px",
                      "marginBottom": "100px"
                    },
                    "addClass": "col-sm-4 col-xl-3 nextBtn",
                    "text": "İleri",
                    "styles": {
                      "backgroundColor": "var(--orkid-color)"
                    },
                    "requires": ["phoneNumber"],
                    "action": {
                      "fn": "sendSms",
                      "params": ""
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Sms Receive
          "pageName": "gillettetirasineniyilerinde/sms-onay",
          "locale": "tr",
          "pageId": 4091,
          "sections": [
            { // Steps and Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "6gx3um",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // heading
                  "fieldType": "paragraph",
                  "fieldId": "0bbo32",
                  "properties": {
                    "text": "Lütfen telefon numaranızı onaylayınız",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ["heading", 'text-center']
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "94xqe4",
                  "properties": {
                    "text": `Lütfen size SMS ile gönderdiğimiz onay kodunu girin.`,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ["subHeading", 'text-center']
                  }
                }
              ]
            },
            { // Form
              "sectionId": "mfh0h1",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // SMS
                  "fieldType": "sms-receiving",
                  "fieldId": "c6yysh",
                  "properties": {
                    "endpoint": "/mobileVerification",
                    "inputWidth": "720px",
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/kayit-tamamlandi"
                    },
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": 4
                    }
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "hmgwhl",
              "grid": {
                "columns": 12,
                "alignItems": "center"
              },
              buttonsWrap: true,
              "fields": [
                { // back Button
                  "fieldType": "button-component",
                  "fieldId": "16tzm2",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "40px",
                      "marginBottom": "100px"
                    },
                    "text": "Geri",
                    "addClass": "col-sm-4 col-xl-3 backBtn",
                    "action": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/sms-sayfasi"
                    }
                  }
                },
                {
                  "fieldType": "button-component",
                  "fieldId": "16tzm2",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "40px",
                      "marginBottom": "100px"
                    },
                    "addClass": "col-sm-4 col-xl-3 nextBtn",
                    "text": "İleri",
                    "styles": {
                      "backgroundColor": "var(--orkid-color)"
                    },
                    "requires": ["code"],
                    "action": {
                      "fn": "verifySms",
                      "params": ""
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Fuel Sms Receive
          "pageName": "gillettetirasineniyilerinde/fuel-sms-onay",
          "locale": "tr",
          "pageId": 4091,
          "sections": [
            { // Steps and Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12
              },
              "fields": [
                { // Steps
                  "fieldType": "step-counter",
                  "fieldId": "6gx3um",
                  "properties": {
                    "activeIndex": 2,
                    "colorSchema": "var(--orkid-color)",
                    "campaignName": "altin",
                    "steps": [
                      {
                        "title": "Seçili Gilette ürünlerini <b>SATIN AL</b>",
                        "page": "ürün-ekle"
                      },
                      {
                        "title": "Fişini/faturanı <b>YÜKLE</b>",
                        "page": "fis-yukleme"
                      },
                      {
                        "title": "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
                        "page": "banka-bilgileri"
                      }
                    ]
                  }
                },
                { // heading
                  "fieldType": "paragraph",
                  "fieldId": "0bbo32",
                  "properties": {
                    "text": "Lütfen telefon numaranızı onaylayınız",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ["heading", 'text-center']
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "94xqe4",
                  "properties": {
                    "text": `Lütfen size SMS ile gönderdiğimiz onay kodunu girin.`,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    "classes": ["subHeading", 'text-center']
                  }
                }
              ]
            },
            { // Form
              "sectionId": "mfh0h1",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // SMS
                  "fieldType": "sms-receiving",
                  "fieldId": "c6yysh",
                  "properties": {
                    "endpoint": "/mobileVerification",
                    "inputWidth": "720px",
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/kayit-tamamlandi"
                    },
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": 4
                    }
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "hmgwhl",
              "grid": {
                "columns": 12,
                "alignItems": "center"
              },
              buttonsWrap: true,
              "fields": [
                { // back Button
                  "fieldType": "button-component",
                  "fieldId": "16tzm2",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "40px",
                      "marginBottom": "100px"
                    },
                    "text": "Geri",
                    "addClass": "col-sm-4 col-xl-3 backBtn",
                    "action": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/fuel-uye-ol"
                    }
                  }
                },
                {
                  "fieldType": "button-component",
                  "fieldId": "16tzm2",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "40px",
                      "marginBottom": "100px"
                    },
                    "addClass": "col-sm-4 col-xl-3 nextBtn",
                    "text": "İleri",
                    "styles": {
                      "backgroundColor": "var(--orkid-color)"
                    },
                    "requires": ["code"],
                    "action": {
                      "fn": "verifySms",
                      "params": ""
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Thank you page
          "pageName": "gillettetirasineniyilerinde/kayit-tamamlandi",
          "locale": "tr",
          "pageId": 410,
          "sections": [
            {
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12,
                'maxWidth': '1270px',
                "margin": "auto"
              },
              "fields": [
                {
                  fieldId: "dte-003",
                  fieldType: "dataLayer-event",
                  properties: {
                    eventName: 'consumerIdSend',
                    sendconsumerId: true
                  }
                },
                { // Thank you Texts and Reference Number
                  "fieldType": "empty-bucket",
                  "fieldId": "05m65ds",
                  "properties": {
                    "grid": {
                      columns: 8,
                      "marginTop": "40px",
                      "marginBottom": "50px"
                    },
                    "styles": {
                      "padding": "0",
                      "flexDirection": "column",
                      "alignItems": "center"
                    },
                    fields: [
                      { // Image
                        "fieldId": "vn0934kf",
                        "fieldType": "square-image",
                        "properties": {
                          "grid": {
                            "columns": "12"
                          },
                          "styles": {
                            "marginBottom": "20px",
                            "padding": "0"
                          },
                          "className": "thankyouImage",
                          "imageSource": "/assets/success.svg",
                          "imageSource2": "/assets/success.svg"
                        }
                      },
                      { // Heading
                        "fieldType": "paragraph",
                        "fieldId": "88z8gv",
                        "properties": {
                          "text": "<center>Tebrikler, katılımınız tamamlandı!</center>",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "10"
                          },
                          "classes": ["heading"]
                        }
                      },
                      { // Sub Heading
                        "fieldType": "paragraph",
                        "fieldId": "88z8gv",
                        "properties": {
                          "variables": {
                            "referenceNumber": "referenceNumber"
                          },
                          "text": `<center>
                              Fişiniz/faturanız bize gönderildi ve şimdi kontrol ediliyor.
                                <br/><br/>
                              <strong>Referans numarası: {referenceNumber}</strong>
                                <br/><br/>
                              Bilgilerinizi kontrol ettikten sonra, eğer katılım koşullarımıza uygunsa bir onay e-postası alacaksınız. Başvurunuzda bir eksiklik olması halinde, sizinle tekrar iletişime geçeceğiz. Bu süreç 36 saate kadar sürebilir. Sizinle iletişime geçene kadar beklemenizi rica ederiz.
                                <br/><br/>
                              Satın alma fişini/faturasını ve diğer tüm bilgileri başarılı bir şekilde kontrol ettikten sonra eğer katılım koşullarına uyuyorsa, geri ödeme tutarını 2 ile 4 hafta içerisinde belirttiğiniz hesaba aktaracağız.
                            </center>`,
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "10"
                          },
                          classes: ["subHeading"]
                        }
                      },
                      { // Key Visual
                        "fieldType": "key-visual",
                        "fieldId": "296eg1",
                        "properties": {
                          "grid": {
                            "columns": "12"
                          },
                          "noContainer": true,
                          "desktopImageUrl": "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
                          "tabletImageUrl": "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
                          "mobileImageUrl": "/assets/gillettetirasineniyilerinde/mobile-banner.jpg",
                        }
                      },
                      { // Button
                        "fieldType": "button-component",
                        "fieldId": "oql2de",
                        "properties": {
                          "text": "Başlangıç sayfasına geri dön",
                          "grid": {
                            "columns": "6",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px",
                            "marginBottom": "90px"
                          },
                          "addClass": "nextBtn",
                          "styles": {
                            "backgroundColor": "var(--orkid-color)"
                          },
                          "disabled": false,
                          "action": {
                            "anchor": true,
                            "link": "https://www.firsatyaninda.com/gillettetirasineniyilerinde"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Forgot Password
          "pageName": "gillettetirasineniyilerinde/parola",
          "locale": "tr",
          "pageId": 408,
          "sections": [
    
            { // Image
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12,
                "paddingTop": "50px"
              },
              fields: [
                {
                  "fieldType": "square-image",
                  "fieldId": "gb0wjv",
                  "properties": {
                    "imageSource": "/assets/images/forgotPassword.svg",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "4"
                    },
                    "className": "forgotImageMobile"
                  }
                }
              ]
            },
            { // Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "gjd10",
                  "properties": {
                    "text": "<center>Parolanızı mı unuttunuz?</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginBottom": "50px"
                    },
                    "classes": ["heading"]
                  }
                }
              ]
            },
            { // Form and Button
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              fields: [
                { // Form
                  "fieldType": "form-component",
                  "fieldId": "9hj453vnv3e",
                  "properties": {
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": 12
                    },
                    "styles": {
                      "justifyContent": "center"
                    },
                    "successAction": {
                      fn: "displayModal",
                      params: {
                        message: 'Şifrenizi değiştirmenize yardımcı olmak için bir e-posta gönderdik.',
                        type: 'success'
                      }
                    },
                    "endpoint": "/forgetPassword",
                    "fields": [
                      {
                        "fieldId": "f9234pg",
                        "fieldType": "empty-bucket",
                        "properties": {
                          "grid": {
                            "columns": "4"
                          },
                          "className": "col-sm-8 col-md-6 justify-content-center",
                          "fields": [
                            { // Email
                              "fieldId": "h34gwb",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "email",
                                "id": "email",
                                "label": "E-posta adresi*",
                                "placeholder": "",
                                "text": "",
                                "validation": "required|email",
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                }
                              }
                            },
                            { // Button
                              "fieldId": "f9234pg",
                              "fieldType": "empty-bucket",
                              "properties": {
                                "grid": {
                                  "columns": "8",
                                  "paddingBottom": "100px"
                                },
                                "className": "col-sm-8 col-md-6 btnMobile",
                                "fields": [
                                  { // Button
                                    "fieldType": "button-component",
                                    "fieldId": "h34wevdss",
                                    "properties": {
                                      "text": "İleri",
                                      "grid": {
                                        "columns": "12",
                                        "alignItems": "center",
                                        "justifyContent": "center",
                                        "marginTop": "40px",
                                        "marginBottom": "81px"
                                      },
                                      "addClass": "nextBtn",
                                      "styles": {
                                        "backgroundColor": "var(--orkid-color)"
                                      },
                                      "disabled": false,
                                      "action": {
                                        "fn": "sendForm",
                                        "params": "9hj453vnv3e"
                                      }
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // T&C
          "pageName": "gillettetirasineniyilerinde/katilim-kosullari",
          "locale": "tr",
          "pageId": 421,
          "isPublic": true,
          "sections": [
            {
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": "12",
                "margin-bottom": "30px"
              },
              "fields": [
                { // Header
                  "fieldType": "paragraph",
                  "fieldId": "uwxo2o",
                  "properties": {
                    "text": "<center>KATILIM KOŞULLARI</center>",
                    "classes": ["heading"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    }
                  }
                },
                {
                  "fieldType": "embedded-doc",
                  "fieldId": "vabd69",
                  "properties": {
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    mainWrapperClass: 'embed-wrapper-no-padding',
                    "docSource": "https://docs.google.com/document/d/e/2PACX-1vQRNnXCK_pMzsJsj03l3sL_7ypYq82gfB4UJPhmigTUJGNxKP8qVlbI3e8tpH4_dA/pub?embedded=true"
                  }
                }
              ]
            }
          ]
        },
        { // FAQ
          "pageName": "gillettetirasineniyilerinde/sss",
          "locale": "tr",
          "pageId": 422,
          "isPublic": true,
          "sections": [
            {
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12,
                "marginBottom": "40px"
              },
              "fields": [
                { // Header
                  "fieldType": "paragraph",
                  "fieldId": "uwxo2o",
                  "properties": {
                    "text": "<center>SIKÇA SORULAN SORULAR</center>",
                    "classes": ["heading"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    }
                  }
                },
                {
                  "fieldType": "embedded-doc",
                  "fieldId": "vabd69",
                  "properties": {
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8"
                    },
                    mainWrapperClass: 'embed-wrapper-no-padding',
                    "docSource": "https://docs.google.com/document/d/e/2PACX-1vRFHGNntL633u3mQm6n8vbYLZy9TZ44u2UG-xNSp9AMGt7yow6jl9vV5V7LZjePoA/pub?embedded=true"
                  }
                }
              ]
            }
          ]
        },
        { // DOI page
          "pageName": "gillettetirasineniyilerinde/doi",
          "locale": "tr",
          "pageId": 423,
          "isPublic": true,
          "sections": [
            {
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12,
                "maxWidth": "1440px",
                "margin": "auto"
              },
              "fields": [
                {
                  "fieldType": "doi",
                  "fieldId": "r3doi",
                  "properties": {
                    "campaignName": "altin",
                    "colorSchema": "var(--orkid-color)",
                    "endpoint": "/checkDoi",
                    "title": "Katılımınız için teşekkür ederiz",
                    "text": "Bültenimize başarıyla kayıt oldunuz. Artık ücretsiz ürün testleri, yarışmalar ve ilham verici makaleler olduğunda ilk öğrenenlerden biri siz olacaksınız.",
                    "button": "Başlangıç   sayfasına geri dön"
                  }
                }
              ]
            }
          ]
        },
        { // Error page
          "pageName": "gillettetirasineniyilerinde/hata-sayfasi",
          "locale": "tr",
          "pageId": 413,
          isPublic: true,
          "sections": [
            { // Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "gjd10",
                  "properties": {
                    "text": "<center>Oops, bir şeyler hatalı oldu!</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginTop": "50px"
                    },
                    "classes": ["heading"]
                  }
                },
                { // Sub Heading
                  "fieldType": "paragraph",
                  "fieldId": "sfdjk2",
                  "properties": {
                    "text": "<center>Lütfen sayfayı yeniden yüklemeyi deneyin!</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    }
                  }
                }
              ]
            },
            { // Image and back button
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              fields: [
                { // Image
                  "fieldType": "square-image",
                  "fieldId": "gb0wjv",
                  "properties": {
                    "imageSource": "/assets/images/404.svg",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginTop": "50px"
                    }
                  }
                },
                { // Button
                  "fieldType": "button-component",
                  "fieldId": "oql2de",
                  "properties": {
                    "text": "Başlangıç   sayfasına geri dön",
                    "styles": {
                      "background-color": "var(--orkid-color);",
                      "border-radius": "100px;"
                    },
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "100px",
                      "marginBottom": "70px"
                    },
                    "addClass": "nextBtn",
                    "disabled": false,
                    "action": {
                      "anchor": true,
                      "link": "https://www.firsatyaninda.com/gillettetirasineniyilerinde"
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Expired page
          "pageName": "gillettetirasineniyilerinde/expired-page",
          "locale": "tr",
          "pageId": 414,
          isPublic: true,
          "sections": [
            { // Heading
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                { // Heading
                  "fieldType": "paragraph",
                  "fieldId": "gjd10",
                  "properties": {
                    "text": `<center>Maalesef, ulaşmaya çalıştığınız bağlantının süresi doldu. Lütfen tekrar kampanyaya katılın veya destek servisimize başvurun: <a href="mailto:gillette-destek@firsatyaninda.com"><u>gillette-destek@firsatyaninda.com</u></a></center>`,
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "6",
                      "marginTop": "50px"
                    },
                    "classes": ["heading"]
                  }
                }
              ]
            },
            { // Image and back button
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              fields: [
                { // Image
                  "fieldType": "square-image",
                  "fieldId": "gb0wjv",
                  "properties": {
                    "imageSource": "/assets/images/expired.svg",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginTop": "50px"
                    },
                    "styles": {
                      "max-width": "645px"
                    }
                  }
                }
              ]
            },
            {
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              fields: [
                { // Button
                  "fieldType": "button-component",
                  "fieldId": "oql2de",
                  "properties": {
                    "text": "Başlangıç   sayfasına geri dön",
                    "styles": {
                      "background-color": "var(--orkid-color);",
                      "border-radius": "100px;"
                    },
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "100px",
                      "marginBottom": "70px"
                    },
                    "addClass": "nextBtn",
                    "disabled": false,
                    "action": {
                      "anchor": true,
                      "link": "https://www.firsatyaninda.com/gillettetirasineniyilerinde"
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Reset Password page
          "pageName": "gillettetirasineniyilerinde/yeni-parola",
          "locale": "tr",
          isPublic: true,
          "pageId": 415,
          "sections": [
            {
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                {
                  "fieldId": "v09234i",
                  "fieldType": "empty-bucket",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "onSuccess": "hide",
                    "styles": {
                      "flexDirection": "column",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "maxWidth": "1440px"
                    },
                    "fields": [
                      {
                        "fieldType": "paragraph",
                        "fieldId": "gjd10",
                        "properties": {
                          "text": "<center><h2>Yeni bir parola seçiniz</h2></center>",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "12",
                            "marginTop": "50px"
                          },
                          "styles": {
                            "font-family": "Montserrat;",
                            "font-style": "normal;",
                            "font-weight": "600;",
                            "font-size": "32px;",
                            "line-height": "39px;",
                            "color": "#1D1D1D;"
                          }
                        }
                      },
                      {
                        "fieldType": "square-image",
                        "fieldId": "gb0wjv",
                        "properties": {
                          "imageSource": "/assets/images/reset-pass.svg",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "5"
                          },
                          "styles": {
                            "max-width": "346px !important",
                            "objectFit": "contain"
                          }
                        }
                      },
                      {
                        "fieldId": "f9234pg",
                        "fieldType": "empty-bucket",
                        "properties": {
                          "grid": {
                            "columns": "4"
                          },
                          "className": "col-sm-8 col-md-6",
                          "fields": [
                            {
                              "fieldType": "form-component",
                              "fieldId": "5h43gwe",
                              "properties": {
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": 12
                                },
                                "styles": {
                                  "justifyContent": "center",
                                  "alignItems": "center",
                                  "flexDirection": "column"
                                },
                                "successAction": [
                                  {
                                    "fn": "goToPage",
                                    "params": "/"//COMEBACKHERE
                                  },
                                  {
                                    "fn": "displaySuccess"
                                  },
                                ],
                                "endpoint": "/checkResetToken",
                                "fields": [
                                  {
                                    "fieldId": "j45brev",
                                    "fieldType": "text-input",
                                    "properties": {
                                      "name": "newPassword",
                                      "id": "newPassword",
                                      "vid": "newPassword",
                                      "inputType": "password",
                                      "label": "Parola*",
                                      "placeholder": "",
                                      "text": "",
                                      "validation": "required|password",
                                      "grid": {
                                        "alignItems": "center",
                                        "justifyContent": "center",
                                        "columns": "12"
                                      },
                                      "styles": {
                                        "border-radius": "40px !important",
                                        "margin-top": "20px"
                                      }
                                    }
                                  },
                                  {
                                    "fieldId": "7666b4re",
                                    "fieldType": "text-input",
                                    "properties": {
                                      "name": "newPasswordConfirm",
                                      "id": "newPasswordConfirm",
                                      "inputType": "password",
                                      "label": "Parola Tekrarı*",
                                      "placeholder": "",
                                      "text": "",
                                      "validation": "required|confirmed:newPassword",
                                      "grid": {
                                        "alignItems": "center",
                                        "justifyContent": "center",
                                        "columns": "12"
                                      },
                                      "styles": {
                                        "border-radius": "40px !important",
                                        "margin-top": "20px"
                                      }
                                    }
                                  },
                                  {
                                    "fieldType": "query-string-to-state",
                                    "fieldId": "uwxo2o1",
                                    properties: {
                                      grid: {
                                        columns: 0
                                      },
                                      variables: [
                                        {
                                          query: "code"
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              "fieldType": "button-component",
                              "fieldId": "h34wevdss",
                              "properties": {
                                "text": "Yeni parolanızı oluşturun",
                                "grid": {
                                  "columns": "12",
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "marginTop": "40px",
                                  "marginBottom": "81px"
                                },
                                "styles": {
                                  "background-color": "var(--orkid-color);",
                                  "border-radius": "100px;"
                                },
                                "textStyles": {
                                  "font-family": "Montserrat;",
                                  "color": "#FFFFFF;",
                                  "fontWeight": "600;",
                                  "font-size": "18px;",
                                  "line-height": "22px;"
                                },
                                "disabled": false,
                                "action": {
                                  "fn": "sendForm",
                                  "params": "5h43gwe"
                                }
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "fieldId": "v09234i",
                  "fieldType": "empty-bucket",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "onSuccess": "show",
                    "styles": {
                      "flexDirection": "column",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "max-width": "1440px"
                    },
                    "fields": [
                      {
                        "fieldType": "paragraph",
                        "fieldId": "fk2340ed",
                        "properties": {
                          "text": "<center><h2>Parolanız sıfırlandı ve değiştirildi.</h2></center>",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "12",
                            "marginTop": "50px"
                          },
                          "styles": {
                            "font-family": "Montserrat;",
                            "font-style": "normal;",
                            "font-weight": "600;",
                            "font-size": "32px;",
                            "line-height": "39px;",
                            "color": "#1D1D1D;"
                          }
                        }
                      },
                      {
                        "fieldType": "square-image",
                        "fieldId": "gb0wjv",
                        "properties": {
                          "imageSource": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/thankYou_tick.png",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "2"
                          },
                          "styles": {
                            "objectFit": "contain"
                          }
                        }
                      },
                      {
                        "fieldType": "paragraph",
                        "fieldId": "fk2340ed",
                        "properties": {
                          "text": "<center>Artık kampanya web sitesinde tekrar giriş yapabilirsiniz.<br>Bunu yapmak için, istenen kampanya web sitesini ziyaret edin.</center>",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "12"
                          },
                          "styles": {
                            "font-family": "Montserrat;",
                            "font-style": "normal;",
                            "font-size": "21px;",
                            "line-height": "39px;",
                            "color": "#1D1D1D;",
                            "margin": "20px 0 60px 0;"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Campaign Is Over
          "pageName": "gillettetirasineniyilerinde/kampanya-bitti",
          "locale": "tr",
          "pageId": 416,
          isPublic: true,
          "sections": [
            {
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12,
                "margin": "auto"
              },
              "fields": [
                { // Heading and Image and Button
                  "fieldType": "empty-bucket",
                  "fieldId": "05m65ds",
                  "properties": {
                    "grid": {
                      columns: 12,
                      "marginTop": "40px",
                      "marginBottom": "50px"
                    },
                    "styles": {
                      "padding": "0",
                      "flexDirection": "column",
                      "alignItems": "center"
                    },
                    fields: [
                      { // Heading
                        "fieldType": "paragraph",
                        "fieldId": "88z8gv",
                        "properties": {
                          "text": "<center>Kampanya süresi sona erdi</center>",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "10"
                          },
                          "classes": ["heading"]
                        }
                      },
                      { // Sub Heading
                        "fieldType": "paragraph",
                        "fieldId": "88z8gv",
                        "properties": {
                          "text": `<center>Kampanyaya göstermiş olduğunuz ilgiye teşekkür ederiz, maalesef kampanya dönemi sona ermiştir.</center>`,
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "10"
                          },
                          classes: ["subHeading"]
                        }
                      },
                      { // Key Visual
                        "fieldType": "key-visual",
                        "fieldId": "296eg1",
                        "properties": {
                          "grid": {
                            "columns": "12"
                          },
                          "styles": {
                            "margin": "0 0 50px 0"
                          },
                          "noContainer": true,
                          "desktopImageUrl": "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
                          "tabletImageUrl": "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
                          "mobileImageUrl": "/assets/gillettetirasineniyilerinde/mobile-banner.jpg"
                        }
                      },
                      { // Button
                        "fieldType": "button-component",
                        "fieldId": "oql2de",
                        "properties": {
                          "text": "Başlangıç   sayfasına geri dön",
                          "styles": {
                            "background-color": "var(--orkid-color);",
                            "border-radius": "100px;"
                          },
                          "grid": {
                            "columns": "4",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "40px",
                            "marginBottom": "90px"
                          },
                          "addClass": "nextBtn",
                          "disabled": false,
                          "action": {
                            "anchor": true,
                            "link": "https://www.firsatyaninda.com/"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Reupload Receipt
          "pageName": "gillettetirasineniyilerinde/tekrar-fis-yukle",
          "locale": "tr",
          "isPublic": true,
          "pageId": 417,
          "sections": [
            { // Query and Heading
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "justifyContent": "center"
              },
              "sectionId": "fu30zq",
              "fields": [
                { // Query String
                  "fieldType": "query-string-to-state",
                  "fieldId": "uwxo2o1",
                  properties: {
                    variables: [
                      {
                        query: "referenceNumber",
                        state: "referenceNumber"
                      },
                      {
                        query: "token",
                        state: "receiptToken"
                      }
                    ]
                  }
                },
                { // Header
                  "fieldType": "paragraph",
                  "fieldId": "uwxo2o",
                  "properties": {
                    "text": "<center>Lütfen fişinizi/faturanızı tekrar yükleyiniz</center>",
                    "classes": ["heading"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    }
                  }
                },
                { // Sub Header
                  "fieldType": "paragraph",
                  "fieldId": "vb309g",
                  "properties": {
                    "text": "<center>Bu adımda, P&G alışverişinizi içeren fiş ya da faturanızı tekrar yükleyiniz.</center>",
                    "classes": ["subHeading"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginBottom": "26px"
                    }
                  }
                }
              ]
            },
            { // Receipt Info and Uploader
              "sectionId": "dyx8uv",
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "justifyContent": "center"
              },
              "fields": [
                { // 72 Hours check
                  "fieldType": "checkReceiptToken",
                  "fieldID": "dksnfsdkjf",
                  properties: {
                    grid: { columns: 12 }
                  }
                },
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "h453eadaf",
                  "properties": {
                    "grid": {
                      "columns": "10"
                    },
                    "styles": {
                      "justifyContent": "center !important"
                    },
                    "fields": [
                      { // Receipt Info
                        "fieldType": "empty-bucket",
                        "fieldId": "vdf932fc",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-sm-9 xl-6 pb-4 receiptInfo desktopOnly",
                          "fields": [
                            {
                              "fieldType": "receipt-information",
                              "fieldId": "5mq1p6",
                              "properties": {
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                                "steps": [
                                  {
                                    "text": "Satış noktası",
                                    "label": "A"
                                  },
                                  {
                                    "text": "Satın alma tarihi ve saati",
                                    "label": "B"
                                  },
                                  {
                                    "text": "Ürünler ve satın alma fiyatları",
                                    "label": "C"
                                  },
                                  {
                                    "text": "Toplam fiş/fatura ve KDV tutarı",
                                    "label": "D"
                                  },
                                  {
                                    "text": "Fiş/Fatura Numarası",
                                    "label": "E"
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { // Receipt Upload
                        "fieldType": "empty-bucket",
                        "fieldId": "vdf932fg",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-md-9 pb-4 receiptUpload",
                          "fields": [
                            {
                              "fieldType": "receipt-processor",
                              "fieldId": "klvakj",
                              "properties": {
                                texts: {
                                  choose: 'Bir Dosya Seçin',
                                  receiptUpload1: 'Fişinizi/Faturanızı yüklemek için sürükleyip bırakınız.',
                                  receiptUpload2: 'Yalnızca JPEG, PNG ve PDF desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                                  receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                                  wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, .png, .pdf ve .tiff dosyaları kabul edilir.',
                                  fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                                },
                                "subCampaignId": "firsatyaninda-gillette-cashback",
                                "colorSchema": "var(--orkid-color)",
                                "grid": {
                                  "columns": "12",
                                  "alignItems": "center",
                                  "justifyContent": "center"
                                },
                                "reUpload": true,
                                "tokenName": "token",
                                "addClass": "p-0",
                                "successAction": {
                                  "fn": "goToPage",
                                  "params": "gillettetirasineniyilerinde/kayit-tamamlandi"
                                },
                                "phrases": [
                                  "Ihr Beleg wird jetzt hochgeladen und bearbeitet.",
                                  "Der Beleg wird automatisch bearbeitet. Dies kann eine Minute dauern. Bitte haben Sie Geduld.",
                                  "Sollten Sie eine Fehlermeldung erhalten, nehmen Sie bitte eine bessere Version des Belegs auf und laden Sie ihn erneut hoch."
                                ],
                                "cornerDetectionPhrases": [
                                  "Lütfen gri dikdörtgeni fiş ile eşleşecek şekilde ayarlayın."
                                ],
                                "eraseColor": "#ffffff",
                                "eraseSize": 12,
                                "cropColor": "#3A96F3",
                                "cropBackground": "rgba(0,0,0,0.6)",
                                "cropTitle": "Kes",
                                "eraseTitle": "Düzenle",
                                "dragTitle": "Hareket ettir",
                                "zoomInTitle": "Yakınlaştır",
                                "zoomOutTitle": "Uzaklaştır",
                                "selectImageTitle": "Select an Image",
                                "selectAnotherTitle": "Select Another",
                                "controlsBackground": "#4193d3",
                                "backgroundColorBtn": "var(--orkid-color)"
                              }
                            }
                          ]
                        }
                      },
                      { // Receipt Info
                        "fieldType": "empty-bucket",
                        "fieldId": "vdf932fz",
                        "properties": {
                          "grid": {
                            "columns": "6"
                          },
                          "className": "col-sm-9 xl-6 pb-4 receiptInfo desktopNone",
                          "fields": [
                            {
                              "fieldType": "receipt-information",
                              "fieldId": "5mq1p6",
                              "properties": {
                                "grid": {
                                  "alignItems": "center",
                                  "justifyContent": "center",
                                  "columns": "12"
                                },
                                "descriptionText": "Lütfen ürünlerinizi içeren fiş ya da faturanızın fotoğrafını çekiniz. Aşağıdaki bilgilerin açıkça görülebildiğinden emin olunuz:",
                                "steps": [
                                  {
                                    "text": "Satış noktası",
                                    "label": "A"
                                  },
                                  {
                                    "text": "Satın alma tarihi ve saati",
                                    "label": "B"
                                  },
                                  {
                                    "text": "Ürünler ve satın alma fiyatları",
                                    "label": "C"
                                  },
                                  {
                                    "text": "Toplam fiş/fatura ve KDV tutarı",
                                    "label": "D"
                                  },
                                  {
                                    "text": "Fiş/Fatura Numarası",
                                    "label": "E"
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "r0924fw",
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "marginBottom": "90px",
                "marginTop": "40px",
                "justifyContent": "center"
              },
              "fields": [
                {
                  "fieldId": "chr23985",
                  "fieldType": "empty-bucket",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "styles": {
                      "justifyContent": "center"
                    },
                    "className": "col-md-12",
                    "fields": [
                      {
                        "fieldType": "button-component",
                        "fieldId": "cwer23452q",
                        "properties": {
                          "grid": {
                            "columns": "4",
                            "alignItems": "center",
                            "justifyContent": "center"
                          },
                          "addClass": "col-sm-8 col-xl-3 nextBtn",
                          "text": "İleri",
                          "shouldCheckCrop": true,
                          "requires": ["klvakj"],
                          "action": {
                            "fn": "sendReceipt",
                            "params": ""
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        },
        { // Reupload Product
          "pageName": "gillettetirasineniyilerinde/tekrar-urun-yukle",
          "isPublic": true,
          "locale": "tr",
          "pageId": 418,
          "sections": [
            { // Query and Heading
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "justifyContent": "center"
              },
              "sectionId": "vgw364bh",
              "fields": [
                { // Query String
                  "fieldType": "query-string-to-state",
                  "fieldId": "uwxo2o1",
                  properties: {
                    variables: [
                      {
                        query: "referenceNumber",
                        state: "referenceNumber"
                      },
                      {
                        query: "token",
                        state: "receiptToken"
                      }
                    ]
                  }
                },
                { // Header
                  "fieldType": "paragraph",
                  "fieldId": "uwxo2o",
                  "properties": {
                    "text": "<center>Ürünlerin fotoğrafını yükleyiniz</center>",
                    "classes": ["heading"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    }
                  }
                },
                { // Sub Header
                  "fieldType": "paragraph",
                  "fieldId": "vb309g",
                  "properties": {
                    "text": "<center>Lütfen satın almış olduğunuz ürünlerin fotoğrafını barkod numarası görünecek şekilde ve fiş/fatura ile birlikte yükleyiniz.</center>",
                    "classes": ["subHeading"],
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12",
                      "marginBottom": "26px"
                    }
                  }
                }
              ]
            },
            { // Product Upload
              "sectionId": "dyx8uv",
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "justifyContent": "center"
              },
              "fields": [
                { // 72 Hours check
                  "fieldType": "checkReceiptToken",
                  "fieldID": "dksnfsdkjf",
                  properties: {
                    grid: { columns: 12 }
                  }
                },
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "h453eadaf",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "styles": {
                      "justifyContent": "center !important;",
                      "padding-top": "30px"
                    },
                    "className": "col-sm-9 col-xl-6 col-lg-12",
                    "fields": [
                      {
                        "fieldType": "product-upload",
                        "fieldId": "cakl305",
                        "properties": {
                          texts: {
                            choose: 'Bir Dosya Seçin',
                            receiptUpload1: 'Ürünlerin fotoğrafını yüklemek için sürükleyip bırakın.',
                            receiptUpload2: 'Yalnızca JPEG veya PNG desteklenir. <br/><br/> Maksimum dosya boyutu: 16 MB',
                            receiptUpload3: 'Fişinizin/Faturanızın görselini yüklemek için kameranızı kullanabilir ya da galerinizden fotoğraf seçebilirsiniz.',
                            wrongFormat: 'Bu dosya biçimi desteklenmiyor. Yalnızca .jpeg, ve .png dosyaları kabul edilir.',
                            fileSizeLimit: "Bu dosya çok büyük. Yalnızca 16 MB'a kadar olan dosyalar yüklenebilir."
                          },
                          "colorSchema": "var(--orkid-color)",
                          "grid": {
                            "columns": "12",
                            "alignItems": "center",
                            "justifyContent": "center"
                          },
                          "reupload": {
                            "status": true
                          },
                          "reUpload": true,
                          "tokenName": "token",
                          "endpoint": "/uploadProductPhoto",
                          "successAction": {
                            "fn": "goToPage",
                            "params": "gillettetirasineniyilerinde/kayit-tamamlandi"
                          }
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // Buttons
              "sectionId": "r0924fw",
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "marginBottom": "90px",
                "marginTop": "40px"
              },
              buttonsWrap: true,
              "fields": [
                {
                  "fieldType": "button-component",
                  "fieldId": "cwer23452q",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center"
                    },
                    "addClass": "col-sm-8 col-xl-3 nextBtn",
                    "text": "İleri",
                    "shouldCheckCrop": true,
                    "requires": ["cakl305"],
                    "action": {
                      "fn": "sendProductPhotos",
                      "params": "cakl305"
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Iban reuploader
          "pageName": "gillettetirasineniyilerinde/iban-yeniden-gonderme",
          "locale": "tr",
          "isPublic": true,
          "pageId": 419,
          "requires": [
            1
          ],
          "sections": [
            { // HEADING AND SUBHEADING
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "flex-direction": "column",
                "columns": 12
              },
    
              "fields": [
                {
                  "fieldType": "iban-reupload",
                  "fieldId": "ij3411"
                },
                {
                  "fieldType": "paragraph",
                  "fieldId": "0bbo32",
                  "properties": {
                    "text": "<center><h2>Banka hesap bilgilerinizi giriniz</h2></center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "marginTop": "100px"
                    },
                    "classes": ['col-md-10', 'heading']
                  }
                },
                {
                  "fieldType": "paragraph",
                  "fieldId": "94xqe4",
                  "properties": {
                    "text": "<center>Lütfen geri ödeme tutarının aktarılacağı hesabınızın bilgilerini giriniz.</center>",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "8",
                      "marginBottom": "50px"
                    },
                    "classes": ['col-md-10', 'subHeading']
                  }
                }
              ]
            },
            { // FORMS
              "sectionId": "mfh0h1",
              "grid": {
                "alignItems": "center",
                "justifyContent": "center",
                "columns": 12
              },
              "fields": [
                {
                  "fieldType": "form-component",
                  "fieldId": "bank1Nf0",
                  "properties": {
                    "successAction": {
                      "fn": "goToPage",
                      "params": "gillettetirasineniyilerinde/thank-you-iban"
                    },
                    "endpoint": "/updateIBAN",
                    "grid": {
                      "alignItems": "center",
                      "justifyContent": "center",
                      "columns": "12"
                    },
                    "styles": {
                      "justifyContent": "center"
                    },
                    "fields": [
                      { // form
                        "fieldType": "empty-bucket",
                        "fieldId": "f934if",
                        "properties": {
                          "grid": {
                            "columns": "6",
                            "paddingTop": "30px",
                            "paddingBottom": "30px"
                          },
                          "className": "col-md-6 col-xl-4 formLayout",
                          "fields": [
                            {
                              "fieldType": "empty-bucket",
                              "fieldId": "f934if",
                              "properties": {
                                "grid": {
                                  "columns": "2"
                                },
                                "className": "ibanLabelWrapper col-2",
                                "fields": [
                                  {
                                    "fieldType": "paragraph",
                                    "fieldId": "tw3qab",
                                    "properties": {
                                      "grid": {
                                        "columns": "12"
                                      },
                                      "text": "TR",
                                      "classes": ["ibanLabel"]
                                    }
                                  }
                                ]
                              }
                            },
                            { // IBAN
                              "fieldId": "h9arlr",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "iban",
                                "id": "iban",
                                "validation": "required|iban",
                                "label": "IBAN*",
                                "placeholder": "",
                                "text": "",
                                "className": "col-10",
                                "grid": {
                                  "columns": "10",
                                  "paddingLeft": "5px !important"
                                }
                              }
                            },
                            { // trMobile
                              "fieldId": "7rMob2le",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "phoneNumber",
                                "id": "phoneNumber",
                                "label": "Telefon Numarası*",
                                "validation": "required|phoneNumber",
                                "placeholder": "",
                                "text": "",
                                "grid": {
                                  "columns": "12"
                                }
                              }
                            },
                            { // First Name
                              "fieldId": "tp6zfb",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "firstname",
                                "id": "firstname",
                                "label": "Hesap Sahibi Adı*",
                                "validation": "required|ibanName",
                                "placeholder": "",
                                "text": "",
                                "grid": {
                                  "columns": "12"
                                }
                              }
                            },
                            { // Last Name
                              "fieldId": "p55fao",
                              "fieldType": "text-input",
                              "properties": {
                                "name": "lastname",
                                "id": "lastname",
                                "validation": "required|ibanName",
                                "label": "Hesap Sahibi Soyadı*",
                                "placeholder": "",
                                "text": "",
                                "grid": {
                                  "columns": "12"
                                }
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            },
            { // BUTTONS
              "sectionId": "hmgwhl",
              "grid": {
                "columns": 12,
                "alignItems": "center",
                "marginBottom": "20px",
                "maxWidth": "1440px",
                "marginTop": "40px"
              },
              "buttonsWrap": true,
              "fields": [
                {
                  "fieldType": "button-component",
                  "fieldId": "16tzm2",
                  "properties": {
                    "grid": {
                      "columns": "4",
                      "alignItems": "center",
                      "justifyContent": "center"
                    },
                    "text": "İleri",
                    "addClass": "col-sm-4 col-xl-3 nextBtn",
                    "disabled": false,
                    "action": {
                      "fn": "sendForm",
                      "params": "bank1Nf0"
                    }
                  }
                }
              ]
            }
          ]
        },
        { // Iban thank you page
          "pageName": "gillettetirasineniyilerinde/thank-you-iban",
          "locale": "de",
          "pageId": 420,
          "sections": [
            {
              "grid": {
                "alignItems": "start",
                "justifyContent": "center",
                "columns": "6",
                "maxWidth": "1440px",
                "margin": "auto"
              },
              "fields": [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "4w3vw5b8",
                  "properties": {
                    "grid": {
                      "columns": "12"
                    },
                    "styles": {
                      "justifyContent": "center",
                      flexDirection: 'column',
                      alignItems: 'center',
                      "padding": "0"
                    },
                    "fields": [
                      {
                        "fieldType": "paragraph",
                        "fieldId": "gjd10",
                        "properties": {
                          "text": "<center><h2>Teşekkürler</h2></center>",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "12"
                          },
                          "classes": ['heading']
                        }
                      },
                      { // Image
                        "fieldId": "vn0934kf",
                        "fieldType": "square-image",
                        "properties": {
                          "grid": {
                            "columns": "12"
                          },
                          "styles": {
                            "marginBottom": "10px",
                            "marginTop": "-10px",
                            "padding": "0"
                          },
                          "className": "thankyouImage",
                          "imageSource": "/assets/success.svg",
                          "imageSource2": "/assets/success.svg"
                        }
                      },
                      {
                        "fieldType": "paragraph",
                        "fieldId": "sfdjk2",
                        "properties": {
                          "text": "<center>Banka bilgilerinizi yeniden gönderdiğiniz için teşekkür ederiz. Başarılı bir transferin önünde hiçbir şey durmamalıdır.<br><br>Banka bilgilerinizle ilgili herhangi bir sorunuz varsa, lütfen <a href='mailto:gillette-iban@firsatyaninda.com'>gillette-iban@firsatyaninda.com</a> ile iletişime geçin.<br>Diğer konular için <a href='mailto:gillette-destek@firsatyaninda.com'>gillette-destek@firsatyaninda.com</a> adresinden müşteri hizmetimizle iletişime geçebilirsiniz.</center>",
                          "grid": {
                            "alignItems": "center",
                            "justifyContent": "center",
                            "columns": "12",
                            "marginBottom": "40px"
                          },
                          "classes": ['subHeading']
                        }
                      },
                      { // Key Visual
                        "fieldType": "key-visual",
                        "fieldId": "296eg1",
                        "properties": {
                          "grid": {
                            "columns": "12"
                          },
                          "noContainer": true,
                          "desktopImageUrl": "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
                          "tabletImageUrl": "/assets/gillettetirasineniyilerinde/desktop-banner.jgp",
                          "mobileImageUrl": "/assets/gillettetirasineniyilerinde/mobile-banner.jpg",
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ]
        }
      ],
      "header": {
        "fieldType": "navbar",
        "fieldId": "cbx34t",
        "properties": {
          "backgroundColor": "#fff",
          "title": "",
          "color": "#FFE082",
          "grid": {
            "columns": 12
          },
          "logo": {
            "title": "Fırsat Yanında",
            "imageSource": "/assets/images/logo@1x.png",
            "imageSource2x": "/assets/images/logo@2x.png",
            "width": 75,
            "alternateText": "Brand Logo",
            "paddingTop": "30px"
          },
          "favicon": {
            "imageSource": "https://azcdn.pioneer.pgsitecore.com/en-gb/-/media/Downy_UK/Images/Common/favicon-32x32.jpg?v=1-201708041111"
          },
          "fields": [
            { // Terms
              "fieldId": "ikz8vc",
              "fieldType": "anchor",
              "properties": {
                "text": "KATILIM KOŞULLARI",
                "url": "/gillettetirasineniyilerinde/katilim-kosullari",
                "target": "_blank",
                "cssClass": "nav-link headerLink"
              }
            },
            { // FAQ
              "fieldId": "slnmdk",
              "fieldType": "anchor",
              "properties": {
                "text": "SIKÇA SORULAN SORULAR",
                "url": "/gillettetirasineniyilerinde/sss",
                "target": "_blank",
                "cssClass": "nav-link headerLink"
              }
            }
          ],
          "showLine": false,
          "meta": [
            { "name": "title", "content": "P&G Türkiye - Fırsat Yanında" },
            {
              "name": "description",
              "content": `P&G Türkiye - Fırsat Yanında Para İadesi Kampanyası. Belirli satış noktalarından alacağınız toplamda 100 TL P&G ürünü alışverişlerinize 100 TL para iadesi kampanyası.`
            },
            {
              "name": "keywords",
              "content": `Ariel, Alo, Head and Shoulders, Oral-B, Gillette, Firsat Yaninda, P&G Türkiye`
            }
          ]
        }
      },
      "footer": {
        "fieldType": "footer-component",
        "fieldId": "4tfbzz",
        "properties": {
          "logo": {
            "imageSource": "/assets/images/logo@1x.png",
            "imageSource2x": "/assets/images/logo@2x.png",
            "width": 75,
            "alternateText": "Brand Logo",
            "paddingTop": "30px"
          },
          "links": [
            { "name": "Hüküm ve Koşullar", "link": "http://www.pg.com/tr_TR/terms_conditions/index.shtml" },
            { "name": "P&G'ye ulaşın", "link": "https://pgconsumersupport.secure.force.com/CarehubStandalone?Country=tr&Language=tr&Brand=Kadinlar+Bilir#brandListDiv" },
            { "name": "Gizlilik", "link": "https://privacypolicy.pg.com/tr-TR/" },
            { "name": "Benim Verilerim", "link": "https://preferencecenter.pg.com/tr-tr/"},
            { "name": "Hakkımızda", "link": "https://www.kadinlarbilir.com/hakkimizda" },
          { "name": "Aydınlatma Metni", "link": "/pg-aydinlatma-metni"}
          ],
          pgText: "© 2023 Procter & Gamble. Tüm hakları saklıdır. Bu sitedeki içeriklere erişim ve içeriklerin kullanılması, yasal anlaşmada belirtilen sözleşme ve şartlara bağlıdır.<br/>Destek departmanımız 21 Haziran 2024’e kadar hizmet verecektir: <a href='mailto:gillette-destek@firsatyaninda.com'>gillette-destek@firsatyaninda.com</a>"
        }
      },
      dictionary: {
        tr: {
          messages: {
            "required": (field) => `${field} alanı gereklidir`,
            "mandatory": "Bu alan gereklidir.",
            "alpha_spaces": (field) => `${field} sadece harf içerebilir.`,
            "email": "Geçerli bir e-posta adresi giriniz.",
            "password": "Şifreniz en az 8 karakterden oluşmalı, en az bir büyük harf, bir küçük harf ve bir sayı içermelidir.",
            "confirmPass": "Şifreler aynı değildir.",
            "postal": "Posta kodu 5 rakamdan oluşmalıdır.",
            "plz": "Posta kodu 5 rakamdan oluşmalıdır.",
            "street": "Yalnızca harf, boşluk, eğik çizgi ve kısa çizgi içerebilir",
            "iban": "Lütfen katılımcı ülkelerin geçerli bir IBAN'ını kullanınız",
            "phoneNumber": "Lütfen 5XXXXXXXXX formatı ile geçerli bir telefon numarası giriniz.",
            "hausnr": "Bina numarası yanlıştır.",
            "smsCodeLength": "Doğrulama kodu 4 basamaklı olmalıdır."
          },
          names: {
            iban: 'IBAN',
            gender: 'Cinisyet',
            bic: 'BIC',
            firstname: 'İsim',
            name: 'İsim',
            firstName: 'İsim',
            lastname: 'Soy isim',
            surname: 'Soy isim',
            lastName: 'Soy isim',
            email: 'E-mail',
            birthdate: 'Doğum tarihi',
            tckn: "TC Kimlik Numarası",
            password: 'Şifre',
            newPassword: 'Şifre',
            confirmPassword: 'Tekrar Şifre',
            newPasswordConfirm: 'Tekrar Şifre',
            strasse: 'Sokak',
            hausnr: 'No',
            plz: 'Posta kodu',
            ort: 'Şehir',
            city: 'İl',
            address: 'Adres',
            marital: 'Medeni hal',
            kids: 'Çocuk',
            area: 'Mahalle',
            district: 'İlçe',
            land: 'Ülke',
            phoneNumber: 'Telefon numarası',
            code: 'Kod',
            terms: "Katılım koşulları",
            receiveEmails: "Kullanım ve gizlilik politikası şartları",
          }
        }
      },
      "errors": {
        "tr": {
          default: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
          noEmail: "Bu e-posta adresine sahip bir hesap yok.",
          emailUsed: "Bu e-mail adresi zaten kullanımda.",
          incorrect: "Geçersiz kullanıcı adı veya yanlış şifre. Lütfen tekrar deneyin!",
          invalidCode: "Kullanmaya çalıştığınız bağlantının süresi zaten dolmuş. Lütfen yeni bir parola sıfırlama bağlantısı isteyin.",
          manyTries: 'Fiş çok fazla yüklendiğinden bir hata oluştu.',
          generateCode: 'Telefon numarasına izin verilmediğinden doğrulama kodu gönderilemedi. Lütfen girdiğiniz numarayı kontrol edin ve tekrar deneyin.',
          wrongCode: 'SMS kodu yanlış',
          network: 'Hata. Sunucuda bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.'
        }
      },
      "url": "firsatyaninda.com/gillettetirasineniyilerinde",
      "name": "firsatyaninda-gillette-cashback"
    },
    componentStatus: {},
    modal: {
      type: 'error',
      message: 'Oops. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
      isActive: false
    },
    customerToken: '',
    receiptToken: '',
    receiptImage: '',
    campaignId: 'firsatyaninda-gillette-cashback',
    formData: new FormData(),
    campaignEndDate: '2024-06-29',
    scannerActive: false,
    file: {},
    barcode: {
      code: '',
      format: ''
    },
    productCode: '',
    searchStatus: null,
    imgList: [],
    errorMessage: "Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?",
    errorImageList: ["", ""],
    errorActivate: false,
    scannedProducts: [],
    types: ['ean_reader', 'code_128_reader', 'code_39_reader', 'ean_8_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader', 'codabar_reader'],
    pageToBack: '',
    receiptBarcode: '',
    userFuelPhone : ''
  }
  