<template>
  <div :style="`--brand-color: ${colorSchema}`">
    <div v-if="showDisplayFull" class='barcode-scanner__wrapper'>
      <div class='left-side col-lg-6'>
        <barcodeInformation/>
        <barcodeScanner :texts="texts" :colorSchema="colorSchema" :height="readerHeight" :width="readerWidth" :cameraTimeOut="cameraTimeOut" :maxProductCount="maxProductCount" />
      </div>
      <div class='right-side pb-4 col-lg-6'>
        <SearchModule v-on:some-event="redirect($event)" :colorSchema="colorSchema" :maxProductCount="maxProductCount"/>
        <RetailerDropdown v-on:some-event="redirect($event)" :colorSchema="colorSchema"/>
        <div v-if="list.length" class='product_list-relative_container'>
          <div class='product_list-absolute_container' :class="{'scroll': list.length ? true : false}">
            <productsList :fullSize="true" :maxProductCount="maxProductCount" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class='barcode-scanner__wrapper'>
      <barcodeScanner
        :texts="texts"
        :colorSchema="colorSchema"
        :height="readerHeight"
        :width="readerWidth"
        :cameraTimeOut="cameraTimeOut"
        :tabletView="true"
        :list="list"
        :maxProductCount="maxProductCount"
      />
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-4" style="padding-top: 40px">
        <button
          v-if="pageToBack !== 'reviewProducts'"
          type="button"
          class="btn w-100 next-btn"
          :class="{'disabled': nextButtonDisabled}"
          :disabled="nextButtonDisabled"
          @click="sendProducts"
        >
          İleri
        </button>
        <button
          v-if="pageToBack === 'reviewProducts'"
          type="button"
          class="btn w-100 next-btn"
          :class="{'disabled': nextButtonDisabled}"
          :disabled="nextButtonDisabled"
          @click="backToProductsOverview"
        >
          Geri
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BarcodeScanner from './BarcodeScanner'
import SearchModule from './SearchModule'
import ProductsList from '../ProductsList'
import BarcodeInformation from '../barcode-information'
import RetailerDropdown from '../RetailerDropdown.vue'
export default {
  name: 'barcode-scanner',
  components: {
    BarcodeScanner,
    SearchModule,
    ProductsList,
    BarcodeInformation,
    RetailerDropdown
  },
  computed: {
    ...mapState({
      list: state => state.scannedProducts,
      selectedRetailer: state => state.selectedRetailer,
      customRetailer: state => state.customRetailer,
      nextButtonDisabled: state => !state.scannedProducts.length || !state.selectedRetailer || (state.selectedRetailer === "Diğer" && !state.customRetailer),
      status: state => state.searchStatus,
      barcode: state => state.productCode
    }),
    showDisplayFull () {
      return !this.isMobileOrTablet(screen.width)
    },
    pageToBack () {
      return this.$store.getters.pageToBack;
    }
  },
  props: {
    readerHeight: {
      type: Number
    },
    readerWidth: {
      type: Number
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    endpoint: {
      type: String
    },
    successAction: {
      type: [Object, Array]
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#002458"
    },
    errorPageName: {
      type: String
    },
    cameraTimeOut: {
      type: Number
    },
    maxProductCount: Number
  },
  methods: {
    ...mapActions(["getBarCode", "searchProductByBarcode", "setErrorActivate" , "updateSearchStatus"]),
    sendProducts () {
      const endpoint = "/submitProducts";

      if (this.selectedRetailer.label === "Diğer") {
        this.selectedRetailer.label = this.customRetailer
      }

      const formData = {
        selectedProducts: this.list,
        retailer: this.selectedRetailer
      };
      this.$store.dispatch("sendProducts", { formData, endpoint }).then(r => {
        if (r.response) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
          return;
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },
    backToProductsOverview () {
      this.$eventHub.$emit('backToPage-event')
    }
  },
  destroyed () {
    // Set the error message to false
    this.setErrorActivate(false)
    this.$store.dispatch("findProducts", []);
    this.getBarCode('')
    this.updateSearchStatus(null)
  },
  mounted () {
    // Check if the search status error is true
    if (this.status === "not_found")
      this.setErrorActivate(true)
    else
      this.setErrorActivate(false)
  }
}
</script>

<style scoped lang="scss">
.barcode-scanner__wrapper {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 40px;
}
.left-side {
  width: 50%;
}
.right-side {
  width: 80%;

  &_scroll {
      overflow-x: hidden;
  }
}
@media (max-width: 768px) {
  .right-side {
    order: -1;
  }
}

@media (max-width: 768px) {
  .barcode-scanner__wrapper {
    flex-direction: column;
    justify-content: center;
    max-width: 540px;
    margin: 0 auto;
  }
  .left-side {
    width: 100%;
  }
  .right-side {
    width: 100%;
  }
}
.product_list-relative_container {
  position: relative;
  height: 100%;
}
.product_list-absolute_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
@media (max-width: 768px) {
  .product_list-absolute_container {
    position: static;
  }
}
.scroll {
  overflow-y: scroll;
}
.next-btn {
    background-color: var(--brand-color);
    border-radius: 6px;
    margin-bottom: 40px;
    color: white;
    height: 48px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}
.next-btn.disabled {
    background: #E3E3E3 !important;
    border-color: #E3E3E3 !important;
    color: #6F6F6F;


    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}
.confirm-msg {
    background: #FFFFFF;
    border: 1px solid #DDDFE1;
    box-sizing: border-box;
    border-radius: 6px;


    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}
@media screen and (max-width: 425px) {
  .next-btn,
  .next-btn.disabled {
    height: 48px;
    width: 100%;
  }
}
</style>
