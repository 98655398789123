import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import de from 'vee-validate/dist/locale/de.json';
import fr from 'vee-validate/dist/locale/fr.json';
import tr from 'vee-validate/dist/locale/tr.json';

const ibantools = require('ibantools');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('mandatory', {
  ...rules['required'],
  message: 'Bu alan zorunludur'
})

extend('smsCodeLength', {
  validate: value => /^(\d{4})?$/.test(value),
})
extend('min2characters', {
  validate: value => {
    if(value.length < 2){
      return false
    } else return true
  }
})
extend('ibanName' , {
  validate: value => rules['alpha_spaces'].validate(value),
  message: item => `IBAN hesabının ${item.includes('Soyadı') ? 'soyadı' : 'ilk adı'} yalnızca harf içerebilir.`
})

extend('nameWithSpaces',{
  validate: value => {
    return /^([a-zA-ZßäöüÄÖÜÅåÅåÆæØø]{1,}(\s|-))*[a-zA-ZßäöüÄÖÜÅåÅåÆæØø]{1,}$/.test(value);
  },
  message: 'İsim yalnızca harf içerebilir.'
})

extend('lastNameWithSpaces',{
  validate: value => {
    return /^([a-zA-ZßäöüÄÖÜÅåÅåÆæØø]{1,}(\s|-))*[a-zA-ZßäöüÄÖÜÅåÅåÆæØø]{1,}$/.test(value);
  },
  message: 'Soy isim yalnızca harf içerebilir.'
})
extend('birthdate' , {
  validate: value => true,
})

extend('password', {
  validate: value => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W]{8,}$/.test(value),
});
extend('plz', {
  validate: value => /^(\d{5})?$/.test(value),
  message: 'Posta kodu 5 rakamdan oluşmalıdır.'
});
extend('numara', {
  validate: value => /^[a-zA-Z0-9-\s/]+$/.test(value),
  message: 'No geçersiz'
});

extend('iban', {
  validate: value => {
    const valNoSpace = "TR" + value.replace(/\s/g, '')
    return ibantools.isValidIBAN(valNoSpace)
  },
  message: `Lütfen katılımcı ülkelerin geçerli bir IBAN'ını kullanın`
});
extend('ibanCountries', {
  validate: (value, countries) => {
    if (countries.length) {
      const countryCode = value.substring(0, 2)
      return countries.includes(countryCode)
    }
  },
  message: 'Bitte geben Sie einen gültigen IBAN ein'
});
extend('sepa', {
  validate: (value) => {
    const countryCode = value.substring(2, 0)
    return ibantools.isSEPACountry(countryCode)
  },
  message: 'Lütfen geçerli bir IBAN girin'
});
extend('ibanTurkey', {
  validate: (value) => {
    const countryCode = value.substring(2, 0)
    if(countryCode ==="TR") return true
  },
  message: 'Lütfen geçerli bir IBAN girin'
});
extend('bic', {
  validate: value => !!ibantools.isValidBIC(value),
  message: 'BIC ist ungültig'
});
extend('phonePrefix', {
  validate: value => /^[0-9]{1,3}$/.test(value),
  message: 'Prefix ist ungültig'
});
extend('street', {
  validate: value => /^[a-zA-Z0-9\s/-]+$/.test(value),
  message: item => `${item} yalnızca harf, boşluk, eğik çizgi ve kısa çizgi içerebilir`
});
extend('identityCard', {
  validate: value => /^(\d{11})?$/.test(value),
  message: `T.C. kimlik numarası 11 karakterden oluşmalıdır. Sadece rakam kullanabilirsiniz.`
});
extend('phoneNumber', {
  validate: value => {
    if(value.substring(0,1) == '5' && value.length == 10 && /^[0-9]+$/.test(value)) return true
  }
});
extend('phoneNumberAz', {
  validate: value => {
    if(value.length == 9 && /^[0-9]+$/.test(value)) return true
  }
});
extend('fiscalId', {
  validate: value => {
    return value.length == 12
  }
})

extend('phoneNumberHome', {
  validate: value => {
    if(value.substring(0,1) == '5' && value.length == 10 && /^[0-9]+$/.test(value)) return true
  },
  message: 'Telefon numarası geçersiz'
});
// let wihtCustomMessages = {
//   ...de.messages,
//   length: (input, { length }) => `${input} muss eine ${length}-stellige Zahl sein`
// }

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule] // copies rule configuration
    // message: wihtCustomMessages[rule] // assign message
  });
});


localize({
  de: {
    messages: de.messages
  },
  fr: {
    messages: fr.messages
  },
  tr: {
    messages: tr.messages
  }
});
let LOCALE = "tr";


// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get () {
    return LOCALE;
  },
  set (val) {
    LOCALE = val;
    localize(val);
  }
});
