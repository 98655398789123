<template>
  <div class="content poz_rel content_doi" :style="`--brand-color:${colorSchema}; background-image: url('/assets/doi/${campaignName}/doi-first.png'), url('/assets/doi/${campaignName}/doi-second.png'), url('/assets/doi/${campaignName}/flowers-bg.png');`">
    <div class="container steps_container">
      <div class="row justify-content-center">
        <div class="col-lg-12 offset-lg-0 col-md-10 col-sm-12 offset-sm-0">
          <div class="wrapper_d_title wrapper_d_title_dop wrapper_d_title_l">
            <h1 class="h1">{{title}}</h1>
            <h4 class="h4">{{text}}</h4>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a href="/" class="btn btn-yellow btn-lg btn-yellow-min mb-10">{{button}}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "doi",
  props: {
    endpoint: {
      type: String,
      required: true
    },
    title: String,
    text: String,
    button: String,
    colorSchema: {
      type: String,
      required: true
    },
    campaignName: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      verification_code: "",
      loading: true,
      sent: false
    };
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId", "receiptToken"])
  },
  methods: {
    ...mapActions(['checkDoi'])
  },
  mounted () {
    this.verification_code = this.$route.query.verification_code;

    if (this.verification_code) {
      this.checkDoi({ endpoint: this.endpoint, formData: { campaignId: this.campaignId,
        verification_code: this.verification_code,
        customerToken: this.customerToken } }).then(res => {
        if (window.dataLayer) {
          // eslint-disable-next-line
          dataLayer.push({
            event_action: "CompleteRegistration"
          });
        }
        if (res.response) {
          this.sent = true;
        } else {
          // redirect to home if verification code is not valid
          this.$router.replace("/");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content_doi {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left bottom, right bottom, 22% 25%;
  background-size: 30%, 18%, 60%;
  padding-bottom: 270px;
  color: #1D1D1D !important;
}
.steps_container {
  max-width: 700px !important;
  margin: 0 auto;
}
.wrapper_d_title_dop {
  text-align: center;
}
.wrapper_d_title {
  margin-top: 81px;
  margin-bottom: 75px;
  text-align: center;
}
.btn {
  color: #FFFFFF !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 100px;
  transition: all 0.3s;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  padding: 3px 20px;
  box-shadow: none !important;
  max-height: 40px;
  &.disabled {
    opacity: 0.3;
  }
  &-lg {
    max-width: 470px;
    width: 100%;
  }
  &-md {
    max-width: 226px;
    width: 100%;
  }
  &-yellow {
    border-color: var(--brand-color);
    background-color: var(--brand-color);
    &:hover,
    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: var(--brand-color);
    }
    &-min {
      @media (max-width: 574px) {
        font-size: 17px;
      }
    }
  }
  &-white {
    font-size: 16px;
    font-weight: 700;
    border-color: #ffffff;
    background-color: #ffffff;
    color: #1d1d1d !important;
    &:hover,
    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: #1d1d1d;
    }
  }
  &-green {
    border-color: var(--brand-color);
    background-color: var(--brand-color);
    color: #ffffff !important;
    &:hover,
    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: #028c86;
    }
  }
  &-light-blue {
    border-color: #beebe4;
    background-color: #beebe4;
    &:hover,
    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: var(--brand-color);
    }
  }
  &-transparent {
    max-width: 350px;
    border-color: #424242;
    color: #424242;
    &:hover,
    &focus {
      background-color: #424242;
      color: #ffffff;
    }
  }
}
.btn_abs {
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  margin-top: 15px;
  margin-bottom: 32px;
}
.h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}
@media (max-width: 1240px) {
  .text-center {
    margin-top: 50%;
  }
  .content_doi {
    background-position: 25% 70%, 75% 70%, center top;
    background-size: 35%, 35%, 80%;
    padding-bottom: 50px;
  }
}
@media (max-width: 550px) {
  .text-center {
    margin-top: 38%;
  }
  .content_doi {
    background-position: 22% 75%, 78% 75%, center top;
    background-size: 40%, 40%, 100%;
  }
}
</style>
