<template>
  <div class="gray_block">
    <img src="../../assets/icons/barcode-info.svg" alt="Barcode info icon">
    <div class="inner_gray_block">
      <div class="text color-black mb-30" v-html="descriptionText" />
    </div>
    <div class="row align-items-center wrapper-steps-example ">
      <div class="col-3">
      </div>
      <div class="col-5 pr-0 receipt-example" >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "barcode-information",
  props: {
    descriptionText: {
      required: false,
      type: String,
      default: "Lütfen ürününüzün barkod numarasını girin, fotoğrafını çekin veya sürükleyip bırakarak yükleyiniz. <br> Lütfen promosyon ürünleri için barkod bilgilerinin net bir şekilde göründüğünden emin olunuz."
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    background-color: transparent;
    border: 1px solid #DDDFE1;
    margin-bottom: 34px;
    padding: 24px;
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 991px) {
      padding: 16px;
    }
    .inner_gray_block {
      padding-left: 5%;
      text-align: left;
    }
  }
  .wraper_punkt {
    li {
      margin-bottom: 30px;
      font-size: 16px;
      letter-spacing: 0.16px;
      line-height: 24px;
      @media (max-width: 991px) {
        margin-bottom: 15px;
      }
      .item {
        width: 25px;
        height: 25px;
        display: inline-block;
        background: #8B8B8B;
        color: #ffffff;
        border-radius: 100px;
        text-transform: uppercase;
        margin-right: 20px;
        text-align: center;
        padding: 3px 8px;
      }
      .item_text {
        color: #2E0606;
        display: inline-block;
      }
    }
  }
  .checkMark {
    position: relative;
    float: left;
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
    .checkMark_img {
      position: absolute;
      top: 65px;
      left: 50%;
      margin-left: -27px;
      @media (max-width: 767px) {
        max-width: 30px;
        margin-left: -15px;
      }
    }
    .cross_red_img {
      position: absolute;
      top: 70px;
      left: 50%;
      margin-left: -18px;
      @media (max-width: 767px) {
        max-width: 24px;
        margin-left: -12px;
      }
    }
  }
  .wrapper_check_block {
    padding-top: 90px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 991px) {
      padding-top: 20px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .receipt-example {
    width: 100%;
  }
  .wrapper-steps-example {
    margin-bottom: 30px;
  }
</style>
