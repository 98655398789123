<template>
  <span></span>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "checkReceiptToken",
  mounted () {
    this.check72Hours()
      .then(res => {
        if (!res.response) {
          location.href='expired-page'
        }
      })
  },
  methods: {
    ...mapActions(['check72Hours'])
  }
}
</script>
