import heading from "@/components/renderers/heading";
import paragraph from "@/components/renderers/paragraph";
import squareImage from "@/components/renderers/square-image";
import alert from "@/components/renderers/alert";
import bucket from "@/components/renderers/bucket";
import container from "@/components/renderers/container";
import flexContainer from "@/components/renderers/flex-container";
import card from "@/components/renderers/card";
import keyVisual from "@/components/renderers/key-visual";
import navbar from "@/components/renderers/navbar";
import anchor from "@/components/renderers/anchor";
import receiptProcessor from "@/components/renderers/receipt-processor"
import receiptProcessorAz from "@/components/renderers/receipt-processor-az"
import productUpload from "@/components/renderers/product-upload"
import formComponent from "@/components/renderers/form-component"
import textInput from "@/components/renderers/text-input"
import stepCard from "@/components/renderers/step-card"
import stepsComponent from "@/components/renderers/steps-component"
import selectInput from "@/components/renderers/select-input"
import radioInput from "@/components/renderers/radio-input"
import dateInput from "@/components/renderers/date-input"
import buttonComponent from "@/components/renderers/button-component"
import productSelection from "@/components/renderers/ProductSelection/index.vue"
import emptyBucket from "@/components/renderers/empty-bucket"
import receiptInformation from "@/components/renderers/receipt-information"
import barcodeInformation from "@/components/renderers/barcode-information"
import smsVerification from "@/components/renderers/SmsVerification/sms-verification"
import smsReceiving from "@/components/renderers/SmsVerification/sms-receiving.vue"
import checkbox from "@/components/renderers/checkbox"
import gtinComponent from "@/components/renderers/gtin-component"
import stepCounter from "@/components/renderers/step-counter"
import footerComponent from "@/components/renderers/footer-component"
import scrollButton from "@/components/renderers/scroll-button"
import stepsHorizontal from "@/components/renderers/steps-horizontal"
import question from "@/components/renderers/question"
import textList from "@/components/renderers/text-list"
import reclaimBucket from "@/components/renderers/reclaim-bucket"
import injectTag from "@/components/renderers/inject-tag"
import dataLayerEvent from "@/components/renderers/datalayer-event"
import refreshToken from "@/components/renderers/refresh-token"
import segmentaCookieHandler from "@/components/renderers/segmenta-cookieHandler"
import background from "@/components/renderers/background"
import doi from "@/components/renderers/doi"
import queryStringToState from "@/components/renderers/query-string-to-state"
import barcodeScanner from "@/components/renderers/BarcodeScanner"
import barcodeError from "@/components/renderers/BarcodeError"
import ibanReupload from "@/components/renderers/iban-reupload"
import checkReceiptToken from "@/components/renderers/checkReceiptToken"
import campaignGrid from "@/components/renderers/campaign-grid"
import survey from "@/components/renderers/survey"
import campaignCarousel from "@/components/renderers/campaign-carousel"
import fivePoints from "@/components/renderers/fivePoints"
import RetailerDropdown from "@/components/renderers/RetailerDropdown"
import conditions from "@/components/renderers/conditions"
import faq from "@/components/renderers/faq"
import checkToken from "@/components/renderers/check-token"
import codeComponent from "@/components/renderers/code-component"
import embeddedDoc from "@/components/renderers/embedded-doc"
import participationCount from "@/components/renderers/participation-count"
import changer from "@/components/renderers/changer"
import clipboard from "@/components/renderers/clipboard"
import receiptProcessor123 from "@/components/renderers/Cashback123/receipt-processor-123"
import receiptInformation123 from "@/components/renderers/Cashback123/receipt-information-123"
import smsVerification123 from "@/components/renderers/Cashback123/sms-verification-123"
import productShowcase from "@/components/renderers/productShowcase"
import productSlider from "@/components/renderers/productSlider"
import rewardSelection from "@/components/renderers/RewardSelection/index.vue"
import participantType from "@/components/renderers/ParticipantType"
import shareContactInfo from "@/components/renderers/share-contact-info"
import participantType2 from "@/components/renderers/ParticipantType2"
import tiraj1QazananlarAZ from "@/components/renderers/lotereya/tiraj1-qazananlar"
import tiraj2QazananlarAZ from "@/components/renderers/lotereya/tiraj2-qazananlar"
import pdfViewer from "@/components/renderers/pdf-viewer"

export default [
  queryStringToState,
  segmentaCookieHandler,
  refreshToken,
  dataLayerEvent,
  injectTag,
  reclaimBucket,
  ibanReupload,
  textList,
  question,
  stepCounter,
  receiptInformation,
  barcodeInformation,
  smsVerification,
  smsVerification123,
  smsReceiving,
  heading,
  paragraph,
  squareImage,
  alert,
  bucket,
  emptyBucket,
  container,
  flexContainer,
  card,
  keyVisual,
  navbar,
  anchor,
  receiptProcessor,
  receiptProcessorAz,
  productUpload,
  formComponent,
  textInput,
  stepCard,
  stepsComponent,
  selectInput,
  radioInput,
  dateInput,
  buttonComponent,
  productSelection,
  checkbox,
  gtinComponent,
  footerComponent,
  background,
  doi,
  stepsHorizontal,
  scrollButton,
  barcodeScanner,
  barcodeError,
  checkReceiptToken,
  campaignGrid,
  survey,
  campaignCarousel,
  fivePoints,
  RetailerDropdown,
  conditions,
  faq,
  checkToken,
  codeComponent,
  embeddedDoc,
  participationCount,
  clipboard,
  changer,
  receiptProcessor123,
  receiptInformation123,
  productShowcase,
  productSlider,
  rewardSelection,
  participantType,
  shareContactInfo,
  participantType2,
  tiraj1QazananlarAZ,
  tiraj2QazananlarAZ,
  pdfViewer
];
