<template>
  <div class="barcode-scanner center" :style="`--brand-color: ${colorSchema}`">
    <SearchModule v-if="tabletView" :colorSchema="colorSchema" :maxProductCount="maxProductCount"/>
    <RetailerDropdown v-if="tabletView" v-on:some-event="redirect($event)" :colorSchema="colorSchema"/>

    <div v-if="tabletView && list.length" class='product_list-relative_container'>
      <div class='product_list-absolute_container' :class="{'scroll': list.length ? true : false}">
        <productsList :fullSize="true" :maxProductCount="maxProductCount" />
      </div>
    </div>
    <barcodeInformation v-if="tabletView"/>
    <select v-if="videoDevices.length > 1 && scannerActive && !isIOS" v-model="selectedDeviceId" @change="switchCamera">
      <option v-for="(device, index) in videoDevices" :value="device.deviceId" :key="device.label">
        {{ device.label.includes('front') ? `Vordere Kamera ${index}` : device.label.includes('back') ? `Rückseitige Kamera ${index}` : `Kamera ${index}` }}
      </option>
    </select>
    <div id="videoWindow" class="video" :class="{'active': scannerActive && cameraIsFound || stayActive}">
      <video id="video" src=""></video>
      <canvas class="drawingBuffer"></canvas>
       <div v-if="!scannerActive && !!imgList.length" class="video-icon">
        <b-icon icon="check-circle" style="width: 120px; height: 120px;" variant="success"></b-icon>
      </div>""
    </div>

    <img src="../../../assets/images/red_cross.png" style="width: 350px; height: 350px; margin-bottom: 30px;" id="redCross" v-if="!scannerActive && errorPicDisplay && resultSearch && !hideCross"/>
    <div class="barcode-scanner_actions d-flex" :style="{margin: tabletView ? '0px' : '0 0 20px'}">
      <b-button block @click="start" v-if="!scannerActive && !imgList.length && !stayActive" class="scan_btn">Ürün Tarama (En kolay!)</b-button>
      <b-button block @click="start" v-if="!scannerActive && !!imgList.length && !stayActive" class="scan_btn">Ürün Tarama (En kolay!)</b-button>
      <b-button block @click="stop" v-if="scannerActive || stayActive">Stop</b-button>
    </div>

    <div v-if="tabletView" class="barcode-scanner__divider row">
        <div class="col-3"><hr></div>
        <div class="col-6 text-center">veya</div>
        <div class="col-3"><hr></div>
    </div>

    <div v-if="!tabletView" class="barcode-scanner_icons">
      <img src="../../../assets/icons/form/video-camera.svg"/>
      <img src="../../../assets/icons/form/ar-camera.svg"/>
    </div>

    <div  class="uploadInputSection">
      <div class="container container-md " style="height: 100%; padding: 0px;">
        <file-uploader
          :file-uploader-border-color="colorSchema"
          :file-uploader-background-color="colorSchema"
          :description="texts.receiptUploadDescription"
          :btn-text="texts.receiptUpload"
          :isBarcodeUploader="true"
          @file-picked="onFilePicked"
          :texts="{
            wrongFormat: 'Dosya biçimi desteklenmiyor. Yalnızca .jpeg veya .png dosyaları kabul edilir.',
            fileSizeLimit: 'Dosya çok büyük. Yalnızca 16 MB\'a kadar olan dosyalar desteklenir.'
            }"
          :btnShape="tabletView"
        />
      </div>
    </div>
    <div v-if="!tabletView" id="result_strip">
      <ul class="thumbnails p-0">
        <template v-for="(item, idx) in imgList">
          <li v-if="idx + 1 === imgList.length" :key="item+Math.random()">
            <div class="img-container" v-if="!!item">
              <div class="img-bg-container" :style="{backgroundImage: `url(${item.img.toDataURL()})`}" />
              <div class="img-cross-container" v-if="!inputStatus || errorTextVisible" @click="unsetFile">X</div>
            </div>
          </li>
        </template>
      </ul>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { BrowserBarcodeReader } from '@zxing/library';
import { promiseTimeout } from './promise-timeout'
import FileUploader from '../file-uploader'
import SearchModule from './SearchModule'
import ProductsList from '../ProductsList'
import BarcodeInformation from '../barcode-information'

const codeReader = new BrowserBarcodeReader();

export default {
  components: { FileUploader , SearchModule, ProductsList, BarcodeInformation },
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      errorTextVisible: false,
      selectedDeviceId: null,
      url: null,
      stopPressed: false,
      errorPicDisplay: false,
      cameraIsFound: true,
      videoDevices: [],
      hideCross: false,
      stayActive: false
    }
  },
  props: {
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#002458"
    },
    cameraTimeOut: {
      type: Number
    },
    tabletView: {
      type: Boolean
    },
    list: {
      type: Array
    },
    maxProductCount: Number
  },
  computed: {
    ...mapState({
      barcode: state => state.barcode,
      types: state => state.types,
      file: state => state.file,
      scannerActive: state => state.scannerActive,
      imgList: state => state.imgList,
      status: state => state.searchStatus,
      inputStatus () {
        let state;
        switch(this.status) {
        case 'success':
          state = true;
          break;
        case 'not_found':
          state = false;
          break;
        case null:
          state = null;
          break;
        default:
          break;
        }
        return state
      },
      resultSearch () {
        return this.status !== 'success'
      }
    }),
    isIOS () {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
  },
  mounted () {
    codeReader
      .listVideoInputDevices()
      .then(videoInputDevices => {
        if (videoInputDevices[1] && videoInputDevices[1].deviceId) {
          this.videoDevices = videoInputDevices;
          this.selectedDeviceId = videoInputDevices[1].deviceId;
        } else if (videoInputDevices[0] && videoInputDevices[0].deviceId) {
          this.videoDevices = videoInputDevices;
          this.selectedDeviceId = videoInputDevices[0].deviceId;
        }
      })
      .catch(err => console.error(err));
  },
  methods: {
    ...mapActions(["getBarCode", "updateScanner", "uploadFile", "setImgList", "searchProductByBarcode", "updateSearchStatus", "handleProductCode", "updateScannerErrorMessage" , "setErrorActivate"]),
    onFilePicked (file) {
      this.url = URL.createObjectURL(file);
      this.selectedFile = file;
      this.decode();
      this.errorTextVisible = this.errorPicDisplay = false;
      this.$emit('input', file);
      this.setErrorActivate(false);
    },
    decode () {
      this.errorTextVisible = this.errorPicDisplay = false;
      this.setErrorActivate(false);
      this.updateSearchStatus(null);
      codeReader.decodeFromImage(undefined, this.url)
        .then(result => {
          const foundResult = result.text;
          this.getBarCode(foundResult);
          // Barcode is found but need to check if it exists in this campaign
          this.searchProductByBarcode({ barcode: this.barcode, maxProductCount: this.maxProductCount }).then(res => {
            res = res.result
            console.log(res)
            if(res.length === 0) {
              this.updateScannerErrorMessage(this.texts.notParticipating)
              this.searchProductByBarcode({ barcode: this.barcode, maxProductCount: this.maxProductCount })
            }
          });
        })
        .catch(err => {
          console.log("over here ")
          console.error(err);
          this.getBarCode('');
          this.updateScannerErrorMessage(this.texts.invalidBarCode)
          this.searchProductByBarcode({ barcode: this.barcode, maxProductCount: this.maxProductCount })
        })
    },
    start () {
      this.errorTextVisible = false;
      this.setErrorActivate(false)
      this.stopPressed = false;
      this.getBarCode(null);
      this.updateSearchStatus(null);
      this.updateScanner(true); // Update scanner if no error message

      if (!this.cameraIsFound) {
        navigator.getUserMedia = (
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia
        );

        if (typeof navigator.mediaDevices.getUserMedia !== 'undefined') {
          navigator.mediaDevices.getUserMedia({
            video: true
          })
            .then(res => {

              // Camera is on and can be restarted then
              this.errorPicDisplay = false
              this.cameraIsFound = true
              this.start()
              console.log("CAMERA ON")
            }).
            catch(err => {
              console.log("CAMERA OFF")
              console.log(err)
            });
        }
      }


      // Run the actual code reader if scannerActive is true
      if (this.scannerActive) {
        console.log("Chacking barcode")
        let codeReaderPromise = promiseTimeout(this.cameraTimeOut , codeReader.decodeOnceFromVideoDevice(this.selectedDeviceId, 'video'))
        codeReaderPromise.then((result) => {
          this.updateScanner(true);
          const foundResult = result.text;
          this.getBarCode(foundResult);
          if(this.barcode) {
            this.playSound();
            this.searchProductByBarcode({ barcode: this.barcode, maxProductCount: this.maxProductCount });
          }
        }).catch((err) => {
          console.error(err);

          // Error Message When Camera not found
          if (err?.name == "NotAllowedError") {
            this.updateScannerErrorMessage(this.texts.cameraNotFound)
            console.log(this.texts.cameraNotFound)

            // When camera is not found, turn camer is found to false
            this.cameraIsFound = false;
          }

          // The promise timed out
          if (err === "time-out") {
            this.updateScannerErrorMessage(this.texts.timedOut)
            this.errorPicDisplay = true
          }

          this.searchProductByBarcode(this.barcode)
          this.errorPicDisplay = this.stopPressed === true ? false : true;

        }).finally(() => {
          // With a completion of a Scan or Failure, in both cases the Video will be closedss
          this.stop();
        })
      }
    },
    stop () {
      this.updateScanner(false);
      codeReader.reset();
      this.stopPressed = true;
    },
    playSound () {
      let sound = 'http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3';
      if(sound) {
        let audio = new Audio(sound);
        audio.play();
      }
    },
    unsetFile () {
      this.setImgList([]);
      this.handleProductCode('');
      this.errorTextVisible = this.errorPicDisplay = false;
      this.setErrorActivate(false);
    },
    switchCamera (e) {
      this.stayActive = true;
      this.selectedDeviceId = e.target.value;
      delete this.$store.state.errorActivate;
      this.hideCross = true;
      codeReader.reset();
      setTimeout(() => {
        this.start();
        this.stayActive = false;
        this.$store.state['errorActivate'] = false;
        this.hideCross = false;
      }, 100)
    }
  }
}
</script>

<style scoped lang="scss">
#videoWindow {
  height: 0;
  overflow: hidden;
  max-width: 415px;
  position: relative;
  &.active {
    height: 310px;
  }
}
video {
  max-height: 400px;
  max-width: 415px;
  height: 310px;
  width: 415px;
}
canvas {
  max-width: 100px;
  max-height: 100px;
}
.video-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.barcode-scanner {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}
.gray_block {
  margin-top: 30px;
}
.barcode-scanner {
  &_actions {
    // margin: 0 0 20px;
    width: 100%;
  }
  &_icons {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    padding: 0 10px;
    flex-direction: row;
    justify-content: space-between;
  }
  ul, li {
    list-style: none;
  }
  &__divider {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 25px;
      hr {
        border: 1px solid #939393;
      }
      .col-6 {
        font-size: 20px;
        line-height: 32px;
      }
    }
}
.scan_btn {
  border: 0;
  padding: 0;
  letter-spacing: -0.7px;
  height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    /* identical to box height, or 129% */

    background: var(--brand-color);
    border-radius: 6px;
}
.drag-drop {
    &_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-weight: 200;
      }
      div {
        font-family: 'Montserrat', serif
      }
    }
    &_btn {
      width: 75%;
      height: 40px;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: var(--brand-color);
      border: 1px solid var(--brand-color);
      border-radius: 6px;
  }
}
#result_strip {
  ul {
    width: 100%;
    margin-top: 1rem;
  }
  .img-container {
    position: relative;
    width: 100%;
    padding-bottom: 62.5%;
    .img-bg-container {
      background-size: cover;
      background-position: center center;
    }
    .img-cross-container, .img-bg-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .img-cross-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: 700;
      color: red;
      cursor: pointer;
    }
  }
}
.uploadInputSection {
  @media (max-width: 425px) {
    width: 100%;
  }
}
.uploadDescription {
  font-size: 12px;
  line-height: 22px;
}
.text-center {
  text-align: center !important;
}
.file_upload {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  border: 1px dashed #6F6F6F;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  margin: 0;
  &:hover {
    & .file_upload_background {
      opacity: 0.2;
      transition: 0.4s ease-in-out;
    }
  }
  & .file_upload_background {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
    background-color: #009639;
    opacity: 0.06;
    transition: 0.4s;
  }
}
.file_upload input[type="file"] {
  display: none;
}
.hidden {
  display: none;
}
.wrapper_square_img {
  position: relative;
  display: inline-block;
  width: 89%;
}
.inputfile {
  display: none;
}
.error-text {
  color: red;
}

#redCross {
  color: red;
  height: 350px;
  width: 350px;;
}

</style>
