<template>
  <div class="poz_rel product-upload" :class="className" :style="`--brand-color: ${colorSchema}`">
    <div class="wrapper_file_upload"
         :class="{ highlight: highlight }"
         @dragover.prevent
         @drop.stop.prevent="handleDrop"
         @dragenter.stop.prevent="onDragEnter"
         @dragleave.stop.prevent="onDragLeave"
    >
      <div class="file_upload_background"></div>
      <label v-show="!selectedFile" class="file_upload">
        <input
          @change="onFilePicked"
          accept="image/*"
          class="inputfile"
          type="file"
          name="file"
          ref="file"
        />

        <svg class="upload_img" xmlns="http://www.w3.org/2000/svg" width="55" height="56">
          <rect width="55" height="56" rx="16" :style="`fill:${colorSchema}`"/>
          <path style="fill:#fff" d="M40.752 22.902a8.39 8.39 0 0 0 .142-1.528 8.623 8.623 0 0 0-16.729-2.926 4.311 4.311 0 0 0-5.787 3 6.467 6.467 0 0 0 .959 12.863h5.389a1.078 1.078 0 0 0 0-2.156h-5.389a4.312 4.312 0 1 1 0-8.623 1.078 1.078 0 0 0 1.077-1.078 2.156 2.156 0 0 1 3.665-1.536 1.078 1.078 0 0 0 1.82-.595 6.461 6.461 0 0 1 12.747 2.119q-.052.315-.136.623a1.078 1.078 0 0 0 .445 1.186 4.312 4.312 0 0 1-2.373 7.9h-3.233a1.078 1.078 0 0 0 0 2.156h3.234a6.467 6.467 0 0 0 4.169-11.405z"/>
          <path style="fill:#fff" d="M35.189 28.16 29.8 22.771a1.065 1.065 0 0 0-.351-.234 1.081 1.081 0 0 0-1.175.234l-5.389 5.389a1.078 1.078 0 0 0 1.524 1.524l3.552-3.549v15.719a1.078 1.078 0 1 0 2.156 0V26.135l3.549 3.549a1.078 1.078 0 0 0 1.524-1.524z"/>
        </svg>        <div class="text receiptUpload1 color-black" v-html="this.texts.receiptUpload1"></div>
        <div class="text receiptUpload3 color-black" v-html="this.texts.receiptUpload3"></div>
        <div class="text receiptUpload2 color-black" v-html="this.texts.receiptUpload2"></div>
      </label>
      <div v-show="selectedFile" class="row row-dop justify-content-center">
        <label class="wrapper_square_img">
          <img
            class="square-img img-fluid"
            ref="preview"
            src="../../assets/icons/placeholder.svg"
            alt="Placeholder"
          />
          <div @click="removeImage" class="delete_block">
            <img class="delete_img" src="../../assets/icons/delete.svg" alt="Delete icon" />
          </div>
        </label>
      </div>
      <a href="#" @click.prevent="$refs.file.click()" class="btn btn-green w-100 btn_abs btn-min">{{ this.texts.choose }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "product-upload",
  props: {
    fieldId: String,
    endpoint: String,
    successAction: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    title: {
      type: String,
      default: "Laden Sie Ihr Foto hoch"
    },
    uploadText: {
      type: String,
      default: "Datei auswählen"
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    tokenName: {
      required: false,
      type: String,
      default: "token"
    },
    reUpload: {
      required: false,
      type: Boolean
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    className: String
  },
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      highlight: false
    };
  },
  components: {},
  methods: {
    clear () {
      this.selectedFile = null;
      this.setValue(null);
    },
    removeImage () {
      this.selectedFile = null;
      this.setValue(null);
      this.$refs.file.value = null;
    },
    onFilePicked (event) {
      const files = event.target.files || event.dataTransfer.files;
      this.handleFiles(files)
    },
    onDragEnter () {
      this.highlight = true
    },
    onDragLeave () {
      this.highlight = false
    },
    handleDrop (e) {
      let dt = e.dataTransfer
      let files = dt.files
      this.handleFiles(files)
    },
    handleFiles (files) {
      const maxfilesize = 1024 * 1024 * 16; // 1 Mb

      if (files && files[0]) {
        let filename = files[0].name;

        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }
        if (files[0].size > maxfilesize) {
          this.showModal(this.texts.fileSizeLimit);
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          this.showModal(this.texts.wrongFormat);
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        this.selectedFile = files[0];
        // console.log("file", this.selectedFile)
        this.setPreview(this.selectedFile);
        this.setValue(this.selectedFile);
      }
    },
    setValue (value) {
      this.$emit("component-updated", {
        value
      });
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: !!value
      });
    },
    setPreview (file) {
      const reader = new FileReader();

      this.selectedFile = file;
      reader.onload = e => {
        this.$refs.preview.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    sendProductPhoto (formId, triggers) {
      const formData = {
        image: this.selectedFile,
        endpoint: this.endpoint,
        receiptToken: this.$store.getters.receiptToken
      };

      if (this.reUpload) {
        formData["receiptToken"] = this.$route.query[this.tokenName];
        formData["customerToken"] = 1;
        // console.log("REUPLOAD",this.reUpload, formData)
      }

      this.$store.dispatch("uploadProductPhotos", formData).then(r => {
        // console.log(this.successAction, this.response);
        if (r.response && this.successAction) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
        }
      }).catch (err => {
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      })
    }
  },
  mounted () {
    if (this.value) {
      this.setPreview(this.value);
    }
    this.$eventHub.$on("sendProductPhotos-event", this.sendProductPhoto);
  }
};
</script>

<style scoped lang="scss">
.product-upload {
  min-height: 529px;
  @media (max-width: 1024px) {
    min-height: 341px;
  }
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.4s;
}
.uploadIcon {
  position: absolute;
  top: 0;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.uploadDescription {
  font-size: 12px;
  line-height: 22px;
}
.receiptUploaderWrapper {
  border: 1px solid #dddfe1;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 240px;
  padding: 0;
  margin-bottom: 20px;
  width: 86%;
  .receiptScannerPlaceholder {
    color: #97999b;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.text-center {
  text-align: center !important;
}
.hidden {
  display: none;
}
.receipt-uploader {
  overflow: hidden;
}

.wrapper_square_img {
  position: relative;
  display: inline-block;
  width: 89%;
}
.delete_block {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.delete_block .delete_img {
  width: 16px;
}
.section-title {
  color: #474747;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  text-align: center;
  margin-bottom: 30px;
}
.upload-canvas {
  box-sizing: border-box;
  height: 176px;
  border: 2px dashed #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  transition: background-color 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }

  .upload-text {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.16px;
  }

  .upload-icon {
    text-align: center;
  }
}
.upload-hint {
  margin-top: 14px;
  opacity: 0.64;
  color: #474747;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 16px;
}
.inputfile {
  display: none;
}
.wrapper_file_upload {
  position: relative;
  height: 100%;
  background-color: rgba(255,255,255,.3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  border: 2px dashed var(--brand-color);
  border-bottom-style: solid;
  border-radius: 8px;

  padding-bottom: 54px;
  padding-left: 55px;
  padding-right: 55px;
  @media (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  &_prog {
    border-style: solid;
  }
  .file_upload {
    max-width: 350px;
    text-align: center;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;
    .text {
      @media (max-width: 767px) {
        line-height: 32px;
      }
    }
  }
  &:hover {
            & .file_upload_background {
                opacity: 0.2;
                transition: 0.4s ease-in-out;
            }
        }
  & .file_upload_background {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            z-index: -1;
            background-color: var(--brand-color);
            opacity: 0.06;
            transition: 0.4s;
        }
}
}
.upload_img {
  transform: scale(2);
  margin-bottom: 60px;
}
.file_upload input[type="file"] {
  display: none;
}
.color-green {
  color: var(--brand-color) !important;
}
.btn {
  color: #1D1D1D !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: all 0.3s;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  padding: 10px 30px;
  box-shadow: none !important;
  min-height: 54px;
  &.disabled {
    opacity: 0.3;
  }
  &-lg {
    max-width: 470px;
    width: 100%;
  }
  &-md {
    max-width: 226px;
    width: 100%;
  }
  &-yellow {
    border-color: #FED406;
    background-color: #FED406;
    &:hover,
    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: #DEBA06;
    }
  }
  &-min {
    @media (max-width: 574px) {
      font-size:  17px;
    }
  }
  &-white {
    font-size: 16px;
    font-weight: 32px;
    border-color: #ffffff;
    background-color: #ffffff;
    color: #1D1D1D !important;
    &:hover,
    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: #1D1D1D;
    }
  }
  &-green {
    border-color: var(--brand-color);
    background-color: var(--brand-color);
    color: #ffffff !important;
    &:hover,
    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: #028c86;
    }
  }
  &-light-blue {
    border-color: #BEEBE4;
    background-color: #BEEBE4;
    &:hover,
    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: var(--brand-color);
    }
  }
  &-transparent {
    max-width: 350px;
    border-color: #424242;
    color: #424242;
    &:hover,
    &focus {
      background-color: #424242;
      color: #ffffff;
    }
  }
}
.btn_abs {
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.poz_rel {
  position: relative;
}
.container_sm {
  max-width: 710px !important;
}

.receiptUpload1 {
  font: normal normal 500 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  padding-bottom: 20px;

  @media (max-width: 991px) { display: none; }
}

.receiptUpload2 {
  font: normal normal 300 16px/22px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  @media (max-width: 991px) { font: normal normal 500 12px/16px Montserrat !important; }
}

.receiptUpload3 {

  display: none;

  @media (max-width: 991px) {

    display: grid;

    padding-bottom: 20px;

    font: normal normal 500 16px/19px Montserrat !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
}
</style>
