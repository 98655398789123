<template>
  <div class="participants-container">
    <participant
      v-for="(participant, idx) in participants"
      :participant="participant"
      :type="participant.type"
      @itemClick="itemClick(participant)"
      :key="idx"
      :selected="isSelected(participant)"
      :class="{ active: isSelected(participant) }"
      :color-schema="colorSchema != '' ? colorSchema : '#8B8B8B'"
      :css-string="cssString"
      style
    />
    <div
      class="scroll-btn"
      v-if="this.selected != null"
      @click.prevent="scrollDown"
    >
      <span
        id="scroll-btn"
        class="material-icons md-48 d-md-flex d-none"
      >arrow_circle_down</span>
    </div>
    <section
      data-section="r0924fw"
      class="section-container row col-10 col-lg-12 col-sm-12 col-md-12"
      style="margin-bottom: 35px; margin-top: 40px; justify-content: center;"
    >
      <div
        style="justify-content: center; align-items: center; column-gap: 30px;;"
        class="row col-lg-10 col-lg-10 component-wrapper"
      >
        <button-component
          class="btn  col-sm-4 col-xl-3 backBtn col-lg-4"
          text="Geri"
          :action="{ fn: 'goToPage', params: this.backUrl }"
        />
        <button-component
          class="btn  col-sm-4 col-xl-3 nextBtn col-lg-4"
          text="İleri"
          :disabled="this.selected == null"
          :action="{ fn: 'selectParticipantType', params: '' }"
        />
      </div>
    </section>
  </div>
</template>

<script>
import participant from "./participant.vue";

export default {
  name: "ParticipantType",
  components: { participant },
  props: {
    participants: {
      type: Array,
      required: true,
    },
    endpoint: {
      type: String,
      default: "/chooseReward",
    },
    backUrl: {
      type: String,
      default: "/",
      required: true,
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B",
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fieldId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      cssString: "",
      selected: null,
      isActive: false
    };
  },
  watch: {
    styles: {
      immediate: true,
      handler() {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    }
  },
  methods: {
    clear() {
      this.selected = null;
    },
    itemClick(participant) {
      let isSelectedBefore = this.selected?.type === participant.type

      if (isSelectedBefore) {
        this.clear();
      } else {
        this.selected = participant;
        this.$emit("component-updated", {
          selected: this.selected
        });
      }
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: this.selected != null
      });
    },
    isSelected(participant) {
      return this.selected?.type === participant.type;
    },
    selectType() {
      const formData = {
        type: this.selected.type
      };
      this.$store.dispatch("selectParticipantType", { formData, endpoint: this.endpoint }).then((r) => {
        if (r.response) {
          if (this.selected.type === 'company') this.$store.dispatch('setParticipantIsCompany', true)
          else this.$store.dispatch('setParticipantIsCompany', false)
        
          const fn = this.selected.action['fn'];
          const params = this.selected.action['params'];
          this[fn](params, this);
          
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },
    scrollDown() {
      window.scrollTo({
        top: document.getElementsByClassName("participants-container")[0]
          .clientHeight,
        left: 0,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    this.$eventHub.$on("selectParticipantType-event", this.selectType);
  },
  beforeDestroy() {
    this.clear();
    this.$eventHub.$off("selectParticipantType-event");
  }
};
</script>

<style lang="scss" scoped>
.participants-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 30px;
  margin-top: 30px;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  div {
    flex: 1 1 20%;
    box-sizing: border-box;
    align-self: stretch;
  }
}

.scroll-btn {
  @media (min-width: 767px) {
    display: none;
  }

  transition-delay: 1s;

  span {
    cursor: pointer;
  }

  position: fixed;
  bottom: 35vh;
  left: 80vw;
  text-align: right;
}
</style>
