<template>
  <ValidationObserver ref="codeForm">
    <form class="row" :id="fieldId" :style="styles">
      <div class="wrapper">
        <div :class="topClass"></div>
        <div class="middle">
          <div class="barcode-img">
            <img v-if="barcodeImg" :src="barcodeImg" alt="Barcode">
            <span v-else>Sizinle paylaşılan kuponda yer alan kodu aşağı giriniz.</span>
          </div>
          <div class="input">
            <textInput
              name="code"
              id="code"
              v-model="code"
              label="Sizinle paylaşılan kodu giriniz*"
              :validation="`required`"
              :label-on-top="false"
              :no-label="true"
            />
            <paragraph
              text="Her kod tek bir seferde geçerlidir."
              :styles="{
                color: '#000',
                fontSize: '16px',
                textAlign: 'center'
              }"
            />
          </div>
          <div class="button">
            <buttonComponent
              class="nextBtn"
              text="DEVAM"
              :action="{ fn: 'sendCode', params: '' }"
              :requires="['code']"
            />
          </div>
        </div>
        <div :class="bottomClass"></div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import textInput from "@/components/renderers/text-input.vue";
import buttonComponent from "@/components/renderers/button-component.vue";
import paragraph from "@/components/renderers/paragraph.vue";
import { mapGetters } from "vuex";

export default {
  name: "code-component",
  components: {
    textInput,
    buttonComponent,
    paragraph
  },
  data () {
    return {
      code: ""
    };
  },
  props: {
    fieldId: String,
    successAction: [Object, Array],
    endpoint: {
      type: String,
      required: true
    },
    styles: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    barcodeImg: {
      type: String,
      default: '/assets/dishekimlerio/barcode.svg'
    },
    topClass: {
      type: String,
      default: 'top'
    },
    bottomClass: {
      type: String,
      default: 'bottom'
    }
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId"])
  },
  methods: {
    sendCode () {
      let formData = {};

      if (this.locale) {
        formData.lang = this.locale;
      }
      formData.customerToken = this.customerToken;
      formData.campaignId = this.campaignId;
      formData.code = this.code

      this.$refs.codeForm.validate().then(isValid => {
        if (isValid) {
          // console.log("SENDING FORM>>", formData);
          this.$store
            .dispatch("sendFormData", { formData, endpoint: this.endpoint })
            .then(r => {
              if (r.response && this.successAction) {
                const fn = this.successAction.fn;
                const params = this.successAction.params;
                this[fn](params, this);
                this.$eventHub.$emit("done-event-trigger", {
                  parent: this,
                  status: true
                });
              }
            })
            .catch(e => {
              this.$eventHub.$emit("done-event-trigger", {
                parent: this,
                status: false
              });
            });
        }
      });
      return formData
    }
  },
  mounted () {
    this.$eventHub.$on("sendCodeData-event", this.sendCode);
  },
  beforeDestroy () {
    this.$eventHub.$off("sendCodeData-event");
  }
};
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .top {
    background: linear-gradient(0deg, #0059F1 0%, #001AAA 100%);
    border-radius: 12px 12px 0px 0px;
    width: 100%;
    height: 50px;
  }

  .middle {
    background: url(@/assets/images/png/middle-code.png);
    background-size: 100% 100%;
    width: 100%;
    height: 300px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    padding: 20px 40px;
    div {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .barcode-img {
      max-width: 280px;
      img {
        width: 100%;
      }
      @media(max-width: 392px){
        margin-top: 20px
      }
      @media(max-width:325px){
        margin-top: 10px;
      }
    }
    .input {
      min-width: 55%;
    }
  }

  .bottom {
    background-image: url(@/assets/images/png/bottom-code.png);
    background-size: 100% 100%;
    width: 100%;
    height: 235px;
    border-radius: 0px 0px 12px 12px;
  }

  .topFixodent {
    background: linear-gradient(0deg, #5682BA 0%, #3675A7 100%);
    border-radius: 12px 12px 0px 0px;
    width: 100%;
    height: 50px;
  }

  .bottomFixodent {
    background-image: url(@/assets/images/png/bottom-code-fixodent.png);
    background-size: 100% 100%;
    width: 100%;
    height: 235px;
    border-radius: 0px 0px 12px 12px;
  }
}
</style>
