<template>
  <div class="m-0 p-0" :class="className">
    <ValidationProvider
      v-if="validation"
      :rules="validation"
      v-slot="{ errors }"
      :name="name"
    >
      <div class="input-group form-group">
        <label
          v-if="labelOnTop"
          :for="id"
          class="input-label"
        >
          {{ label }}
          <span v-if="validation.includes('required')">*</span>
        </label>
        <input
          :style="styles"
          :type="inputType"
          class="form-control"
          :class="{'is-invalid': errors.length}"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="label"
          :maxlength="maxlength"
          :title="placeholder"
        />
        <label
          v-if="!labelOnTop && !noLabel"
          :for="id"
          class="floating-label"
          :class="{'slideUp': slideUp}"
        >
          {{ label }}
        </label>
        <div
          class="validation-error"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <div
      class="m-0 p-0"
      v-else
    >
      <div class="form-group">
        <label
          v-if="labelOnTop"
          :style="labelOnTopStyle"
          class="input-label"
        >
          {{ label }}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>(optional)</span>
        </label>
        <input
          :style="styles"
          :type="inputType"
          class="form-control textInput"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="label"
          :maxlength="maxlength"
          :title="placeholder"
        />
        <label
          v-if="!labelOnTop && !noLabel"
          :for="id"
          class="floating-label"
          :class="{'slideUp': slideUp}"
        >
          {{ label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  data () {
    return{
      label_visibility: true
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    value: {
      type: String,
      default: ""
    },
    maxlength: {
      type: Number,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    slideUp:Boolean,
    labelOnTop: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: true
    },
    isUpperCase: Boolean,
    className: {
      type: String,
      default: ""
    },
  },
  methods: {
    updateValue (val) {
      if (this.name === "iban") {
        val = val.replace(/[^\dA-Z]/g, '')
        // remove the first 2 characters in the value
        const first2 = val.length >= 2 ? val.substring(0, 2) : val;
        const rest = first2.length == 2 ? (val.substring(2, val.length)).replace(/(.{4})/g , '$1 ').trim() : "";
        val = (first2  + rest).replace(/(.{2})/ , '$1 ')
        if (val.length > 30) val = val.substring(0, val.length - 1)
      }
      if (this.$data.label_visibility) {
        this.$data.label_visibility = false
      }

      if(!val){
        this.$data.label_visibility = true
      }
      // console.log(this.$data.label_visibility)
      this.$emit("component-updated", {
        text: val,
        slideUp:!!val
      });
      this.$emit("input", val);
      this.$store.dispatch("setComponentStatus", {
        component: this.id,
        status: !!val
      });
    },
    change_visibility(){
      this.$data.label_visibility = !this.$data.label_visibility
      console.log(this.$data.label_visibility)
    }
  }
};
</script>
