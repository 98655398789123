<template>
  <a :id="fieldId" v-if="url" :target="target" :href='url'>
    <img :srcset="srcset" class="img-fluid p-0" :class="className" :alt="alternateText" :style="styles"/>
  </a>
  <img :id="fieldId" v-else :srcset="srcset" class="img-fluid p-0" :class="className" :alt="alternateText" :style="styles"/>

</template>

<script>
export default {
  name: "square-image",
  props: {
    target:String,
    fieldId:{
      type:String,
      required:false
    },
    imageSource: {
      type: String,
      default: "@/assets/logo.png"
    },
    imageSource2: {
      type: String,
      default: "@/assets/logo.png"
    },
    alternateText: {
      type: String,
      default: "Sample Image"
    },
    url:{
      type:String
    },
    styles:{
      type: Object,
      default:()=>{}
    },
    className: String
  },
  computed: {
    srcset () {
      if (this.imageSource2x) {
        return `${this.imageSource} 1x, ${this.imageSource2x} 2x`
      }
      return `${this.imageSource} 1x`;
    }
  }
};
</script>

<style lang="scss">
img[data-field-type="square-image"] {
  object-fit: cover
}
.forgotImageMobile { max-width: 250px !important; }
.thankyouImage { max-width: 250px !important; object-fit: contain;}

@media (max-width: 991px) {
  .imgMobile {
    margin-top: 30px !important;
    margin-bottom: -30px !important;
  }

  .forgotImageMobile { max-width: 100px !important; }
  .thankyouImage { max-width: 125px !important; margin-bottom: 0px !important;}
}

a {
  display: contents;
}
</style>
