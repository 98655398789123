<template>
  <div class="d-flex flex-column justify-content-center align-items-center w-90 my-4">
    <h1>Çempionlar Lotereyasının 1-ci tirajında qazananlar!</h1>
    <table class="c26">
      <tr class="c1">
        <td
          class="c18"
          colspan="1"
          rowspan="1"
        >
          <p class="c21">
            <span class="c19 c14 c8">İdentifikasiya</span>
          </p>
        </td>
        <td
          class="c17"
          colspan="1"
          rowspan="1"
        >
          <p class="c21">
            <span class="c14 c8 c19">Hədiyyə</span>
          </p>
        </td>
      </tr>
      <tr
        class="c1"
        v-for="(winner, idx) in winners"
        :key="idx"
      >
        <td
          class="c18"
          colspan="1"
          rowspan="1"
        >
          <p class="c3">
            <span class="c9 c8">{{ winner.ref }}</span>
          </p>
        </td>
        <td
          class="c17"
          colspan="1"
          rowspan="1"
        >
          <p class="c3">
            <span class="c9 c8">{{ winner.prize }}</span>
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "Tiraj1Qazananlar",
  data() {
    return {
      winners: [
        { ref: 'ZMQWXL9YTC', prize: 'Samsung Crystal Ultra HD 55 inch TV' },
        { ref: 'HB63FSNV49', prize: 'Samsung Crystal Ultra HD 55 inch TV' },
        { ref: '96BN4ZED9W', prize: 'Samsung Crystal Ultra HD 55 inch TV' },
        { ref: 'TUUCY8EVVT', prize: 'Samsung qabyuyan maşın ' },
        { ref: 'DIS84LPL8J', prize: 'Samsung qabyuyan maşın ' },
        { ref: 'WMTEAQP2Q1', prize: 'Samsung qabyuyan maşın ' },
        { ref: 'S61GF7G9X9', prize: 'Oral-B iO9 diş fırçası' },
        { ref: 'UWSB6HXW4W', prize: 'Oral-B iO9 diş fırçası' },
        { ref: '9UUJVJ1YT7', prize: 'Oral-B iO9 diş fırçası' },
        { ref: 'Z49TSRYGXY', prize: 'Oral-B iO9 diş fırçası' },
        { ref: 'C1L6481KP5', prize: 'Oral-B iO9 diş fırçası' },
        { ref: 'F541X7XYU7', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: '171KFA5GFH', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'SH1M1VFMST', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: '85CNA75FEE', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'YRGNQNJIWG', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'QA8L5D1NID', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'RAMSRDG5SU', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'MTV59L5ALE', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: '3SWM33WRJC', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'WALPVED7M9', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'UCQRAB8HML', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'QF64U6Y86V', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'CIQ54CB7V4', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'VUT5DA32PZ', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'Q2ZEWT42IY', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: '7LYSCBEBXK', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'AB5KJU9VNI', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: '5128Z7KYEJ', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'EA12YV94NA', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'U5E66VJSUQ', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'RCA55AYQXC', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'B8QR9Y3Y93', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: '2K4CZD6NB6', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: 'UJR64TCA4E', prize: 'P&G məhsullarından ibarət səbət' },
        { ref: '2SQ5Q8CKAR', prize: 'P&G məhsullarından ibarət səbət' }
      ]
    }
  }
};
</script>

<style scoped>
.doc-content {
  padding: 20px !important;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.lst-kix_list_4-1>li {
  counter-increment: lst-ctn-kix_list_4-1;
}

.lst-kix_list_14-1>li:before {
  content: "o  ";
}

.lst-kix_list_14-3>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_14-0>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_14-4>li:before {
  content: "o  ";
}

.lst-kix_list_14-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_14-7>li:before {
  content: "o  ";
}

.lst-kix_list_14-6>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_9-3 {
  list-style-type: none;
}

ul.lst-kix_list_9-4 {
  list-style-type: none;
}

ul.lst-kix_list_9-1 {
  list-style-type: none;
}

ul.lst-kix_list_9-2 {
  list-style-type: none;
}

ul.lst-kix_list_9-7 {
  list-style-type: none;
}

ul.lst-kix_list_9-8 {
  list-style-type: none;
}

ul.lst-kix_list_9-5 {
  list-style-type: none;
}

.lst-kix_list_5-0>li {
  counter-increment: lst-ctn-kix_list_5-0;
}

ul.lst-kix_list_9-6 {
  list-style-type: none;
}

ul.lst-kix_list_1-3 {
  list-style-type: none;
}

ul.lst-kix_list_1-4 {
  list-style-type: none;
}

ul.lst-kix_list_1-1 {
  list-style-type: none;
}

ul.lst-kix_list_1-2 {
  list-style-type: none;
}

ul.lst-kix_list_1-7 {
  list-style-type: none;
}

ul.lst-kix_list_9-0 {
  list-style-type: none;
}

ul.lst-kix_list_1-8 {
  list-style-type: none;
}

ul.lst-kix_list_1-5 {
  list-style-type: none;
}

.lst-kix_list_14-2>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_1-6 {
  list-style-type: none;
}

ol.lst-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0;
}

.lst-kix_list_4-3>li {
  counter-increment: lst-ctn-kix_list_4-3;
}

ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}

.lst-kix_list_14-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_5-2>li {
  counter-increment: lst-ctn-kix_list_5-2;
}

.lst-kix_list_5-0>li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) " ";
}

.lst-kix_list_5-4>li {
  counter-increment: lst-ctn-kix_list_5-4;
}

.lst-kix_list_5-3>li:before {
  content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
}

.lst-kix_list_5-2>li:before {
  content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
}

.lst-kix_list_5-1>li:before {
  content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". ";
}

.lst-kix_list_5-7>li:before {
  content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
}

ul.lst-kix_list_8-4 {
  list-style-type: none;
}

ul.lst-kix_list_8-5 {
  list-style-type: none;
}

.lst-kix_list_5-6>li:before {
  content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
}

.lst-kix_list_5-8>li:before {
  content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
}

ul.lst-kix_list_8-2 {
  list-style-type: none;
}

ul.lst-kix_list_8-3 {
  list-style-type: none;
}

ul.lst-kix_list_8-8 {
  list-style-type: none;
}

ul.lst-kix_list_8-6 {
  list-style-type: none;
}

ul.lst-kix_list_8-7 {
  list-style-type: none;
}

.lst-kix_list_5-4>li:before {
  content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
}

.lst-kix_list_5-5>li:before {
  content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
}

ul.lst-kix_list_8-0 {
  list-style-type: none;
}

ul.lst-kix_list_8-1 {
  list-style-type: none;
}

.lst-kix_list_6-1>li:before {
  content: "o  ";
}

.lst-kix_list_6-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_6-0>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_6-4>li:before {
  content: "o  ";
}

ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}

.lst-kix_list_6-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_6-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_6-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_6-7>li:before {
  content: "o  ";
}

.lst-kix_list_6-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_2-7>li:before {
  content: "o  ";
}

.lst-kix_list_7-4>li:before {
  content: "o  ";
}

.lst-kix_list_7-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_2-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_7-2>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_3-8 {
  list-style-type: none;
}

ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}

.lst-kix_list_10-1>li:before {
  content: "o  ";
}

.lst-kix_list_13-7>li:before {
  content: "o  ";
}

ul.lst-kix_list_3-1 {
  list-style-type: none;
}

ul.lst-kix_list_3-2 {
  list-style-type: none;
}

.lst-kix_list_5-7>li {
  counter-increment: lst-ctn-kix_list_5-7;
}

.lst-kix_list_7-8>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_3-0 {
  list-style-type: none;
}

ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}

ul.lst-kix_list_3-5 {
  list-style-type: none;
}

.lst-kix_list_4-7>li {
  counter-increment: lst-ctn-kix_list_4-7;
}

ul.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_3-3 {
  list-style-type: none;
}

ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_10-7>li:before {
  content: "o  ";
}

.lst-kix_list_15-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_10-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_10-3>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_11-7 {
  list-style-type: none;
}

ul.lst-kix_list_11-6 {
  list-style-type: none;
}

.lst-kix_list_4-1>li:before {
  content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
}

ul.lst-kix_list_11-5 {
  list-style-type: none;
}

ul.lst-kix_list_11-4 {
  list-style-type: none;
}

ul.lst-kix_list_11-3 {
  list-style-type: none;
}

.lst-kix_list_15-7>li:before {
  content: "o  ";
}

ul.lst-kix_list_11-2 {
  list-style-type: none;
}

ul.lst-kix_list_11-1 {
  list-style-type: none;
}

ul.lst-kix_list_11-0 {
  list-style-type: none;
}

.lst-kix_list_9-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_4-3>li:before {
  content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
}

.lst-kix_list_4-5>li:before {
  content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
}

ol.lst-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0;
}

ul.lst-kix_list_11-8 {
  list-style-type: none;
}

.lst-kix_list_15-1>li:before {
  content: "o  ";
}

.lst-kix_list_5-5>li {
  counter-increment: lst-ctn-kix_list_5-5;
}

.lst-kix_list_9-0>li:before {
  content: "-  ";
}

.lst-kix_list_15-3>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}

.lst-kix_list_9-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_9-4>li:before {
  content: "o  ";
}

.lst-kix_list_11-3>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

.lst-kix_list_12-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_11-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_12-1>li:before {
  content: "o  ";
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

.lst-kix_list_9-8>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

.lst-kix_list_1-1>li:before {
  content: "o  ";
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

.lst-kix_list_11-7>li:before {
  content: "o  ";
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

ul.lst-kix_list_10-0 {
  list-style-type: none;
}

.lst-kix_list_1-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_13-3>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_10-8 {
  list-style-type: none;
}

.lst-kix_list_4-8>li {
  counter-increment: lst-ctn-kix_list_4-8;
}

ul.lst-kix_list_10-7 {
  list-style-type: none;
}

.lst-kix_list_1-7>li:before {
  content: "o  ";
}

ol.lst-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0;
}

ul.lst-kix_list_10-6 {
  list-style-type: none;
}

ul.lst-kix_list_10-5 {
  list-style-type: none;
}

ul.lst-kix_list_10-4 {
  list-style-type: none;
}

ul.lst-kix_list_10-3 {
  list-style-type: none;
}

.lst-kix_list_1-5>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_10-2 {
  list-style-type: none;
}

ul.lst-kix_list_10-1 {
  list-style-type: none;
}

.lst-kix_list_13-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_5-6>li {
  counter-increment: lst-ctn-kix_list_5-6;
}

.lst-kix_list_12-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_12-7>li:before {
  content: "o  ";
}

.lst-kix_list_2-1>li:before {
  content: "o  ";
}

.lst-kix_list_2-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_4-2>li {
  counter-increment: lst-ctn-kix_list_4-2;
}

.lst-kix_list_13-1>li:before {
  content: "o  ";
}

.lst-kix_list_5-1>li {
  counter-increment: lst-ctn-kix_list_5-1;
}

.lst-kix_list_3-0>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_3-1>li:before {
  content: "o  ";
}

.lst-kix_list_3-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_8-1>li:before {
  content: "o  ";
}

.lst-kix_list_4-0>li {
  counter-increment: lst-ctn-kix_list_4-0;
}

.lst-kix_list_8-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_3-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_3-4>li:before {
  content: "o  ";
}

.lst-kix_list_3-3>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_8-0>li:before {
  content: "-  ";
}

.lst-kix_list_8-7>li:before {
  content: "o  ";
}

.lst-kix_list_3-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_8-5>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_8-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_8-3>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_13-5 {
  list-style-type: none;
}

ul.lst-kix_list_13-4 {
  list-style-type: none;
}

ul.lst-kix_list_13-3 {
  list-style-type: none;
}

.lst-kix_list_3-6>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_13-2 {
  list-style-type: none;
}

ul.lst-kix_list_13-1 {
  list-style-type: none;
}

.lst-kix_list_3-7>li:before {
  content: "o  ";
}

ul.lst-kix_list_13-0 {
  list-style-type: none;
}

.lst-kix_list_8-4>li:before {
  content: "o  ";
}

ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}

.lst-kix_list_11-2>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_13-8 {
  list-style-type: none;
}

.lst-kix_list_11-1>li:before {
  content: "o  ";
}

ul.lst-kix_list_13-7 {
  list-style-type: none;
}

ul.lst-kix_list_13-6 {
  list-style-type: none;
}

ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}

.lst-kix_list_11-0>li:before {
  content: "-  ";
}

.lst-kix_list_8-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_4-4>li {
  counter-increment: lst-ctn-kix_list_4-4;
}

.lst-kix_list_4-8>li:before {
  content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
}

.lst-kix_list_4-7>li:before {
  content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
}

ol.lst-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0;
}

ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}

ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}

ul.lst-kix_list_12-6 {
  list-style-type: none;
}

ul.lst-kix_list_12-5 {
  list-style-type: none;
}

ul.lst-kix_list_12-4 {
  list-style-type: none;
}

ul.lst-kix_list_12-3 {
  list-style-type: none;
}

ul.lst-kix_list_12-2 {
  list-style-type: none;
}

ul.lst-kix_list_12-1 {
  list-style-type: none;
}

ul.lst-kix_list_12-0 {
  list-style-type: none;
}

ol.lst-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0;
}

ul.lst-kix_list_12-8 {
  list-style-type: none;
}

ul.lst-kix_list_12-7 {
  list-style-type: none;
}

.lst-kix_list_7-0>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}

ol.lst-kix_list_5-0 {
  list-style-type: none;
}

.lst-kix_list_2-6>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_5-1 {
  list-style-type: none;
}

ol.lst-kix_list_5-2 {
  list-style-type: none;
}

.lst-kix_list_2-4>li:before {
  content: "o  ";
}

.lst-kix_list_2-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_7-1>li:before {
  content: "o  ";
}

.lst-kix_list_7-5>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0;
}

.lst-kix_list_7-3>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_7-5 {
  list-style-type: none;
}

.lst-kix_list_10-0>li:before {
  content: "-  ";
}

ul.lst-kix_list_7-6 {
  list-style-type: none;
}

ul.lst-kix_list_7-3 {
  list-style-type: none;
}

ul.lst-kix_list_7-4 {
  list-style-type: none;
}

.lst-kix_list_13-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_13-8>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0;
}

ul.lst-kix_list_7-7 {
  list-style-type: none;
}

ul.lst-kix_list_7-8 {
  list-style-type: none;
}

ol.lst-kix_list_5-7 {
  list-style-type: none;
}

ol.lst-kix_list_5-8 {
  list-style-type: none;
}

ol.lst-kix_list_5-3 {
  list-style-type: none;
}

ul.lst-kix_list_7-1 {
  list-style-type: none;
}

ol.lst-kix_list_5-4 {
  list-style-type: none;
}

ul.lst-kix_list_7-2 {
  list-style-type: none;
}

ol.lst-kix_list_5-5 {
  list-style-type: none;
}

ol.lst-kix_list_5-6 {
  list-style-type: none;
}

ul.lst-kix_list_7-0 {
  list-style-type: none;
}

.lst-kix_list_7-7>li:before {
  content: "o  ";
}

.lst-kix_list_15-4>li:before {
  content: "o  ";
}

.lst-kix_list_15-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_5-8>li {
  counter-increment: lst-ctn-kix_list_5-8;
}

.lst-kix_list_10-4>li:before {
  content: "o  ";
}

.lst-kix_list_10-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_4-0>li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
}

ul.lst-kix_list_15-3 {
  list-style-type: none;
}

ul.lst-kix_list_15-2 {
  list-style-type: none;
}

.lst-kix_list_15-0>li:before {
  content: "-  ";
}

ul.lst-kix_list_15-1 {
  list-style-type: none;
}

.lst-kix_list_15-8>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_15-0 {
  list-style-type: none;
}

.lst-kix_list_10-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_4-6>li {
  counter-increment: lst-ctn-kix_list_4-6;
}

.lst-kix_list_4-4>li:before {
  content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
}

ul.lst-kix_list_15-8 {
  list-style-type: none;
}

.lst-kix_list_4-2>li:before {
  content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
}

.lst-kix_list_4-6>li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
}

ul.lst-kix_list_15-7 {
  list-style-type: none;
}

ul.lst-kix_list_15-6 {
  list-style-type: none;
}

.lst-kix_list_9-3>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_15-5 {
  list-style-type: none;
}

ul.lst-kix_list_15-4 {
  list-style-type: none;
}

.lst-kix_list_15-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_10-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_9-1>li:before {
  content: "o  ";
}

ol.lst-kix_list_4-0 {
  list-style-type: none;
}

ol.lst-kix_list_4-1 {
  list-style-type: none;
}

ol.lst-kix_list_4-2 {
  list-style-type: none;
}

ol.lst-kix_list_4-3 {
  list-style-type: none;
}

.lst-kix_list_9-7>li:before {
  content: "o  ";
}

.lst-kix_list_11-4>li:before {
  content: "o  ";
}

.lst-kix_list_12-4>li:before {
  content: "o  ";
}

.lst-kix_list_9-5>li:before {
  content: "\0025aa   ";
}

ul.lst-kix_list_6-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-7 {
  list-style-type: none;
}

.lst-kix_list_5-3>li {
  counter-increment: lst-ctn-kix_list_5-3;
}

ul.lst-kix_list_6-4 {
  list-style-type: none;
}

ul.lst-kix_list_6-5 {
  list-style-type: none;
}

ul.lst-kix_list_6-8 {
  list-style-type: none;
}

.lst-kix_list_12-2>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_11-6>li:before {
  content: "\0025cf   ";
}

.lst-kix_list_1-0>li:before {
  content: "\0025cf   ";
}

ol.lst-kix_list_4-4 {
  list-style-type: none;
}

ul.lst-kix_list_6-2 {
  list-style-type: none;
}

.lst-kix_list_11-8>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_4-5 {
  list-style-type: none;
}

ul.lst-kix_list_6-3 {
  list-style-type: none;
}

.lst-kix_list_1-2>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_4-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-0 {
  list-style-type: none;
}

.lst-kix_list_12-0>li:before {
  content: "-  ";
}

ol.lst-kix_list_4-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-1 {
  list-style-type: none;
}

.lst-kix_list_1-4>li:before {
  content: "o  ";
}

.lst-kix_list_13-0>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_14-4 {
  list-style-type: none;
}

ul.lst-kix_list_14-3 {
  list-style-type: none;
}

ul.lst-kix_list_14-2 {
  list-style-type: none;
}

.lst-kix_list_13-4>li:before {
  content: "o  ";
}

ul.lst-kix_list_14-1 {
  list-style-type: none;
}

ul.lst-kix_list_14-0 {
  list-style-type: none;
}

.lst-kix_list_1-6>li:before {
  content: "\0025cf   ";
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

ul.lst-kix_list_14-8 {
  list-style-type: none;
}

ul.lst-kix_list_14-7 {
  list-style-type: none;
}

.lst-kix_list_2-0>li:before {
  content: "-  ";
}

.lst-kix_list_12-6>li:before {
  content: "\0025cf   ";
}

ul.lst-kix_list_14-6 {
  list-style-type: none;
}

ul.lst-kix_list_14-5 {
  list-style-type: none;
}

.lst-kix_list_4-5>li {
  counter-increment: lst-ctn-kix_list_4-5;
}

.lst-kix_list_1-8>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_2-2>li:before {
  content: "\0025aa   ";
}

.lst-kix_list_13-2>li:before {
  content: "\0025aa   ";
}

ol.lst-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0;
}

.lst-kix_list_12-8>li:before {
  content: "\0025aa   ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c18 {
  border-right-style: solid;
  padding: 0pt 5pt 0pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 105.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c17 {
  border-right-style: solid;
  padding: 0pt 5pt 0pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 326.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c11 {
  border-right-style: solid;
  padding: 0pt 5pt 0pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 78.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c0 {
  color: #222529;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: "Oxanium";
  font-style: normal;
}

.c20 {
  color: #864ea8;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9.5pt;
  font-family: "Calibri";
  font-style: italic;
}

.c15 {
  color: #323f48;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: "Oxanium";
  font-style: normal;
}

.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: "Roboto";
  font-style: normal;
}

.c3 {
  padding-top: 10pt;
  padding-bottom: 10pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c9 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Oxanium";
  font-style: normal;
}

.c27 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c12 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Roboto";
  font-style: normal;
}

.c4 {
  padding-top: 10pt;
  padding-bottom: 10pt;
  line-height: 2;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c26 {
  border-spacing: 0;
  border-collapse: collapse;
}

.c29 {
  font-size: 30pt;
  font-family: "Oxanium";
  color: #323f48;
  font-weight: 700;
}

.c21 {
  padding-top: 10pt;
  padding-bottom: 10pt;
  line-height: 2;
  text-align: center;
}

.c13 {
  -webkit-text-decoration-skip: none;
  color: #222529;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.c19 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c25 {
  background-color: #f2f2f2;
  max-width: 503.1pt;
  padding: 49.7pt 49.7pt 21.6pt 42.5pt;
}

.c7 {
  font-size: 14pt;
  font-family: "Roboto";
  font-weight: 400;
}

.c23 {
  font-weight: 700;
  font-size: 30pt;
  font-family: "Roboto";
}

.c28 {
  font-weight: 400;
  font-size: 9.5pt;
  font-family: "Century Gothic";
}

.c24 {
  font-weight: 700;
  font-size: 20pt;
  font-family: "Roboto";
}

.c14 {
  font-size: 14pt;
  font-family: "Roboto";
  font-weight: 700;
}

.c16 {
  padding: 0;
  margin: 0;
}

.c10 {
  color: inherit;
  text-decoration: inherit;
}

.c6 {
  margin-left: 18pt;
  padding-left: 0pt;
}

.c1 {
  height: 14.2pt;
}

.c5 {
  height: 9.5pt;
}

.c8 {
  color: #000000;
}

.c22 {
  background-color: #ffffff;
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Century Gothic";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 9.5pt;
  font-family: "Century Gothic";
}

p {
  margin: 0;
  color: #000000;
  font-size: 9.5pt;
  font-family: "Century Gothic";
}

h1 {
  padding-top: 12pt;
  color: #000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 6pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 6pt;
  font-family: "Century Gothic";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 6pt;
  color: #593470;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Century Gothic";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 12pt;
  color: #864ea8;
  font-size: 9.5pt;
  padding-bottom: 6pt;
  font-family: "Century Gothic";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 2pt;
  color: #864ea8;
  font-size: 9.5pt;
  padding-bottom: 0pt;
  font-family: "Century Gothic";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Century Gothic";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
