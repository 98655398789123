<template>
  <div>
    <div class="inner_gray_block">
      <div class="text color-black mb-30">
        {{ descriptionText }}
      </div>
    </div>
    <div class="row align-items-center wrapper-steps-example">
      <div
        class="col-7"
      >
        <ul class="wraper_punkt">
          <li
            class="media"
            :key="step.label"
            v-for="step in steps"
          >
            <div class="item">
              {{ step.label }}.
            </div>
            <div class="media-body item_text">
              {{ step.text }}
            </div>
          </li>
        </ul>
      </div>
      <div class="col-5 pr-0 reWrapper">
        <img
          class="img-fluid receipt-example"
          src="../../assets/images/png/cash.png"
          alt="Receipt example"
        >
      </div>
    </div>
    <p
      class="item_text"
      :style="styles"
      :text="receiptText"
    >
      {{ receiptText }}
    </p>
    <div class="wrapper_check_block clearfix">
      <div class="checkMark">
        <img
          class="img-fluid"
          src="../../assets/images/png/check_1.png"
          alt="Correct example"
        >
        <img
          class="checkMark_img checkMark_img_1"
          src="../../assets/images/svg/checkMark.svg"
          alt="Checkmark"
        >
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          src="../../assets/images/png/check_2.png"
          alt="Incorrect example"
        >
        <img
          class="cross_red_img cross_red_img_2"
          src="../../assets/images/svg/cross_red.svg"
          alt="Cross red icon"
        >
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          src="../../assets/images/png/check_3.png"
          alt="Incorrect example"
        >
        <img
          class="cross_red_img cross_red_img_3"
          src="../../assets/images/svg/cross_red.svg"
          alt="Cross red icon"
        >
      </div>
      <div class="checkMark">
        <img
          class="img-fluid"
          src="../../assets/images/png/check_4.png"
          alt="Incorrect example"
        >
        <img
          class="cross_red_img cross_red_img_4"
          src="../../assets/images/svg/cross_red.svg"
          alt="Cross red icon"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReceiptInformation",
  props: {
    descriptionText: {
      required: false,
      type: String,
      default: ""
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    receiptText: {
      default: "*Fişinizi yükledikten sonra yukarıdaki bilgiler dışında, paylaşmak istemediğiniz bilgileri, dilerseniz sağ taraftaki düzenleme butonlarını kullanarak kaldırabilirsiniz.",
      type: String
    },
    styles: {
      default: '',
      type: String
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    background-color: #F4F4F4;
    padding: 30px;
    border-radius: 4px;
    @media (max-width: 991px) {
      padding: 40px 60px;
    }
    @media (max-width: 767px) {
      padding: 40px 15px;
    }
    .inner_gray_block {
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .wraper_punkt {
    margin-top: 20px;
    li {
      margin-bottom: 25px;
      font-size: 13px;
      letter-spacing: 0.16px;
      line-height: 19px;
      display: flex;
      @media (max-width: 991px) {
        margin-bottom: 15px;
      }
      .item {
        width: 20px;
        height: 20px;

        font: normal normal 500 16px/22px Montserrat;
        letter-spacing: 0px;
        color: #1B1B1B;

        display: inline-block;
        text-transform: uppercase;
        margin-right: 10px;
        text-align: center;
        padding: 0px 3px;

        @media (max-width: 991px) { font: normal normal 500 12px/16px Montserrat !important; }
      }
      .item_text {
        font: normal normal 300 15px/19px Montserrat;
        letter-spacing: 0px;
        color: #1B1B1B;

        @media (max-width: 991px) { font: normal normal 300 12px/16px Montserrat !important; }
      }
    }
  }
  .checkMark {
    position: relative;
    float: left;
    padding: 15px;
    /* margin-left: 15px; */
    border: 2px solid #004D94;
    border-radius: 9px;
    &:first-child {
      margin-left: 0;
    }
    .checkMark_img {
      position: absolute;
      top: 65px;
      left: 50%;
      margin-left: -27px;
      @media (max-width: 767px) {
        max-width: 30px;
        margin-left: -15px;
      }
    }
    .cross_red_img {
      position: absolute;
      top: 70px;
      left: 50%;
      margin-left: -18px;
      @media (max-width: 767px) {
        max-width: 24px;
        margin-left: -12px;
      }
    }
  }
  .wrapper_check_block {
    //padding-top: 90px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 991px) {
      padding-top: 20px;
    }
    @media(max-width: 426px){
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .receipt-example {
    width: 80%;
  }
  .wrapper-steps-example {
    margin-bottom: 30px;
  }

.text {
  text-align: left;
  font: normal normal 600 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  @media (max-width: 991px) { font: normal normal 500 12px/16px Montserrat !important; }
}
.receipt-example {
  width: 100%;

  transform: matrix(1, 0, 0, 1, 0, 0);
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 8px;
  opacity: 1;
}

@media (max-width: 991px) {
  .reWrapper { padding: 0px; padding-right: 20px !important; }
}
</style>
