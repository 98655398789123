<template>
  <div class="m-0 checkboxWrapper">
    <ValidationProvider :rules="validation" v-slot="{ errors }">
      <div class="wrapper_best" :style="styles">
        <div class="custom-control custom-checkbox custom-checkbox-arrow">
          <input
            v-model="localChecked"
            :name="name"
            :checked="checked"
            type="checkbox"
            class="custom-control-input"
            :id="id"
            @input="clickCheckbox"
            required
          />
          <label class="custom-control-label text_min" :for="id"></label>
        </div>
        <label v-if="checkOnClick" :for="id" v-html="label"></label>
        <label v-else for v-html="label"></label>
        <div
          @click="checkBoxClicked"
          :class="{'expandedDivImage': expand}"
        >
          <img
            v-if="expandable"
            class="down-arrow"
            src="../../assets/icons/form/down-arrow.svg"
            alt
          />
        </div>
      </div>
      <div v-if="expandable" ref='expandedDiv' class="expandedDiv">
        <div class="card card-body card-body-dop" v-html="expandableText" ref="popUp"></div>
      </div>
      <div class="validation-error checkbox-error" style="margin-left: 5px;" v-if="errors.length">
        <img class="error_min" src="../../assets/icons/form/error.svg" alt="error" />
        {{errors[0]}}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: "checkbox",
  data () {
    return {
      localChecked: this.checked,
      popUp1: null,
      popUp2: null,
      expand: false
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "Empty Link Text"
    },
    expandable: {
      required: false,
      type: Boolean,
      default: false
    },
    expandableText: {
      required: false,
      type: String,
      default: ""
    },
    checked: {
      required: false,
      type: Boolean,
      default: false
    },
    fieldId: {
      type: String,
      required: true
    },
    validation: {
      type: [String, Object],
      required: false,
      default: ""
    },
    checkOnClick: {
      required: false,
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  mounted () {
    this.$emit("component-updated", {
      checked: !!this.checked
    });
    this.$emit("input", !!this.checked);
    if (this.$refs['popUp']) {
      this.popUp1 = [...this.$refs['popUp'].firstElementChild.firstElementChild.children].find(item => item.firstChild?.attributes?.ref?.nodeValue === "popUp1")
      this.popUp2 = [...this.$refs['popUp'].firstElementChild.firstElementChild.children].find(item => item.firstChild?.attributes?.ref?.nodeValue === "popUp2")
      if (this.popUp1)
        this.popUp1.addEventListener('click', (event) => {
          event.preventDefault();
          this.showModal(event.target.attributes.title.nodeValue, 'custom')
        })
      if (this.popUp2)
        this.popUp2.addEventListener('click', (event) => {
          event.preventDefault();
          this.showModal(event.target.attributes.title.nodeValue, 'custom')
        })
    }
  },
  watch: {
    localChecked (value) {
      this.$emit("component-updated", {
        checked: value
      });
      this.$emit("input", value);
    }
  },
  methods: {
    clickCheckbox (value) {
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: value.target.checked
      });
    },
    checkBoxClicked () {
      this.expand = !this.expand;
      const expandedDiv = this.$refs['expandedDiv'];
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + "px";
    }
  },
  beforeDestroy () {
    if(this.popUp1)
      this.popUp1.removeEventListener('click')
    if (this.popUp2)
      this.popUp2.removeEventListener('click')
  }
};
</script>

<style lang="scss" scoped>
$medium: 800px;

.checkboxWrapper {
  padding: 5px;
}
.form-group {
  margin-bottom: 20px;
}
.form-field {
  margin-bottom: 20px;
}
.wrapper_input {
  position: relative;
}
.form-field__label {
  position: absolute;
  top: 50%;
  left: 10px;
  padding-left: 7px;
  padding-right: 7px;
  transition: transform 0.1s;
  pointer-events: none;
  transform-origin: 0 100%;
  transform: translateY(-50%);
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 16px;
}
.form-field__input {
  background: transparent !important;
  outline: 0;
  box-shadow: none;
  transition: border-color 0.15s;
  border-radius: 0 !important;
  border: 1px solid #808080 !important;
  box-sizing: border-box !important;
  padding-left: 15px !important;
  height: 48px !important;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}
.form-field__input:focus + .form-field__label,
.form-field__input.visited + .form-field__label {
  transform: translateY(-140%) scale(0.7);
}
.wrapper-i {
  cursor: pointer;
}
.wrapper_weit {
  display: flex;
  align-items: center;
}
.information-img {
  width: 18px;
}
.add-img {
  width: 30px;
  margin-right: 20px;
}
.is-invalid {
  .form-field__input {
    border-color: #ff5a5a !important;
  }
  .invalid-feedback {
    display: block;
  }
}
.wrapper_best {
  display: flex;
  position: relative;
  .custom-control {
    padding-left: 35px;
    padding-right: 10px;
    padding-top: 0.1rem;
  }
  .custom-checkbox .custom-control-label::before,
  .custom-checkbox .custom-control-label::after {
    left: -35px;
  }
}
.down-arrow {
  width: 16px;
  transition: all 0.3s;
  margin-left: 10px;
  margin-top: -8px;
}
[aria-expanded="true"] {
  .down-arrow {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.card-body-dop {
  border: none;
  padding-left: 35px;
}
.custom-radio {
  padding-left: 35px;
  margin-right: 20px;
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before {
  width: 20px;
  height: 20px;
  top: 0;
  left: -35px;
}
.custom-checkbox-dop {
  position: absolute;
  bottom: 5px;
  right: 10px;
  .custom-control-label {
    &:after,
    &:before {
      left: -20px;
    }
  }
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.custom-control-input[type="radio"] {
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
.custom-checkbox .custom-control-label::before {
  background-image: url("../../assets/icons/form/unchecked_checkbox.svg") !important;
  background-repeat: no-repeat;
  box-shadow: none !important;
  background-size: 20px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../../assets/icons/form/checked_checkbox.svg") !important;
  background-repeat: no-repeat;
  background-size: 20px;
}
.text_min {
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 16px;
}
.expandedDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
  -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
  -o-transition: height 0.4s ease-out;;  /* Opera */
  &Image {
    -webkit-transform: rotate(180deg); /* Safari and Chrome */
    -moz-transform: rotate(180deg);   /* Firefox */
    -ms-transform: rotate(180deg);   /* IE 9 */
    -o-transform: rotate(180deg);   /* Opera */
    transform: rotate(180deg);
  }
}

.checkbox-error {
  position: static !important;
  margin-left: 40px !important;
}
</style>
