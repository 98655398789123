<template>
    <div class="barcode-scanner__search d-flex center" :style="`--brand-color: ${colorSchema}`">
      <b-input-group class="barcode-scanner__search-input" style="width: 100% !important;">
        <b-form-input
          :value="barcode"
          @input="handleInput($event)"
          :state="inputStatus"
          placeholder="Barkod numarası"
          style="border-radius: 100px !important;
                z-index: 0 !important;
                background: #FFFFFF !important;
                border: 1px solid #DDDFE1 !important;
                box-sizing: border-box !important;
                border-radius: 6px !important;"
          >
        </b-form-input>
        <b-input-group-append>
            <button
              v-if="!disabled"
              type="button"
              class="btn btn-append"
              style="width:100%; z-index: 0!important"
              :class="`btn_${status}`"
              @click="searchProductByBarcode({barcode, maxProductCount})"
              ><img src="../../../assets/icons/barcode-search.svg" alt=""> Ekle
              </button>
            <button v-if="disabled" type="button" class="btn btn-append" style="width:100%; z-index: 0!important" :class="`btn_disabled`">Add</button>
        </b-input-group-append>
        <div class="validation-error" v-if="disabled && error">
            <div class="error_message">{{errorMessage}}</div>
        </div>

        <b-form-invalid-feedback id="input-live-feedback">
          Geçersiz barkod numarası
        </b-form-invalid-feedback>
        <b-form-valid-feedback id="input-live-feedback">
          Geçerli barkod numarası
        </b-form-valid-feedback>
      </b-input-group>

    </div>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: 'searchModule',
  data () {
    return {
      disabled: false,
      error: false,
      errorMessage: 'Geçerli bir barkod için lütfen tam olarak 13 hane giriniz.'
    }
  },
  props: {
    colorSchema: {
      required: false,
      type: String,
      default: "#002458"
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    maxProductCount: Number
  },
  computed: {
    ...mapState({
      barcode: state => state.productCode,
      status: state => state.searchStatus
    }),
    inputStatus () {
      let state;
      switch(this.status) {
      case 'success':
        state = true
        break;
      case 'not_found':
        state = false
        break;
      case null:
        state = null
        break;
      default:
        break;
      }
      return state
    }
  },
  methods:
  {
    ...mapActions(["uploadFile", "getBarCode", "findProducts", "handleProductCode", "updateSearchStatus", "searchProductByBarcode", "checkBarCodeParticipating" ]),
    handleInput (ev) {
    //   console.log("BARCODE" , ev.length)
    //   if (ev.length != 13) {
    //     this.disabled = true;
    //     this.error = true;

      //     this.updateSearchStatus('not_found')
      //     this.handleProductCode(ev)
      //   } else {
      //     this.disabled = false;
      //     this.error = false;

      this.updateSearchStatus(null);
      this.handleProductCode(ev)
    //   }
    },
    resetSelectedProducts () {
      this.$store.commit('resetProducts');
      this.$store.commit('setProductCode', '');
      this.$store.commit('setSearchStatus', null);
    }
  },
  mounted () {
    this.code = this.barcode;
    this.$eventHub.$on("resetProducts-event", this.resetSelectedProducts);
  }
}
</script>

<style scoped lang="scss">
  .btn_disabled {
    font-size: 16px;
    font-weight: 500;
  }
 input[type=file] {
    position: absolute;
    left: -99999px;
  }
  .hide {
    display:none
  }
  .form-control {
    display: block;
    width: 40%;
    font-size: 12px;
  }
  label {
    margin-bottom: 0;
  }
  .was-validated .form-control:invalid, .form-control.is-invalid,
  .form-control:valid, .form-control.is-valid {
    background-image: none !important;
  }
  .barcode-scanner {
    &__search {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      padding: 0 !important;
      position: relative !important;
      &-input {
        input {
          font-size: 14px;
          line-height: 32px;
            height: 48px;
          border-radius: 6px 0 0 6px !important;
          @media (max-width: 425px) {
            font-size: 10.5px;
          }
        }
      }
    }
    &__divider {
      width: 100%;
      margin-top: 50px;
      hr {
        border: 1px solid #939393;
      }
      .col-6 {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

@media (max-width: 768px) {
  .barcode-scanner__search {
    padding: 0;
  }
}

.btn {
    font-style: normal;
  font-weight: 500;
    font-size: 16px;

    background-color: var(--brand-color);
    border: 1px solid var(--brand-color);
    box-sizing: border-box;
    border-radius: 6px !important;
    color: white;

    &_not_found {
        background: #DD0019 !important;
        border-color: #DD0019 !important;
        height: 67% !important;
    }

    &_success {
        background: var(--brand-color) !important;
        border-color: var(--brand-color) !important;
        height: 67% !important;
    }

    &_button {
        color: #fff;
        background: #6f6f6f;
        border-color: #6f6f6f;
    }

    &_disabled {
        background: rgba(28, 135, 150, 0.1) !important;
        border-color: rgba(28, 135, 150, 0.1) !important;
        color: var(--brand-color) !important;
    }

    &-append {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 138px !important;
      height: 100%;
      top: 0;
      right: 0;
      position: absolute;
      max-height: 100%;
      @media (max-width: 991px) {
        font-size: 13px;
        width: 118px !important;
      }
      @media (max-width: 425px) {
        font-size: 11px;
        width: 98px !important;
      }
    }
}


.error_message {
  display: inline-block;
}

</style>
