<template>
  <div
    class="container"
    :style="cssVars"
  >
    <div class="row">
      <div class="page-title">
        {{ title }}
      </div>
      <template v-if="campaignName == 'rengarenk'">
        <ul class="list">
          <li>
            <p> P&amp;G Rengarenk Bahar Fırsatları kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?</p>
            <p>• Minimum Yaş: En az 18 yaşında olmalısınız.<br /> • İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.<br /> • IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.<br /> • T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası<br /> • Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.<br /> • E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.<br /> Not: Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
          </li>
          <li>
            <p> P&amp;G Rengarenk Bahar Fırsatları kampanyasına nasıl katılabilirim?</p>
            <p>Yalnızca <u><a href="https://www.firsatyaninda.com/rengarenkbaharfirsatlari">www.firsatyaninda.com/rengarenkbaharfirsatlari</a></u> kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:<br /> Kampanya ürününün satın alınmasından sonra <u><a href="https://www.firsatyaninda.com/rengarenkbaharfirsatlari">www.firsatyaninda.com/rengarenkbaharfirsatlari</a></u>’na giriniz.<br /> Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).<br /> Banka bilgilerini ve kişisel verileri sağlayınız.<br /> Katılım + veri koruma koşullarını kabul ediniz.</p>
          </li>
          <li>
            <p>Geri ödememi ne zaman alabilirim?</p>
            <p>Fişiniz/faturanız 32 saat içerisinde kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) 2-4 hafta arasında aktarılacaktır.</p>
          </li>
          <li>
            <p>P&amp;G Rengarenk Bahar Fırsatları kampanyasına katılmak için hangi ürünleri satın almam gerekiyor?</p>
            <p>Kampanyaya tüm P&amp;G ürünleri dahildir.</p>
          </li>
          <li>
            <p> P&amp;G Rengarenk Bahar Fırsatları kampanyasına katılan ürünleri nereden satın alabilirim?</p>
            <p>Rossmann, Eve, Kozkar, Ali Özkan, Sine İtr., Platin, Yüncü Ali, T Shop, Yücel Parfümeri, Hazal Parfümeri, Emre Parfümeri, Deniz Kimya, Boğalar AVM, Komşu Mağazaları, Kağan Parfümeri. Online yapılan alışverişler geçerlidir.</p>
          </li>
          <li>
            <p>Ne kadar geri ödeme alacağım?</p>
            <p>Kullanıcı fişinde/faturasında yer alan 75 TL ve üzeri P&amp;G ürünlerine 20 TL geri ödeme hak kazanır. fiş/faturasındaki P&amp;G Maksimum 20 TL iade yapılır.</p>
          </li>
          <li>
            <p> P&amp;G Rengarenk Bahar Fırsatları kampanyasına ne zaman katılabilirim?</p>
            <p>Kampanya ürünlerini satın almak için kampanya dönemi 01.04.2022 ile 13.06.2022 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).<br /> Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 13.06.2022, 23:59.</p>
          </li>
          <li>
            <p>Kampanyaya ne sıklıkla katılabilirim?</p>
            <p>Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.</p>
          </li>
          <li>
            <p>Kampanyaya kaç ürün ile katılabilirim?</p>
            <p>P&amp;G Rengarenk Bahar Fırsatları kampanyasında yer alabileceğiniz minimum veya maksimum sayıda ürün bulunmamaktadır.</p>
          </li>
          <li>
            <p>Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?</p>
            <p>Evet katılabilirsiniz.</p>
          </li>
          <li>
            <p>Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de Rengarenk Bahar Fırsatları kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?</p>
            <p>Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.</p>
          </li>
          <li>
            <p>Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?</p>
            <p>Prensip olarak, bir satın alma kanıtı P&amp;G Rengarenk Bahar Fırsatları kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.</p>
          </li>
          <li>
            <p>Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?</p>
            <p>Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.<br /> 10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <u><a href="https://www.firsatyaninda.com/rengarenkbaharfirsatlari">www.firsatyaninda.com/rengarenkbaharfirsatlari</a></u> üzerinden tekrar kayıt olunuz.</p>
          </li>
          <li>
            <p>Barkod numarası nedir ve onu nerede bulabilirim?</p>
            <p>Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.<br /> Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.</p>
          </li>
          <li>
            <p>Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?</p>
            <p>Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: <a href="mailto:destek@bahar.firsatyaninda.com">destek@bahar.firsatyaninda.com</a>.<br /> Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.</p>
          </li>
          <li>
            <p>IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?</p>
            <p>IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN' dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.</p>
          </li>
          <li>
            <p>Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?</p>
            <p>Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.</p>
          </li>
          <li>
            <p>Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?</p>
            <p>Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.</p>
          </li>
          <li>
            <p>Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?</p>
            <p>Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.</p>
          </li>
          <li>
            <p>Doğru ürünleri almış olmama rağmen neden reddedildim?</p>
            <p>Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.<br /> Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.<br /> Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (<a href="mailto:destek@bahar.firsatyaninda.com">destek@bahar.firsatyaninda.com</a>) bizimle iletişime geçiniz.</p>
          </li>
          <li>
            <p>Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?</p>
            <p>Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.</p>
          </li>
          <li>
            <p>Satın alma belgemi e-posta ile de gönderebilir miyim?</p>
            <p>Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.<br /> Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.</p>
          </li>
          <li>
            <p>Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?</p>
            <p>Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen <a href="mailto:iban@bahar.firsatyaninda.com">iban@bahar.firsatyaninda.com</a> adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın P&amp;G Rengarenk Bahar Fırsatları kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.</p>
          </li>
          <li>
            <p>Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?</p>
            <p>Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.<br /> Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.</p>
          </li>
          <li>
            <p>Kampanya web sitesine girdiğim verilerime ne olacak?</p>
            <p>Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.<br /> Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.<br /> Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999">https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999</a> websitesinden).<br /> Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.<br /> Veri korumayla ilgili daha fazla bilgi <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml">https://www.pg.com/privacy/turkish/privacy_statement.shtml</a> adresinde bulunabilir.</p>
          </li>
          <li>
            <p>P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?</p>
            <p>Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).<br /> <a href="https://www.pg.com.tr/bize-ulasin/">https://www.pg.com.tr/bize-ulasin/</a></p>
          </li>
        </ul>
      </template>
      <template v-else-if="campaignName == 'bahar-ozel'">
        <ul class="list">
          <li>
            P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?
            <br>• Minimum Yaş: En az 18 yaşında olmalısınız.<br />• İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.<br />• IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.<br />• T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası<br />• Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.<br />• E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.<br />Not: Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.
          </li>
          <li>
            P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyasına nasıl katılabilirim?
            <br>Yalnızca <a href="http://www.firsatyaninda.com/migros">www.firsatyaninda.com/</a>baharevinizde kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:<br />Kampanya ürününün satın alınmasından sonra  <a href="http://www.firsatyaninda.com/migros">www.firsatyaninda.com/</a>baharevinizde linkine giriniz.<br />Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).<br />Banka bilgilerini ve kişisel verileri sağlayınız.<br />Katılım + veri koruma koşullarını kabul ediniz.
          </li>
          <li>
            Geri ödememi ne zaman alabilirim?
            <br>Fişiniz/faturanız 32 saat içerisinde kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) 2-4 hafta arasında aktarılacaktır.
          </li>
          <li>
            P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyasına katılmak için hangi ürünleri satın almam gerekiyor?
            <br>Kampanyaya Alo, Ariel, Fairy ve Febreze ürünleri dahildir.
          </li>
          <li>
            P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyasına katılan ürünleri nereden satın alabilirim?
            <br>Tüm Türkiye ve Online yapılan alışverişler geçerlidir.
          </li>
          <li>
            Ne kadar geri ödeme alacağım?
            <br>Kullanıcı fişinde/faturasında yer alan minimum 60 TL ve üzeri P&amp;G ürünlerine %20 geri ödeme hak kazanır.Maksimum 30 TL iade yapılır.
          </li>
          <li>
            P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyasına ne zaman katılabilirim?
            <br>Kampanya ürünlerini satın almak için kampanya dönemi 18.04.2022 ile 31.05.2022 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).<br />Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 31.05.2022, 23:59.
          </li>
          <li>
            Kampanyaya ne sıklıkla katılabilirim?
            <br>Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.
          </li>
          <li>
            Kampanyaya kaç ürün ile katılabilirim?
            <br>P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyasında yer alabileceğiniz minimum veya maksimum sayıda ürün bulunmamaktadır.
          </li>
          <li>
            Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?
            <br>Evet katılabilirsiniz.
          </li>
          <li>
            Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de SİZE ÖZEL %20 PARA İADESİ kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?
            <br>Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.
          </li>
          <li>
            Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?
            <br>Prensip olarak, bir satın alma kanıtı P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.
          </li>
          <li>
            Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?
            <br>Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.<br />10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <a href="http://www.firsatyaninda.com/">www.firsatyaninda.com/</a>baharevinizde üzerinden tekrar kayıt olunuz.
          </li>
          <li>
            Barkod numarası nedir ve onu nerede bulabilirim?
            <br>Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.<br />Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.
          </li>
          <li>
            Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?
            <br>Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: destek@baharevinizde.firsatyaninda.com.<br />Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.
          </li>
          <li>
            IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?
            <br>IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN' dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.
          </li>
          <li>
            Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?
            <br>Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.
          </li>
          <li>
            Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?
            <br>Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.
          </li>
          <li>
            Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?
            <br>Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.
          </li>
          <li>
            Doğru ürünleri almış olmama rağmen neden reddedildim?
            <br>Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.<br />Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.<br />Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (destek@baharevinizde.firsatyaninda.com) bizimle iletişime geçiniz.
          </li>
          <li>
            Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?
            <br>Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.
          </li>
          <li>
            Satın alma belgemi e-posta ile de gönderebilir miyim?
            <br>Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.<br />Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.
          </li>
          <li>
            Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?
            <br>Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen iban@baharevinizde.firsatyaninda.com adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın P&amp;G SİZE ÖZEL %20 PARA İADESİ kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.
          </li>
          <li>
            Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?
            <br>Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.<br />Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.
          </li>
          <li>
            Kampanya web sitesine girdiğim verilerime ne olacak?
            <br>Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.<br />Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.<br />Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&sub=9999">https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&sub=9999</a> websitesinden)
            <br>
            Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.
            <br>
            Veri korumayla ilgili daha fazla bilgi <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml">https://www.pg.com/privacy/turkish/privacy_statement.shtml</a> adresinde bulunabilir.
          </li>
          <li>
            P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?
            <br>Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).<br /><a href="https://www.pg.com.tr/bize-ulasin/">https://www.pg.com.tr/bize-ulasin/</a>
          </li>
        </ul>
      </template>
      <template v-else-if="campaignName == 'olimpiyat'">
        <ol>
          <li>
            <p><strong>Her Gün Daha İyiye kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?</strong></p>
            <ul>
              <li>
                <p>Minimum Yaş: En az 18 yaşında olmalısınız.</p>
              </li>
              <li>
                <p>İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.</p>
              </li>
              <li>
                <p>IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.</p>
              </li>
              <li>
                <p>T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası</p>
              </li>
              <li>
                <p>Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.</p>
              </li>
              <li>
                <p>E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.</p>
              </li>
            </ul>
            <p><strong>Not:</strong> Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
          </li>
          <li>
            <p><strong>Her Gün Daha İyiye</strong><strong>kampanyasına</strong><strong>nasıl katılabilirim?</strong></p>
            <p>
              Yalnızca <strong><a
                href="https://www.firsatyaninda.com/olimpiyat"
                target="_blank"
                rel="noopener noreferrer"
              >www.firsatyaninda.com/olimpiyat</a></strong>kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:
            </p>
            <ul>
              <li>
                <p>
                  Kampanya ürünlerinin satın alınmasından sonra <strong><a
                    href="https://www.firsatyaninda.com/olimpiyat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >www.firsatyaninda.com/olimpiyat</a></strong>’a giriniz.
                </p>
              </li>
              <li>
                <p>Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).</p>
              </li>
              <li>
                <p>Banka bilgilerini ve kişisel verileri sağlayınız.</p>
              </li>
              <li>
                <p>Katılım + veri koruma koşullarını kabul ediniz.</p>
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Geri ödememi ne zaman alabilirim?</strong></p>
            <p>Fişiniz/faturanız <strong>32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) <strong>2-4 hafta arasında</strong> aktarılacaktır.</p>
          </li>
          <li>
            <p><strong>Her Gün Daha İyiye kampanyasına</strong><strong>katılmak için hangi ürünleri satın almam gerekiyor?</strong></p>
            <p>Kampanyaya tüm P&amp;G ürünleri dahildir.</p>
          </li>
          <li>
            <p><strong>Her Gün Daha İyiye kampanyasına katılan ürünleri nereden satın alabilirim?</strong></p>
            <p>Tüm satış noktalarından alınabilir. Online ve offline alışverişlerde geçerlidir.</p>
          </li>
          <li>
            <p><strong>Ne kadar geri ödeme alacağım?</strong></p>
            <p>Kampanya ürünleri için 100 TL ödediğini ispat eden kullanıcıya 25 TL geri ödeme yapılır.</p>
          </li>
          <li>
            <p><strong>Her Gün Daha İyiye kampanyasına ne zaman katılabilirim?</strong></p>
            <p>Kampanya ürünlerini satın almak için kampanya dönemi 19.05.2022 ile 15.08.2022 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).</p>
            <p>Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 15.08.2022, 23:59.</p>
          </li>
          <li>
            <p><strong>Kampanyaya ne sıklıkla katılabilirim?</strong></p>
            <p>Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.</p>
          </li>
          <li>
            <p><strong>Kampanyaya kaç ürün ile katılabilirim?</strong></p>
            <p><strong>Her Gün Daha İyiye</strong> kampanyasında en az bir ürün alınması gerekmektedir.</p>
          </li>
          <li>
            <p><strong>Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?</strong></p>
            <p>Maalesef bu mümkün değildir. <strong>Her Gün Daha İyiye</strong> kampanyasına katılım, henüz başka P&amp;G kampanyalarına katılmamış ürünlerle sınırlıdır.</p>
          </li>
          <li>
            <p><strong>Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de Her Gün Daha İyiye</strong><strong>kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?</strong></p>
            <p>Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.</p>
          </li>
          <li>
            <p><strong>Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?</strong></p>
            <p>Prensip olarak, bir satın alma kanıtı <strong>Her Gün Daha İyiye </strong>kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.</p>
          </li>
          <li>
            <p><strong>Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?</strong></p>
            <p>Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.</p>
            <p>
              10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <strong><a
                href="https://www.firsatyaninda.com/olimpiyat"
                target="_blank"
                rel="noopener noreferrer"
              >www.firsatyaninda.com/olimpiyat</a></strong>üzerinden tekrar kayıt olunuz.
            </p>
          </li>
          <li>
            <p><strong>Barkod numarası nedir ve onu nerede bulabilirim?</strong></p>
            <p>Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.</p>
            <p>Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.</p>
          </li>
          <li>
            <p><strong>Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?</strong></p>
            <p>Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: <strong><a href="mailto:destek@olimpiyat.firsatyaninda.com">destek@olimpiyat.firsatyaninda.com</a></strong>.<br /> Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.</p>
          </li>
          <li>
            <p><strong>IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?</strong></p>
            <p>IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN' dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.</p>
          </li>
          <li>
            <p><strong>Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?</strong></p>
            <p>Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.</p>
          </li>
          <li>
            <p><strong>Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?</strong></p>
            <p>Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.</p>
          </li>
          <li>
            <p><strong>Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?</strong></p>
            <p>Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.</p>
          </li>
          <li>
            <p><strong>Doğru ürünleri almış olmama rağmen neden reddedildim?</strong></p>
            <p>Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.</p>
            <p>Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.</p>
            <p>Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (<strong><a href="mailto:destek@olimpiyat.firsatyaninda.com">destek@olimpiyat.firsatyaninda.com</a></strong>) bizimle iletişime geçiniz.</p>
          </li>
          <li>
            <p><strong>Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?</strong></p>
            <p>Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.</p>
          </li>
          <li>
            <p><strong>Satın alma belgemi e-posta ile de gönderebilir miyim?</strong></p>
            <p>Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.</p>
            <p>Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.</p>
          </li>
          <li>
            <p><strong>Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?</strong></p>
            <p>Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen <strong><a href="mailto:iban@olimpiyat.firsatyaninda.com">iban@olimpiyat.firsatyaninda.com</a></strong> adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın <strong>Her Gün Daha İyiye </strong>kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.</p>
          </li>
          <li>
            <p><strong>Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?</strong></p>
            <p>Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.</p>
            <p>Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.</p>
          </li>
          <li>
            <p><strong>Kampanya web sitesine girdiğim verilerime ne olacak?</strong></p>
            <p>Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.</p>
            <p>Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.</p>
            <p>Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999"><u>https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999</u></a> websitesinden).</p>
            <p>Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.</p>
            <p>Veri korumayla ilgili daha fazla bilgi <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml </u></a>adresinde bulunabilir.</p>
          </li>
          <li>
            <p><strong>P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?</strong></p>
            <p>Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).</p>
            <p><a href="https://www.pg.com.tr/bize-ulasin/">https://www.pg.com.tr/bize-ulasin/</a></p>
          </li>
        </ol>
      </template>
      <template v-else-if="campaignName == 'orkid'">
        <ol>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?</strong></p>
            <ul>
              <li>
                <p>Minimum Yaş: En az 18 yaşında olmalısınız.</p>
              </li>
              <li>
                <p>İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.</p>
              </li>
              <li>
                <p>IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.</p>
              </li>
              <li>
                <p>T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası</p>
              </li>
              <li>
                <p>Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.</p>
              </li>
              <li>
                <p>E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.</p>
              </li>
            </ul>
            <p><strong>Not:</strong> Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına</strong><strong>nasıl katılabilirim?</strong></p>
            <p>
              Yalnızca <strong><a
                href="https://www.firsatyaninda.com/orkid"
                target="_blank"
                rel="noopener noreferrer"
              >www.firsatyaninda.com/orkid</a></strong> kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:
            </p>
            <ul>
              <li>
                <p>
                  Kampanya ürününün satın alınmasından sonra <strong><a
                    href="https://www.firsatyaninda.com/orkid"
                    target="_blank"
                    rel="noopener noreferrer"
                  >www.firsatyaninda.com/orkid</a></strong>’e giriniz.
                </p>
              </li>
              <li>
                <p>Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).</p>
              </li>
              <li>
                <p>Banka bilgilerini ve kişisel verileri sağlayınız.</p>
              </li>
              <li>
                <p>Katılım + veri koruma koşullarını kabul ediniz.</p>
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Geri ödememi ne zaman alabilirim?</strong></p>
            <p>Fişiniz/faturanız <strong>32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) <strong>2-4 hafta arasında</strong> aktarılacaktır.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına</strong><strong>katılmak için hangi ürünleri satın almam gerekiyor?</strong></p>
            <p>Kampanyaya Orkid ürünleri dahildir.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına katılan ürünleri nereden satın alabilirim?</strong></p>
            <p>Promosyon ürünleri online ve offline tüm satış noktalarından alınabilir.</p>
          </li>
          <li>
            <p><strong>Ne kadar geri ödeme alacağım?</strong></p>
            <p>Kampanya ürününü satın aldığını ispat eden kullanıcı 10 TL para iadesi alır.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına ne zaman katılabilirim?</strong></p>
            <p>Kampanya ürünlerini satın almak için kampanya dönemi 01.08.2022 ile 14.10.2022 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).</p>
            <p>Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 14/10/2022, 23:59.</p>
          </li>
          <li>
            <p><strong>Kampanyaya ne sıklıkla katılabilirim?</strong></p>
            <p>Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.</p>
          </li>
          <li>
            <p><strong>Kampanyaya kaç ürün ile katılabilirim?</strong></p>
            <p><strong>P&amp;G Orkid Para iadesi </strong>kampanyasında yer alabileceğiniz minimum veya maksimum sayıda ürün bulunmamaktadır.</p>
          </li>
          <li>
            <p><strong>Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?</strong></p>
            <p>Maalesef bu mümkün değildir. <strong>P&amp;G Orkid Para iadesi</strong> kampanyasına katılım, henüz başka P&amp;G kampanyalarına katılmamış ürünlerle sınırlıdır.</p>
          </li>
          <li>
            <p><strong>Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de P&amp;G Orkid Para iadesi kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?</strong></p>
            <p>Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.</p>
          </li>
          <li>
            <p><strong>Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?</strong></p>
            <p>Prensip olarak, bir satın alma kanıtı <strong>P&amp;G Orkid Para iadesi </strong>kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.</p>
          </li>
          <li>
            <p><strong>Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?</strong></p>
            <p>Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.</p>
            <p>10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <a href="http://www.firsatyaninda.com/orkid"><strong>www.firsatyaninda.com/orkid</strong></a> üzerinden tekrar kayıt olunuz.</p>
          </li>
          <li>
            <p><strong>Barkod numarası nedir ve onu nerede bulabilirim?</strong></p>
            <p>Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.</p>
            <p>Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.</p>
          </li>
          <li>
            <p><strong>Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?</strong></p>
            <p>Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: <strong><a href="mailto:destek@orkid.firsatyaninda.com">destek@orkid.firsatyaninda.com</a></strong>.<br /> Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.</p>
          </li>
          <li>
            <p><strong>IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?</strong></p>
            <p>IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN'dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.</p>
          </li>
          <li>
            <p><strong>Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?</strong></p>
            <p>Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.</p>
          </li>
          <li>
            <p><strong>Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?</strong></p>
            <p>Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.</p>
          </li>
          <li>
            <p><strong>Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?</strong></p>
            <p>Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.</p>
          </li>
          <li>
            <p><strong>Doğru ürünleri almış olmama rağmen neden reddedildim?</strong></p>
            <p>Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.</p>
            <p>Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.</p>
            <p>Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (<strong><a href="mailto:destek@orkid.firsatyaninda.com">destek@orkid.firsatyaninda.com</a></strong>) bizimle iletişime geçiniz.</p>
          </li>
          <li>
            <p><strong>Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?</strong></p>
            <p>Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.</p>
          </li>
          <li>
            <p><strong>Satın alma belgemi e-posta ile de gönderebilir miyim?</strong></p>
            <p>Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.</p>
            <p>Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.</p>
          </li>
          <li>
            <p><strong>Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?</strong></p>
            <p>Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen <strong><a href="mailto:iban@orkid.firsatyaninda.com">iban@orkid.firsatyaninda.com</a></strong> adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın <strong>P&amp;G Orkid Para iadesi</strong> kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.</p>
          </li>
          <li>
            <p><strong>Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?</strong></p>
            <p>Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.</p>
            <p>Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.</p>
          </li>
          <li>
            <p><strong>Kampanya web sitesine girdiğim verilerime ne olacak?</strong></p>
            <p>Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.</p>
            <p>Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.</p>
            <p>Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999"><u>https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999</u></a> websitesinden).</p>
            <p>Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.</p>
            <p>Veri korumayla ilgili daha fazla bilgi <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml </u></a>adresinde bulunabilir.</p>
          </li>
          <li>
            <p><strong>P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?</strong></p>
            <p>Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).</p>
            <p><a href="https://www.pg.com.tr/bize-ulasin/">https://www.pg.com.tr/bize-ulasin/</a></p>
          </li>
          <!-- <li>
  <p><strong>Amazon.com üzerinden yaptığım alışveriş faturama nasıl ulaşabilirim?</strong></p>
  <p>Amazon.com üzerinden yaptığınız alışveriş faturanızı görüntülemek için masaüstü ya da dizüstü bilgisayarda <a href="amazon.com.tr">amazon.com.tr</a> ana sayfasında sağ üst bölümdeki “Hesap ve Listeler”e tıklayın. Açılan sayfada “Siparişlerim” kutusuna tıklayın. Siparişinizi bulduktan sonra sağ üstte yer alan “Fatura” linkine tıklayın ve 1’i seçin.</p>
</li> -->
        </ol>
      </template>
      <template v-else-if="campaignName == 'orkid-2'">
        <ol>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?</strong></p>
            <ul>
              <li>
                <p>Minimum Yaş: En az 18 yaşında olmalısınız.</p>
              </li>
              <li>
                <p>İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.</p>
              </li>
              <li>
                <p>IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.</p>
              </li>
              <li>
                <p>T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası</p>
              </li>
              <li>
                <p>Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.</p>
              </li>
              <li>
                <p>E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.</p>
              </li>
            </ul>
            <p><strong>Not:</strong> Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
          </li>
          <li>
            <p><strong>P&G Orkid Para iadesi kampanyasına nasıl katılabilirim?</strong></p>
            <p>
              Yalnızca <strong><a
                href="https://www.firsatyaninda.com/orkid"
                target="_blank"
                rel="noopener noreferrer"
              >www.firsatyaninda.com/orkid</a></strong> kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:
            </p>
            <ul>
              <li>
                <p>
                  Kampanya ürününün satın alınmasından sonra <strong><a
                    href="https://www.firsatyaninda.com/orkid"
                    target="_blank"
                    rel="noopener noreferrer"
                  >www.firsatyaninda.com/orkid</a></strong>’e giriniz.
                </p>
              </li>
              <li>
                <p>Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).</p>
              </li>
              <li>
                <p>Banka bilgilerini ve kişisel verileri sağlayınız.</p>
              </li>
              <li>
                <p>Katılım + veri koruma koşullarını kabul ediniz.</p>
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Geri ödememi ne zaman alabilirim?</strong></p>
            <p>Fişiniz/faturanız <strong>32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) <strong>2-4 hafta arasında</strong> aktarılacaktır.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına</strong><strong>katılmak için hangi ürünleri satın almam gerekiyor?</strong></p>
            <p>Kampanyaya Orkid ürünleri dahildir.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına katılan ürünleri nereden satın alabilirim?</strong></p>
            <p>Promosyon ürünleri online ve offline tüm satış noktalarından alınabilir.</p>
          </li>
          <li>
            <p><strong>Ne kadar geri ödeme alacağım?</strong></p>
            <p>Kampanya ürünlerinden 50 TL’lik ürün satın aldığını ispat eden kullanıcı 25 TL para iadesi alır.</p>
          </li>
          <li>
            <p><strong>P&amp;G Orkid Para iadesi kampanyasına ne zaman katılabilirim?</strong></p>
            <p>Kampanya ürünlerini satın almak için kampanya dönemi 01.09.2022 ile 30.04.2023 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir). Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 14/05/2022, 23:59.</p>
          </li>
          <li>
            <p><strong>Kampanyaya ne sıklıkla katılabilirim?</strong></p>
            <p>Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.</p>
          </li>
          <li>
            <p><strong>Kampanyaya kaç ürün ile katılabilirim?</strong></p>
            <p><strong>P&amp;G Orkid Para iadesi </strong>kampanyasında yer alabileceğiniz minimum veya maksimum sayıda ürün bulunmamaktadır.</p>
          </li>
          <li>
            <p><strong>Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?</strong></p>
            <p>Maalesef bu mümkün değildir. <strong>P&amp;G Orkid Para iadesi</strong> kampanyasına katılım, henüz başka P&amp;G kampanyalarına katılmamış ürünlerle sınırlıdır.</p>
          </li>
          <li>
            <p><strong>Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de P&amp;G Orkid Para iadesi kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?</strong></p>
            <p>Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.</p>
          </li>
          <li>
            <p><strong>Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?</strong></p>
            <p>Prensip olarak, bir satın alma kanıtı <strong>P&amp;G Orkid Para iadesi </strong>kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.</p>
          </li>
          <li>
            <p><strong>Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?</strong></p>
            <p>Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.</p>
            <p>10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <a href="http://www.firsatyaninda.com/orkid"><strong>www.firsatyaninda.com/orkid</strong></a> üzerinden tekrar kayıt olunuz.</p>
          </li>
          <li>
            <p><strong>Barkod numarası nedir ve onu nerede bulabilirim?</strong></p>
            <p>Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.</p>
            <p>Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.</p>
          </li>
          <li>
            <p><strong>Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?</strong></p>
            <p>Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: <strong><a href="mailto:destek@orkid.firsatyaninda.com">destek@orkid.firsatyaninda.com</a></strong>.<br /> Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.</p>
          </li>
          <li>
            <p><strong>IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?</strong></p>
            <p>IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN'dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.</p>
          </li>
          <li>
            <p><strong>Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?</strong></p>
            <p>Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.</p>
          </li>
          <li>
            <p><strong>Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?</strong></p>
            <p>Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.</p>
          </li>
          <li>
            <p><strong>Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?</strong></p>
            <p>Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.</p>
          </li>
          <li>
            <p><strong>Doğru ürünleri almış olmama rağmen neden reddedildim?</strong></p>
            <p>Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.</p>
            <p>Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.</p>
            <p>Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (<strong><a href="mailto:destek@orkid.firsatyaninda.com">destek@orkid.firsatyaninda.com</a></strong>) bizimle iletişime geçiniz.</p>
          </li>
          <li>
            <p><strong>Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?</strong></p>
            <p>Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.</p>
          </li>
          <li>
            <p><strong>Satın alma belgemi e-posta ile de gönderebilir miyim?</strong></p>
            <p>Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.</p>
            <p>Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.</p>
          </li>
          <li>
            <p><strong>Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?</strong></p>
            <p>Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen <strong><a href="mailto:iban@orkid.firsatyaninda.com">iban@orkid.firsatyaninda.com</a></strong> adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın <strong>P&amp;G Orkid Para iadesi</strong> kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.</p>
          </li>
          <li>
            <p><strong>Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?</strong></p>
            <p>Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.</p>
            <p>Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.</p>
          </li>
          <li>
            <p><strong>Kampanya web sitesine girdiğim verilerime ne olacak?</strong></p>
            <p>Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.</p>
            <p>Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.</p>
            <p>Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999"><u>https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999</u></a> websitesinden).</p>
            <p>Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.</p>
            <p>Veri korumayla ilgili daha fazla bilgi <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml </u></a>adresinde bulunabilir.</p>
          </li>
          <li>
            <p><strong>P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?</strong></p>
            <p>Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).</p>
            <p><a href="https://www.pg.com.tr/bize-ulasin/">https://www.pg.com.tr/bize-ulasin/</a></p>
          </li>
          <!-- <li>
  <p><strong>Amazon.com üzerinden yaptığım alışveriş faturama nasıl ulaşabilirim?</strong></p>
  <p>Amazon.com üzerinden yaptığınız alışveriş faturanızı görüntülemek için masaüstü ya da dizüstü bilgisayarda <a href="amazon.com.tr">amazon.com.tr</a> ana sayfasında sağ üst bölümdeki “Hesap ve Listeler”e tıklayın. Açılan sayfada “Siparişlerim” kutusuna tıklayın. Siparişinizi bulduktan sonra sağ üstte yer alan “Fatura” linkine tıklayın ve 1’i seçin.</p>
</li> -->
        </ol>
      </template>
      <template v-else-if="campaignName == 'pg35yil'">
        <ol>
          <li>
            P&amp;G 35. Yıl kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?
            <ul>
              <li>Minimum Yaş: En az 18 yaşında olmalısınız.</li>
              <li>İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.</li>
              <li>IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.</li>
              <li>T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası</li>
              <li>Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.</li>
              <li>E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.</li>
            </ul>
          </li>
          <li>
            Not: Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.
          </li>
          <li>
            P&amp;G 35. Yıl kampanyasına nasıl katılabilirim?<br />Yalnızca <a href="https://www.firsatyaninda.com/pg35yil">www.firsatyaninda.com/pg35yil</a> kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:
            <ul>
              <li>Kampanya ürünlerinin satın alınmasından sonra <a href="https://www.firsatyaninda.com/pg35yil">www.firsatyaninda.com/pg35yil</a>’a giriniz.</li>
              <li>Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).</li>
              <li>Banka bilgilerini ve kişisel verileri sağlayınız.</li>
              <li>Katılım + veri koruma koşullarını kabul ediniz.</li>
            </ul>
          </li>
          <li>
            Geri ödememi ne zaman alabilirim?<br />Fişiniz/faturanız 32 saat içerisinde kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) 2-4 hafta arasında aktarılacaktır.
          </li>
          <li>
            P&amp;G 35. Yıl kampanyasına katılmak için hangi ürünleri satın almam gerekiyor?<br />Kampanyaya tüm P&amp;G ürünleri dahildir.
          </li>
          <li>
            P&amp;G 35. Yıl kampanyasına katılan ürünleri nereden satın alabilirim?<br />Yalnızca online alışverişlerde geçerlidir.
          </li>
          <li>
            Ne kadar geri ödeme alacağım?<br />Kampanya ürünleri için 150 TL ödediğini ispat eden kullanıcıya 35 TL geri ödeme yapılır.
          </li>
          <li>
            P&amp;G 35. Yıl kampanyasına ne zaman katılabilirim?<br />Kampanya ürünlerini satın almak için kampanya dönemi 05.01.2023 ile 31.03.2023 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).<br />Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 31.03.2023, 23:59.
          </li>
          <li>
            Kampanyaya ne sıklıkla katılabilirim?<br />Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.
          </li>
          <li>
            Kampanyaya kaç ürün ile katılabilirim?<br />P&amp;G 35. Yıl kampanyasında en az bir ürün alınması gerekmektedir.
          </li>
          <li>
            Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?<br />Maalesef bu mümkün değildir. P&amp;G 35. Yıl kampanyasına katılım, henüz başka P&amp;G kampanyalarına katılmamış ürünlerle sınırlıdır.
          </li>
          <li>
            Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de P&amp;G 35. Yıl kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?<br />Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.
          </li>
          <li>
            Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?<br />Prensip olarak, bir satın alma kanıtı P&amp;G 35. Yıl kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.
          </li>
          <li>
            Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?<br />Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.<br />10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <a href="https://www.firsatyaninda.com/pg35yil">www.firsatyaninda.com/pg35yil</a> üzerinden tekrar kayıt olunuz.
          </li>
          <li>
            Barkod numarası nedir ve onu nerede bulabilirim?<br />Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.<br />Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.
          </li>
          <li>
            Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?<br />Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: destek@pg35yil.firsatyaninda.com.<br />Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.
          </li>
          <li>
            IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?<br />IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN' dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.
          </li>
          <li>
            Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?<br />Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.
          </li>
          <li>
            Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?<br />Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.
          </li>
          <li>
            Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?<br />Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.
          </li>
          <li>
            Doğru ürünleri almış olmama rağmen neden reddedildim?<br />Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.<br />Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.<br />Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (destek@pg35yil.firsatyaninda.com) bizimle iletişime geçiniz.
          </li>
          <li>
            Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?<br />Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.
          </li>
          <li>
            Satın alma belgemi e-posta ile de gönderebilir miyim?<br />Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.<br />Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.
          </li>
          <li>
            Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?<br />Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen iban@olimpiyat.firsatyaninda.com adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın P&amp;G 35. Yıl kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.
          </li>
          <li>
            Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?<br />Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.<br />Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.
          </li>
          <li>
            Kampanya web sitesine girdiğim verilerime ne olacak?<br />Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.<br />Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.<br />Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999">https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999</a> websitesinden).<br />Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.<br />Veri korumayla ilgili daha fazla bilgi <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml">https://www.pg.com/privacy/turkish/privacy_statement.shtml </a>adresinde bulunabilir.
          </li>
          <li>
            P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?<br />Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).<br /><a href="https://www.pg.com.tr/bize-ulasin/">https://www.pg.com.tr/bize-ulasin/</a>
          </li>
        </ol>
      </template>
      <template v-else-if="campaignName == 'dishekimlerio'">
        <ol>
          <li>
            <strong>P&amp;G Diş Hekimleri Özel Para İadesi kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?</strong>
            <ul>
              <li>Minimum Yaş: En az 18 yaşında olmalısınız.</li>
              <li>İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.</li>
              <li>IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.</li>
              <li>T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası</li>
              <li>Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.</li>
              <li>E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.</li>
            </ul>
            <p><strong>Not:</strong> Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
          </li>


          <li>
            <strong>P&amp;G Diş Hekimleri Özel Para İadesi kampanyasına</strong><strong>nasıl katılabilirim?</strong>
            <p>
              Yalnızca <strong><a
                href="https://www.firsatyaninda.com/dishekimlerio"
                target="_blank"
                rel="noopener noreferrer"
              >www.firsatyaninda.com/dishekimlerio</a> </strong>kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:
            </p>
            <ul>
              <li>
                Kampanya ürününün satın alınmasından sonra <strong><a
                  href="https://www.firsatyaninda.com/dishekimlerio"
                  target="_blank"
                  rel="noopener noreferrer"
                >www.firsatyaninda.com/dishekimlerio</a></strong>’e giriniz.
              </li>
              <li>Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).</li>
              <li>Sizinle paylaşılan kodu giriniz.</li>
              <li>Banka bilgilerini ve kişisel verileri sağlayınız.</li>
              <li>Katılım + veri koruma koşullarını kabul ediniz.</li>
            </ul>
          </li>


          <li>
            <strong>Geri ödememi ne zaman alabilirim?</strong>
            <p>Fişiniz/faturanız <strong>32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) <strong>4-5 hafta arasında</strong> aktarılacaktır.</p>
          </li>


          <li>
            <strong>P&amp;G Diş Hekimleri Özel Para İadesi kampanyasına</strong><strong>katılmak için hangi ürünleri satın almam gerekiyor?</strong>
            <p>Oral B iO serisi şarjlı diş fırçaları, Oral B Pro 3 serisi şarjlı diş fırçaları ve Aquacare Ağız duşu kampanya kapsamındadır.</p>
          </li>


          <li>
            <strong>P&amp;G Diş Hekimleri Özel Para İadesi kampanyasına katılan ürünleri nereden satın alabilirim?</strong>
            <p>Kampanya kapsamındaki ürünleri online ve offline tüm satış noktalarından alınabilir.</p>
          </li>


          <li>
            <strong>Ne kadar geri ödeme alacağım?</strong>
            <p>Kampanya ürününü satın aldığını ispat eden kullanıcı satın alım tutarının %15’i oranında para iadesi alır.</p>
          </li>


          <li>
            <strong>P&amp;G Diş Hekimleri Özel Para İadesi kampanyasına ne zaman katılabilirim?</strong>
            <p>Kampanya ürünlerini satın almak için kampanya dönemi 01.12.2022 ile 30.06.2023 tarihleri arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).</p>
            <p>Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 15.07.2023, saat 23:59’dur.</p>
          </li>


          <li>
            <strong>Kampanyaya ne sıklıkla katılabilirim?</strong>
            <p>Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.</p>
          </li>

          <li>
            <strong>Kampanyaya kaç ürün ile katılabilirim?</strong>
            <p><strong>P&amp;G Diş Hekimleri Özel Para İadesi </strong>kampanyasında yer alabileceğiniz maksimum ürün 3 adettir. 3’ten fazla ürün bulunan fiş/faturaların geri ödeme tutarının belirlenmesinde fiyatı en yüksek 3 ürün baz alınır.</p>
          </li>


          <li>
            <strong>Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?</strong>
            <p>Maalesef bu mümkün değildir. <strong>P&amp;G Diş Hekimleri Özel Para İadesi</strong> kampanyasına katılım, henüz başka P&amp;G kampanyalarına katılmamış ürünlerle sınırlıdır.</p>
          </li>


          <li>
            <strong>Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de P&amp;G Diş Hekimleri Özel Para İadesi kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?</strong>
            <p>Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.</p>
          </li>


          <li>
            <strong>Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?</strong>
            <p>Prensip olarak, bir satın alma kanıtı <strong>P&amp;G Diş Hekimleri Özel Para İadesi </strong>kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.</p>
          </li>


          <li>
            <strong>Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?</strong>
            <p>Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.</p>
            <p>10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <a href="http://www.firsatyaninda.com/dishekimlerio"><strong>www.firsatyaninda.com/</strong></a><strong>dishekimlerio</strong> üzerinden tekrar kayıt olunuz.</p>
          </li>


          <li>
            <strong>Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?</strong>
            <p>Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: <strong><a href="mailto:destek@dishekimlerio.firsatyaninda.com">destek@dishekimlerio.firsatyaninda.com</a></strong>.<br />Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.</p>
          </li>


          <li>
            <strong>IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?</strong>
            <p>IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN'dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.</p>
          </li>


          <li>
            <strong>Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?</strong>
            <p>Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.</p>
          </li>


          <li>
            <strong>Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?</strong>
            <p>Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.</p>
          </li>


          <li>
            <strong>Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?</strong>
            <p>Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.</p>
          </li>


          <li>
            <strong>Doğru ürünleri almış olmama rağmen neden reddedildim?</strong>
            <p>Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.</p>
            <p>Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.</p>
            <p>Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (<strong><a href="mailto:destek@dishekimlerio.firsatyaninda.com">destek@dishekimlerio.firsatyaninda.com</a></strong>) bizimle iletişime geçiniz.</p>
          </li>


          <li>
            <strong>Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?</strong>
            <p>Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.</p>
          </li>

          <li>
            <strong>Satın alma belgemi e-posta ile de gönderebilir miyim?</strong>
            <p>Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.</p>
            <p>Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.</p>
          </li>


          <li>
            <strong>Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?</strong>
            <p>Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen <strong>iban@dishekimlerio.firsatyaninda.com</strong> adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın <strong>P&amp;G Diş Hekimleri Özel Para İadesi</strong> kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.</p>
          </li>


          <li>
            <strong>Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?</strong>
            <p>Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.</p>
            <p>Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.</p>
          </li>


          <li>
            <strong>Kampanya web sitesine girdiğim verilerime ne olacak?</strong>
            <p>Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.</p>
            <p>Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.</p>
            <p>Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999">https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999</a> websitesinden).</p>
            <p>Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.</p>
            <p>Veri korumayla ilgili daha fazla bilgi <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml">https://www.pg.com/privacy/turkish/privacy_statement.shtml </a>adresinde bulunabilir.</p>
          </li>


          <li>
            <strong>P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?</strong>
            <p>Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).</p>
            <p><a href="https://www.pg.com.tr/bize-ulasin/">https://www.pg.com.tr/bize-ulasin/</a></p>
          </li>
        </ol>
      </template>
      <template v-else-if="campaignName == 'gilletteparaiadesi'">
        <ol>
          <li>
            <strong>P&amp;G Gillette Para iadesi kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?</strong>
            <ul>
              <li>Minimum Yaş: En az 18 yaşında olmalısınız.</li>
              <li>İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.</li>
              <li>IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.</li>
              <li>T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası</li>
              <li>Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.</li>
              <li>E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.</li>
            </ul>
            <p><strong>Not:</strong>Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
          </li>

          <li>
            <strong>P&amp;G Gillette Para iadesi kampanyasına nasıl katılabilirim?</strong>
            <p>
              Yalnızca <strong><a
                href="https://www.firsatyaninda.com/gilletteparaiadesi"
                target="_blank"
                rel="noopener noreferrer"
              >www.firsatyaninda.com/gilletteparaiadesi</a></strong> kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:
            </p>
            <ul>
              <li>
                Kampanya ürününün satın alınmasından sonra <strong><a
                  href="https://www.firsatyaninda.com/gilletteparaiadesi"
                  target="_blank"
                  rel="noopener noreferrer"
                >www.firsatyaninda.com/gilletteparaiadesi</a></strong>’e giriniz.
              </li>
              <li>Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).</li>
              <li>Banka bilgilerini ve kişisel verileri sağlayınız.</li>
              <li>Katılım + veri koruma koşullarını kabul ediniz.</li>
            </ul>
          </li>


          <li>
            <strong>Geri ödememi ne zaman alabilirim?</strong>
            <p>Fişiniz/faturanız <strong>32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) <strong>2-4 hafta arasında</strong> aktarılacaktır.</p>
          </li>


          <li>
            <strong>P&amp;G Gillette Para iadesi kampanyasına katılmak için hangi ürünleri satın almam gerekiyor?</strong> <br><br>
            <table style="margin-bottom: 25px;">
              <tbody>
                <tr>
                  <td class="207">
                    <p><strong><em>FPC Description</em></strong></p>
                  </td>
                  <td class="211">
                    <p><em></em></p>
                  </td>
                  <td class="206">
                    <p><em></em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUS5 ProShield MNL RAZOR</em></p>
                  </td>
                  <td class="211">
                    <p><em>FUSION5 MNL CRT 4CT</em></p>
                  </td>
                  <td class="206">
                    <p><em>MACH3 START 15CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION MANUAL 8 CT FFU Pack</em></p>
                  </td>
                  <td class="211">
                    <p><em>FUSION5 MNL CRT 2CT</em></p>
                  </td>
                  <td class="206">
                    <p><em>SENSOR3 16CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION POWER 8 CT FFU Pack</em></p>
                  </td>
                  <td class="211">
                    <p><em>FUSION5 MNL CRT 8CT ISD</em></p>
                  </td>
                  <td class="206">
                    <p><em>MACH3 START H+3 6CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION 16CT FFU Pack</em></p>
                  </td>
                  <td class="211">
                    <p><em>SENSOR3 8CT</em></p>
                  </td>
                  <td class="206">
                    <p><em>M3 START H+3</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>556 GIL SKINGUARD MNL CRT 2CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>560 MACH3 18 CT ECOMMERCE</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 GIL SKINGUARD ALOE V. MNL CRT 4CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>556 GIL SKINGUARD MNL CRT 4CT ISD</em></p>
                  </td>
                  <td class="211">
                    <p><em>560 MACH3 8 CT ECOMMERCE</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 MACH3 CRT 2CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION PROGLIDE 2CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>560 MACH3 TURBO 8 CT ECOMMERCE</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 MACH3 CRT 4CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION PROGLIDE POWER 2CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>M3 BASE H+5 TFF</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 MACH3 CRT 8CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION 4CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>M3 TURBO H+5 TFF</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 MACH3 CRT 12CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>M3 START 4CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>557 FUSION5 MANUAL 8 CT ECOM</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 MACH3 TURBO CRT 5CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>M3 START 2CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>557 FUSION PROGLIDE MANUAL 8 CT ECOM</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 MACH3 TURBO CRT 8CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>560 MACH3 TURBO CRT 2</em></p>
                  </td>
                  <td class="211">
                    <p><em>557 FUSION PROGLIDE POWER 8 CT ECOM</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 FUSION MNL CRT 2CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>560 MACH3 TURBO CRT 4</em></p>
                  </td>
                  <td class="211">
                    <p><em>557 FUSION5 MANUAL 16 CT ECOM</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 FUSION MNL CRT 4CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>560 MACH3 TURBO CRT 12 ISD</em></p>
                  </td>
                  <td class="211">
                    <p><em>557 FUS PROGLIDE MANUAL 14 CT ECOM</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 FUSION MNL CRT 8CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>560 MACH3 CRT 2</em></p>
                  </td>
                  <td class="211">
                    <p><em>557 MACH3 18 CT ECOM</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 PROGLIDE MNL CRT 2CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>560 MACH3 CRT 4</em></p>
                  </td>
                  <td class="211">
                    <p><em>FUSION5 START RAZOR 2UP</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 PROGLIDE MNL CRT 4CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>560 MACH3 CRT 12 ISD</em></p>
                  </td>
                  <td class="211">
                    <p><em>FUSION5 START CRT 4</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 PROGLIDE MNL CRT 8CT</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>PROGLIDE H+4 TR FOOTBAL BESIKTAS</em></p>
                  </td>
                  <td class="211">
                    <p><em>FUSION5 START CRT 8</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 GIL SKINGUARD ALOE V. RAZOR 2UP</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>M3 START 2CT X 10SRP</em></p>
                  </td>
                  <td class="211">
                    <p><em>557 MACH3 8 CT ECOM</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 MACH3 BASE RAZOR 2UP</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUS5 PROSHIELD CART 4CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>SENSOR3 8CT SENSITIVE</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 MACH3 TURBO RAZOR 2UP</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUS5 PROSHIELD CHILL CART 4CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>SENSOR3 H+6 SENSITIVE</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 PROGLIDE MNL RAZOR FRR</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSIONPOW BASE CARTRIDGE 4CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>SENSOR3 H+6 RED RAZOR</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 FUSION PWR RAZOR 1UP</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION PROGLIDE MANUAL 8 CT FFU Pack</em></p>
                  </td>
                  <td class="211">
                    <p><em>SENSOR3 8CT+8CT</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 FUSION5 MNL RAZOR 2UP</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION PROGLIDE POWER 8 CT FFU Pack</em></p>
                  </td>
                  <td class="211">
                    <p><em>SENSOR3 5CT</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 FUSION MNL H+4</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUS5 PROGLIDE PWR CRT 4CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>SENSOR3 H+6</em></p>
                  </td>
                  <td class="206">
                    <p><em>557 PROGLIDE MNL H+4</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUS5 PROGLIDE MNL CRT 8CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>FUS5 PROGLIDE MNL CRT 4CT</em></p>
                  </td>
                  <td class="206">
                    <p><em>MACH3 START H+3 (RED) EM</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUS5 PROGLIDE PWR CRT 2CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>MACH3 START LIGHT RAZOR 2UP</em></p>
                  </td>
                  <td class="206">
                    <p><em>MACH3 START H+3 (BLUE) EM</em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUS5 PROGLIDE MNL CRT 2CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>SENSOR3 8CT CS6 BL</em></p>
                  </td>
                  <td class="206">
                    <p><em></em></p>
                  </td>
                </tr>
                <tr>
                  <td class="207">
                    <p><em>FUSION5 PWR 2CT</em></p>
                  </td>
                  <td class="211">
                    <p><em>MACH3 START 10CT</em></p>
                  </td>
                  <td class="206">
                    <p><em></em></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>


          <li>
            <strong>P&amp;G Gillette Para iadesi kampanyasına katılan ürünleri nereden satın alabilirim?</strong>
            <p>Promosyon ürünleri yalnızca offline satış noktalarından alınabilir..</p>
          </li>


          <li>
            <strong>Ne kadar geri ödeme alacağım?</strong>
            <p>Kampanya ürününü satın aldığını ispat eden kullanıcı  200 TL ve üzeri Gillette ürünlerine 40 TL geri ödemeye hak kazanır. Maksimum 40 TL para iadesi yapılır.</p>
          </li>


          <li>
            <strong>P&amp;G Gillette Para iadesi kampanyasına ne zaman katılabilirim?</strong>
            <p>Kampanya ürünlerini satın almak için kampanya dönemi 01.02.2023 ile 31.05.2023 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).</p>
            <p>Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 31.05.2023, 23:59.</p>
          </li>


          <li>
            <strong>Kampanyaya ne sıklıkla katılabilirim?</strong>
            <p>Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.</p>
          </li>

          <li>
            <strong>Kampanyaya kaç ürün ile katılabilirim?</strong>
            <p><strong>P&amp;lG Gillette Para iadesi</strong> kampanyasında yer alabileceğiniz minimum veya maksimum sayıda ürün bulunmamaktadır.</p>
          </li>


          <li>
            <strong>Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?</strong>
            <p>Maalesef bu mümkün değildir. <strong>P&amp;G Gillette Para iadesi</strong> kampanyasına katılım, henüz başka P&amp;G  kampanyalarına katılmamış ürünlerle sınırlıdır.</p>
          </li>


          <li>
            <strong>Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de P&amp;G Gillette Para iadesi kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?</strong>
            <p>Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.</p>
          </li>


          <li>
            <strong>Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?</strong>
            <p>Prensip olarak, bir satın alma kanıtı <strong>P&amp;G Gillette Para iadesi</strong> kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.</p>
          </li>


          <li>
            <strong>Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?</strong>
            <p>Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.</p>
            <p>10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi  <a href="http://www.firsatyaninda.com/gilletteparaiadesi"><strong>www.firsatyaninda.com/gilletteparaiadesi</strong></a> üzerinden tekrar kayıt olunuz</p>
          </li>


          <li>
            <strong>Barkod numarası nedir ve onu nerede bulabilirim?</strong>
            <p>
              Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.
              Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.
            </p>
          </li>


          <li>
            <strong>Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?</strong>
            <p>
              Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz:
              <br>
              <a
                href="mailto:destek@gilletteparaiadesi.firsatyaninda.com"
                target="_blank"
              ><strong>destek@gilletteparaiadesi.firsatyaninda.com</strong></a>
              <br>
              Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.
            </p>
          </li>


          <li>
            <strong>IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?</strong>
            <p>IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN'dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.</p>
          </li>


          <li>
            <strong>Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?</strong>
            <p>Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.</p>
          </li>


          <li>
            <strong>Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?</strong>
            <p>Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.</p>
          </li>

          <li>
            <strong>Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?</strong>
            <p>Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.</p>
          </li>

          <li>
            <strong>Doğru ürünleri almış olmama rağmen neden reddedildim?</strong>
            <p>
              Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.
              Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.
              Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden <br>
              (<a
                href="mailto:destek@gilletteparaiadesi.firsatyaninda.com"
                target="_blank"
              ><strong>destek@gilletteparaiadesi.firsatyaninda.com</strong></a>) bizimle iletişime geçiniz.
            </p>
          </li>


          <li>
            <strong>Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?</strong>
            <p>Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.</p>
          </li>


          <li>
            <strong>Satın alma belgemi e-posta ile de gönderebilir miyim?</strong>
            <p>
              Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.
              Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.
            </p>
          </li>


          <li>
            <strong>Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?</strong>
            <p>
              Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen <br>
              <a
                href="mailto:iban@gilletteparaiadesi.firsatyaninda.com"
                target="_blank"
              ><strong>iban@gilletteparaiadesi.firsatyaninda.com</strong></a> adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın <strong>P&amp;G Gillette Para iadesi</strong> kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.
            </p>
          </li>


          <li>
            <strong>Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?</strong>
            <p>
              Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.
              Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.
            </p>
          </li>
          <li>
            <strong>Kampanya web sitesine girdiğim verilerime ne olacak?</strong>
            <p>Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.</p>
            <p>Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.</p>
            <p>Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. </p>
            <p>
              Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz <br>
              (<a
                href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&sub=9999"
                target="_blank"
              ><u>https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&sub=9999</u></a> websitesinden)
            </p>
            <p>Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.</p>
            <p>
              Veri korumayla ilgili daha fazla bilgi <a><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml</u></a> adresinde bulunabilir.
            </p>
          </li>
          <li>
            <strong>P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?</strong>
            Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45). <br>
            <a
              href="https://www.pg.com.tr/bize-ulasin/"
              target="_blank"
            >https://www.pg.com.tr/bize-ulasin/</a>
          </li>
        </ol>
      </template>
      <template v-else-if="campaignName == 'fairyparaiadesi'">
        <ol>
          <li>
            <p><strong>P&amp;G Fairy Para iadesi kampanyasına katılmak için hangi koşulları yerine getirmem gerekiyor?</strong></p>
            <ul>
              <li>
                <p>Minimum Yaş: En az 18 yaşında olmalısınız.</p>
              </li>
              <li>
                <p>İkamet Yeri: İkamet yeriniz Türkiye’de olmalıdır.</p>
              </li>
              <li>
                <p>IBAN - Hesap Bilgileri : Türkiye için geçerli bir IBAN girmeniz gereklidir.</p>
              </li>
              <li>
                <p>T.C. Kimlik Numarası: Kişinin kendi Türkiye Cumhuriyeti kimlik numarası</p>
              </li>
              <li>
                <p>Telefon Numarası: Sadece kendi telefon numaranız ile katılabilirsiniz.</p>
              </li>
              <li>
                <p>E-posta Adresi: Sadece kendi e-posta adresinizle katılabilirsiniz.</p>
              </li>
            </ul>
            <p><strong>Not:</strong> Aynı IBAN, kimlik numarası, telefon numarası veya e-posta adresine sahip birden fazla kişinin kampanyaya katılması mümkün değildir.</p>
          </li>
          <li>
            <p><strong>P&amp;G Fairy Para iadesi kampanyasına</strong><strong>nasıl katılabilirim?</strong></p>
            <p>
              Yalnızca <strong><a
                href="https://www.firsatyaninda.com/fairyparaiadesi"
                target="_blank"
                rel="noopener noreferrer"
              >www.firsatyaninda.com/fairyparaiadesi</a></strong> kampanya web sitesi üzerinden çevrimiçi olarak katılabilirsiniz. Maalesef posta yoluyla katılım mümkün değildir. Süreç şu şekilde tanımlanabilir:
            </p>
            <ul>
              <li>
                <p>
                  Kampanya ürününün satın alınmasından sonra <strong><a
                    href="https://www.firsatyaninda.com/fairyparaiadesi"
                    target="_blank"
                    rel="noopener noreferrer"
                  >www.firsatyaninda.com/fairyparaiadesi</a></strong>’e giriniz.
                </p>
              </li>
              <li>
                <p>Satın alma kanıtının fotoğrafını çekin ve yükleyiniz (YALNIZCA istek üzerine: kampanya ürünlerinin kendi fotoğrafını yükleyiniz).</p>
              </li>
              <li>
                <p>Banka bilgilerini ve kişisel verileri sağlayınız.</p>
              </li>
              <li>
                <p>Katılım + veri koruma koşullarını kabul ediniz.</p>
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Geri ödememi ne zaman alabilirim?</strong></p>
            <p>Fişiniz/faturanız <strong>32 saat içerisinde</strong> kontrol edilir ve onaylandığı koşulda para transferiniz belirttiğiniz banka hesabına (IBAN'a) <strong>2-4 hafta arasında</strong> aktarılacaktır.</p>
          </li>
          <li>
            <p><strong>P&amp;G Fairy Para iadesi kampanyasına</strong><strong>katılmak için hangi ürünleri satın almam gerekiyor?</strong></p>
            <p>Kampanyaya tüm Fairy ürünleri dahildir.</p>
          </li>
          <li>
            <p><strong>P&amp;G Fairy Para iadesi kampanyasına katılan ürünleri nereden satın alabilirim?</strong></p>
            <table class="dcf-table dcf-table-responsive dcf-table-bordered dcf-table-striped dcf-w-100%">
              <thead>
                <tr>
                  <th scope="col">
                    Satış Noktası Adı
                  </th>
                  <th scope="col">
                    Satış Noktası Açık Adresi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Bravo Pınar
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Gürselpaşa, Türkmenbaşı Blv. 28/A, 01120 Seyhan/Adana
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    SEYRANTEPE KARATAŞ AVM
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    KARATAŞ MAHALLESİ 103414 NOLU SOKAK SEBE SİTESİ ALTI NO 9/E ŞAHİNBEY /GAZİANTEP
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    EGE ÇARŞI/BORNOVA ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Kazımdirik, Mustafa Kemal Cd. No62, 35135 Bornova/İzmir
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    TAHTAKALE/ LİMAN ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Sarısu, Kemer Yolu , Akdeniz Blv. No624 DNo624, 07030 Konyaaltı
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÇELİKKAYALAR/MERKEZ AVM ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Nişantaşı Mah. Kemerli Cad. No:110 Z-01/B Selçuklu / Konya
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ACEM /CAMİALTI ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Gaziosmanpaşa Cd. No 179/A Eyüp Sultan Camii Altı Evka-1, 35100 Buca
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    TAHTAKALE/ GAZİ ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Gazi, 6583. Sk., 07320 Kepez/Antalya
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÇELİKKAYALAR/SELÇUKLU ŞUBESİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Dumlupınar, Lale Cd. No1, 42070 Horozluhan Osb/Selçuklu/Konya
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ALTUN GIDA İHT. TÜK. MAD. SAN. TİC.AŞ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Barış Mah.Sakarya Cad.No:2/10 BEYLİKDÜZÜ
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ANPA GROSS MAĞAZACILIK A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Güzelyurt Mah. Mustafa Kemal Cad. Carrefour Apt. 38.Sok. No:6/1 E-5 Karayolu Üzeri Haramidere ESENYURT
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    BİÇEN MAĞAZACILIK SAN. VE TİC. A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Büyükşehir mah.beylikdüzü cad.belicuim avm -1 zemin kat Beylikdüzü
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    BİÇEN MAĞAZACILIK SAN. VE TİC. A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mevlana Mah. Sultan Abdülhamid Cad. No:10A ESENYURT
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ULUKARDEŞLER GIDA İNŞ.SAN DIŞ. TİC. LTD. ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Alibeyköy Mah. Uludağ Cad. No:8 (İmar Blokları) EYÜP
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ULUKARDEŞLER GIDA İNŞ.SAN DIŞ. TİC. LTD. ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Hamidiye Mah. Girne Cad. No:25/A (Ulu Camii Altı) KAĞITHANE
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ANPA GROSS MAĞAZACILIK A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mahmutbey Merkez Mah. Taşocağı Yolu Cad. 212 İstanbul AVM BAĞCILAR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÖZEN ALIŞVERİŞ HİZMETLERİ A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Göztepe Mah. Batışehir Cad.G-1 Blok No:14 BAĞCILAR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ERSAN ALIŞVERİŞ HİZ.VE GIDA SAN.TİC.A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Fevzi Çakmak Mah. Osmangazi Cad. 2/17-C Sok. No:27 BAĞCILAR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ULUKARDEŞLER GIDA İNŞ.SAN DIŞ. TİC. LTD. ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Birlik Mah. Atışalanı Cad. No:165C-1 ESENLER
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÖZKURUŞLAR GIDA SAN. VE TİC. A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Esenşehir Mah. Kanuni Sok. No: 2 /127 - 51G ( SUR YAPI ŞEHİR KONAKLARI ) ÜMRANİYE
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    SEYHANLAR GIDA TEKSTİL TRİ.VE OTO SAN.TİC.LTD.ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mehmet Akif Mah.Şahin Cad.No:1 ÜMRANİYE
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ANPA GROSS MAĞAZACILIK A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Orta Mah. Yalnız Selvi Cad. Dış Kapı No:6 AVM İç Kapı No:23 Kartal
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ALTUN GIDA İHT. TÜK. MAD. SAN. TİC.AŞ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Feyzullah Mah. Atatürk Cad. No:35 MALTEPE
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ÖZKURUŞLAR GIDA SAN. VE TİC. A.Ş.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mevlana Mah. Kıbrıs Cad.No:2/1 ATAŞEHİR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    SEYHANLAR GIDA TEKSTİL TRİ.VE OTO SAN.TİC.LTD.ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Bulgurlu Mah.Bulgurlu Cad.No:115 ÜSKÜDAR
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    HAKMAR GIDA TURZ HAY İNŞ SAN LTD ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Kurtköy Mah. Ankara Cad. No:305 PENDİK
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    HAKMAR GIDA TURZ HAY İNŞ SAN LTD ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Çınardere Mah. Bergüzar Sok. No:2/A PENDİK
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    YUNUS MARKET İŞLETMELERİ TİC.A.Ş.(ANKARA)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    30 Ağustos Mah. Şehit Hasan Öztürk Cad. No:1/1-A Etimesgut / Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    ALDEMİR TOPTAN GIDA PAZ.SAN.TİC.LTD.ŞTİ. (TURUNCU AVM)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Battalgazi Mah. Yavuz Cad. No:20/A-B Melikgazi / Kayseri
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    GÜLMAR GIDA SAN.TİC.TAAH.LTD.ŞTİ.(CİTY GROSS)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Uğur Mumcu, Fatih Sultan Mehmet Blv No:308 06105 Ostim Osb/Yenimahalle/Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    NEV GIDA SAN.VE TİC.LTD.ŞTİ. (NEVGROSS)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    204. Cadde No:74 06370 - Macunköy / ANKARA
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    GROSS 38 TOP. VE PERAKENDE SAT. HİZ. SAN. TİC. LTD. ŞTİ.
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Mimarsinan Mah. Kocasinan Bulvarı No:144 Kocasinan / Kayseri
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    DURMAZLAR GIDA İNŞ.TEM.SAN.TİC.LTD.ŞTİ. (ELVANKENT)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Oğuzlar Mah. 1562 Cad. No:16/A Etimesgut / Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    DURMAZLAR GIDA İNŞ.TEM.SAN.TİC.LTD.ŞTİ. (HÜRRİYET)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Osmanlı Mah. Hürriyet Cad. No:69 Elvankent / Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    MİMARSİNAN PAZARI GIDA İHT.MAD.SAN. TİC. A.Ş. (ALP)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    M. Gazi Mah. M. Şimşek Cad. No:122/A Kayseri
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    İMES GROSS GIDA MAĞAZACILIK TİC.LTD.ŞTİ
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Andiçen Mah. Göktüğ Cad. No:2/4 Sincan / Ankara
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Özdilek (Kaplıkaya)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Siteler, Kanuni Cd., 16310 Yıldırım/Bursa
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Özdilek (Gemlik)
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Hisar, Bursa Yalova Yolu 1. km, 16600 Gemlik/Bursa
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Güvendik Istasyon
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    İstasyon, İstasyon Cd. No1, 25100 Yakutiye/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Güvendik Yenişehir
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Palandöken, 1. Sk., 25080 Palandöken/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Burak Gross
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Batman, Gap, 72070 Batman Merkez/Batman(Gap mah.Turgut Özal bulvarı No:348)
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Güvendik Kayakyolu
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Yunusemre, Tomurcuk Sk. No8, 25080 Palandöken/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Düzgün Şükrüpaşa-1
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Çaykur cad. Şükrüpaşa mah. No:42 Palandöken/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Worldmar
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Pınarbaşı,Turgut Özal Bulv. No:259, Merkez/Batman
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Düzgün Bölge Eğitim
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Alparslan Türkeş Bulvarı Adnan Menderes Mahallesi No142
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Öz Coşkunlar
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    ŞAFAK MAH. YENİ PTT BİNASI YANI NO:45 BATMAN/MERKEZ
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Düzgün Dadaşkent
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Saltuklu, Şht. Onbaşı Ömer Budak Cd., 25090 Gez Osb/Aziziye/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Sabah Market
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Gap, 2503. Sk., 72070 Batman Merkez/Batman
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Tema Dadaşkent
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Saltuklu, Prof. Dr. İhsan Doğramacı Blv., 25090 Gez Osb/Aziziye/Erzurum
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Yunmar
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    DİCLE KENT MAH. KAYAPINAR CAD. 260.SOKAK GAP SİTESİ C BLOK ALTI NO:55/A
                  </td>
                </tr>
                <tr>
                  <td data-label="Satış Noktası Adı">
                    Tema Lalapaşa
                  </td>
                  <td data-label="Satış Noktası Açık Adresi">
                    Lalapaşa, No, Kemeraltı Sk. No4, 25030 Yakutiye/Erzurum
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <p><strong>Ne kadar geri ödeme alacağım?</strong></p>
            <p>Kampanya ürününü satın aldığını ispat eden kullanıcı en fazla 1 kez katılarak fişinde/faturasında yer alan Fairy ürünlerinden %20 geri ödemeye hak kazanır. Maksimum 50 TL para iadesi yapılır.</p>
          </li>
          <li>
            <p><strong>P&G Fairy Para iadesi kampanyasına ne zaman katılabilirim?</strong></p>
            <p>Kampanya ürünlerini satın almak için kampanya dönemi 02.05.2023 ile 31.05.2023 arasındadır (fiş üzerindeki tarih bu kısımla ilgilidir).</p>
            <p>Çevrimiçi kayıt formunu göndermek ve satın alma kanıtını yüklemek için son tarih 15/06/2023, 23:59.</p>
          </li>
          <li>
            <p><strong>Kampanyaya ne sıklıkla katılabilirim?</strong></p>
            <p>Kampanyaya katılım kişi başına en fazla 1 kez (kullanıcı hesabı) ile sınırlıdır. Katılım maksimum 1 fiş/fatura ile gerçekleşebilir.</p>
          </li>
          <li>
            <p><strong>Kampanyaya kaç ürün ile katılabilirim?</strong></p>
            <p><strong>P&amp;G Fairy Para iadesi </strong>kampanyasında yer alabileceğiniz minimum veya maksimum sayıda ürün bulunmamaktadır.</p>
          </li>
          <li>
            <p><strong>Diğer P&amp;G kampanyalarına zaten katılmış olan ürünlerle katılabilir miyim?</strong></p>
            <p>Maalesef bu mümkün değildir. <strong>P&amp;G Fairy Para iadesi</strong> kampanyasına katılım, henüz başka P&amp;G kampanyalarına katılmamış ürünlerle sınırlıdır.</p>
          </li>
          <li>
            <p><strong>Partnerim ve benim ortak bir IBAN hesabımız var. İkimiz de P&amp;G Fairy Para iadesi kampanyasına bu hesapla ayrı ayrı katılabilir miyiz?</strong></p>
            <p>Her IBAN, tüm kampanya dönemi boyunca yalnızca bir kullanıcı tarafından kullanılabilir (IBAN, telefon numarası, TC kimlik numarası ve e-posta adresi sabit bir kombinasyon halindedir). Herhangi bir kişisel bağlantıya bakılmaksızın başka bir katılımcıyla ortak kullanım mümkün değildir.</p>
          </li>
          <li>
            <p><strong>Satın alma fişim katılım limitini aşan ürünler içeriyor. Bu satın alma belgesini iki kişi kullanabilir mi?</strong></p>
            <p>Prensip olarak, bir satın alma kanıtı <strong>P&amp;G Fairy Para iadesi </strong>kampanyamıza yalnızca bir kez katılabilir. Satın alma fişinde yer alan kampanyalı ürünün kapsamı dikkate alınmayacağı gibi, fazla miktar başka bir kişiye devredilemez.</p>
          </li>
          <li>
            <p><strong>Katılım sürecini tamamladıktan sonra bir onay e-postası almadım, şimdi ne olacak?</strong></p>
            <p>Kayıt olduğunuz anda, 10 basamaklı bir referans numarası içeren bir e-posta onayı alacaksınız. Lütfen SPAM veya JUNK klasörünü de kontrol ediniz.</p>
            <p>10 basamaklı bir referans numarası almadıysanız, bu, geri ödeme kaydının başarılı olmadığının bir göstergesidir. Bu durumda, lütfen kampanya web sitesi <a href="http://www.firsatyaninda.com/fairyparaiadesi"><u><strong>www.firsatyaninda.com/fairyparaiadesi</strong></u></a> üzerinden tekrar kayıt olunuz.</p>
          </li>
          <li>
            <p><strong>Barkod numarası nedir ve onu nerede bulabilirim?</strong></p>
            <p>Barkod numarası, ürün numarası anlamına gelir. Bu numarayı mağazalardaki tüm ürünlerde bulabilirsiniz. Standart bir barkod numarası, tire olmadan birlikte yazılan 13 rakamdan oluşur.</p>
            <p>Satın almış olduğunuz kampanya ürünlerinde genellikle barkod kodunu ilgili ürünün arkasında bulacaksınız.</p>
          </li>
          <li>
            <p><strong>Geri ödeme sürecinden geçerken işler belli bir noktada durur. Ne yapmalıyım?</strong></p>
            <p>Katılım sürecinde teknik nedenlerle daha ileri gitmemeniz gerekiyorsa, mümkünse başka bir cihaz kullanmanız önerilir. Örneğin, cep telefonunuzdan bilgisayarınıza geçiniz. Bu işe yaramazsa, farklı bir tarayıcı kullanmayı deneyebilirsiniz. Bu küçük ipuçları size yardımcı olmazsa, lütfen destek departmanımıza bir e-posta gönderiniz: <strong><a href="mailto:destek@fairyparaiadesi.firsatyaninda.com">destek@fairyparaiadesi.firsatyaninda.com</a></strong>.<br /> Lütfen e-postanızda sorunun tam bir tanımını yapınız - devam edemediğiniz noktanın ekran görüntüsünü de paylaşınız. Destek ekibine iletebileceğiniz diğer yararlı veriler, hangi cihaz, hangi işletim sistemi ve hangi tarayıcıyı kullandığınızdır. Bunları ilettikten sonra destek ekibimiz sizin yanınızda olacaktır.</p>
          </li>
          <li>
            <p><strong>IBAN’ ım kampanya sayfasında kabul edilmiyor, neden?</strong></p>
            <p>IBAN ınızı boşluksuz girdiğinizden emin olunuz. Lütfen başında ülke kodunu unutmayınız. Ayrıca IBAN'dan önce veya sonra yanlışlıkla boşluk eklemediğinizden emin olunuz.</p>
          </li>
          <li>
            <p><strong>Satın alma kanıtı yükleme işlemini başarıyla tamamladığımı nasıl anlarım?</strong></p>
            <p>Satın alma belgenizi yükledikten, kişisel bilgilerinizi ve banka bilgilerinizi girdikten sonra ekranda katılım girişiminizin başarısını onaylayan ve kişisel referans numaranızı gösteren bir mesaj görünecektir. Ayrıca, verdiğiniz e-posta adresine derhal bir onay veya red e-postası alacaksınız.</p>
          </li>
          <li>
            <p><strong>Satın alma belgem, tüm bilgiler görünmediği için reddedildi. Bu kapsamda hangi bilgiler kastedilmektedir?</strong></p>
            <p>Satış fişinde/faturasında, perakendeci (şube), kampanya ürünü/ürünleri, kampanya ürün(ler)inin satın alma fiyatı, satış fişinin toplam tutarı, satış fiş numarası ve tarih ve saat bilgileri bulunmaktadır. Bu bir fatura ise (çok sayfalı PDF dosyası), gerçekte gerekli verilerin bulunduğu sayfadan bağımsız olarak tüm sayfalar yüklenmelidir.</p>
          </li>
          <li>
            <p><strong>Satın alma belgemi kaybettim, yine de kampanyaya katılabilir miyim?</strong></p>
            <p>Maalesef bu mümkün değil - katılmak için satın alınan ve katılımcı ürünün açıkça görülebildiği satın alma kanıtının bir fotoğrafını yüklemelisiniz. Geri ödemenizi alana kadar satın alma belgenizi de saklamalısınız.</p>
          </li>
          <li>
            <p><strong>Doğru ürünleri almış olmama rağmen neden reddedildim?</strong></p>
            <p>Bunun çeşitli nedenleri olabilir, örneğin teknik bir hata olabilir, katılım limitinizin aşılmış olması veya satın alma kanıtınızın yeterince keskin olmaması olabilir.</p>
            <p>Reddetme e-postanızın alt kısmında, fişinizi/faturanızı yeniden yüklemek için genellikle bir buton bulacaksınız. Bu butona tıkladığınızda otomatik olarak satın alma fişini yeniden yüklemeye yönlendirileceksiniz. Lütfen satın alma belgenizin yeni bir fotoğrafını çekiniz ve 36 saat içinde tekrar yükleyiniz. Butonun 36 saat sonra işlevselliğini kaybettiğini unutmayınız.</p>
            <p>Katılımınız tekrar reddedilirse, lütfen ret e-postanızda da bulunan destek e-posta adresinden (<strong><a href="mailto:destek@fairyparaiadesi.firsatyaninda.com">destek@fairyparaiadesi.firsatyaninda.com</a></strong>) bizimle iletişime geçiniz.</p>
          </li>
          <li>
            <p><strong>Satın alma belgem kabul edilmedi ve hatta sahte olarak etiketlendi. Nasıl olabilir?</strong></p>
            <p>Satın alma kanıtınız çok bulanık veya çok karanlık veya aşırı pozlanmışsa, bu nedenle orjinalliği belirlenemiyorsa, satın alma kanıtının sahte olarak tanımlandığı veya bizden bir red e-postası alma olasılığınız vardır. Daha iyi çekilmiş bir satın alma belgenizi yükleyiniz. Satın alma fişinizin orijinal bir fatura olduğunu ve üzerinde gerekli tüm verilerin açıkça görülebildiğini gösteren kaliteli fotoğraflar yükleyerek (tarama şeklinde versiyonlar kabul edilmemektedir) katılımınızı hızlı ve sorunsuz bir şekilde kabul etmemize yardımcı olunuz.</p>
          </li>
          <li>
            <p><strong>Satın alma belgemi e-posta ile de gönderebilir miyim?</strong></p>
            <p>Satın alma kanıtının daha sonra sunulması, yalnızca reddetme e-postanızda bulabileceğiniz bir yeniden yükle butonu aracılığıyla gerçekleştirilebilir.</p>
            <p>Reddetme e-postası veya onay e-postası almadıysanız, bu, katılım girişiminizin başarısız olduğunu ve geri ödeme sürecini yeniden yapmanız gerektiğini gösterir.</p>
          </li>
          <li>
            <p><strong>Kayıt olurken yanlış IBAN girdim. Bu durumda ne olur?</strong></p>
            <p>Transferin gerçekleştirilmesi için, yanlış IBAN girdiyseniz, lütfen <strong>iban@fairyparaiadesi.firsatyaninda.com</strong> adresine e-posta gönderiniz. Sorumlu personel tarafından işleminiz devreye alınıp size geri dönüş yapılacaktır. Lütfen bu işlemin sadece 1 kez yapılabilir olduğunu unutmayın. Ayrıca girdiğiniz IBAN'ın <strong>P&amp;G Fairy Para iadesi</strong> kampanyamızın bir parçası olarak başka bir geri ödeme için başka bir e-posta ile birlikte kullanılmadığından emin olun.</p>
          </li>
          <li>
            <p><strong>Katılımımı posta yoluyla göndermem istendi. Bu ne anlama geliyor?</strong></p>
            <p>Tüm fişlerin orijinalliğini iyice kontrol etmek zorunda olduğumuzu anlamanızı rica ediyoruz. Elbette, bir satış fişinin yanlış bir şekilde reddedilme ihtimali vardır.Bu durumda, orjinal satın alma kanıtınızı posta yoluyla Justsnap Teknoloji Ticaret A.Ş. göndermenizi rica ederiz. Daha sonra ilgili adres size e-posta ile verilecektir.</p>
            <p>Katılımınız nihayet geçerli olarak değerlendirilirse, tabii ki size hem geri ödeme tutarını hem de posta masraflarını aktaracağız.</p>
          </li>
          <li>
            <p><strong>Kampanya web sitesine girdiğim verilerime ne olacak?</strong></p>
            <p>Çevrimiçi kayıt formunu gönderdiğinizde, gönüllü olarak sağladığınız kişisel verileriniz Procter &amp; Gamble A.Ş, Nida Kule Kuzey Sit. C Kapısı Barabaros Mah. Begonya Sok. No 3E 34746 , Ataşehir, İstanbul adresinin veritabanında saklanacaktır. ("P&amp;G") promosyonu gerçekleştirmek için veriler saklanır.</p>
            <p>Reklam izninizi verdiyseniz - örneğin haber bültenine abone olarak - kişisel verileriniz yalnızca P&amp;G tarafından daha fazla reklam amacıyla kullanılacaktır. P&amp;G hiçbir koşulda verilerinizi üçüncü şahıslara aktarmayacaktır.</p>
            <p>Reklam izninizi vermek isteğe bağlıdır ve kampanyanın uygulanmasına hiçbir etkisi yoktur. Reklam onayınızı dilediğiniz zaman kısmen veya tamamen iptal edebilirsiniz (<a href="https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999"><u>https://unsubscribe.pg.com/privacy/optsengine/tr_TR/cot_std_brandopts.do?brand=goldenhousehold&amp;sub=9999</u></a> websitesinden).</p>
            <p>Kampanyanın sona ermesinden sonra, yasal saklama gereksinimlerini karşılamak için daha fazla depolama gerekmedikçe veya reklamcılık izninize bağlı olarak istenmedikçe verileriniz silinecektir.</p>
            <p>Veri korumayla ilgili daha fazla bilgi <a href="https://www.pg.com/privacy/turkish/privacy_statement.shtml"><u>https://www.pg.com/privacy/turkish/privacy_statement.shtml </u></a>adresinde bulunabilir.</p>
          </li>
          <li>
            <p><strong>P&amp;G hakkında genel bir sorum var. Kiminle konuşmalıyım?</strong></p>
            <p>Lütfen 0850 220 09 11 veya 0216 463 83 83 numaralı telefondan Türkiye için P&amp;G müşteri hizmetleriyle iletişime geçiniz (Pazartesi - Cuma, 08:45 - 16:45).</p>
            <p><a href="https://www.pg.com.tr/bize-ulasin/">https://www.pg.com.tr/bize-ulasin/</a></p>
          </li>
        </ol>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "Faq",
  props: {
    campaignName: {
      type: String
    },
    title: {
      type: String
    },
    colorSchema: {
      type: String,
      default: "#000"
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 5px;
  word-wrap: anywhere !important;
}
.page-title {
  width: 100%;
  text-align: center;
  color: var(--color);
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  margin: 30px;
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.box-notice {
  background-color: #DFDFDF;
  border: 1px solid black;
  padding: 15px;
  margin: 5px;
  color: #3D3D41;
}
.list {
  list-style-type: decimal;
  word-break: break-word;
  margin: 10px 4px;
  li::marker {
    font-size: 26px;
    font-weight: 700;
    color: var(--color);
  }
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding-left: 20px;
  padding-right: 20px;
}
.number {
  color: var(--color);
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  display: inline;
  margin-right: 5px;
  p {
      display: inline;
    }
}
p {
  margin-bottom: 5px;
}
.list-item-wrapper {
  margin-top: 24px;
  margin-right: 5px;
  white-space: initial;
  color: var(--color);
  .list-item {
    font-weight: 400;
    white-space: initial;
    font-size: 16px;
    line-height: 24px;
  }
  .list-item-details {
    margin-top: 10px;
    text-justify: auto;
    a {
      text-decoration: underline;
    }
  }
}
a {
    text-decoration: underline !important;
  }
</style>
