<template>
  <p :id="fieldId" :class="classString" :style="`${cssString}${color ? `color: ${color}`: ''}`" @input="updateComponent" v-html="html" @click="triggerActions"></p>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "paragraph",
  props: {
    fieldId:{
      type:String,
      required: false
    },
    text: {
      type: String,
      default: "Empty paragraph"
    },
    grid: {
      type: Object,
      default: () => ({
        backgroundColor: "#fff"
      })
    },
    actions: {
      type: Array
    },
    variables: Object,
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    color: String,
    classes: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      cssString: "",
      classString:""
    };
  },
  computed: {
    html () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state.formData[variable];
          if(variable === "referenceNumber") {
            value = this.$store.state.referenceNumber
          }
          result = result.replace(new RegExp(`{${variable}}`, "g"), value);
        }
      }
      return result;
    }
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx] + ";"));
      }
    },
    classes: {
      immediate: true,
      handler () {
        this.classes.forEach(cls=>{
          this.classString += cls+" ";
        })
      }
    }
  },
  methods: {
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  outline: 0;
}

.heading {
  font: 32px/34px Anton;
  letter-spacing: 0px;
  color: var(--dark-gray);
  opacity: 1;

  padding-top: 50px;

  @media (max-width: 500px) { font: normal normal 24px/34px Anton; padding-bottom: 0px !important; padding-top: 25px !important }
}

.subHeading {
  font: normal normal 500 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  @media (max-width: 500px) { font: normal normal 400 16px/19px Montserrat; }
}
.rewardSub{
  font-size: 20px;
}
.smallSubHeading {
  font: normal normal 500 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  @media (max-width: 500px) { font: normal normal 400 10px/13px Montserrat; }
}

.formTitle {
  font: normal normal 600 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  @media (max-width: 991px) { padding-left: 10px;}
}

.loginText {
  font: normal normal 500 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-align: center;

  @media (max-width: 991px) { font: normal normal 500 14px/18px Montserrat !important;  }
}

.stepCard {
  text-align: center;
  font: normal normal 600 18px/20px Montserrat;
  letter-spacing: 0px;
  color: #1C3A68;
  opacity: 1;

  // margin-left : 13%;
  @media (max-width: 500px) { font: normal normal 600 13px/15px Montserrat !important; }
}

.ibanLabel {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  letter-spacing: 0px;
  color: #1B1B1B;
  border-radius: 6px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
